import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl'
import {BACKEND_URL} from '../../../../utils/constants'
import prependHttp from 'prepend-http';
import {apiGetVCardURL} from "../../../../api/api"
import {ClipLoader} from "react-spinners";
import Envelope from "./icons/Envelope";
import Web from "./icons/Web";
import Info from "./icons/Info";
import Location from "./icons/Location";
import Phone from "./icons/Phone";
import Case from "./icons/Case";

function DigitalCard({slug, cardData}) {
    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState(false)

    const [data, setData] = useState(cardData || null)
    const [image, setImage] = useState(cardData?.image || null)
    const [items, setItems] = useState(cardData?.items || [])

    function formatForURL(str) {
        return str.replaceAll(/(?:\r\n|\r|\n|\s)/g, '+');
    }

    useEffect(() => {
        if (slug) {
            const url = BACKEND_URL + '/digital-cards-by-slug/' + slug
            fetch(url)
                .then(res => {
                    if (res.status === 404) {
                        setNotFound(true);
                        throw new Error(res.status + " Page not found ");
                    }

                    return res.json();
                })
                .then((result) => {
                    setData(result);
                    setItems(result.items)
                    setImage(result.image)
                }).catch((error) => {
                })
        }
    }, []);

    useEffect(() => {
        setData(cardData)
        setImage(cardData?.image)
        setItems(cardData?.items ?? [])
    }, [cardData])

    async function saveVCard() {
        setLoading(true)
        const response = await apiGetVCardURL(data);
        setLoading(false)

        window.open(response.url);
    }

    const RedTheme = React.lazy(() => import('./themes/redTheme'));
    const GreenTheme = React.lazy(() => import('./themes/greenTheme'));
    const BlackTheme = React.lazy(() => import('./themes/blackTheme'));
    const BlueTheme = React.lazy(() => import('./themes/blueTheme'));

    return (
        <>
            {data &&
                <div id="micro-page-wrapper" className={data.theme}>
                    <div className="digital-card-page-wrapper">
                        <div className={`digital-card`}>
                        <React.Suspense fallback={<></>}>
                            {(data.theme === 'red') && <RedTheme/>}
                            {(data.theme === 'green') && <GreenTheme/>}
                            {(data.theme === 'black') && <BlackTheme/>}
                            {(data.theme === 'blue') && <BlueTheme/>}
                        </React.Suspense>
                        {data.image &&
                            <div className="digital-card__avatar">
                                <img className="image" src={data.image.url} alt="Avatar" style={{
                                    'border-radius': '50%'
                                }}/>
                            </div>
                        }
                        <div className="digital-card__title">{data.fullName}</div>
                        {
                            data.title &&
                            <div className="digital-card__subtitle">{data.title}</div>
                        }
                        <div className="digital-card__save-btn">
                            <button className="action-button position-relative" onClick={() => {
                                saveVCard()
                            }}>
                                <FormattedMessage id="microweb.digitalCard.save"/>
                                {/* Loader */}
                                {loading && <div style={{
                                    position: 'absolute',
                                    right: '-10px',
                                    top: '11px'
                                }} className="px-5">
                                    {/*<Loader color={'#fa4c4c'}/>*/}
                                    <ClipLoader size={15} color={'white'}/>
                                </div>
                                }
                            </button>
                        </div>
                        {
                            data.about &&
                            <div className="digital-card__info" style={{
                                alignItems: 'baseline'
                            }}>
                                <div className="digital-card__info-icon">
                                    <Info/>
                                </div>
                                <div className="digital-card__info-text">{data.about}</div>
                            </div>
                        }
                        {
                            data.phone &&
                            <div className="digital-card__info">
                                <div className="digital-card__info-icon">
                                    <Phone/>
                                </div>
                                <div className="digital-card__info-text">{data.phone}</div>
                            </div>
                        }
                        {
                            data.email &&
                            <div className="digital-card__info">
                                <div className="digital-card__info-icon">
                                    <Envelope/>
                                </div>
                                <div className="digital-card__info-text">{data.email}</div>
                            </div>
                        }
                        {
                            data.organization &&
                            <div className="digital-card__info">
                                <div className="digital-card__info-icon">
                                    <Case/>
                                </div>
                                <div className="digital-card__info-text">{data.organization}</div>
                            </div>
                        }
                        {
                            data.website && (prependHttp(data.website) !== prependHttp('')) &&
                            <div className="digital-card__info">
                                <div className="digital-card__info-icon">
                                    <Web/>
                                </div>
                                <div className="digital-card__info-text">{data.website}</div>
                            </div>
                        }
                        {
                            data.address &&
                            <div className="digital-card__info" style={{
                                alignItems: 'baseline'
                            }}>
                                <div className="digital-card__info-icon">
                                    <Location/>
                                </div>
                                <div className="digital-card__info-text">
                                    <span style={{whiteSpace: "pre-wrap"}}>
                                        {data.address}
                                    </span>
                                    <a href={`https://www.google.com/maps/search/${formatForURL(data.address)}`}
                                       target="_blank"><FormattedMessage id="microweb.digitalCard.openMap"/></a>
                                </div>
                            </div>
                        }
                        <div className="digital-card__socials">
                            {
                                items.map((item, key) => {
                                    return (
                                        <a key={key} href={prependHttp(item.url)} target="_blank">
                                            <img src={`/assets/icons/${item.type}.svg`} alt={item.type}/>
                                        </a>
                                    )
                                })
                            }
                        </div>
                    </div>
                    </div>

                </div>
            }
        </>
    )
}

export default DigitalCard;
