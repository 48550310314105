import React, {useState} from "react";
import {ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {Link} from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import {downloadQr} from "../../utils/exportGenerator";
import {getTemplateType} from "../../../store/card/cardSelectors";
import {updateUserValues} from "../../../store/order/orderActions";
import {setActiveLayer} from "../../../store/card/cardActions";
import {TYPES} from "../../../utils/constants";

const ExportButton = ({templateType, onUpdateUserValues, onSetActiveLayer}) => {
	const [dropdownOpen, setOpen] = useState(new URLSearchParams(window.location.search).get("action") === 'export' ? true : false)

	return (
		<div id="export">
			<div className="align-right header-right">
				{templateType !== TYPES.QR_ONLY ?
					<Link to="/export">
						<ButtonDropdown className="export" onClick={() => onUpdateUserValues()} toggle={() => {}}>
							<DropdownToggle className="export-button green-button">
								<FormattedMessage id="header.export"/>
								<svg className="arrow-right" width="14" height="9" viewBox="0 0 14 9">
									<path d="M0.463867 4.17276H8.64569" stroke="white" strokeWidth="1.5"/>
									<path fillRule="evenodd" clipRule="evenodd"
											d="M8.31836 0.0820312L13.2275 4.17294L8.31836 8.26385V0.0820312Z"
											fill="white"/>
								</svg>
							</DropdownToggle>
						</ButtonDropdown>
					</Link> :
					<ButtonDropdown className="download-qr" isOpen={dropdownOpen} toggle={() => setOpen(!dropdownOpen)}>
						<DropdownToggle onClick={() => onSetActiveLayer(null)} className='download-button green-button'>
							<FormattedMessage id="export.download.download" />
							<svg className='export-arrow-down' width="9" height="7" viewBox="0 0 9 7" fill="none"
									xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd"
										d="M7.94254 0.0732422C8.77697 0.0732422 9.24438 1.03493 8.72885 1.69106L5.28632 6.07247C4.88594 6.58205 4.11406 6.58204 3.71368 6.07247L0.271145 1.69106C-0.244386 1.03493 0.223032 0.0732422 1.05746 0.0732422L7.94254 0.0732422Z"
										fill="#FFFFFF"/>
							</svg>
							<ReactSVG className="export-loader-icon" src="/assets/icons/loader.svg" />
						</DropdownToggle>
						<DropdownMenu>
							<DropdownItem onClick={() => downloadQr("svg")}>
								SVG
							</DropdownItem>
							<DropdownItem onClick={() => downloadQr("png", 1000)}>
								PNG 1000 x 1000 px
							</DropdownItem>
							<DropdownItem onClick={() => downloadQr("png", 2000)}>
								PNG 2000 x 2000 px
							</DropdownItem>
							<DropdownItem onClick={() => downloadQr("jpeg", 1000)}>
								JPEG 1000 x 1000 px
							</DropdownItem>
							<DropdownItem onClick={() => downloadQr("jpeg", 2000)}>
								JPEG 2000 x 2000 px
							</DropdownItem>
						</DropdownMenu>
					</ButtonDropdown>
				}
			</div>
		</div>
	)
}

const mapState = (state) => ({
	templateType: getTemplateType(state),
})

const mapDispatch = (dispatch) => ({
	onUpdateUserValues: () => dispatch(updateUserValues()),
	onSetActiveLayer: (layer) => dispatch(setActiveLayer(layer)),
})

// @ts-ignore
export default connect(mapState, mapDispatch)(ExportButton)
