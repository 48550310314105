import React, {useEffect, useState} from "react";
import {ReactComponent as Cross} from "../../../common/images/elements/cross.svg";
import {useForm} from "react-hook-form";
import Item from "./Item";
import {connect} from "react-redux";
import PlusButton from "../PlusButton";
import './FeedbackForm.scss'
import Select from "react-select";
import Errors from "../Errors";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import AddImage from "../AddImage";
import Loader from "../../../common/Loader";
import { nanoid } from 'nanoid'
import ThemePicker from "../ThemePicker";
import {setFeedbackForm} from "../../../../../../store/microPage/microPageActions";
import {getFeedbackForm} from "../../../../../../store/microPage/microPageSelector";

function FeedbackForm(props) {

    // Mounted: populate from redux into validation (useForm)
    useEffect(() => {
        setValue('name', props?.feedbackForm?.name ?? '')
        props.setDynamicQrType({ "FeedbackForm": props.feedbackForm })
    }, [])

    const [image, setImage] = useState(props?.feedbackForm?.image)
    const [loading, setLoading] = useState(false)
    const [outputFormat, setOutputFormat] = useState('email')
    const {register, watch, setValue, formState, handleSubmit} = useForm()

    const [items, setItems] = useState(props?.feedbackForm?.items ?? []);

    const [theme, setTheme] = useState(props?.feedbackForm?.theme ?? 'brown');

    useEffect(() => {
        handleSaveFeedbackFormData({ ...props.feedbackForm, items, image, theme })
    }, [items, image, theme]);

    function handleSaveFeedbackFormData(data) {
        props.saveLocallyFeedbackForm(data, 'update');
        props.setDynamicQrType({ "FeedbackForm": data })
    }

    function handleUpdate(e) {
        const value = e.target.value

        handleSaveFeedbackFormData({ ...props.feedbackForm, name: value })
    }

    const formatOptions = [
        {value: 'excel', label: 'Save replies in my account'},
        {value: 'email', label: 'Save replies in my account & send email'},
    ]

    async function onSubmit(data) {

    }

    function onError(data) {
        console.log(data, 'onError');
    }

    async function addEmptyItem() {
        await setItems([...items, {
            id: nanoid(),
            question: 'Type text',
            type: 'text',
        }])
    }

    function updateItem(updatedItem) {
        const newItems = [...items];

        const index = newItems.findIndex((item) => item.id === updatedItem.id)

        newItems[index] = updatedItem
        setItems(newItems)
    }

    function deleteItem(id) {
        setItems([...items.filter((item) => item.id !== id)])
    }

    const customStyles = {
        singleValue: (provided, state) => ({
            ...provided,
            color: '#38517c',
            fontWeight: 'bold',
        }),
        container: (provided, state) => ({
            ...provided,
            width: '100%',
            paddingRight: '6px',
        }),
        control: (provided, state) => ({
            ...provided,
            border: 'none',
            backgroundColor: 'white',
            boxShadow: 'none',
            cursor: 'pointer'
        }),
        option: (provided, {data, isDisabled, isFocused, isSelected}) => ({
            ...provided,
            border: 'none',
            backgroundColor: isFocused
                ? '#ebf3ff'
                : '',
            cursor: 'pointer',
            color: '#38517c',
        }),
    }

    function handleSelectChange(option) {
        setOutputFormat(option.value);
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const newItems = Array.from(items);
        const [reorderedItem] = newItems.splice(result.source.index, 1)
        newItems.splice(result.destination.index, 0, reorderedItem)

        setItems(newItems);
    }

    function onImageChange(image) {
        setImage(image);
    }

    function onLoadingChange(value) {
        setLoading(value)
    }


    const themesOptions = [
        {
            value: 'brown',
            icon: '/assets/icons/theme_feedback_form_brown.svg'
        },
        {
            value: 'blue',
            icon: '/assets/icons/theme_feedback_form_blue.svg'
        },
        {
            value: 'black',
            icon: '/assets/icons/theme_feedback_form_black.svg'
        },
        {
            value: 'green',
            icon: '/assets/icons/theme_feedback_form_green.svg'
        }
    ]

    function handleThemeChange(theme) {
        setTheme(theme);
    }

    return (
        <div className="feedback-form">
            <form onSubmit={handleSubmit(onSubmit, onError)}>

                {/* name */}
                <div className="format-container">
                    <div className="inputs-container">
                        <div className="input-container">
                            <input type="text" placeholder="Company or questionnaire name"
                                   {...register("name", {required: true, minLength: 0})} onChange={(e) => handleUpdate(e)}/>
                            <Cross className={watch("name") ? '' : 'hidden'} onClick={() => setValue('name', '')}/>
                        </div>
                        <Errors formState={formState} name="name"/>

                    </div>
                </div>

                {/* TODO: make useable component, only dragHandle style is different  */}
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <div className="mt-4">
                        <Droppable droppableId="characters">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {items.map((item, key) => {
                                        return (
                                            <Draggable draggableId={item.id} index={key} key={item.id}
                                                       style={{position: 'relative'}}>
                                                {(provided) => (
                                                    <div className="p-2 mt-4" {...provided.draggableProps}
                                                         ref={provided.innerRef}>
                                                        <div className="position-relative">
                                                            <img {...provided.dragHandleProps}
                                                                 src="/assets/icons/drag.svg" alt="" width="22px"
                                                                 style={
                                                                     {
                                                                         position: 'absolute',
                                                                         right: '-10px',
                                                                         top: '8px',
                                                                         zIndex: '1',
                                                                     }
                                                                 }
                                                            />
                                                        </div>

                                                        <Item
                                                            key={item.id}
                                                            item={item}
                                                            itemField="question"
                                                            onUpdate={updateItem}
                                                            onDelete={deleteItem}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                </DragDropContext>

                {/* add item */}
                <div className="inputs-container d-inline-block mt-3">
                    <div className="add-file-input" onClick={(e) => addEmptyItem()}>
                        <div
                            className="button my-1 add-file-button"
                            src="/assets/icons/plus.svg">
                            <PlusButton/>
                        </div>
                    </div>
                </div>

                {/* output format */}
                <div className="format-container">
                    <div className="inputs-container">
                        <div className="input-container py-0">
                            <Select
                                styles={customStyles}
                                onChange={handleSelectChange}
                                isSearchable={false} defaultValue={formatOptions[0]} options={formatOptions} components={{
                                IndicatorSeparator: () => null,
                                // DropdownIndicator: () => <img className='blue-image' src="assets/icons/arrow_down.svg" width="8" alt=""/>,
                            }}/>
                        </div>

                    </div>
                </div>

                {/* Upload Image */}
                <AddImage
                    onImageChange={onImageChange}
                    onLoadingChange={onLoadingChange}
                    hideCheckbox={true}
                    aspect={3 / 2}
                    removeRadius={true}
                />

                {/* Loader */}
                {loading &&
                    <Loader loading={loading}/>
                }

                {/* Theme select */}
                <ThemePicker value={theme} options={themesOptions} onChange={handleThemeChange}/>
            </form>
        </div>
    )
}

const mapState = (state) => ({
    feedbackForm: getFeedbackForm(state),
})

const mapDispatch = (dispatch) => ({
    saveLocallyFeedbackForm: (page, status) => dispatch(setFeedbackForm(page, status)),
})

export default connect(mapState, mapDispatch)(FeedbackForm)
