// @flow
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import TemplatesList from './customization/TemplatesList'
import CustomizeSection from './customization/CustomizeSection'
import {getActiveDesign, getActiveTemplate, getFirstTemplateByTypeAndSubtype} from '../../store/card/cardSelectors'
import Editor from "./customization/Editor";
import PageLayout from "../layout/PageLayout";
import {setActiveDesign, setActiveTemplate} from "../../store/card/cardActions";
import {createDesignObjectFromTemplate} from "../utils/designObjectGenerator";
import LiveMicrowebPreview from "./customization/qrcode/formatTab/LiveMicrowebPreview";

const EditorPage = ({ design, initialTemplate, onSetActiveDesign, template, onSetActiveTemplate }) => {
  const [isSticker, setIsSticker] = useState(false)
  const [dynamicQrType, setDynamicQrType] = useState("")

  /*
   This component is responsible to initiate design and template so that whole EditorPage works with non-nullable values
   */
  useEffect(() => {
    if (!design) {
      onSetActiveDesign(createDesignObjectFromTemplate(initialTemplate));
    }
  }, [design])

  const hideLivePreview = () => {
    setDynamicQrType(false)
  }

  return (
    <PageLayout>
      <TemplatesList onChange={hideLivePreview} />
        {design &&
          <>
            <CustomizeSection setDynamicQrType={setDynamicQrType}/>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  {!dynamicQrType ?
                    <div className="customization-page">
                      <div className="row">
                        <Editor design={design} isSticker={isSticker} setIsSticker={setIsSticker}/>
                      </div>
                    </div> : 
                      <>
                        <Editor design={design} isSticker={isSticker} setIsSticker={setIsSticker} isHidden/>
                        <LiveMicrowebPreview data={dynamicQrType}/>
                      </>
                  }
                </div>
              </div>
            </div>
          </>
        }
    </PageLayout>
  )
}

const mapState = (state) => ({
  design: getActiveDesign(state),
  template: getActiveTemplate(state),
  // todo: it should be used only in some cases
  initialTemplate: getFirstTemplateByTypeAndSubtype(state),
})

const mapDispatch = (dispatch) => ({
  onSetActiveDesign: (layer) => dispatch(setActiveDesign(layer)),
  onSetActiveTemplate: (layer) => dispatch(setActiveTemplate(layer)),
})

export default connect(mapState, mapDispatch)(EditorPage)
