import $ from 'jquery'

$(document).ready(function () {
  var $window = $(window)

  const targetNode = document.getElementById('template')

  // Options for the observer (which mutations to observe)
  const config = { attributes: true, childList: true, subtree: true, characterData: true }

  const callback = function (mutationsList, observer) {
    // Use traditional 'for loops' for IE 11
    for (const mutation of mutationsList) {
      // console.log(mutation)
      if (mutation.type === 'attributes' && (mutation.attributeName === 'class' || mutation.attributeName === 'style')) {
        // console.log(mutation.type, mutation.attributeName)
        $('#template .background').backgroundDraggable({ bound: true })
        // console.log('target: ', $('#template .svg-background svg'))
        $('#template .svg-background svg').backgroundDraggable({ bound: true, svg: true })
      }
    }
  }

  const observer = new MutationObserver(callback)

  // Start observing the target node for configured mutations
  try {
    observer.observe(targetNode, config);
  } catch(err) {
    console.log(err)
  }

  // Helper function to guarantee a value between low and hi unless bool is false
  var limit = function (low, hi, value, bool) {
    if (arguments.length === 3 || bool) {
      if (value < low) return low
      if (value > hi) return hi
    }
    return value
  }

  // Adds clientX and clientY properties to the jQuery's event object from touch
  var modifyEventForTouch = function (e) {
    e.clientX = e.originalEvent.touches[0].clientX
    e.clientY = e.originalEvent.touches[0].clientY
  }

  var getBackgroundImageDimensions = function ($el) {
    var bgSrc = ($el.css('background-image').match(/^url\(['"]?(.*?)['"]?\)$/i) || [])[1]
    if (!bgSrc) return

    // console.log('bgSrc: ', bgSrc);

    var imageDimensions = { width: 0, height: 0 },
      image = new Image()

    image.onload = function () {
      if ($el.css('background-size') === 'cover') {
        var elementWidth = $el.innerWidth(),
          elementHeight = $el.innerHeight(),
          elementAspectRatio = elementWidth / elementHeight,
          imageAspectRatio = image.width / image.height,
          scale = 1
        // console.log('elementWidth: ', elementWidth);
        // console.log('elementHeight: ', elementHeight);
        // console.log('elementAspectRatio: ', elementAspectRatio);
        // console.log('imageAspectRatio: ', imageAspectRatio);

        if (imageAspectRatio >= elementAspectRatio) {
          scale = elementHeight / image.height
        } else {
          scale = elementWidth / image.width
        }

        // console.log('scale: ', scale);

        imageDimensions.width = image.width * scale
        imageDimensions.height = image.height * scale
      } else {
        imageDimensions.width = image.width
        imageDimensions.height = image.height
      }
    }

    image.src = bgSrc

    // console.log('imageDimensions: ', imageDimensions);

    return imageDimensions
  }

  function Plugin(element, options) {
    this.element = element
    this.options = options
    this.init()
  }

  Plugin.prototype.init = function () {
    var $el = $(this.element),
      bgSrc = ($el.css('background-image').match(/^url\(['"]?(.*?)['"]?\)$/i) || [])[1],
      options = this.options,
      $elPath = $('#template .svg-background svg path')

    if (!bgSrc) return

    // Get the image's width and height if bound
    var imageDimensions = { width: 0, height: 0 }
    if (options.bound) {
      imageDimensions = getBackgroundImageDimensions($el)
    }

    if (options.svg) {
      $elPath.on('mousedown.dbg touchstart.dbg', function (e) {
        // if (e.target !== $el[0]) {
        //   return;
        // }
        e.preventDefault()

        if (e.originalEvent.touches) {
          modifyEventForTouch(e)
        } else if (e.which !== 1) {
          return
        }

        var x0 = e.clientX,
          y0 = e.clientY,
          pos = $el.css('background-position').match(/(-?\d+).*?\s(-?\d+)/) || [],
          xPos = parseInt(pos[1]) || 0,
          yPos = parseInt(pos[2]) || 0

        // console.log('x0: ', x0);
        // console.log('y0: ', y0);
        // console.log('pos: ', pos);
        // console.log('xPos: ', xPos);
        // console.log('yPos: ', yPos);

        $window.on('mousemove.dbg touchmove.dbg', function (e) {
          e.preventDefault()

          if (e.originalEvent.touches) {
            modifyEventForTouch(e)
          }

          var x = e.clientX,
            y = e.clientY

          // console.log('x: ', x);
          // console.log('y: ', y);

          xPos = options.axis === 'y' ? xPos : limit($el.innerWidth() - imageDimensions.width, 0, xPos + x - x0, options.bound)
          yPos = options.axis === 'x' ? yPos : limit($el.innerHeight() - imageDimensions.height, 0, yPos + y - y0, options.bound)
          x0 = x
          y0 = y

          // console.log('updated xPos: ', xPos)
          // console.log('updated yPos: ', yPos)

          $el.css('background-position', xPos + 'px ' + yPos + 'px')
        })

        $window.on('mouseup.dbg touchend.dbg mouseleave.dbg', function () {
          if (options.done) {
            options.done()
          }

          $window.off('mousemove.dbg touchmove.dbg')
          $window.off('mouseup.dbg touchend.dbg mouseleave.dbg')
        })
      })
    }

    $el.on('mousedown.dbg touchstart.dbg', function (e) {
      if (e.target !== $el[0]) {
        return
      }
      e.preventDefault()

      if (e.originalEvent.touches) {
        modifyEventForTouch(e)
      } else if (e.which !== 1) {
        return
      }

      var x0 = e.clientX,
        y0 = e.clientY,
        pos = $el.css('background-position').match(/(-?\d+).*?\s(-?\d+)/) || [],
        xPos = parseInt(pos[1]) || 0,
        yPos = parseInt(pos[2]) || 0

      // console.log('x0: ', x0);
      // console.log('y0: ', y0);
      // console.log('pos: ', pos);
      // console.log('xPos: ', xPos);
      // console.log('yPos: ', yPos);

      $window.on('mousemove.dbg touchmove.dbg', function (e) {
        e.preventDefault()

        if (e.originalEvent.touches) {
          modifyEventForTouch(e)
        }

        var x = e.clientX,
          y = e.clientY

        // console.log('x: ', x);
        // console.log('y: ', y);

        xPos = options.axis === 'y' ? xPos : limit($el.innerWidth() - imageDimensions.width, 0, xPos + x - x0, options.bound)
        yPos = options.axis === 'x' ? yPos : limit($el.innerHeight() - imageDimensions.height, 0, yPos + y - y0, options.bound)
        x0 = x
        y0 = y

        // console.log('updated xPos: ', xPos)
        // console.log('updated yPos: ', yPos)

        $el.css('background-position', xPos + 'px ' + yPos + 'px')
      })

      $window.on('mouseup.dbg touchend.dbg mouseleave.dbg', function () {
        if (options.done) {
          options.done()
        }

        $window.off('mousemove.dbg touchmove.dbg')
        $window.off('mouseup.dbg touchend.dbg mouseleave.dbg')
      })
    })
  }

  Plugin.prototype.disable = function () {
    var $el = $(this.element)
    $el.off('mousedown.dbg touchstart.dbg')
    $window.off('mousemove.dbg touchmove.dbg mouseup.dbg touchend.dbg mouseleave.dbg')
  }

  $.fn.backgroundDraggable = function (options) {
    var options = options
    var args = Array.prototype.slice.call(arguments, 1)

    return this.each(function () {
      var $this = $(this)

      if (typeof options == 'undefined' || typeof options == 'object') {
        options = $.extend({}, $.fn.backgroundDraggable.defaults, options)
        var plugin = new Plugin(this, options)
        $this.data('dbg', plugin)
      } else if (typeof options == 'string' && $this.data('dbg')) {
        var plugin = $this.data('dbg')
        Plugin.prototype[options].apply(plugin, args)
      }
    })
  }

  $.fn.backgroundDraggable.defaults = {
    bound: true,
    axis: undefined,
    svg: false,
  }

  $('#template .background').backgroundDraggable({ bound: true })
})
//   $('.background').focus(function() {
//     var $bg = $('.background.focused'),
//       $templates = $('.template'),
//       $container = $templates[$templates.length - 1],
//       $scale = $container.getBoundingClientRect().width / $container.offsetWidth,
//       elbounds = {
//         w: parseInt($bg.width() * $scale),
//         h: parseInt($bg.height() * $scale)
//       },
//       bounds = {
//         w:  elbounds.w,
//         h:  elbounds.h
//       },
//       origin = {
//         x: 0,
//         y: 0
//       },
//       start = {
//         x: 0,
//         y: 0
//       },
//       movecontinue = false;
//     bgSize($bg, function(w, h) { //act on and store the most up and left
//       console.log(`image dimensions => width:${w}, height:${h}`);
//       $bg.data("mostleft", (elbounds.w * -1) + w);
//       $bg.data("mostup", (elbounds.h * -1) + h);
//     });
//
//
//     function move(e) {
//       var inbounds = {
//           x: false,
//           y: false
//         },
//         offset = {
//           x: start.x - (origin.x - e.clientX),
//           y: start.y - (origin.y - e.clientY)
//         };
//       console.log($bg)
//       console.log($bg.data("mostleft"))
//       console.log($bg.data("mostup"))
//       console.log(bounds.w)
//       console.log(bounds.h)
//       console.log(offset.x)
//       console.log(offset.y)
//
//       inbounds.x = offset.x < 0 && (offset.x * -1) < bounds.w;
//       inbounds.y = offset.y < 0 && (offset.y * -1) < 45;
//
//       if (movecontinue && inbounds.x && inbounds.y) {
//         // ensure that up and left are limited appropriately
//         start.x = offset.x < ($bg.data("mostleft") * -1) ? ($bg.data("mostleft") * -1) : offset.x;
//         start.y = offset.y < ($bg.data("mostup") * -1) ? ($bg.data("mostup") * -1) : offset.y;
//         console.log(start.x)
//         console.log(start.y)
//
//         $(this).css('background-position', start.x + 'px ' + start.y + 'px');
//       }
//
//       origin.x = e.clientX;
//       origin.y = e.clientY;
//
//       e.stopPropagation();
//       return false;
//     }
//
//     function handle(e) {
//       movecontinue = false;
//       $bg.unbind('mousemove', move);
//
//       if (e.type === 'mousedown') {
//         origin.x = e.clientX;
//         origin.y = e.clientY;
//         movecontinue = true;
//         $bg.bind('mousemove', move);
//       } else {
//         $(document.body).focus();
//       }
//
//       e.stopPropagation();
//       return false;
//     }
//
//     // function reset() {
//     //   start = {
//     //     x: 0,
//     //     y: 0
//     //   };
//     //   $(this).css('backgroundPosition', '0 0');
//     // }
//
//     $bg.bind('mousedown mouseup mouseleave', handle);
//     // $bg.bind('dblclick', reset);
//   });
// });
// //function to accurately calculate image size.
// function bgSize($el, cb) {
//   $('<img />')
//     .on('load', function() {
//       cb(this.width, this.height);
//     })
//     .attr('src', $el.css('background-image').match(/^url\("?(.+?)"?\)$/)[1]);
// }
