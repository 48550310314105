import React, { Component } from 'react'
import { connect } from 'react-redux'

import ColorPicker from '../common/ColorPicker'
import ColorGradient from '../common/ColorGradient'
import IconsList from './IconsList'
import { getIcons, getQrIcons } from '../../../../store/card/cardSelectors'
import { changeTemplate, updateDesign } from '../../../../store/card/cardActions'

import { FormattedMessage } from 'react-intl'

import './IconCustomizationSection.scss'

class IconCustomizationSection extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeLayer: props.activeLayer,
      currentColorType: props.activeLayer.colorPickerType,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeLayer !== this.props.activeLayer) {
      this.setState({
        currentColorType: this.props.activeLayer.colorPickerType,
      })
    }
  }

  setColorType = (type) => {
    const { onChangeTemplate, activeLayer } = this.props

    this.setState({
      currentColorType: type,
    })

    activeLayer.colorPickerType = type

    this.setState({
      activeLayer,
    })

    onChangeTemplate(activeLayer)
  }

  render() {
    const { currentColorType } = this.state
    const { icons, qrIcons } = this.props
    return (
      <div className="icon-customization-section">
          <div className="buttons">
            <button onClick={() => this.setColorType('Solid color')} className={`${currentColorType === 'Solid color' && 'selected'}`}>
              <FormattedMessage id="colors.solid" />
            </button>
            <button onClick={() => this.setColorType('Gradient')} className={`gradient ${currentColorType === 'Gradient' && 'selected'}`}>
              <FormattedMessage id="colors.gradient" />
            </button>
            <button onClick={() => this.setColorType('Colorful')} className={`${currentColorType === 'Colorful' && 'selected'}`}>
              <FormattedMessage id="colors.colorful" />
          </button>
          </div>
        <IconsList iconsType={currentColorType} icons={currentColorType === 'Colorful' ? qrIcons : icons}/>
        <div className="icon-color customization-dropdown">
        </div>
        {currentColorType === 'Solid color' ? <ColorPicker type="icon" /> : currentColorType=== "Gradient" ? <ColorGradient type="icon" /> : <></>}
      </div>
    )
  }
}

const mapState = (state) => ({
  icons: getIcons(state),
  qrIcons: getQrIcons(state),
})

const mapDispatch = (dispatch) => ({
  onChangeTemplate: (layer) => dispatch(updateDesign(layer)),
})

export default connect(mapState, mapDispatch)(IconCustomizationSection)
