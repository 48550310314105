import React, {useEffect, useState} from "react";
import './MultiEntryItem.scss'
import Errors from "../Errors";
import {ReactComponent as Cross} from "../../../common/images/elements/cross.svg";
import {useForm, useWatch} from "react-hook-form";
import useComponentVisible from "../../../../UserAccountsComponents/useComponentVisible";
import SocialSelect from '../SocialSelect';

function Item({item, onUpdate, onDelete, ...rest}) {

	const [ref, isFocused, setIsFocused] = useComponentVisible(null);

	const {
		setValue,
		getValues,
		register,
		watch,
		formState,
		control,
	} = useForm({
		mode: "onTouched" // "onChange"
	});

	useEffect(() => {
		setValue('id', item.id)
		setValue('type', item.type)
		setValue('name', item.name)
		setValue('url', item.url)
	}, [])

	// watch changes
	const name = useWatch({
		defaultValue: item.name,
		control,
		name: 'name'
	})
	const url = useWatch({
		defaultValue: item.url,
		control,
		name: 'url'
	})

	useEffect(() => {
		onUpdate(
			{
				...item,
				name: name,
				url: url
			}
		);

	}, [name, url])

	function handleSelectChange(option) {
		onUpdate({...item, type: option.value})
	}

	return (
		<div className="mt-4">
			<div id="one-line" className="d-flex justify-content-center">
				<div>
					<SocialSelect
						item={item}
						onChange={handleSelectChange}
						differentIcons={true}
					>
					</SocialSelect>
				</div>
				<div ref={ref} className="format-container w-100 py-0 my-0" style={{height: '48px'}} onClick={() => setIsFocused(true)}>
					<div className="inputs-container">
						<div className="input-container">

							<input name={'name'} type="text" placeholder="Name"
								   {...register('name', {required: false, maxLength: 20})}/>


							{/* Cross/Trash button */}
							{
								watch('name') !== ''
									?
									<Cross onClick={() => setValue('name', '')}/>
									:
									<div className="float-right trash-button" onClick={() => onDelete(item.id)}>
										<img className='blue-image' src="/assets/icons/delete.svg" width="15" alt=""/>
									</div>
							}

						</div>
						<Errors formState={formState} name={'name'}/>
					</div>
				</div>
			</div>
			{isFocused &&
				<div ref={ref} id="second-line">
					<div className="format-container w-100 py-0 my-0">
						<div className="inputs-container">
							<div className="input-container">

								<input name={'url'} type="text" placeholder="Link"
									   {...register('url', {required: false, maxLength: 20})}/>

							</div>
							<Errors formState={formState} name={'url'}/>
						</div>
					</div>
				</div>
			}
		</div>
	)
}

export default Item;
