import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
  LOGIN_START,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  SIGNUP_START,
  SIGNUP_FAIL,
  SIGNUP_SUCCESS,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_START,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  USER_SETTINGS_CHANGE_START,
  USER_SETTINGS_CHANGE_SUCCESS,
  USER_SETTINGS_CHANGE_FAIL,
  ERROR_CLEAR,
  LOGIN_PROVIDER_SET,
  IS_SUBSCRIPTION_ACTIVE_SET,
  LOGOUT,
} from './authActions'


const initialState = {
  userData: '',
  fetchStatus: 'UNTOUCHED',
  error: '',
  userEmail: '',
  isRememberChecked: false,
  loginProvider: '',
}


const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_START:
      return {
        ...state,
        fetchStatus: 'WORKING',
        error: ''
      }

    case LOGIN_SUCCESS: {
      if (action.isRememberChecked) {
        return {
          ...state,
          userData: action.data,
          userEmail: action.data.user.email,
          fetchStatus: 'SUCCESS',
          isRememberChecked: true,
        }
      } else {
        return {
          ...state,
          userData: action.data,
          userEmail: '',
          fetchStatus: 'SUCCESS',
          isRememberChecked: false,
        }
      }
    }

    case LOGIN_FAIL:
      return {
        ...state,
        fetchStatus: 'ERROR',
        error: action.message
      }

    case LOGOUT: {
      return {
        ...state,
        userData: '',
      }
    }

    case SIGNUP_START:
      return {
        ...state,
        fetchStatus: 'WORKING',
        error: ''
      }

    case SIGNUP_SUCCESS: {
      return {
        ...state,
        fetchStatus: 'SUCCESS',
      }
    }

    case SIGNUP_FAIL:
      return {
        ...state,
        fetchStatus: 'ERROR',
        error: action.message
      }

    case FORGOT_PASSWORD_START:
      return {
        ...state,
        fetchStatus: 'WORKING',
        error: ''
      }

    case FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        fetchStatus: 'SUCCESS',
      }
    }

    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        fetchStatus: 'ERROR',
        error: action.message
      }

    case RESET_PASSWORD_START:
      return {
        ...state,
        fetchStatus: 'WORKING',
        error: ''
      }

    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        fetchStatus: 'SUCCESS',
      }
    }

    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        fetchStatus: 'ERROR',
        error: action.message
      }

    case USER_SETTINGS_CHANGE_START:
      return {
        ...state,
        fetchStatus: 'WORKING',
      }

    case USER_SETTINGS_CHANGE_SUCCESS: {
      let updatedUserData = { ...state.userData }

      updatedUserData.user = action.user

      return {
        ...state,
        fetchStatus: 'SUCCESS',
        userData: updatedUserData,
      }
    }

    case USER_SETTINGS_CHANGE_FAIL:
      return {
        ...state,
        fetchStatus: 'ERROR',
      }

    case ERROR_CLEAR:
      return {
        ...state,
        error: null,
      }

    case LOGIN_PROVIDER_SET:
      return {
        ...state,
        loginProvider: action.loginProvider,
      }
    
    case IS_SUBSCRIPTION_ACTIVE_SET:
      let updatedUserData = { ...state.userData }

      updatedUserData.user.isSubscriptionActive = action.isSubscriptionActive

      return {
        ...state,
        userData: updatedUserData,
      }

    default:
      break
  }

  return state
}

/**
 * Configuration of redux-persist used for serialization of redux state to local storage.
 */
const persistConfig = {
  key: 'user',
  storage,
}

const persistableReducer = persistReducer(persistConfig, authReducer)

export default persistableReducer
