import React from 'react'

function Support() {
  return (
    <div>
      Support
    </div>
  )
}

export default Support
