import React from 'react'

import './Header.scss'
import ButtonPanel from "../ButtonPanel/ButtonPanel";
import LogoNavigation from "../LogoNavigation/LogoNavigation";

const Header = () => {

  return (
    <div className="header">
      <div className="container-fluid">
        <div className="row">
          <LogoNavigation/>
          <ButtonPanel/>
        </div>
      </div>
    </div>
  )
}

export default Header
