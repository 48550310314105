import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
  DESIGNS_SET,
} from './myLibraryActions'


const initialState = {
  designs: [],
}


const myLibraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case DESIGNS_SET:
      return {
        ...state,
        designs: action.designs,
      }

    default:
      break
  }

  return state
}

/**
 * Configuration of redux-persist used for serialization of redux state to local storage.
 */
const persistConfig = {
  key: 'myLibrary',
  storage,
  blacklist: [],
}

const persistableReducer = persistReducer(persistConfig, myLibraryReducer)

export default persistableReducer