import { getUserSettings } from '../auth/authSelectors'
import {TYPES} from "../../utils/constants";

export const getMaterial = (state) => state.order.material

export const getQuantity = (state) => state.order.quantity

export const getPrice = (state) => state.order.price

export const getIsRounded = (state) => state.order.rounded

export const getCountry = (state) => state.order.country

export const getName = (state) => state.order.name

export const getShippingAddress = (state) => state.order.shippingAddress

export const getPhoneNumber = (state) => state.order.phone

export const getPaymentOption = (state) => state.order.paymentOption

export const getEmail= (state) => state.order.email

export const getBillingAddress = (state) => state.order.billingAddress

export const getTermsAgreement = (state) => state.order.termsAgreement

export const getEmailAgreement = (state) => state.order.emailAgreement

export const getExpressDelivery = (state) => state.order.expressDelivery

export const getSubscriptionPlans = (state) => state.order.subscriptionPlans

export const getSubscriptionPlanType = (state) => state.order.subscriptionPlanType

export const getSubscriptionPeriod = (state) => state.order.subscriptionPeriod

export const getIsRedirected = (state) => state.order.isRedirected

export const getOrderData = (state) => {
  const { material, quantity, price, rounded, country, name, shippingAddress, phone,
          paymentOption, email, billingAddress, termsAgreement, emailAgreement, expressDelivery  } = state.order

  return {
    users_permissions_user: getUserSettings(state),
    material,
    quantity,
    price,
    rounded,
    country,
    name,
    shippingAddress,
    phone,
    paymentOption,
    email,
    billingAddress,
    termsAgreement,
    emailAgreement,
    expressDelivery,
  }
}

export const getCurrentPrice = (state) => {
  const { material, quantity, rounded, expressDelivery, prices } = state.order
  const { templateType } = state.card
  const { lessThan1m2, lessThan2m2, lessThan3m2, lessThan5m2, lessThan7m2,
          lessThan10m2, matt, laminated, shine, structured, sticker, roundedCorners, economy, express } = prices[0]
  let price
  let pages = templateType === sticker ? 1 : 2
  let currentMaterialPrice
  let roundedCornersPrice = rounded ? roundedCorners : 1
  let deliveryPrice = expressDelivery ? express : economy
  let size = templateType === TYPES.B_CARD ? 89*59 : templateType === TYPES.STICKER ? 124*64 : 94*64
  let colorPrice

  if (material === 'Matt') {
    currentMaterialPrice = matt ? matt : 0
  } else if (material === 'Laminated') {
    currentMaterialPrice = laminated ? laminated : 0
  } else if (material === 'Shine') {
    currentMaterialPrice = shine ? shine : 0
  } else if (material === 'Structued') {
    currentMaterialPrice = structured ? structured : 0
  } else if (templateType === TYPES.STICKER) {
    currentMaterialPrice = sticker ? sticker : 0
  }

  if (quantity*size/1000000*pages < 1) {
    colorPrice = lessThan1m2
  } else if (quantity*size/1000000*pages < 2) {
    colorPrice = lessThan2m2
  } else if (quantity*size/1000000*pages < 3) {
    colorPrice = lessThan3m2
  } else if (quantity*size/1000000*pages < 5) {
    colorPrice = lessThan5m2
  } else if (quantity*size/1000000*pages < 7) {
    colorPrice = lessThan7m2
  } else if (quantity*size/1000000*pages < 10) {
    colorPrice = lessThan10m2
  }

  price = ((quantity*size/1000000*currentMaterialPrice) + (quantity*size/1000000*pages*colorPrice) + (quantity*roundedCornersPrice*deliveryPrice))

  return Math.ceil(price)
}
