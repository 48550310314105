import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {SET_DIGITAL_CARD, SET_FEEDBACK_FORM, SET_MULTI_ENTRY, SET_PRODUCT_PAGE, RESET_TO_INITIAL, SET_MICRO_PAGE_SUCCESS} from "./microPageActions";
import {DefaultFeedbackForm, DefaultMultiEntry, MicroPageType} from "../../ts/types";
import {QRCodeType} from "../../components/pages/customization/qrcode/formatTab/formatting/codeGenerator";
import {DefaultProductPage, DefaultDigitalCard} from "../../ts/types";
import { nanoid } from 'nanoid'
import { update } from 'lodash';

const initialState: {
    updateStatus: string,
    productPage: DefaultProductPage,
    digitalCard: DefaultDigitalCard,
    feedbackForm: DefaultFeedbackForm,
    multiEntry: DefaultMultiEntry,
} = {
    updateStatus: 'UNTOUCHED',
    productPage: {
        header: 'QRcards stickers ',
        content: 'Brand new type of stickers with improved adhesion technology and better surface treatment. Design them and order them right home with our QRcards web app.',
        buttonText: 'Objednat nyní',
        buttonLink: '',
        images: [
            {
              _id: "663380d5b591ac4780b22904",
              name: "1_cb979feeda.png",
              hash: "1_cb979feeda_cdf9277293",
              ext: ".png",
              mime: "image/png",
              size: 111.11,
              width: 488,
              height: 376,
              url: "https://res.cloudinary.com/qrcards/image/upload/v1714651348/1_cb979feeda_cdf9277293.png",
              provider_metadata: {
                public_id: "1_cb979feeda_cdf9277293",
                resource_type: "image"
              },
              formats: {
                thumbnail: {
                  name: "thumbnail_1_cb979feeda.png",
                  hash: "thumbnail_1_cb979feeda_cdf9277293",
                  ext: ".png",
                  mime: "image/png",
                  width: 202,
                  height: 156,
                  size: 42.34,
                  path: null,
                  url: "https://res.cloudinary.com/qrcards/image/upload/v1714651349/thumbnail_1_cb979feeda_cdf9277293.png",
                  provider_metadata: {
                    public_id: "thumbnail_1_cb979feeda_cdf9277293",
                    resource_type: "image"
                  }
                }
              },
              provider: "cloudinary",
              related: [],
              createdAt: "2024-05-02T12:02:29.842Z",
              updatedAt: "2024-05-02T12:02:42.237Z",
              __v: 0,
              id: "663380d5b591ac4780b22904"
            },
            {
              _id: "663380d5b591ac4780b22903",
              name: "3_1ae1e3f75c.png",
              hash: "3_1ae1e3f75c_0d6d9f60f7",
              ext: ".png",
              mime: "image/png",
              size: 199.96,
              width: 488,
              height: 376,
              url: "https://res.cloudinary.com/qrcards/image/upload/v1714651348/3_1ae1e3f75c_0d6d9f60f7.png",
              provider_metadata: {
                public_id: "3_1ae1e3f75c_0d6d9f60f7",
                resource_type: "image"
              },
              formats: {
                thumbnail: {
                  name: "thumbnail_3_1ae1e3f75c.png",
                  hash: "thumbnail_3_1ae1e3f75c_0d6d9f60f7",
                  ext: ".png",
                  mime: "image/png",
                  width: 202,
                  height: 156,
                  size: 51.87,
                  path: null,
                  url: "https://res.cloudinary.com/qrcards/image/upload/v1714651349/thumbnail_3_1ae1e3f75c_0d6d9f60f7.png",
                  provider_metadata: {
                    public_id: "thumbnail_3_1ae1e3f75c_0d6d9f60f7",
                    resource_type: "image"
                  }
                }
              },
              provider: "cloudinary",
              related: [],
              createdAt: "2024-05-02T12:02:29.551Z",
              updatedAt: "2024-05-02T12:02:42.237Z",
              __v: 0,
              id: "663380d5b591ac4780b22903"
            },
            {
              _id: "663380d6b591ac4780b22905",
              name: "2_1325074f5b.png",
              hash: "2_1325074f5b_4c861b69d9",
              ext: ".png",
              mime: "image/png",
              size: 152.92,
              width: 488,
              height: 376,
              url: "https://res.cloudinary.com/qrcards/image/upload/v1714651348/2_1325074f5b_4c861b69d9.png",
              provider_metadata: {
                public_id: "2_1325074f5b_4c861b69d9",
                resource_type: "image"
              },
              formats: {
                thumbnail: {
                  name: "thumbnail_2_1325074f5b.png",
                  hash: "thumbnail_2_1325074f5b_4c861b69d9",
                  ext: ".png",
                  mime: "image/png",
                  width: 202,
                  height: 156,
                  size: 43.63,
                  path: null,
                  url: "https://res.cloudinary.com/qrcards/image/upload/v1714651349/thumbnail_2_1325074f5b_4c861b69d9.png",
                  provider_metadata: {
                    public_id: "thumbnail_2_1325074f5b_4c861b69d9",
                    resource_type: "image"
                  }
                }
              },
              provider: "cloudinary",
              related: [],
              createdAt: "2024-05-02T12:02:30.085Z",
              updatedAt: "2024-05-02T12:02:42.237Z",
              __v: 0,
              id: "663380d6b591ac4780b22905"
            }
        ],
        theme: 'green'
    },
    digitalCard: {
        image: {
            _id: '63f24abb6308ab1990ad5be1',
            name: 'file.name',
            hash: 'file_3009f8ced2',
            ext: '.name',
            mime: 'image/png',
            size: 175.06,
            width: 322,
            height: 322,
            url: 'https://res.cloudinary.com/qrcards/image/upload/v1676823225/file_3009f8ced2.png',
            provider_metadata: {
                public_id: 'file_3009f8ced2',
                resource_type: 'image'
            },
            formats: {
                thumbnail: {
                    name: 'thumbnail_file.name',
                    hash: 'thumbnail_file_3009f8ced2',
                    ext: '.name',
                    mime: 'image/png',
                    width: 156,
                    height: 156,
                    size: 58.29,
                    path: null,
                    url: 'https://res.cloudinary.com/qrcards/image/upload/v1676823227/thumbnail_file_3009f8ced2.png',
                    provider_metadata: {
                        public_id: 'thumbnail_file_3009f8ced2',
                        resource_type: 'image'
                    }
                }
            },
            provider: 'cloudinary',
            related: [],
            createdAt: '2023-02-19T16:13:47.661Z',
            updatedAt: '2023-02-19T16:13:47.661Z',
            __v: 0,
            id: '63f24abb6308ab1990ad5be1'
        },
        fullName: 'Jane Smiley',
        title: 'Marketing specialist',
        about: 'I am happy to be at your service if you have any questions.',
        phone: '+44 605 167 702',
        email: 'smiley@qrcards.com',
        organization: 'QR Cards s.r.o.',
        address: 'Zahradní 182\n' +
            'Kouty u Třebíče, 675 08',
        website: 'www.qrcards.com',
        items: [
            {
                uid: '6DJ1L-3f3MaogtWmmGSxH',
                type: 'spotify',
                url: ''
            },
            {
                uid: '5IL1EGlY1S_knnhTJoFZ3',
                type: 'instagram',
                url: ''
            },
            {
                uid: '-PdNrgvDmWa6T-plqfhcN',
                type: 'facebook',
                url: ''
            },
            {
                uid: 'eI2BmX-OYyaKsuDOnyPoo',
                type: 'linkedin',
                url: ''
            }
        ],
        theme: 'green'
    },
    feedbackForm: {
        name: 'MY WINE BAR',
        image: {
            _id: "66153f1705334f0014968cef",
            name: "ff_default_78e93286b9.png",
            hash: "ff_default_78e93286b9_036ab7913d",
            ext: ".png",
            mime: "image/png",
            size: 232.24,
            width: 488,
            height: 300,
            url: "https://res.cloudinary.com/qrcards/image/upload/v1712668438/ff_default_78e93286b9_036ab7913d.png",
            provider_metadata: {
              public_id: "ff_default_78e93286b9_036ab7913d",
              resource_type: "image"
            },
            formats: {},
            provider: 'cloudinary',
            related: [],
            createdAt: '2023-02-20T20:46:44.552Z',
            updatedAt: '2023-02-20T20:46:44.552Z',
            __v: 0,
            id: '66153f1705334f0014968cef'
        },
        items: [
            {
                id: nanoid(),
                question: 'How satisfied are you?',
                type: 'scale'
            },
            {
                id: nanoid(),
                question: 'Would you recommend our service?',
                type: 'boolean'
            },
            {
                id: nanoid(),
                question: 'How can we improve?',
                type: 'text'
            }
        ],
        theme: 'brown'
    },
    multiEntry: {
        name: 'Peter van Dijk',
        image: {
            _id: "662110ba7f2ae80015acdc09",
            name: "me_default_0e9a92cab8.png",
            hash: "me_default_0e9a92cab8_d3031ca115",
            ext: ".png",
            mime: "image/png",
            size: 181.35,
            width: 491,
            height: 304,
            url: "https://res.cloudinary.com/qrcards/image/upload/v1713443001/me_default_0e9a92cab8_d3031ca115.png",
            provider_metadata: {
              public_id: "me_default_0e9a92cab8_d3031ca115",
              resource_type: "image"
            },
            formats: {},
            provider: "cloudinary",
            related: [],
            createdAt: "2024-04-18T12:23:22.990Z",
            updatedAt: "2024-04-18T12:23:27.011Z",
            __v: 0,
            id: "662110ba7f2ae80015acdc09"
          },
        items: [
            {
                id: 'yMu6rY-gVq2MOt3Y0v8dW',
                type: 'facebook',
                name: 'Follow me on Facebook',
                url: ''
            },
            {
                id: 'HuB9_WaLvXEs2CKFYJI4c',
                type: 'Instagram',
                name: 'Instagram',
                url: ''
            },
            {
                id: 'bYE6lvar97f-zenHOAf6-',
                type: 'twitter',
                name: 'Twitter',
                url: ''
            },
            {
                id: 'g0KeD4V60hxXa9041MqvH',
                type: 'youtube',
                name: ' Youtube channel',
                url: ''
            },
            {
                id: 'ADNZCi0XQiOEth_s8gxeY',
                type: 'pdf2',
                name: 'Download my ebook',
                url: ''
            }
        ],
        theme: 'black'
    },
};

const microPageReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_MICRO_PAGE_SUCCESS: {
            return {
                ...state,
                updateStatus: 'SUCCESS',
            }
        }

        case SET_PRODUCT_PAGE: {
            if (action.status === 'update') {
                return {
                    ...state,
                    productPage: action.page,
                    updateStatus: 'WORKING',
                }
            } else {
                return {
                    ...state,
                    productPage: action.page
                }
            }
        }

        case SET_DIGITAL_CARD: {
            if (action.status === 'update') {
                return {
                    ...state,
                    digitalCard: action.page,
                    updateStatus: 'WORKING',
                }
            } else {
                return {
                    ...state,
                    digitalCard: action.page
                }
            }
        }

        case SET_FEEDBACK_FORM: {
            if (action.status === 'update') {
                return {
                    ...state,
                    feedbackForm: action.page,
                    updateStatus: 'WORKING',
                }
            } else {
                return {
                    ...state,
                    feedbackForm: action.page
                }
            }
        }

        case SET_MULTI_ENTRY: {
            if (action.status === 'update') {
                return {
                    ...state,
                    multiEntry: action.page,
                    updateStatus: 'WORKING',
                }
            } else {
                return {
                    ...state,
                    multiEntry: action.page
                }
            }
        }

        case RESET_TO_INITIAL: {

            return {
                ...state,
                productPage: initialState.productPage,
                feedbackForm: initialState.feedbackForm,
                digitalCard: initialState.digitalCard,
                multiEntry: initialState.multiEntry
            }

            const type = action.qrType;
            console.log(type, 'type');

            if (type === QRCodeType.ProductPage) {
                return {
                    ...state,
                    productPage: initialState.productPage
                }
            }

            if (type === QRCodeType.VCardDynamic) {
                return {
                    ...state,
                    feedbackForm: initialState.feedbackForm
                }
            }

            if (type === QRCodeType.Feedback) {
                return {
                    ...state,
                    digitalCard: initialState.digitalCard
                }
            }

            if (type === QRCodeType.multiEntry) {
                return {
                    ...state,
                    multiEntry: initialState.multiEntry
                }
            }

        }
    }

    return state;
}

/**
 * Configuration of redux-persist used for serialization of redux state to local storage.
 */
const persistConfig = {
    key: 'page',
    storage,
}

const persistableReducer = persistReducer(persistConfig, microPageReducer)


export default persistableReducer;
