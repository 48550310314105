import React, {Fragment} from "react";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {getUserData} from "../../store/auth/authSelectors";
import { getSubscriptionPlanType, getSubscriptionPeriod } from '../../store/order/orderSelectors'
import Header from "../common/Header/Header";

function PageLayout({ children, user, planType, period }) {
  return (
    <Fragment>
      {user ?        
         (
            <div className='page'>
              <main className="page-container">
                <Header/>
                {React.Children.map(children, (child, key) => {
                  return React.createElement(child.type, {
                    ...{
                      ...child.props,
                    }
                  })
                })}
              </main>
            </div>
          )
         : 

        planType && period ?
          (
            <Redirect
              to={{
                pathname: `/login`,
                state: {
                  source: 'CustomizationPage',
                  redirect: true,
                },
              }}
            />
          )
        
        : (
          <Redirect
            to={{
              pathname: '/',
              state: {
                source: 'CustomizationPage',
                redirect: true,
              },
            }}
          />
        )
      }
    </Fragment>
  );
}

const mapState = (state) => ({
  user: getUserData(state),
  planType: getSubscriptionPlanType(state),
  period: getSubscriptionPeriod(state),
})

export default connect(mapState)(PageLayout)

