import React from 'react';

function Phone() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="#000" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6314 14.5301C9.69986 12.9451 8.41013 10.9893 7.73209 9.87648L7.22631 8.92496C7.40315 8.73561 8.75176 7.29452 9.33618 6.51263C10.0706 5.53079 9.00579 4.64405 9.00579 4.64405C9.00579 4.64405 6.00961 1.6537 5.32677 1.06053C4.64394 0.466533 3.85791 0.796441 3.85791 0.796441C2.42276 1.7218 0.934961 2.52633 0.845706 6.39553C0.842377 10.0181 3.59805 13.7544 6.57801 16.647C9.56275 19.9136 13.6608 23.1878 17.6228 23.1841C21.4996 23.0958 22.3056 21.6114 23.2329 20.1792C23.2329 20.1792 23.5637 19.3954 22.9691 18.7134C22.3741 18.0316 19.3771 15.041 19.3771 15.041C19.3771 15.041 18.4891 13.9782 17.505 14.7117C16.7716 15.2588 15.4546 16.4757 15.1374 16.7707C15.138 16.7717 12.9349 15.5997 11.6314 14.5301Z"/>
        </svg>
    );
}

export default Phone;
