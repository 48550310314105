import React, { Component } from 'react';
import { CustomPicker } from 'react-color';
import './CustomColorPicker.scss'
import domtoimage from 'dom-to-image'


const tinycolor = require("tinycolor2");

const { Saturation, EditableInput, Hue } = require('react-color/lib/components/common');

const inputStyles = {
  input: {
    background: ' #FFFFFF',
    border: '1px solid rgba(23, 35, 57, 0.08)',
    boxSizing: 'border-box',
    borderRadius: '8px',
    width: '22.4rem',
    height: '5.6rem',
    color: '#38517C',
    fontSize: '1.4rem',
    lineHeight: '1.6rem',
    paddingLeft: '1.6rem',
    marginBottom: '1.6rem',
  },
};
const inlineStyles = {
  pointer: {
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.37)',
    background: 'transparent',
    border: '5px solid #ffffff',
    transform: 'translateX(-50%)'
  },
  slider: {
    marginTop: '1px',
    width: '4px',
    borderRadius: '1px',
    height: '8px',
    boxShadow: '0 0 2px rgba(0, 0, 0, .6)',
    background: '#fff',
    transform: 'translateX(-2px)'
  },
  saturationContainer: {
    position: 'relative',
    background: '#FFFFFF',
    border: '1px solid rgba(0, 58, 156, 0.12)',
    boxShadow: '0px 2px 4px rgba(23, 35, 57, 0.08)',
    borderRadius: '8px',
    width: '100%',
    height: '10.8rem',
    marginBottom: '1.5rem',
  },
  hue: {
    position: 'relative',
    height: '1rem',
    borderRadius: '8px',
  }
}

const CustomPointer = () => {
  return (
    <div className="pointer" style={inlineStyles.pointer} />
  )
}

class CustomColorPicker extends Component {
  state = {
    hsl: {
      h: 0,
      s: 0,
      l: 0
    },
    hsv: {
      h: 0,
      s: 0,
      v: 0
    },
    hex: this.props.color,
    isDropperActive: false,
    isCursorOnTemplate: false,
    dropperLoading: false,
  };
  componentDidMount() {
    const editableInputWrapper = document.querySelector(".editable-input-wrapper")
    const hexInputWrapper = editableInputWrapper.firstChild
    const hexInput = hexInputWrapper.firstChild
    hexInputWrapper.style.width = '100%'
    hexInput.style.width = null
    hexInput.style.marginBottom = null
  }
  componentWillUnmount() {
    this.props.handleAddingRecentColor()
  }
  componentWillMount() {
    const color = tinycolor(this.props.color);
    this.setState({
      hsv: color.toHsv(),
      hsl: color.toHsl(),
      hex: color.toHex(),
    });
  }
  getColorOfPixel = (e) => {
    const template = document.getElementById('template');
    const canvas = document.getElementById('temporary-canvas')
    const rect = template.getBoundingClientRect();
    const ctx = canvas.getContext('2d')

    let scale = rect.width / template.offsetWidth
    let x = Math.floor((e.clientX - rect.left) / scale);
    let y = Math.floor((e.clientY - rect.top) / scale);
    let data = ctx.getImageData(x, y, 1, 1).data

    let color = tinycolor(`rgb(${data[0]}, ${data[1]}, ${data[2]})`)
    return color;
  }
  activateDropper = () => {
    const canvas = document.getElementById('temporary-canvas');
    if (!this.state.isDropperActive) {
      canvas.remove()
      return
    };
    const template = document.getElementById('template');

    canvas.removeEventListener('mousemove', this.onMouseMove)

    document.body.style.cursor = 'crosshair'

    canvas.addEventListener('mousemove', this.onMouseMove)
    canvas.addEventListener('click', this.captureColor, { once: true })
    window.addEventListener('click', this.deactivateDropper, { once: true })
  }

  captureColor = (e) => {
    let color = this.getColorOfPixel(e)
    this.setState({
      hsv: color.toHsv(),
      hsl: color.toHsl(),
      hex: color.toHex(),
    });
    this.props.onChangeComplete(`#${color.toHex()}`)
  }

  deactivateDropper = (e) => {
    const canvas = document.getElementById('temporary-canvas');
    document.body.style.cursor = 'default'

    if (this.state.isDropperActive) {
      canvas.remove()
    }
    this.setState({ isDropperActive: false })
  }
  onMouseMove = (e) => {
    if (!this.state.isDropperActive) return
    let color = this.getColorOfPixel(e)
    let circle = document.getElementById('color-circle')
    circle.style.backgroundColor = `#${color.toHex()}`
    circle.style.top = e.clientY + 'px';
    circle.style.left = e.clientX + 'px';
  }
  convertHtmlToCanvas = () => {
    this.setState({ dropperLoading: true })
    document.body.style.pointerEvents = 'none'
    let template = document.querySelector("#template");
    let scale = 1 //quality of snapshot - not result
    let config = {
      height: template.offsetHeight * scale,
      width: template.offsetWidth * scale,
      style: {
        transformOrigin: 'top left',
        transform: `scale(${scale})`,
      },
    }
    domtoimage.toPng(template, config)
      .then((dataUrl) => {
        let img = new Image();
        let canvas = document.createElement('canvas')
        let ctx = canvas.getContext('2d')
        canvas.id = 'temporary-canvas'

        let height = template.offsetHeight
        let width = template.offsetWidth

        img.src = dataUrl;
        canvas.onmouseenter = () => {
          this.setState({ isCursorOnTemplate: true })
        }
        canvas.onmouseleave = () => {
          this.setState({ isCursorOnTemplate: false })
        }
        canvas.width = width
        canvas.height = height
        canvas.style.position = 'absolute'
        canvas.style.zIndex = '100'
        canvas.style.left = '0'
        canvas.style.top = '0'
        canvas.style.opacity = '0'

        img.onload = () => {
          ctx.drawImage(img, 0, 0)
          template.appendChild(canvas);
          document.body.style.pointerEvents = 'auto'
          this.activateDropper();
        }
        this.setState({ dropperLoading: false })
        this.setState({ isDropperActive: true })
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  handleHueChange = hue => {
    const color = tinycolor(hue);
    this.setState({
      hsv: color.toHsv(),
      hsl: color.toHsl(),
      hex: color.toHex(),
    })
    this.props.onChangeComplete(`#${color.toHex()}`)
  }

  handleSaturationChange = hsv => {
    const color = tinycolor(hsv);
    this.setState({
      hsv: color.toHsv(),
      hsl: color.toHsl(),
      hex: color.toHex(),
    });
    this.props.onChangeComplete(`#${color.toHex()}`)
  }

  render() {
    const { dropperLoading } = this.state
    return (
      <div className="custom-color-picker">
        <div className={`${this.state.isDropperActive & this.state.isCursorOnTemplate && 'active'}`} id='color-circle'></div>
        <div className='editable-input-wrapper'>
          <EditableInput
            style={inputStyles}
            value={`#${this.state.hex}`}
            onChange={this.props.onChangeComplete}
          />
          <div onClick={this.convertHtmlToCanvas} className='svg-wrapper'>
            {!dropperLoading ? <img src="/assets/icons/dropper-icon.svg" alt="" /> :
            <div className="spinner-border text-secondary spinner small" role="status"></div>}
          </div>
        </div>
        <div className="color-part">
          <div style={inlineStyles.saturationContainer}>
            <Saturation
              hsl={this.state.hsl}
              hsv={this.state.hsv}
              pointer={CustomPointer}
              onChange={this.handleSaturationChange}
            />
          </div>
          <div style={inlineStyles.hue}>
            <Hue
              hsl={this.state.hsl}
              pointer={CustomPointer}
              onChange={this.handleHueChange}
              direction={'horizontal'}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CustomPicker(CustomColorPicker);
