import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import TextCustomizationSection from './text/TextCustomizationSection'
import IconCustomizationSection from './icon/IconCustomizationSection'
import QRCodeCustomizationSection from './qrcode/QRCodeCustomizationSection'
import BackgroundCustomizationSection from './background/BackgroundCustomizationSection'
import {getActiveLayer, getActiveLayerType, getResetStatus} from '../../../store/card/cardSelectors'
import { resetData, setActiveMicroPageType } from '../../../store/card/cardActions'
import { QRCodeType } from './qrcode/formatTab/formatting/codeGenerator';
import { MicroPageType } from "../../../ts/types";

import { FormattedMessage } from 'react-intl'

import './CustomizeSection.scss'

const CustomizeSection = ({ activeLayer, activeLayerType, onResetData, setDynamicQrType, onSetActiveMicroPageType }) => {
  const [dropdownOpen, setOpen] = useState(false)
  const toggle = () => setOpen(!dropdownOpen)

  const setMicroPageTypeBasedOnQrType = (qrType) => {
		switch (qrType) {
			case QRCodeType.Feedback:
				onSetActiveMicroPageType(MicroPageType.FEEDBACK_FORM)
				break;
			case QRCodeType.ProductPage:
				onSetActiveMicroPageType(MicroPageType.PRODUCT_PAGE)
				break;
			case QRCodeType.VCardDynamic:
				onSetActiveMicroPageType(MicroPageType.DIGITAL_CARD)
				break;
			case QRCodeType.MultiEntry:
				onSetActiveMicroPageType(MicroPageType.MULTI_ENTRY)
				break;
		}
	}

  return (
    <div className="customize-section">
      <span className="section-title">
        <FormattedMessage id="customize.title" />
      </span>
      <span className="reset-dropdown">
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle tag="div" data-toggle="dropdown" aria-expanded={dropdownOpen}>
            <img className="reset-button" src="/images/icons/reset-button.svg" alt="reset" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => onResetData('color')}>
              <FormattedMessage id="customize.reset1" />
            </DropdownItem>
            <DropdownItem onClick={() => onResetData('all colors')}>
              <FormattedMessage id="customize.reset2" />
            </DropdownItem>
            <DropdownItem onClick={() => onResetData('user data')}>
              <FormattedMessage id="customize.remove" />
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </span>
      {activeLayerType && activeLayerType.split('-')[0] === 'text' ? (
        <TextCustomizationSection />
      ) : activeLayerType && activeLayerType.split('-')[0] === 'icon' ? (
        <IconCustomizationSection activeLayer={activeLayer} />
      ) : activeLayerType && activeLayerType.split('-')[0] === 'qr' ? (
        <QRCodeCustomizationSection activeLayer={activeLayer} setDynamicQrType={setDynamicQrType} setActiveMicroPageType={setMicroPageTypeBasedOnQrType}  />
      ) : activeLayerType && activeLayerType.split('-')[0] === 'background' ? (
        <BackgroundCustomizationSection />
      ) : null}
    </div>
  )
}

const mapState = (state) => ({
  resetStatus: getResetStatus(state),
  activeLayer: getActiveLayer(state),
  activeLayerType: getActiveLayerType(state),
})

const mapDispatch = (dispatch) => ({
  onResetData: (type) => dispatch(resetData(type)),
  onSetActiveMicroPageType: (type) => dispatch(setActiveMicroPageType(type)),
})

export default connect(mapState, mapDispatch)(CustomizeSection)
