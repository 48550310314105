import React from "react";

function List({ stats }) {
  return (
    <div className="list-wrapper">
      <div className="list">
        {stats
          .sort((a, b) => a.count < b.count)
          .map((item) => (
            <div className="item">
              <div className="name">{item._id}</div>
              <div className="value">{item.count}</div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default List;
