import React, {useEffect, useState} from "react";
import './FeedbackForm.scss'
import Select from 'react-select'
import Errors from "../Errors";
import {ReactComponent as Cross} from "../../../common/images/elements/cross.svg";
import {useForm, useWatch} from "react-hook-form";

function Item({ item, itemField, onUpdate, onDelete, ...rest }) {

    const {
        setValue,
        getValues,
        register,
        watch,
        formState,
        control,
    } = useForm({
        mode: "onTouched" // "onChange"
    });

    const options = [
        {
            value: 'text',
            label: <div className="d-flex justify-content-center">
                <div style={
                    {
                        'backgroundImage': 'url(/assets/icons/text-field.svg)',
                        'width': '100%',
                        'backgroundSize': 'contain',
                        'backgroundRepeat': 'no-repeat',
                        'backgroundPosition': 'center'
                    }
                }>&nbsp;</div>
            </div>
        },
        {
            value: 'boolean',
            label: <div className="d-flex justify-content-center">
                <div style={
                    {
                        'backgroundImage': 'url(/assets/icons/yes_no-field.svg)',
                        'width': '100%',
                        'backgroundSize': 'contain',
                        'backgroundRepeat': 'no-repeat',
                        'backgroundPosition': 'center'
                    }
                }>&nbsp;</div>
            </div>
        },
        {
            value: 'scale',
            label: <div className="d-flex justify-content-center">
                <div style={
                    {
                        'backgroundImage': 'url(/assets/icons/scale-field.svg)',
                        'width': '100%',
                        'backgroundSize': 'contain',
                        'backgroundRepeat': 'no-repeat',
                        'backgroundPosition': 'center'
                    }
                }>&nbsp;</div>
            </div>
        },
    ]

    const initialOption = options.filter((option) => option.value === item.type)[0] ?? options[0];

    useEffect(() => {
        setValue('id', item.id)
        setValue(itemField, item[itemField])
        setValue('type', item.type)
    }, [])

    // watch url changes
    const field = useWatch({
        defaultValue: item[itemField],
        control,
        name: itemField
    })

    useEffect(() => {
        onUpdate(
            {
                ...item,
                [itemField]: field
            }
        );

    }, [field])


    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            width: '100%',
            paddingRight: '6px',
        }),
        control: (provided, state) => ({
            ...provided,
            border: 'none',
            backgroundColor: 'white',
            boxShadow: 'none',
            cursor: 'pointer'
        }),
        option: (provided, {data, isDisabled, isFocused, isSelected}) => ({
            ...provided,
            border: 'none',
            backgroundColor: isFocused
                ? '#ebf3ff'
                : '',
            cursor: 'pointer'
        }),

    }

    function handleSelectChange(option) {
        onUpdate({...item, type: option.value})
    }

    return (
        <div className="format-container">
            <div className="inputs-container">
                <div className="input-container">
                    <input name={itemField} type="text" placeholder="Question"
                           {...register(itemField, {required: true, maxLength: 20})}/>

                    {/* Cross/Trash button */}
                    {
                        watch(itemField) !== ''
                            ?
                            <Cross onClick={() => setValue(itemField, '')}/>
                            :
                            <div className="float-right trash-button" onClick={() => onDelete(item.id)}>
                                <img className='blue-image' src="assets/icons/delete.svg" width="15" alt=""/>
                            </div>
                    }

                </div>
                <Errors formState={formState} name={itemField}/>

                <div className="input-container py-2">
                    <Select
                        styles={customStyles}
                        onChange={handleSelectChange}
                        isSearchable={false} options={options} defaultValue={initialOption} components={{
                        IndicatorSeparator: () => null,
                        // DropdownIndicator: () => <img className='blue-image' src="assets/icons/arrow_down.svg" width="8" alt=""/>,
                    }}/>
                </div>
            </div>
        </div>
    )
}

export default Item;
