import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { ReactSVG } from 'react-svg'

import { updateDesign } from '../../../../store/card/cardActions'
import { getActiveLayer, getUploadedIcons, getUpdateStatus, getIcons } from '../../../../store/card/cardSelectors'
import AddImageButton from '../common/AddImageButton'

import { FormattedMessage } from 'react-intl'

import './IconsList.scss'
import { active } from 'glamor'

class IconsList extends Component {
  constructor(props) {
    super(props)
    this.iconRef = createRef()
    this.state = {
      activeLayer: props.activeLayer,
      tag: '',
    }
  }

  componentDidMount() {
    setTimeout(() => this.scrollToActiveIcon(), 300);
  }

  scrollToActiveIcon = () => {
    const section = document.querySelector('.icon.active');
    section.parentNode.scrollTop = section.offsetTop - section.parentNode.offsetTop;
  }

  handleIconChange = (name, colorPickerType) => {
    const { onUpdateDesign } = this.props
    const { activeLayer } = this.props
    const changedLayer = { ...activeLayer }

    changedLayer.iconName = name

    if (colorPickerType) changedLayer.colorPickerType = colorPickerType

    this.setState({
      activeLayer: changedLayer,
    })

    onUpdateDesign(changedLayer)
  }

  handleInputChange = ({ target: { value } }) => {
    this.setState({
      tag: value,
    })
  }

  handleStylesChange = (type, value) => {
    const { onUpdateDesign } = this.props
    const { activeLayer } = this.props
    const changedLayer = { ...activeLayer }
    const changedLayerStyles = { ...changedLayer.styles }

    changedLayerStyles[type] = value
    changedLayer.styles = changedLayerStyles

    this.setState({
      activeLayer: changedLayer,
    })

    onUpdateDesign(changedLayer)
  }

  render() {
    const { tag, activeLayer } = this.state
    const { uploadedIcons, icons, iconsType } = this.props
    let iconsList = []
    let isIconsFound = false

    icons.forEach((icon) => {
      if (icon.icon.name.indexOf(tag) !== -1) {
        isIconsFound = true
      }
    })

    icons.forEach((icon) => {
      const iconPrefix = icon.icon.name.split('-')[0]

      if (icon.icon.name === 'remove-icon') {
        iconsList.push(
          <div
            key='remove-icon'
            onClick={() => this.handleIconChange(null)}
            className={`icon ${activeLayer.iconName === icon.icon.url ? 'active' : null}`}
          >
            <ReactSVG src={`${icon.icon.url}`} />
          </div>,
        )
      } else if (isIconsFound) {
        if (icon.icon.name.indexOf(tag) !== -1) {
          iconsList.push(
            <div
              key={icon.icon.url}
              onClick={() => this.handleIconChange(icon.icon.url)}
              className={`icon ${activeLayer.iconName === icon.icon.url ? 'active' : null} ${iconPrefix !== 'qr' ? 'solid' : ''}`}
            >
              <ReactSVG src={`${icon.icon.url}`} />
            </div>,
          )
        }
      } else {
        iconsList.push(
          <div
            key={icon.icon.url}
            onClick={() => this.handleIconChange(icon.icon.url)}
            className={`icon ${activeLayer.iconName === icon.icon.url ? 'active' : null} ${iconPrefix !== 'qr' ? 'solid' : ''}`}
          >
            <ReactSVG src={`${icon.icon.url}`} />
          </div>,
        )
      }
    })

    if (uploadedIcons && activeLayer.colorPickerType === 'Colorful') {
      uploadedIcons.forEach((icon) => {
        iconsList.push(
          <div
            key={icon.icon}
            onClick={() => this.handleIconChange(icon.name)}
            className={`icon ${activeLayer.iconName === icon.name ? 'active' : null}`}
            ref={activeLayer.iconName === icon.icon.url && this.iconRef}
          >
            <img src={icon.icon} alt={icon.name} />
          </div>,
        )
      })
    }

    return (
      <div className="icons-list-section">
        <div className="search-upload">
          <div className="search-input">
            <FormattedMessage id="iconslist.search">
              {(msg) => <input type="text" placeholder={msg} value={tag} onChange={(e) => this.handleInputChange(e)} />}
            </FormattedMessage>
          </div>
          <div className="primary-button-wrapper">
            <AddImageButton handleIconChange={this.handleIconChange} />
          </div>
        </div>
        <div className={`icons-list ${iconsType === 'Colorful' ? 'colorful' : ''}`}>
          <div className="icon">
            <img onClick={() => this.handleIconChange(null)} src="/assets/icons/qr-code-icons-list/remove-icon.svg" alt="" />
          </div>
          {iconsList}
          </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  activeLayer: getActiveLayer(state),
  uploadedIcons: getUploadedIcons(state),
  updateStatus: getUpdateStatus(state),
})

const mapDispatch = (dispatch) => ({
  onUpdateDesign: (layer) => dispatch(updateDesign(layer)),
})

export default connect(mapState, mapDispatch)(IconsList)
