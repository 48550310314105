// shape by conf
const mappedShapesOnComponent = [
  // conf: [Top, Right, Bottom, Left] ,
  {
    conf: [0, 0, 0, 0],
    type: 'alone',
  },

  // 1 filled
  {
    conf: [1, 0, 0, 0],
    type: 'bottom', // "▼"
  },
  {
    conf: [0, 1, 0, 0],
    type: 'left', // "◀"
  },
  {
    conf: [0, 0, 1, 0],
    type: 'top', // "▲"
  },
  {
    conf: [0, 0, 0, 1],
    type: 'right', // "▶"
  },

  // 2 neighbore filled
  {
    conf: [1, 1, 0, 0],
    type: 'topright', // "◥"
  },
  {
    conf: [0, 1, 1, 0],
    type: 'bottomright', // "◢"
  },
  {
    conf: [0, 0, 1, 1],
    type: 'bottomleft', // "◣"
  },
  {
    conf: [1, 0, 0, 1],
    type: 'topleft', // "◤"
  },

  // 2 opposite  filled
  {
    conf: [1, 0, 1, 0],
    type: 'fit', // "■"
  },
  {
    conf: [0, 1, 0, 1],
    type: 'fit', // "■"
  },

  // 3 neighbours
  {
    conf: [0, 1, 1, 1],
    type: 'fit', // "■"
  },
  {
    conf: [1, 0, 1, 1],
    type: 'fit', // "■"
  },
  {
    conf: [1, 1, 0, 1],
    type: 'fit', // "■"
  },
  {
    conf: [1, 1, 1, 0],
    type: 'fit', // "■"
  },

  // 4 neighbours
  {
    conf: [1, 1, 1, 1],
    type: 'fit', // "■"
  },
]

export default mappedShapesOnComponent
