import {BACKEND_URL} from "../utils/constants";
import {store} from "../store/initializator";
import {ProductPage} from "../ts/types";

const getToken = () => {
    // @ts-ignore
    return store.getState().auth.userData.jwt;
}

// TODO: craeate reusable func
const getAuthHeader = (token): object => {
    return token ? {Authorization: `Bearer ${token}`} : {}
}

/*
    Product page
 */
export const apiGetProductPage = async (id): Promise<ProductPage|null> => {
    const url = `${BACKEND_URL}/info-pages/${id}`

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            ...getAuthHeader(getToken()),
        },
    });

    if (response.ok) {
        return response.json();
    }

    return null;

}

// TODO: refactor not no return null
export const apiCreateProductPage = async (page): Promise<ProductPage|null> => {
    const url = `${BACKEND_URL}/info-pages`

    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            ...getAuthHeader(getToken()),
        },
        body: JSON.stringify(page)
    });

    if (response.ok) {
        return response.json();
    }

    return null;
}

export const apiUpdateProductPage = async (page) => {
    const url = `${BACKEND_URL}/info-pages/${page.id}`

    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(page)
    });

    if (response.ok) {
        return response.json();
    }
}

/*
    Feedback Form
 */
export const apiGetFeedbackForm = async (id): Promise<ProductPage|null> => {
    const url = `${BACKEND_URL}/feedback-forms/${id}`

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            ...getAuthHeader(getToken()),
        },
    });

    if (response.ok) {
        return response.json();
    }

    return null;

}

export const apiCreateFeedbackForm = async (page) => {
    const url = `${BACKEND_URL}/feedback-forms`

    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(page)
    });

    if (response.ok) {
        return response.json();
    }
}

export const apiUpdateFeedbackForm = async (page) => {
    const url = `${BACKEND_URL}/feedback-forms/${page.id}`

    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(page)
    });

    if (response.ok) {
        return response.json();
    }
}

/*
    Digital card
 */
export const apiGetDigitalCard = async (id): Promise<ProductPage|null> => {
    const url = `${BACKEND_URL}/digital-cards/${id}`

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            ...getAuthHeader(getToken()),
        },
    });

    if (response.ok) {
        return response.json();
    }

    return null;

}

export const apiCreateDigitalCard = async (page) => {
    const url = `${BACKEND_URL}/digital-cards`

    const response = await fetch(url, {
        method: "POST",
        // @ts-ignore
        headers: {
            'Content-Type': 'application/json',
            ...getAuthHeader(getToken()),
        },
        body: JSON.stringify(page)
    });

    if (response.ok) {
        return response.json();
    }
}

export const apiUpdateDigitalCard = async (page) => {
    const url = `${BACKEND_URL}/digital-cards/${page.id}`

    const response = await fetch(url, {
        method: "PUT",
        // @ts-ignore
        headers: {
            'Content-Type': 'application/json',
            ...getAuthHeader(getToken()),
        },
        body: JSON.stringify(page)
    });

    if (response.ok) {
        return response.json();
    }
}

/*
    Multi Entry
 */
export const apiGetMultiEntry = async (id): Promise<ProductPage|null> => {
    const url = `${BACKEND_URL}/multi-entries/${id}`

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            ...getAuthHeader(getToken()),
        },
    });

    if (response.ok) {
        return response.json();
    }

    return null;

}

export const apiCreateMultiEntry = async (page) => {
    const url = `${BACKEND_URL}/multi-entries`

    const response = await fetch(url, {
        method: "POST",
        // @ts-ignore
        headers: {
            'Content-Type': 'application/json',
            ...getAuthHeader(getToken()),

        },
        body: JSON.stringify(page)
    });

    if (response.ok) {
        return response.json();
    }
}

export const apiUpdateMultiEntry = async (page) => {
    const url = `${BACKEND_URL}/multi-entries/${page.id}`

    const response = await fetch(url, {
        method: "PUT",
        // @ts-ignore
        headers: {
            'Content-Type': 'application/json',
            ...getAuthHeader(getToken()),

        },
        body: JSON.stringify(page)
    });

    if (response.ok) {
        return response.json();
    }
}
