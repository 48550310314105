import React from 'react';

function Location() {
    return (
        <svg width="14" height="20" viewBox="0 0 14 20" fill="#000" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.38372 0.480469C3.74037 0.480469 0.785156 3.43569 0.785156 7.07903C0.785156 12.028 7.38372 19.3335 7.38372 19.3335C7.38372 19.3335 13.9823 12.028 13.9823 7.07903C13.9823 3.43569 11.0271 0.480469 7.38372 0.480469ZM7.38372 9.43569C6.08285 9.43569 5.0271 8.37993 5.0271 7.07907C5.0271 5.77821 6.08285 4.72241 7.38372 4.72241C8.68458 4.72241 9.74033 5.77817 9.74033 7.07903C9.74033 8.37989 8.68458 9.43569 7.38372 9.43569Z"/>
        </svg>
    );
}

export default Location;
