import React, {useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl'
import {BACKEND_URL} from '../../../../utils/constants'
import RatingStars from "./RatingStars";
import {apiCreateFeedbackData} from "../../../../api/api"
import {useForm} from "react-hook-form";
import Loader from "../../customization/common/Loader";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useIsMobile from "../../../hooks/useIsMobile";

function FeedbackForm({slug, cardData, isPreview}) {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: {errors, isValid, isDirty, dirtyFields, touchedFields}
    } = useForm({
        mode: "onTouched" // "onChange"
    });

    const [notFound, setNotFound] = useState(false)
    const [loading, setLoading] = useState(false)

    const [data, setData] = useState(cardData || null)
    const [image, setImage] = useState(cardData?.image || null)
    const [items, setItems] = useState(cardData?.items || [])

    const url = `${BACKEND_URL}/feedback-forms-by-slug/${slug}`

    function initializeAnswerFieldInItems(items) {
        return items.map((item) => {
            // default is one star
            if (item.type === 'scale') {
                return {...item, answer: 1}
            } else if (item.type === 'boolean') {
                return {...item, answer: true}
            }
            return {...item, answer: ''}
        })
    }

    useEffect(() => {
        if (slug) {
            fetch(url)
                .then(res => {
                    if (res.status === 404) {
                        setNotFound(true);
                        throw new Error(res.status + " Page not found ");
                    }

                    return res.json();
                })
                .then((result) => {
                    setData(result);
                    setItems(initializeAnswerFieldInItems(result.items))
                    setImage(result.image)
                }).catch((error) => {
            })
        }
    }, []);

    useEffect(() => {
        setData(cardData)
        setImage(cardData?.image)
        setItems(cardData?.items ?? [])
    }, [cardData])

    function setTextValue(updatedItem, e) {
        const newItems = [...items];

        const index = newItems.findIndex((item) => item.id === updatedItem.id)

        newItems[index] = {...updatedItem, answer: e.target.value}
        setItems(newItems)
    }

    function setBooleanValue(updatedItem, value) {
        const newItems = [...items];

        const index = newItems.findIndex((item) => item.id === updatedItem.id)

        newItems[index] = {...updatedItem, answer: value}
        setItems(newItems)
    }

    function setScaleValue(updatedItem, value) {
        const newItems = [...items];

        const index = newItems.findIndex((item) => item.id === updatedItem.id)

        newItems[index] = {...updatedItem, answer: value}
        setItems(newItems)
    }

    function resolveActiveClass(item, boolean) {
        if (item.answer === boolean) return 'active'
    }

    function onError(data, e) {
        toast.warn('Form is not completed')
    }

    function onSubmit(submitData, e) {
        e.preventDefault()
        setLoading(true);
        apiCreateFeedbackData(items, data.id)
            .then(() => {
                window.location.href = '/thankyou'
            })
            .catch(() => {
                toast.error("Oups!")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function validateItem(value) {
        return value !== null;
    }

    function handleKeyDown(e) {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
        // In case you have a limitation
        // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
    }

    const BrownTheme = React.lazy(() => import('./themes/brownTheme'));
    const BlueTheme = React.lazy(() => import('./themes/blueTheme'));
    const BlackTheme = React.lazy(() => import('./themes/blackTheme'));
    const GreenTheme = React.lazy(() => import('./themes/greenTheme'));

    const pageRef = useRef();
    const isMobile = useIsMobile(pageRef);

    return (
        <>
            {data &&
                <div id="micro-page-wrapper" ref={pageRef} className={data.theme}>
                    <div className="feedback-page-wrapper">
                        <form onSubmit={handleSubmit(onSubmit, onError)} className={`feedback`}>
                            <ToastContainer/>
                            <React.Suspense fallback={<></>}>
                                {(data.theme === 'brown') && <BrownTheme/>}
                                {(data.theme === 'blue') && <BlueTheme/>}
                                {(data.theme === 'black') && <BlackTheme/>}
                                {(data.theme === 'green') && <GreenTheme/>}
                            </React.Suspense>
                            {/* Header */}
                            <div className="feedback__header">
                                {image
                                    ?
                                    <div className={isMobile ? 'logo-mobile' : 'logo'} style={{
                                        backgroundImage: `url(${data.image.url})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        position: 'relative',
                                        top: '-2px'
                                    }}>
                                        {/*<img src={data.image.url} alt="Feedback Form"/>*/}
                                        <div className="text w-100 p-5 with-image">{data.name}</div>
                                    </div>
                                    :
                                    <div>
                                        <div className="w-100" alt="Feedback Form">&nbsp;</div>
                                        <div className="text w-100 p-5 without-image" style={{
                                            lineHeight: '60px'
                                        }}>{data.name}</div>
                                    </div>
                                }
                            </div>

                            <div className="feedback__body">
                                {
                                    items.map((item, index) => {
                                        const number = index + 1;

                                        if (item.type === 'text') {
                                            return (
                                                <div className="feedback__item">
                                                    <div className="feedback__item-label">{number}. {item.question}</div>

                                                    <textarea {...register(item.id, {required: true})} onKeyDown={handleKeyDown}
                                                              type="text" value={items.answer} onChange={(e) => {
                                                        setTextValue(item, e)
                                                    }} className="feedback__item-textarea"/>

                                                    {/*<textarea onKeyDown={handleKeyDown} type="text" className="feedback__item-textarea" />*/}


                                                    {/*{errors?.[item.id]?.type === "required" && <p>This field is required</p>}*/}

                                                </div>
                                            )
                                        } else if (item.type === 'scale') {
                                            return (
                                                <div key={`${number}-scale`} className="feedback__item">
                                                    {/* TODO: refactor radio */}

                                                    <div className="feedback__item-label">{number}. {item.question}</div>

                                                    <RatingStars {...register(item.id)} key={index} item={item}
                                                                 onChangeHandler={setScaleValue}/>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className="feedback__item">
                                                    <div className="feedback__item-label">{number}. {item.question}</div>
                                                    <div className="feedback__item-buttons">

                                                        <label htmlFor={item.id + 'y'} className={resolveActiveClass(item, true)}>
                                                            Yes
                                                        </label>

                                                        <input
                                                            id={item.id + 'y'}
                                                            className="boolean-button"
                                                            type="radio"
                                                            {...register(item.id)}
                                                            value={item.answer}
                                                            onChange={() => {
                                                                setBooleanValue(item, true)
                                                            }}
                                                            style={{
                                                                opacity: 0,
                                                                width: '1px',
                                                                height: '1px'
                                                            }}
                                                        >
                                                        </input>
                                                        {/*<FormattedMessage id="microweb.feedbackForm.yes"/>*/}

                                                        <label htmlFor={item.id + 'n'} className={resolveActiveClass(item, false)}>
                                                            No
                                                        </label>
                                                        <input
                                                            id={item.id + 'n'}
                                                            type="radio"
                                                            {...register(item.id)}
                                                            value={item.answer}
                                                            onChange={() => {
                                                                setBooleanValue(item, false)
                                                            }}
                                                            style={{
                                                                opacity: 0,
                                                                width: '1px',
                                                                height: '1px'
                                                            }}
                                                        >
                                                        </input>
                                                        {/*<FormattedMessage id="microweb.feedbackForm.no"/>*/}

                                                        {/*{errors?.[item.id]?.type === "validate" && <p>This field is required</p>}*/}

                                                    </div>
                                                </div>
                                            )
                                        }
                                    })

                                }
                            </div>

                            {/* Submit */}
                            <div className="feedback__send-button">
                                <button className={`action-button ${isPreview ? 'disabled' : ''}`} type="submit"><FormattedMessage id="microweb.feedbackForm.confirm"/></button>
                                {/* Loader */}
                                {loading &&
                                    <Loader loading={loading} color="#000"/>
                                }
                            </div>
                        </form>

                    </div>

                </div>


            }
            </>
    )
}

export default FeedbackForm;
