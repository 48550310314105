import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {ReactComponent as Cross} from "../../common/images/elements/cross.svg";

function Inputs({type, config, changeFormatInput, setErrors}) {
  const [localConfig, setLocalConfig] = useState(config)
  const lang = useSelector((store) => store.intl.currentLocale)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [isWalletTypeOther, setIsWalletTypeOther] = useState(false)

  useEffect(() => {
    setLocalConfig(config)
  }, []);

  useEffect(() => {
    if (!localConfig.walletType) return
    if (localConfig.walletType.content === 'Other') {
      setIsWalletTypeOther(true)
    } else {
      setIsWalletTypeOther(false)
    }
  }, [localConfig])

  const updateInput = (input, value) => {
    if (type === "QRWebsiteURL" || type === "QRCloudFile" || type === "QRSocialNetwork") {
      const validationResult = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(value)
      setErrors({ "website": !validationResult && value !== "" ? "Vámi zadaný text nevypadá jako odkaz" : "" })
    } else if (type === "QREmail") {
      const validationResult = /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(value)
      setErrors({ "email": !validationResult && value !== "" ? "Vámi zadaný text nevypadá jako email" : "" })
    } else if (type === "QRLocation") {
      if (input !== "googleFormat") {
        if (!isNaN(value)) {
          setErrors({ input: "" })
          if (input === "latitude") setErrors({ input: (parseFloat(value) >= -90 && parseFloat(value) <= 90) || value === "" ? "" : "Vámi zadaný text nemá formát latitude" })
          else setErrors({ input:  (parseFloat(value) >= -180 && parseFloat(value) <= 180) || value === "" ? "" : "Vámi zadaný text nemá formát longtitude" })
        }
        else
          setErrors({ input: value !== ""? "Musíte zadat pouze číselný údaj" : "" })
      }
    } else if (type === "QRPayment" && input === 'iban') {
      value = value.replace(" ", "")
      const validationResult = /^(?:(?:CR|DE|ME|RS|VA)\d{20}|(?:CZ|ES|SE|SK|TN)\d{22}|(?:DK|FI|FO|GL|SD)\d{16}|(?:AT|BA|EE|LT|XK)\d{18}|(?:AE|IL|TL)\d{21}|(?:LY|PT|ST)\d{23}|(?:IT|SM)\d{2}[A-Z]\d{10}[A-Za-z0-9]{12}|(?:HU|PL)\d{26}|(?:AL|CY)\d{10}[A-Za-z0-9]{16}|(?:CH|LI)\d{7}[A-Za-z0-9]{12}|(?:FR|MC)\d{12}[A-Za-z0-9]{11}\d{2}|(?:GB|IE)\d{2}[A-Z]{4}\d{14}|(?:KZ|LU)\d{5}[A-Za-z0-9]{13}|(?:GI|IQ)\d{2}[A-Z]{4}[A-Za-z0-9]{15}|(?:PK|RO)\d{2}[A-Z]{4}[A-Za-z0-9]{16}|(?:PS|QA)\d{2}[A-Z]{4}[A-Za-z0-9]{21}|AD\d{10}[A-Za-z0-9]{12}|AZ\d{2}[A-Z]{4}[A-Za-z0-9]{20}|BE\d{14}|BG\d{2}[A-Z]{4}\d{6}[A-Za-z0-9]{8}|BH\d{2}[A-Z]{4}[A-Za-z0-9]{14}|BR\d{25}[A-Z][A-Za-z0-9]|BY\d{2}[A-Za-z0-9]{4}\d{4}[A-Za-z0-9]{16}|DO\d{2}[A-Za-z0-9]{4}\d{20}|EG\d{27}|GE\d{2}[A-Z]\d{16}|GT\d{2}[A-Za-z0-9]{24}|GR\d{9}[A-Za-z0-9]{16}|HR\d{19}|IS\d{24}|JO\d{2}[A-Z]{4}\d{4}[A-Za-z0-9]{18}|KW\d{2}[A-Z]{4}[A-Za-z0-9]{22}|LC\d{2}[A-Z]{4}[A-Za-z0-9]{24}|LB\d{6}[A-Za-z0-9]{20}|LV\d{2}[A-Z]{4}\d{13}|MD\d{2}[A-Za-z0-9]{20}|MK\d{5}[A-Za-z0-9]{10}\d{2}|MR\d{25}|MT\d{2}[A-Z]{4}\d{5}[A-Za-z0-9]{18}|MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}|NL\d{2}[A-Z]{4}\d{10}|NO\d{13}|SA\d{4}[A-Za-z0-9]{18}|SC\d{2}[A-Z]{4}\d{20}[A-Z]{3}|SI\d{17}|SV\d{2}[A-Z]{4}\d{20}|TR\d{8}[A-Za-z0-9]{16}|UA\d{8}[A-Za-z0-9]{19}|VG\d{2}[A-Z]{4}\d{16}|GE\d{2}[A-Z]{2}\d{16})$/.test(value)
      setErrors({ "payment": !validationResult && value !== "" ? "IBAN nemá správný formát" : "" })
    } else if (type === "QRPhone") {
      const validationResult = /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value)
      setErrors({ "phone": !validationResult && value !== "" ? "Telefonní číslo nemá správný formát" : "" })
    } else if (type === "QRVCard") {
      if (input === "web") {
        const validationResult = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(value)
        setErrors({ "website": !validationResult && value !== "" ? "Vámi zadaný text nevypadá jako odkaz" : "" })
      } else if (input === "email") {
        const validationResult = /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(value)
        setErrors({ "email": !validationResult && value !== "" ? "Vámi zadaný text nevypadá jako email" : "" })
      } else {
        const validationResult = /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value)
        setErrors({ "phone": !validationResult && value !== "" ? "Telefonní číslo nemá správný formát" : "" })
      }
    }
    const editedLocalConfig = {...localConfig}

    editedLocalConfig[input].content = value

    changeFormatInput(input, value)
    setLocalConfig(editedLocalConfig)
  }

  const handleCheckboxChange = (e, input) => {
    updateInput(input, e.target.checked)
  }

  const walletTypes = ['Bitcoin', 'Bitcoin Cash', 'Ethereum', 'Litecoin', 'Dash', 'Monero', 'Other']
  const currencyTypes = ['CZK', 'EUR', 'PLN']
  const encryptionTypes = ['WPA - default encryption', 'WEP - old type encryption', 'none']

  return (
    <div className="inputs-container">
      {Object.keys(localConfig).map((input, index) => {
        if (input === 'currency' || input === 'encryption')
          return (
            <ButtonDropdown
              className="dropdown input-container"
              isOpen={dropdownOpen}
              toggle={() => setDropdownOpen((prev) => !prev)}
              >
                <DropdownToggle caret>
                  <input
                    style={{pointerEvents: 'none'}}
                    placeholder={localConfig[input].placeholder}
                    value={localConfig[input].content}
                    type="text"
                  />
                </DropdownToggle>
                <DropdownMenu>
                  {input === 'currency' && currencyTypes.map((type) => <DropdownItem
                    onClick={() => updateInput(input, type)}>{type}</DropdownItem>)}
                    {input === 'encryption' &&
                    encryptionTypes.map((type) => <DropdownItem onClick={() => updateInput(input, type)}>{type}</DropdownItem>)}
                  </DropdownMenu>
                </ButtonDropdown>
              )

            if (localConfig[input].placeholder)
              return (
                <div
                  key={index}
                  className={`input-container ${(input === 'amount') & (type === 'QRPayment') && 'shorter'} ${
                    isWalletTypeOther & (input === 'amount') && 'hidden'}`}
                >
                  <input
                    type="text"
                    value={localConfig[input].content}
                    placeholder={localConfig[input].placeholder[lang === 'en' ? 0 : 1]}
                    onChange={(e) => updateInput(input, e.target.value)}
                  />
                  <Cross className={localConfig[input].content ? '' : 'hidden'}
                         onClick={(e) => updateInput(input, '')}/>
                </div>
              )

      })}
      {type === 'QRLocation' && (
        <div style={{ width: "100%", borderTop: "1px solid rgba(23, 35, 57, 0.08)", paddingTop: "5px", display: "flex", gap: "5px" }}>
          <input
            checked={localConfig['googleFormat'].content}
            className="checkbox"
            type="checkbox"
            id="google-format"
            name="google-format"
            onClick={(e) => handleCheckboxChange(e, 'googleFormat')}
          />
          <label htmlFor="google-format">{lang === 'en' ? 'Google Format' : 'Google Formát'}</label>
        </div>
      )}
    </div>
  )
}

export default Inputs;
