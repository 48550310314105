import {useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import {get, createScan} from '../../api/qrcode'
import {MicroPageType} from "../../ts/types";
import {
  apiGetDigitalCard, apiGetFeedbackForm, apiGetMultiEntry, apiGetProductPage
} from "../../api/micropage";

const RedirectPage = () => {
  const location = useLocation()
  const id = location.pathname.split('/').pop() || ''

  const handleRedirect = async () => {
    try {
      await createScan(id)
      // const res = await createScan(id)
      // const { type, config } = res.config

      // const { CloudFile, WebsiteURL, SocialNetwork } = QRCodeType

      // if (type == CloudFile || type == WebsiteURL || type == SocialNetwork) {
      //   const url = config.url.content

      //   if (!url.match(/^http?:\/\//i) || !url.match(/^https?:\/\//i)) {
      //     window.location.href = 'https://' + url
      //   }
      // }
      // window.location.href = '/success'
      const url = await resolveRedirectUrl()

      if (url[0] !== '/') {
        window.location.href = 'https://' + url
      } else {
        window.location.href = url;
      }
    } catch (e) {
      console.error(e);
      window.location.href = '/'
    }
  }
  useEffect(() => {
    handleRedirect()
  }, [])

  async function resolveRedirectUrl() {
    const dynamicQrCode = await get(id);

    if (dynamicQrCode.microPage) {
      return await resolveUrlFromMicroPage(dynamicQrCode.microPage)
    }

    return dynamicQrCode.url;
  }

  async function resolveUrlFromMicroPage(microPage) {
    const type = microPage.type;
    const id = microPage.id;

    switch (type) {
      case MicroPageType.PRODUCT_PAGE: {
        const microPage = await apiGetProductPage(id)
        // todo: pp as constant
        return `/pp/${microPage.slug}`
      }
      case MicroPageType.FEEDBACK_FORM: {
        const microPage = await apiGetFeedbackForm(id)
        return `/ff/${microPage.slug}`
      }
      case MicroPageType.DIGITAL_CARD: {
        const microPage = await apiGetDigitalCard(id)
        return `/dc/${microPage.slug}`
      }
      case MicroPageType.MULTI_ENTRY: {
        const microPage = await apiGetMultiEntry(id)
        return `/me/${microPage.slug}`
      }
    }


  }

  return 'Přesměrovávám...'
}


export default RedirectPage
