import React, {useEffect} from "react";

function MicroPageWrapper(props) {

    const slug = props.match.params.slug;

    useEffect(() => {
        // TODO: refactor!
        // I have to set height to 100%, but in modal has to be still auto
        setFullHeight();
    }, [])


    function setFullHeight() {
        const el = document.getElementById('micro-page-wrapper');

        if (el) {
            el.style.height = '100%'
        } else {
            setTimeout(() => {
                setFullHeight()
            }, 100);
        }
    }


    return (
        <div style={{
            height: '100%',
            overflow: 'auto'
        }}>
            {React.Children.map(props.children, (child, key) => {
                return React.createElement(child.type, {
                    ...{
                        ...child.props,
                        slug: slug,
                    }
                })
            })}

        </div>
    );
}

export default MicroPageWrapper;
