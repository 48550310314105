import React from "react";
import './PlusButton.scss'

function PlusButton() {
    return (
            <svg className="plus-button" width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.636364" y="0.636364" width="54.7273" height="54.7273" rx="27.3636" fill="white" stroke="#BCBFC6"
                      strokeWidth="1.27273"/>
                <path d="M16.5449 29H39.454" stroke="#38517C" strokeWidth="2.54545" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <path d="M27.999 17.5459L27.999 40.455" stroke="#38517C" strokeWidth="2.54545" strokeLinecap="round"
                      strokeLinejoin="round"/>
            </svg>
    )
}

export default PlusButton;
