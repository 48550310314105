import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import HeatMapGraph from "./HeatMapGraph";
import ReactTooltip from "react-tooltip";
import List from "./List";
function Location({ stats }) {
  const [display, setDisplay] = useState("graph");
  const [tooltipContent, setTooltipContent] = useState("");

  // useEffect(() => {
  //   ReactTooltip.rebuild();
  //   console.log("rebiuld");
  // }, [tooltipContent]);
  return (
    <div className="location">
      <div className="title">
        <h2><FormattedMessage id="dashboard.statistics.location"/></h2>
        <div className="buttons-wrapper">
          <button
            onClick={() => setDisplay("graph")}
            className={`switch-button ${display === "graph" ? "selected" : ""}`}
          >
            <FormattedMessage id="dashboard.statistics.map"/>
          </button>
          <button
            onClick={() => setDisplay("list")}
            className={`switch-button ${display === "list" ? "selected" : ""}`}
          >
            <FormattedMessage id="dashboard.statistics.list"/>
          </button>
        </div>
      </div>
      <div className="view">
        {display === "graph" ? (
          <>
            <HeatMapGraph stats={stats} setTooltipContent={setTooltipContent} />
            {/* <ReactTooltip id="heatmap-tooltip">Hello</ReactTooltip> */}
            <ReactTooltip className="heatmap-tooltip">
              {tooltipContent}
            </ReactTooltip>
          </>
        ) : (
          <List stats={stats} />
        )}
      </div>
    </div>
  );
}

export default Location;
