export const getDesigns = (state) => state.myLibrary.designs

export const getDynamicQrCodesCount = (state) => {
  const designs = getDesigns(state)
  let activeDynamicQrCodesCount = 0

  designs.forEach(design => {
    if (design.dynamicQrCode !== null) activeDynamicQrCodesCount++
  });

  return activeDynamicQrCodesCount
}