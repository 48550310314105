import {BACKEND_URL} from '../utils/constants';
import {CloudinaryImage, MicroPageType} from "../ts/types";
import {store} from "../store/initializator";
import {toast} from "react-toastify";

const getToken = () => {
  // @ts-ignore
  return store.getState().auth.userData.jwt;
}


export const apiGetImages = async (query, lang = "en") => {
  const url = `https://pixabay.com/api/?key=17618644-ee94a5a935cf3ac8ff8349261&q=${
    query ? query : ""
  }&per_page=200&image_type=photo&lang=${lang}`;

  const response = await fetch(url, {
    method: "GET",
  });

  if (response.ok) {
    return response.json();
  }
}

// TODO: craeate reusable func
const getAuthHeader = (token): object => {
  return token ? {Authorization: `Bearer ${token}`} : {}
}

export const apiLogin = async (email, password) => {
  const url = `${BACKEND_URL}/auth/local`

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      identifier: email,
      password: password,
    }),
    headers: {
      'Content-Type': 'application/json'
    },
  })

  if (response.ok) {
    return response.json()
  } else {
    throw(response.json())
  }
}

export const apiUpdatePassword = async (userData, password) => {
  const url = `${BACKEND_URL}/account/update-password`

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      email: userData.user.email,
      newPassword: password,
    }),
    // @ts-ignore
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader(userData.jwt),
    },
  })

  if (response.ok) {
    return response.json()
  } else {
    throw(response.json())
  }
}

export const apiSignup = async (email, password, currency) => {
  const url = `${BACKEND_URL}/auth/local/register`

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      username: email,
      email: email,
      password: password,
      currency,
    }),
    headers: {
      'Content-Type': 'application/json'
    },
  })

  if (response.ok) {
    return response.json()
  } else {
    throw(response.json())
  }
}

export const apiForgotPassword = async (email) => {
  const url = `${BACKEND_URL}/auth/forgot-password`

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      email,
    }),
    headers: {
      'Content-Type': 'application/json'
    },
  })

  if (response.ok) {
    return response.json()
  } else {
    throw(response.json())
  }
}

export const apiResetPassword = async (code, password, passwordConfirmation) => {
  const url = `${BACKEND_URL}/auth/reset-password`

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      code,
      password,
      passwordConfirmation
    }),
    headers: {
      'Content-Type': 'application/json'
    },
  })

  if (response.ok) {
    return response.json()
  } else {
    throw(response.json())
  }
}

export const apiLoginGoogle = async (search) => {
  const url = `${BACKEND_URL}/auth/google/callback${search}`
  const response = await fetch(url)

  if (response.ok) {
    return response.json()
  } else {
    throw(response.json())
  }
}

export const apiLoginFacebook = async (search) => {
  const url = `${BACKEND_URL}/auth/facebook/callback${search}`
  const response = await fetch(url)

  if (response.ok) {
    return response.json()
  } else {
    throw(response.json())
  }
}

export const apiGetUser = async (id) => {
  const url = `${BACKEND_URL}/users/${id}`
  const response = await fetch(url, {
    method: 'GET',
    // @ts-ignore
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader(getToken()),
    },
  })

  if (response.ok) {
    return response.json()
  } else {
    throw(response.json())
  }
}

export const apiUpdateUser = async (userData) => {
  const url = `${BACKEND_URL}/users/${userData.id}`
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(userData),
    // @ts-ignore
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader(getToken()),
    },
  })

  if (response.ok) {
    return response.json()
  } else {
    throw(response.json())
  }
}

export const apiDeleteUser = async (userData) => {
  const url = `${BACKEND_URL}/account/delete-account`

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      user_id: userData.user.id,
    }),
    // @ts-ignore
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader(userData.jwt),
    },
  })

  if (response.ok) {
    return response.json()
  } else {
    throw(response.json())
  }
}

export const apiChangeEmail = async (userData, email) => {
  const url = `${BACKEND_URL}/account/change-email`

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      email_sender: userData.user.email,
      email_receiver: email,
    }),
    // @ts-ignore
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader(userData.jwt),
    },
  })

  if (response.ok) {
    return response.json()
  } else {
    throw(response.json())
  }
}


export const apiGetIcons = async () => {
  const url = `${BACKEND_URL}/icons?_limit=-1`

  const response = await fetch(url, {
    method: "GET",
  });

  if (response.ok) {
    return response.json();
  }
}

export const apiGetQrIcons = async () => {
  const url = `${BACKEND_URL}/qr-icons?_limit=-1`

  const response = await fetch(url, {
    method: "GET",
  });

  if (response.ok) {
    return response.json();
  }
}

export const apiCreateOrder = async (orderData) => {
  const url = `${BACKEND_URL}/orders`

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(orderData),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (response.ok) {
    return response.json()
  } else {
    throw(response.json())
  }
}

export const apiGetOrders = async () => {
  const url = `${BACKEND_URL}/orders`

  const response = await fetch(url, {
    method: "GET",
  });

  if (response.ok) {
    return response.json();
  }
}

export const apiGetPrices = async () => {
  const url = `${BACKEND_URL}/prices`

  const response = await fetch(url, {
    method: "GET",
  });

  if (response.ok) {
    return response.json();
  }
}

export const apiGetSubscriptionPrices = async () => {
  const url = `${BACKEND_URL}/subscription-prices?limit=32`

  const response = await fetch(url, {
    method: "GET",
  });

  if (response.ok) {
    return response.json();
  }
}

export const apiCreateFeedbackData = async (items, feedbackFormId) => {
  const body = {
    items,
    feedbackFormId,
  }
  const url = `${BACKEND_URL}/feedback-data`

  const response = await fetch(url, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });

  if (response.ok) {
    return response.json();
  }
}

export const apiGetFeedbackData = async (feedbackFormId) => {
  const url = `${BACKEND_URL}/feedback-data/${feedbackFormId}`

  const response = await fetch(url, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (response.ok) {
    return response.json();
  }
}

export const apiGetTemplates = async () => {
  const url = `${BACKEND_URL}/templates?_limit=-1`

  const response = await fetch(url, {
    method: "GET",
  });

  if (response.ok) {
    return response.json();
  }
}

export const apiGetDesignById = async (id) => {
  const url = `${BACKEND_URL}/designs/${id}`

  const response = await fetch(url, {
    method: "GET",
  });

  if (response.ok) {
    return response.json();
  }
}

export const apiGetMyLibrary = async (type, page = 1, limit = 15) => {

  let url;
  // if (type === null) {
  //   url = `${BACKEND_URL}/designs?page=${page}&limit=${limit}`
  // } else {
  //   url = `${BACKEND_URL}/designs?page=${page}&limit=${limit}&type=${type}`
  // }
  url = `${BACKEND_URL}/designs?page=${page}&limit=${limit}` 

  const response = await fetch(url, {
    method: "GET",
    // @ts-ignore
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader(getToken()),
    },
  });

  if (response.ok) {
    return response.json();
  }
}

export const apiGetVCardURL = async (data) => {
  const url = `${BACKEND_URL}/digital-cards/${data.id}/vcf`

  const response = await fetch(url, {
    method: "GET",
  });

  if (response.ok) {
    return response.json();
  }
}

export const apiCreateDesign = async (design, name) => {

  // @ts-ignore
  const token = store.getState().auth.userData.jwt;

  const url = `${BACKEND_URL}/designs/`;

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      ...design,
      name: name
    }),
    // @ts-ignore
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader(token),
    },
  })

  if (response.ok) {
    return response.json()
  } else {
    throw(response.json())
  }
}


export const apiUpdateDesign = async (design, name) => {
// @ts-ignore
  const token = store.getState().auth.userData.jwt;

  const url = `${BACKEND_URL}/designs/${design.id}`;

  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify({
      ...design,
      name: name
    }),
    // @ts-ignore
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader(token),
    },
  })

  if (response.ok) {
    return response.json()
  } else {
    toast.error('Update design failed')
    throw(response.json())
  }
}


export const apiDeleteDesign = async (design) => {

  // @ts-ignore
  const token = store.getState().auth.userData.jwt;

  const url = `${BACKEND_URL}/designs/${design.id}`;

  const response = await fetch(url, {
    method: 'DELETE',
    // @ts-ignore
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader(token),
    },
  })

  if (response.ok) {
    return response.json()
  } else {
    throw(response.json())
  }
}


export const apiGetMyDesigns = async (token, page = 1, limit = 10) => {
  const url = `${BACKEND_URL}/designs?page=${page}&limit=${limit}`

  const response = await fetch(url, {
    method: "GET",
    // @ts-ignore
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader(token),
    },
  });

  if (response.ok) {
    return response.json();
  }
}

export const apiUploadFile = async (file): Promise<CloudinaryImage> => {
  const formData = new FormData();

  formData.append(`files`, file, 'file.name')

  return await fetch(`${BACKEND_URL}/upload`, {
    method: "POST",
    body: formData
  }).then(async (response) => {
    const files = await response.json();
    return files[0];
  })
}

export const apiChangeSubscriptionStatus = async (id, email, isCancelled) => {
  const url = `${BACKEND_URL}/subscription-status-change`

  const response = await fetch(url, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      userId: id,
      email,
      isCancelled,
    }),
  });

  if (response.ok) {
    return response.json();
  }
}

export const apiSendConfirmationEmail = async (email, lang) => {
  const url = `${BACKEND_URL}/send-confirmation-email`

  const response = await fetch(url, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email,
      lang,
    }),
  });

  if (response.ok) {
    return response.json();
  }
}
