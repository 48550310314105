import React, {useState} from "react";
import {button} from "glamor/ous";
import './EditText.scss';

function EditText({text, template, onSubmit}) {
  const [opened, setOpened] = useState(false);

  const [value, setValue] = useState(text);

  const submitAndClose = () => {
    onSubmit(template, value);
    setOpened(false);
  }

  return (
    <div className="edit-text my-1">
      {opened
        ?
        <div className="d-flex align-content-center">
          <input className="input" type="text" value={value} onChange={(e) => setValue(e.target.value)}/>
          <button className="submit-button ml-2" onClick={() => submitAndClose()}>ok</button>
        </div>
        :
        <span className="d-flex">
          <div className="truncate">
            {value}
          </div>
          <div className="edit-button ml-2" onClick={() => setOpened(true)}>
            <img src="/images/pencil.svg" />
          </div>
        </span>
      }
    </div>
  )
}

export default EditText;
