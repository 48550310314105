import React from "react";
import MultiEntry from "../../../microweb/multiEntry/MultiEntry";
import DigitalCard from "../../../microweb/digitalCard/DigitalCard";
import ProductPage from "../../../microweb/productPage/ProductPage";
import FeedbackForm from "../../../microweb/feedbackForm/FeedbackForm";
import './LiveMicrowebPreview.scss'

function LiveMicrowebPreview({data}) {

  return (
    <div className="preview-wrapper">
      <div className="preview">
        <div className="mobile-frame">
          <div className="mobile-header"/>
          <div className="microweb-content">
            {
              data.MultiEntry ? <MultiEntry cardData={data.MultiEntry} isPreview/> :
                data.DigitalCard ? <DigitalCard cardData={data.DigitalCard} isPreview/> :
                  data.FeedbackForm ? <FeedbackForm cardData={data.FeedbackForm} isPreview/> :
                    <ProductPage cardData={data.ProductPage} isPreview/>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default LiveMicrowebPreview;
