import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {FormattedMessage} from 'react-intl'
import {connect} from "react-redux";

import {
	setActiveDynamicQrId,
	setActiveMicroPageType, setDynamicQrUrl,
	setIsDynamicQrFormatActive,
	updateDesign
} from '../../../../../store/card/cardActions'

import {ReactComponent as Arrow} from '../../common/images/elements/arrow.svg'
import {generateAdress, list, premiumList} from './formatting/QRCodes'

import './EditFormat.scss'
import {update, create} from '../../../../../api/qrcode'
import {QRCodeType} from "./formatting/codeGenerator";
import ProductPageForm from "./productPage/ProductPageForm"
import DigitalCardForm from "./digitalCard/DigitalCardForm";
import MultiEntryForm from "./multiEntry/MultiEntryForm";
import FeedbackForm from "./feedback/FeedbackForm";
import {MicroPageType} from "../../../../../ts/types";
import Inputs from "./Inputs"
import List from "./List"
import PremiumList from "./PremiumList";
import {
	getActiveDynamicQrCodeId,
	getEditorMode,
	getIsDynamicQrFormatActive
} from "../../../../../store/card/cardSelectors";
import { getIsDynamicQrCodesAllowed} from "../../../../../store/auth/authSelectors";
import {EDITOR_MODES, APP_DOMAIN} from "../../../../../utils/constants";
import UpgradePlan from "./UpgradePlan";

//no need for rerendering after every change (typing)
const debounce = (fn, delay) => {
	let timeoutID
	return function (...args) {
		if (timeoutID) {
			clearTimeout(timeoutID)
		}
		timeoutID = setTimeout(() => {
			fn(...args)
		}, delay)
	}
}

function EditFormat({
	activeLayer,
	setIsFormatShown,
	isFormatShown,
	setDynamicQrType,
	onSetActiveMicroPageType,
	onSetActiveDynamicQrId,
	onSetIsDynamicQrFormatActive,
	editorMode,
	onSetDynamicQrUrl,
	isDynamicQrFormatActive,
	activeDynamicQrId,
	isDynamicQrCodesAllowed,
}) {
	const [selectedFormat, setFormat] = useState(activeLayer.object)
	const [errors, setErrors] = useState({})
	const lang = useSelector((store) => store.intl.currentLocale)
	const token = useSelector((store) => store.auth.userData.jwt)
	const dispatch = useDispatch()

	useEffect(() => {
		if (selectedFormat && selectedFormat.type !== null) {
			setIsFormatShown(true)
		} else {
			setIsFormatShown(false)
		}
	}, [])


	// TODO: maybe refactor and the map will not be needed anymore
	const setMicroPageTypeBasedOnQrType = (qrType) => {
		switch (qrType) {
			case QRCodeType.Feedback:
				onSetActiveMicroPageType(MicroPageType.FEEDBACK_FORM)
				break;
			case QRCodeType.ProductPage:
				onSetActiveMicroPageType(MicroPageType.PRODUCT_PAGE)
				break;
			case QRCodeType.VCardDynamic:
				onSetActiveMicroPageType(MicroPageType.DIGITAL_CARD)
				break;
			case QRCodeType.MultiEntry:
				onSetActiveMicroPageType(MicroPageType.MULTI_ENTRY)
				break;
		}
	}

	const handleFormat = async (format = null) => {
		setFormat(format)

		if (!format) {
			return;
		}

		let dynamicQrCode;
		let dynamicQrCodeId;
		
		if (editorMode === EDITOR_MODES.CREATE) {
			if (format.isDynamic && activeDynamicQrId) {
				// todo: skip creation?
			}
			if (format.isDynamic) {
				dynamicQrCode = await create(token, { })
				dynamicQrCodeId = dynamicQrCode.id
				onSetActiveDynamicQrId(dynamicQrCodeId)
				onSetIsDynamicQrFormatActive(true)
			} else {
				onSetIsDynamicQrFormatActive(false)
			}
		}

		if (editorMode === EDITOR_MODES.UPDATE) {
			// still dynamic, we dont create new one
			if (isDynamicQrFormatActive && format.isDynamic) {
				dynamicQrCodeId = activeDynamicQrId
				setMicroPageTypeBasedOnQrType(format.type)
			}

			if (isDynamicQrFormatActive && !format.isDynamic) {
				onSetIsDynamicQrFormatActive(false)
			}

			if ( !isDynamicQrFormatActive && format.isDynamic) {
				dynamicQrCode = await create(token, { })
				dynamicQrCodeId = dynamicQrCode.id
				onSetActiveDynamicQrId(dynamicQrCodeId)
				onSetIsDynamicQrFormatActive(true)
			}

			if ( !isDynamicQrFormatActive && !format.isDynamic) {
			//	still static
			}
		}

		if (format) {
			if ([QRCodeType.Feedback, QRCodeType.MultiEntry, QRCodeType.VCardDynamic, QRCodeType.ProductPage].includes(format.type)) {
				setMicroPageTypeBasedOnQrType(format.type)
			} else {
				onSetActiveMicroPageType(null)
			}
		}

		const editedActiveLayer = {...activeLayer}

		let isFormatChanged = false
		if (format) {
			if (format.isDynamic) {
				format.code = `${APP_DOMAIN}/r/` + dynamicQrCodeId.slice(-4)
			}
			editedActiveLayer.object = format
		}
		if (activeLayer.object !== editedActiveLayer.object) isFormatChanged = true

		setFormat(format)

		//prevent from unnecessary re-rendering of qr-code
		if (isFormatChanged) {
			dispatch(updateDesign(editedActiveLayer))
		}
	}

	const debouncedChangeTemplate = debounce(async (editedActiveLayer) => {
		let format = editedActiveLayer.object

		if (format.isDynamic && format.dynamicID) {
			try {
				// await update(token, format.dynamicID, {config: format.config, code: format.code, type: format.type})
			} catch (e) {
				console.log(e)
			}
		}
		dispatch(updateDesign(editedActiveLayer))
	}, 800)

	const changeFormatInput = (input, value) => {
		if (selectedFormat.isDynamic) {
			onSetDynamicQrUrl(value)
			return
		}
		const editedSelectedFormat = selectedFormat
		editedSelectedFormat[input] = value

		setFormat(editedSelectedFormat)

		const editedActiveLayer = {...activeLayer}
		editedActiveLayer.object = editedSelectedFormat
		const {type, config} = editedActiveLayer.object
		const result = generateAdress(type, config)
		editedActiveLayer.object.code = result[0]
		editedActiveLayer.object.error = result[1]
		debouncedChangeTemplate(editedActiveLayer)
	}

	const changeQrCode = (url) => {
		const editedActiveLayer = {...activeLayer}
		editedActiveLayer.object.code = url
		debouncedChangeTemplate(editedActiveLayer)
	}

	const handleHeaderClick = () => {
		handleFormat()
		setFormat(activeLayer.object)
		setIsFormatShown(prev => !prev)
		setDynamicQrType("")
	}

	return (
		<div>
			<div className="format-container">
				<div
					className="header"
					onClick={isFormatShown ? handleHeaderClick : undefined}
				>
					{isFormatShown && selectedFormat.type && <img src={selectedFormat?.icon} alt=""/>}
					<span className="title">
						{isFormatShown ? selectedFormat.name[lang === 'en' ? 0 : 1] :
						<FormattedMessage id="qr.select"/>}
					</span>
					<Arrow className={isFormatShown ? 'direction-up opacity-1' : 'direction-down'}/>
				</div>

				{isFormatShown && selectedFormat && selectedFormat.type !== null ? (
					<Inputs type={selectedFormat.type} config={selectedFormat.config}
							changeFormatInput={changeFormatInput} setErrors={setErrors} />
				) : (
					<div>
						<List activeLayer={activeLayer} list={list} setIsFormatShown={setIsFormatShown}
							  handleFormat={handleFormat}/>
						{isDynamicQrCodesAllowed || editorMode === EDITOR_MODES.UPDATE ? 
						<>
							<PremiumList activeLayer={activeLayer} list={premiumList.dynamic} setIsFormatShown={setIsFormatShown}
										handleFormat={handleFormat} type="dynamic" />
							<PremiumList activeLayer={activeLayer} list={premiumList.dynamicWithLanding} setIsFormatShown={setIsFormatShown}
										handleFormat={handleFormat} type="dynamicWithLanding" />
						</>
						: <UpgradePlan />
						}
					</div>
				)}

			</div>
			{isFormatShown && errors && Object.values(errors).map((err, index) => (<p key={index} className="format-input-error">{err}</p>))}

			{/* show form for page builder */
			}
			{
				isFormatShown && selectedFormat && selectedFormat.isDynamic &&
				[
					(
						selectedFormat.type === QRCodeType.ProductPage ? (<ProductPageForm key="ProductPageForm" generate={changeQrCode} setDynamicQrType={setDynamicQrType} />) :
							selectedFormat.type === QRCodeType.Feedback ? (<FeedbackForm key="FeedbackForm" generate={changeQrCode} setDynamicQrType={setDynamicQrType} />) :
								selectedFormat.type === QRCodeType.VCardDynamic ? (
										<DigitalCardForm key="DigitalCardForm" generate={changeQrCode} setDynamicQrType={setDynamicQrType} />) :
									selectedFormat.type === QRCodeType.MultiEntry ? (
											<MultiEntryForm key="MultiEntryForm" generate={changeQrCode} setDynamicQrType={setDynamicQrType} />) :
										null
					)
				]
			}

		</div>
	)
}

const mapState = (state) => ({
	editorMode: getEditorMode(state),
	isDynamicQrFormatActive: getIsDynamicQrFormatActive(state),
	activeDynamicQrId: getActiveDynamicQrCodeId(state),
	isDynamicQrCodesAllowed: getIsDynamicQrCodesAllowed(state),
})

const mapDispatch = (dispatch) => ({
	onSetActiveMicroPageType: (type) => dispatch(setActiveMicroPageType(type)),
	onSetActiveDynamicQrId: (id) => dispatch(setActiveDynamicQrId(id)),
	onSetIsDynamicQrFormatActive: (value) => dispatch(setIsDynamicQrFormatActive(value)),
	onSetDynamicQrUrl: (url) => dispatch(setDynamicQrUrl(url))
})

export default connect(mapState, mapDispatch)(EditFormat)
