import React from "react";
import {Link} from "react-router-dom";
import {Route} from "react-router";

const LogoNavigation = () => {
    return (
        <div className="col-sm-6">
            <div className="logo-wrapper">
                <Link to="/app/dashboard/home">
                    <Route path="/app/editor">
                        <svg width="17" height="27" viewBox="0 0 17 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.0703 2.50586L3.99947 13.5767L15.0703 24.6476" stroke="#172339" strokeWidth="5"/>
                        </svg>
                    </Route>
                    <img className="logo" src="/lib/images/logo.svg" alt="logo"/>
                </Link>
            </div>
        </div>
    )
}

export default LogoNavigation;
