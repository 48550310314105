import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { setImageCount, updateDesign, fetchImages } from '../../../../store/card/cardActions'
import { getImages, getImageCount, getActiveLayer } from '../../../../store/card/cardSelectors'
import { getCurrentLocale } from '../../../../store/intl/intlSelectors'

import './SuggestedImages.scss'

let timeout

class SuggestedImages extends Component {
  constructor(props) {
    super(props)

    this.state = {
      query: '',
    }
  }

  componentDidMount() {
    this.createObserver();
  }

  createObserver = () => {
    const loadingEl = document.getElementById("loading");
    const observer = new IntersectionObserver(
        this.handleIntersect,
        {}
    );
    observer.observe(loadingEl);
  }

  handleIntersect = () => {
    this.fetchMoreImages()
  }

  handleStylesChange = (type, value) => {
    const { onChangeTemplate } = this.props
    const { activeLayer } = this.props
    const changedLayer = { ...activeLayer }
    const changedLayerStyles = { ...changedLayer.styles }

    changedLayerStyles[type] = `url(${value})`

    changedLayerStyles['backgroundRepeat'] = 'no-repeat'
    changedLayerStyles['backgroundPosition'] = '0 0'
    changedLayerStyles['backgroundSize'] = 'cover'
    changedLayerStyles['backgroundUploadedImage'] = value
    changedLayer.styles = changedLayerStyles

    onChangeTemplate(changedLayer)
  }

  handleInputChange = (e) => {
    const { onFetchImages } = this.props
    e.persist()

    clearTimeout(timeout)

    this.setState({
      query: e.target.value,
    })

    timeout = setTimeout(() => {
      onFetchImages(e.target.value, this.props.lang)
    }, 800)
  }

  fetchMoreImages = () => {
    const { imageCount, onSetImageCount } = this.props
    onSetImageCount(imageCount + 6) // Fetch 6 more images
  }

  render() {
    const { suggestedImages } = this.props
    const { query } = this.state

    return (
      <div className="suggested-images">
        <div className="title">
          <FormattedMessage id="image.suggest" />
        </div>
        <div className="search-input">
          <FormattedMessage id="image.search">
            {(msg) => <input type="text" placeholder={msg} value={query} onChange={(e) => this.handleInputChange(e)} />}
          </FormattedMessage>
        </div>
        <div className="suggested-images-content">
          <div className="images-table">
            {suggestedImages.map((image, id) => {
              return (
                <div className="suggested-image" onClick={() => this.handleStylesChange('backgroundImage', image.largeImageURL)}>
                  <img src={image.webformatURL} alt={`suggest-${id}`} />
                </div>
              )
            })}
          </div>
          <div id="loading">
            <h4>Loading...</h4>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  activeLayer: getActiveLayer(state),
  suggestedImages: getImages(state),
  imageCount: getImageCount(state),
  lang: getCurrentLocale(state),
})

const mapDispatch = (dispatch) => ({
  onSetImageCount: (imageCount) => dispatch(setImageCount(imageCount)),
  onChangeTemplate: (layer) => dispatch(updateDesign(layer)),
  onFetchImages: (query, lang) => dispatch(fetchImages(query, lang)),
})

export default connect(mapState, mapDispatch)(SuggestedImages)
