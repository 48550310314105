import interact from 'interactjs'

function dragMoveListener (event) {
  var target = event.target
  var templates = document.getElementsByClassName('template');
  var container = templates[templates.length - 1]
  var scale = container.getBoundingClientRect().width / container.offsetWidth;
  // keep the dragged position in the data-x/data-y attributes
  var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx / scale
  var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy / scale

  // translate the element
  target.style.webkitTransform =
    target.style.transform =
      'translate(' + x + 'px, ' + y + 'px)'

  // update the posiion attributes
  target.setAttribute('data-x', x)
  target.setAttribute('data-y', y)
}

interact('.resizable-image')
  .resizable({
    // resize from all edges and corners
    edges: { left: true, right: true, bottom: true, top: true },

    listeners: {
      move (event) {
        var templates = document.getElementsByClassName('template');
        var container = templates[templates.length - 1]
        var scale = container.getBoundingClientRect().width / container.offsetWidth;

        var target = event.target
        var x = (parseFloat(target.getAttribute('data-x')) || 0)
        var y = (parseFloat(target.getAttribute('data-y')) || 0)

        // update the element's style
        target.style.width = event.rect.width / scale + 'px'
        target.style.height = event.rect.height / scale + 'px'

        // translate when resizing from top or left edges
        x += event.deltaRect.left / scale
        y += event.deltaRect.top / scale

        target.style.webkitTransform = target.style.transform =
          'translate(' + x + 'px,' + y + 'px)'

        target.setAttribute('data-x', x)
        target.setAttribute('data-y', y)
      }
    },
    modifiers: [
      // keep the edges inside the parent
      interact.modifiers.restrictEdges({
        outer: 'parent'
      }),

      // minimum size
      interact.modifiers.restrictSize({
        min: { width: 100, height: 50 }
      }),
      interact.modifiers.aspectRatio({
        ratio: 'preserve' 
      })
    ],

    inertia: true
  })
  .draggable({
    listeners: { move: dragMoveListener },
    inertia: true,
    modifiers: [
      interact.modifiers.restrictRect({
        endOnly: true
      })
    ]
  })
