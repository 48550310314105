import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CirclePicker } from 'react-color'
import { colorPickerColors } from '../../../../utils/constants'
import { updateDesign, setRecentColor } from '../../../../store/card/cardActions'
import { getActiveLayerType, getActiveLayer, getRecentColors, getResetStatus } from '../../../../store/card/cardSelectors'

import CustomColorPicker from './CustomColorPicker'

import './ColorPicker.scss'
const Tick = () => (
  <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.8 2.03636L10.7636 0L4.94545 5.81818L2.03636 2.90909L0 4.94545L4.94545 9.89091L12.8 2.03636Z" fill="white"/>
  </svg>
)

class ColorPicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentColor: props.activeLayer.styles.color || props.activeLayer.styles.backgroundColor || props.activeLayer.solidColor,
      currentPicker: 'circle',
      activeLayer: props.activeLayer,
    }

  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activeLayerType !== nextProps.activeLayerType) {
      this.setState({
        currentPicker: 'circle',
      })
    }
  }

  componentDidMount() {
    const { activeLayer, currentGradientElement, pickerType } = this.props

    if (pickerType === 'gradient') {
      if (currentGradientElement === 'left') {
        this.setState({
          currentColor: activeLayer.leftGradientColor,
        })
      } else {
        this.setState({
          currentColor: activeLayer.rightGradientColor,
        })
      }
    }

    this.createCustomPicker()
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeLayer, currentGradientElement, pickerType, customType } = this.props
    const { currentColor } = this.state

    if (prevState.currentColor !== currentColor && prevState.currentPicker !== 'chrome') {
      this.handleAddingRecentColor()
    }

    if (prevProps.activeLayer !== activeLayer || prevProps.currentGradientElement !== currentGradientElement) {
      if (pickerType === 'gradient') {
        if (currentGradientElement === 'left') {
          this.setState({
            currentColor: activeLayer.leftGradientColor,
          })
        } else {
          this.setState({
            currentColor: activeLayer.rightGradientColor,
          })
        }
      } else if (activeLayer.colorPickerType !== "Custom"){
        this.setState({
          currentColor: activeLayer.styles.color || activeLayer.styles.backgroundColor || activeLayer.solidColor,
        })
      }
    }
    // update currentColor on qr custom design (ball, body, frame) change
    if (prevProps.customType !== customType || prevProps.activeLayer.colorPickerType !== activeLayer.colorPickerType) {
      if (activeLayer.colorPickerType === "Custom" && customType) {
        this.setState({
          currentColor: activeLayer.customDesignConfig[customType].color
        })
      } else {
        this.setState({
          currentColor: activeLayer.solidColor
        })
      }
    }

    this.createCustomPicker()
  }

  handleChangeComplete = (color) => {
    const { pickerType, onGradientChange, type, activeLayer } = this.props
    let colorHex = color.hex

    if (!colorHex) {
      colorHex = color
    }
    if (colorHex) {
      this.setState({
        currentColor: colorHex
      })

      if (pickerType === 'gradient') {
        onGradientChange(colorHex)
      } else if (type === 'background' && (!activeLayer.styles.backgroundSvg || activeLayer.styles.backgroundSvg === 'none')) {
        this.handleStylesChange('backgroundColor', colorHex)
      } else {
        this.handleStylesChange('color', colorHex)
      }
    }
  }

  handleStylesChange = (type, value) => {
    const { onChangeTemplate } = this.props
    const { activeLayer } = this.props
    const changedLayer = { ...activeLayer }
    const changedLayerStyles = { ...changedLayer.styles }
    changedLayerStyles[type] = value
    changedLayerStyles['color'] = value
    changedLayer.styles = changedLayerStyles
    changedLayer.styles.backgroundImage = 'none'
    changedLayer.leftGradientColor = value
    changedLayer.rightGradientColor = value

    if (changedLayer.customDesignConfig && changedLayer.colorPickerType === "Custom") {
      changedLayer.customDesignConfig[this.props.customType].color = value
    }
    // don't change solidColor if we use QR Custom Design (body, frame, ball)
    if (type === 'color' && changedLayer.colorPickerType !== "Custom") {
      changedLayer.solidColor = value
    }

    this.setState({
      activeLayer: changedLayer,
    })

    onChangeTemplate(changedLayer)
  }

  togglePicker = () => {
    const { currentPicker } = this.state

    if (currentPicker === 'circle') {
      const circle = document.querySelector("#custom-circle")
      const circleWidth = circle.offsetWidth
      this.setState({
        currentPicker: 'chrome',
      })
      const colorCircle = document.querySelector("#color-circle2")
      colorCircle.style.width = `${circleWidth + 10}px`
    } else {
      this.setState({
        currentPicker: 'circle',
      })
    }
  }

  handleAddingRecentColor = () => {
    if (!this.props.recentColors.includes(this.state.currentColor)) {
      this.props.onSetRecentColor(this.state.currentColor.toLowerCase())
    }
  }

  removeCustomPicker = () => {
    const customPicker = document.getElementsByClassName('custom-picker-span')[0]

    if (customPicker) {
      customPicker.remove()
    }
  }


  createCustomPicker = (updated = false) => {
    const rootEl = document.getElementById('my-picker')

    if (!rootEl) {
      return
    }
    const parentEl = rootEl.firstChild

    if (parentEl && !updated) {
      this.removeCustomPicker()
      const myEl = document.createElement('span')
      myEl.classList.add("custom-picker-span")
      parentEl.appendChild(myEl)
      myEl.innerHTML = '' +
        '<div id="custom-circle-element" style="width: 43px; height: 43px; margin-right: 7px; margin-bottom: 7px; transform: scale(1); transition: transform 100ms ease 0s;">' +
          '<span>' +
            '<div id="custom-circle" style="background: transparent none repeat scroll 0% 0%; height: 100%; width: 100%; cursor: pointer; position: relative; outline: currentcolor none medium; border-radius: 50%; box-shadow: rgb(24, 148, 202) 0px 0px 0px 22.5px inset; transition: box-shadow 100ms ease 0s;" title="#1894ca" tabindex="0">' +
            '</div>' +
          '</span>' +
        '</div>'

      const customCircle = document.getElementById('custom-circle')

      customCircle.style.backgroundImage = "url('/assets/images/color-circle.png')"
      customCircle.style.backgroundPosition = "center"
      customCircle.style.backgroundRepeat = "no-repeat"
      customCircle.style.boxShadow = "rgba(0, 0, 0, 0.24) 0px 3px 8px"
      myEl.onclick = () => this.togglePicker()
    }
  }

  render() {
    const { currentPicker, currentColor } = this.state
    const { recentColors } = this.props
    return (
      <div className="color-picker">
        {currentPicker === 'circle' ?
        <>
          <div id="my-picker">
            <CirclePicker
              onChangeComplete={this.handleChangeComplete}
              colors={colorPickerColors.concat(recentColors)}
              circleSize={70}
            />
          </div>
          <div className='basic-color-picker'>
            {colorPickerColors.concat(recentColors).map((color, key) => (
              <div key={key} className={`color ${color.toLowerCase() === currentColor.toLowerCase() ? 'selected' : ''}`}>
                <Tick />
              </div>
            ))}
          </div>
        </>
          :
          <CustomColorPicker
            color={this.state.currentColor}
            onChangeComplete={this.handleChangeComplete}
            handleAddingRecentColor={this.handleAddingRecentColor}
            disableAlpha
          />
        }
        <div onClick={() => this.togglePicker()} className={`color-circle ${currentPicker !== 'circle' ? 'chrome' : ''}`}>
          {currentPicker !== 'circle' &&
            <img src="/assets/images/Checkmark.svg" id="checkmark" alt="checkmark" />
          }
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  activeLayerType: getActiveLayerType(state),
  activeLayer: getActiveLayer(state),
  recentColors: getRecentColors(state),
  resetStatus: getResetStatus(state),
})

const mapDispatch = (dispatch) => ({
  onChangeTemplate: (layer) => dispatch(updateDesign(layer)),
  onSetRecentColor: (color) => dispatch(setRecentColor(color))
})

export default connect(mapState, mapDispatch)(ColorPicker)
