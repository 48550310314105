import {apiGetIcons, apiGetImages, apiGetQrIcons, apiGetTemplates} from "../../api/api";
import {
  getActiveDesign,
  getActiveTemplate,
  getActiveSide,
  getCurrentActiveVisual,
  getActiveTemplates,
  getTemplateType,
  getInitialTemplates,
  getTemplatesByTemplateType,
  getUserData,
} from "./cardSelectors";
import {SIDES, TYPES} from "../../utils/constants";
import {Design, EditorMode, MicroPageType, Template, Type} from "../../ts/types"
import {
  resetCurrentTemplate,
  resetAllTemplates,
  resetUserData,
  mergeLayer,
  setColorPalette,
  createUserDataObject,
} from "../../utils/helpers";
// export const PROJECT_DATA_FETCH_START = 'PROJECT_DATA_FETCH_START'
// export const PROJECT_DATA_FETCH_SUCCESS = 'PROJECT_DATA_FETCH_SUCCESS'
// export const PROJECT_DATA_FETCH_FAIL = 'PROJECT_DATA_FETCH_FAIL''

export const RESET_TEMPLATES = "RESET_TEMPLATES";
export const SET_DYNAMIC_QR_URL = "SET_DYNAMIC_QR_URL";
export const SET_IS_DYNAMIC_QR_FORMAT_ACTIVE = "SET_IS_DYNAMIC_QR_FORMAT_ACTIVE";
export const SET_ACTIVE_DYNAMIC_QR_ID = "SET_ACTIVE_DYNAMIC_QR_ID";
export const SET_ACTIVE_MICRO_PAGE_TYPE = "SET_ACTIVE_MICRO_PAGE_TYPE";
export const SET_ACTIVE_TEMPLATE = "SET_ACTIVE_TEMPLATE";
export const RESET_ACTIVE_TEMPLATE = "RESET_ACTIVE_TEMPLATE";
export const APPLY_TEMPLATE_TO_DESIGN = "APPLY_TEMPLATE_TO_DESIGN";
export const SET_ACTIVE_DESIGN_START = "SET_ACTIVE_DESIGN_START";
export const SET_ACTIVE_DESIGN = "SET_ACTIVE_DESIGN"
export const SET_ACTIVE_LAYER_START = "SET_ACTIVE_LAYER_START";
export const SET_ACTIVE_LAYER = "SET_ACTIVE_LAYER";
export const LAYER_SET = "LAYER_SET";
export const ADD_ICON = "ADD_ICON";
export const SET_RECENT_COLOR = "SET_RECENT_COLOR";
export const SET_RECENT_FONT = "SET_RECENT_FONT";
export const RESET_DATA_START = "RESET_DATA_START";
export const RESET_DATA = "RESET_DATA";
export const SET_USER_DATA = "SET_USER_DATA";
export const SWITCH_SIDE = "SWITCH_SIDE";
export const SET_TEMPLATE_TYPE = "SET_TEMPLATE_TYPE";
export const SET_TYPE_AND_CREATE_DESIGN_FROM_TEMPLATE_SET = "SET_TYPE_AND_CREATE_DESIGN_FROM_TEMPLATE_SET";
export const SET_IMAGES = "SET_IMAGES";
export const SET_IMAGE_COUNT = "SET_IMAGE_COUNT";
export const SET_TEMPLATES_COUNT = "SET_TEMPLATES_COUNT"
export const SET_ICONS = "SET_ICONS"
export const SET_QR_ICONS = "SET_QR_ICONS"
export const SET_TEMPLATES = "SET_TEMPLATES"
export const SET_CURRENT_TEMPLATE_SUBTYPE = "SET_CURRENT_TEMPLATE_SUBTYPE"
export const SET_EDITOR_NAME = "SET_EDITOR_NAME"
export const SET_EDITOR_MODE = "SET_EDITOR_MODE"

// const fetchProjectDataStart = () => ({ type: PROJECT_DATA_FETCH_START })
// const fetchProjectDataSuccess = (projects) => ({ type: PROJECT_DATA_FETCH_SUCCESS, projects })
// const fetchProjectDataFail = () => ({ type: PROJECT_DATA_FETCH_FAIL })
export const resetTemplates = (cardType) => ({
  type: RESET_TEMPLATES,
  cardType
})

export const setDynamicQrUrl = (url: string) => ({
  type: SET_DYNAMIC_QR_URL,
  url
})

export const setIsDynamicQrFormatActive = (value: boolean) => ({
  type: SET_IS_DYNAMIC_QR_FORMAT_ACTIVE,
  value
})

export const setActiveDynamicQrId = (id) => ({
  type: SET_ACTIVE_DYNAMIC_QR_ID,
  id
})

export const setActiveMicroPageType = (microPageType: MicroPageType) => ({
  type: SET_ACTIVE_MICRO_PAGE_TYPE,
  microPageType,
})

/*
  Managing templates and design in Editor
 */

export const setActiveDesignStart = () => ({ type: SET_ACTIVE_DESIGN_START });

export const setActiveDesign = (design: Design) => ({
  type: SET_ACTIVE_DESIGN,
  design,
})

export const setActiveTemplate = (template: Template) => ({
  type: SET_ACTIVE_TEMPLATE,
  template,
})

export const resetActiveTemplate = () => ({
  type: RESET_ACTIVE_TEMPLATE
})

export const applyTemplateToDesign = (template: Template) => ({
  type: APPLY_TEMPLATE_TO_DESIGN,
  template,
});

export const switchSide = (sideType) => ({
  type: SWITCH_SIDE,
  sideType,
});

export const setEditorMode = (mode: EditorMode) => ({
  type: SET_EDITOR_MODE,
  mode,
});

export const setEditorName = (name: string) => ({
  type: SET_EDITOR_NAME,
  name,
});

export const setActiveLayer = (layerType: Type) => ({
  type: SET_ACTIVE_LAYER,
  layerType,
});

export const addIcon = (updatedIcons) => ({ type: ADD_ICON, updatedIcons });

export const setRecentColor = (color) => ({ type: SET_RECENT_COLOR, color });

export const setRecentFonts = (font) => ({ type: SET_RECENT_FONT, font });

export const resetDataStart = () => ({ type: RESET_DATA_START });

export const resetDataSuccess = (templates) => ({
  type: RESET_DATA,
  templates,
});

export const setUserData = (userData) => ({ type: SET_USER_DATA, userData });

export const setTemplateType = (templateType) => ({
  type: SET_TEMPLATE_TYPE,
  templateType,
});

export const setTemplateSubtype = (templateType) => ({
  type: SET_CURRENT_TEMPLATE_SUBTYPE,
  templateType,
});

export const setTemplateTypeAndTemplates = (templateType) => ({
  type: SET_TYPE_AND_CREATE_DESIGN_FROM_TEMPLATE_SET,
  templateType,
});

export const setRetrievedImages = (images) => ({ type: SET_IMAGES, images });

export const setImageCount = (imageCount) => ({
  type: SET_IMAGE_COUNT,
  imageCount,
});

export const setTemplatesCount = (templatesCount) => ({
  type: SET_TEMPLATES_COUNT,
  templatesCount,
});

export const setIcons = (icons) => ({ type: SET_ICONS, icons });

export const setQrIcons = (qrIcons) => ({ type: SET_QR_ICONS, qrIcons });

export const setTemplates = (cardTemplates, labelTemplates, stickerTemplates, qrTemplates) => ({
  type: SET_TEMPLATES, cardTemplates, labelTemplates, stickerTemplates, qrTemplates
});

export const updateDesign = (changedLayer) => (dispatch, getState) => {
  const currentTemplate = getActiveDesign(getState());
  const userData = getUserData(getState());
  const templateType = getTemplateType(getState());
  let updatedActiveTemplate = {...getActiveTemplate(getState())}
  let updatedDesign = {...currentTemplate};

  const side = getActiveSide(getState());

  let layerId;

  if (side === SIDES.FRONT) {
    layerId = updatedDesign.layers.front.findIndex(
      (layer) => layer.type === changedLayer.type
    );
  } else {
    layerId = updatedDesign.layers.back.findIndex(
        (layer) => layer.type === changedLayer.type
    );
  }

  let activeVisual = getCurrentActiveVisual(getState());

  dispatch(setActiveDesignStart());
  
  if (activeVisual) {
    const activeVisualLayerId = activeVisual.findIndex(
      (layer) => layer.type === changedLayer.type
    );
    activeVisual[activeVisualLayerId].isTextUpdated = changedLayer.isTextUpdated

    activeVisual[activeVisualLayerId] = mergeLayer(
      activeVisual[activeVisualLayerId],
      changedLayer
    );
    activeVisual = setColorPalette(activeVisual);
    updatedActiveTemplate[updatedActiveTemplate.visuals] = activeVisual;

    dispatch(setActiveTemplate(updatedActiveTemplate));
  }

  if (side === SIDES.FRONT) {
    updatedDesign.layers.front[layerId] = changedLayer;
  } else {
    updatedDesign.layers.back[layerId] = changedLayer;
  }


  dispatch(setActiveDesign(updatedDesign));

  // Set user data
  if (changedLayer.type.split('-')[0] === 'qr') {
    const userLayerId = userData.findIndex(
      (layer) => layer.type === changedLayer.type
    );
    const updatedUserData = [...userData];
    const changedObject = createUserDataObject(changedLayer, templateType);
    
    if (userLayerId !== -1) {
      updatedUserData[userLayerId] = changedObject;
    } else {
      updatedUserData.push(changedObject);
    }
    
    dispatch(setUserData(updatedUserData));
  }
};

export const resetData = (dataType) => (dispatch, getState) => {
  const initialTemplates = getInitialTemplates(getState());
  const activeTemplates = getActiveTemplates(getState());
  const currentTemplate = getActiveDesign(getState());
  const templateType = getTemplateType(getState());

  dispatch(resetDataStart());

  let updatedTemplates = [...activeTemplates];
  const templateIndex = updatedTemplates.findIndex(
    (template) => template.id === currentTemplate._id
  );

  if (dataType === "color") {
    updatedTemplates[templateIndex] = resetCurrentTemplate(
      updatedTemplates[templateIndex],
      templateType,
      initialTemplates
    );
  } else if (dataType === "all colors") {
    updatedTemplates = resetAllTemplates(updatedTemplates, templateType, initialTemplates);
  } else {
    updatedTemplates[templateIndex] = resetUserData(
      updatedTemplates[templateIndex],
      templateType,
      initialTemplates
    );
  }

  dispatch(resetDataSuccess(updatedTemplates));
  dispatch(applyTemplateToDesign(updatedTemplates[templateIndex]));
};

export const changeVisual = (visual, visualId) => (dispatch, getState) => {
  let updatedActiveTemplate = {...getActiveTemplate(getState())}
  let updatedDesign = {...getActiveDesign(getState())};
  let activeVisual = getCurrentActiveVisual(getState());

  // TODO: fix once visuals are ready

  // dispatch(setActiveTemplatestart());

  const side = getActiveSide(getState());

  visual.forEach((visualLayer) => {
    let layerId = updatedDesign.layers[side].findIndex(
      (layer) => layer.type === visualLayer.type
    );
    const updatedLayer = { ...updatedDesign.layers[side][layerId] };
    let changedStyles = { ...updatedLayer.styles };
    let updatedLayerText
    const changedVisualLayer = { ...visualLayer }
    
    if (layerId !== -1) {
      if (changedVisualLayer.type.split('-')[0] === 'text') {
        updatedLayerText = updatedLayer.text
      }

      changedStyles = Object.assign(changedStyles, changedVisualLayer.styles);
      updatedActiveTemplate.visuals = visualId;
      updatedDesign.layers[side][layerId] = Object.assign(updatedLayer, changedVisualLayer);
      updatedDesign.layers[side][layerId].styles = changedStyles;

      if (updatedLayer.isTextUpdated) {
        updatedDesign.layers[side][layerId].text = updatedLayerText
      }

      dispatch(setActiveTemplate(updatedActiveTemplate));
    }
  });

  dispatch(setActiveDesign(updatedDesign));
};

export const fetchImages = (query, lang) => (dispatch) => {
  return apiGetImages(query, lang)
    .then((imagesObject) => {
      dispatch(setRetrievedImages(imagesObject.hits));
    })
    .catch((error) => {});
};

export const fetchIcons = () => (dispatch) => {
  return apiGetIcons()
    .then((data) => dispatch(setIcons(data)))
    .catch(async error => {
      await error
    })
}

export const fetchQrIcons = () => (dispatch) => {
  return apiGetQrIcons()
    .then((data) => dispatch(setQrIcons(data)))
    .catch(async error => {
    })
}

export const fetchTemplates = () => (dispatch) => {
  return apiGetTemplates()
    .then((data) => {
      const cardTemplates = getTemplatesByTemplateType(data, TYPES.B_CARD)
      const labelTemplates = getTemplatesByTemplateType(data, TYPES.LABEL)
      const stickerTemplates = getTemplatesByTemplateType(data, TYPES.STICKER)
      const qrTemplates = getTemplatesByTemplateType(data, TYPES.QR_ONLY)


      return dispatch(setTemplates(cardTemplates, labelTemplates, stickerTemplates, qrTemplates))
     })
    .catch(async error => {
    })
}
