import React, { useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

function UserManagement() {
  const [dropdownOpen, setOpen] = useState(false)
  const toggle = () => setOpen(!dropdownOpen)
  const [role, setRole] = useState('Select')
  return (
    <div id='UserManagement'>
      <h1>User management</h1>
      <div className="blocks-wrapper">
        <div className="block">
          <h2>User 1</h2>
          <div className="rect bigger-rect">
            <span>Email</span>
            <input className='rect' placeholder='Email' type="text" />
            <span>Role</span>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle
                tag="div"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
                className='rect'
              >
                {role}
                <svg className='arrow' width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.24" fill-rule="evenodd" clip-rule="evenodd" d="M6.34637 0.529297C7.21925 0.529297 7.67314 1.56931 7.07957 2.20931L4.73366 4.73874C4.33796 5.16539 3.66295 5.16539 3.26725 4.73874L0.921336 2.20931C0.327771 1.56931 0.781662 0.529297 1.65454 0.529297L6.34637 0.529297Z" fill="#172339" />
                </svg>

              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => setRole('Owner')}>Owner</DropdownItem>
                <DropdownItem onClick={() => setRole('Member')}>Member</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <p>This is your account</p>
          </div>
        </div>
        <div className="block">
          <h2>User 2</h2>
          <div className="rect bigger-rect">
            <span>Email</span>
            <input className='rect' placeholder='Email' type="text" />
            <span>Role</span>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle
                tag="div"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
                className='rect'
              >
                {role}
                <svg className='arrow' width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.24" fill-rule="evenodd" clip-rule="evenodd" d="M6.34637 0.529297C7.21925 0.529297 7.67314 1.56931 7.07957 2.20931L4.73366 4.73874C4.33796 5.16539 3.66295 5.16539 3.26725 4.73874L0.921336 2.20931C0.327771 1.56931 0.781662 0.529297 1.65454 0.529297L6.34637 0.529297Z" fill="#172339" />
                </svg>

              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => setRole('Owner')}>Owner</DropdownItem>
                <DropdownItem onClick={() => setRole('Member')}>Member</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <button className='round-button'>Send Invitation</button>
          </div>
        </div>
        <div className="block">
          <h2>User 3</h2>
          <div className="rect bigger-rect">
            <div className='text'>You have to upgrade your subcsription plan to add more users</div>
            <button className='round-button'>Upgrade</button>
          </div>
        </div>
      </div>
      <p>
        <svg className='plus-img' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="7.99712" cy="7.79888" r="7.25494" fill="#38517C" />
          <path d="M4.30273 7.79883H11.383" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7.99805 11.3389L7.99805 4.25859" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <strong>Upgrade your <u>plan</u> to add another users</strong>
      </p>
    </div>
  )
}

export default UserManagement
