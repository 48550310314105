import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { getInitialLanguage } from '../../utils/helpers'
import csLocalization from '../../messages/cs.json'
import enLocalization from '../../messages/en.json'
import { LOCALES } from '../../utils/constants'
import { CURRENT_LOCALE_SET } from './intlActions'

const messages = { en: enLocalization, cs: csLocalization }

const initialState = {
  currentLocale: getInitialLanguage(),
  locales: LOCALES,
  messages,
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CURRENT_LOCALE_SET: {
      return {
        ...state,
        currentLocale: action.locale,
      }
    }

    default:
      break
  }

  return state
}

/**
 * Configuration of redux-persist used for serialization of redux state to local storage.
 */
const persistConfig = {
  key: 'intl',
  storage,
  blacklist: [],
}

// @ts-ignore
const persistableReducer = persistReducer(persistConfig, reducer)

export default persistableReducer
