import React, {useEffect, useState, useRef} from 'react';
import {BACKEND_URL} from '../../../../utils/constants'
import prependHttp from 'prepend-http';
import BlackTheme from './themes/blackTheme'
import RedTheme from './themes/redTheme'
import WhiteTheme from './themes/whiteTheme'
import OrangeTheme from './themes/orangeTheme'
import useIsMobile from "../../../hooks/useIsMobile";

function MultiEntry({slug, cardData}) {

    const [notFound, setNotFound] = useState(false)

    const [data, setData] = useState(cardData || null)
    const [image, setImage] = useState(cardData?.image || null)
    const [items, setItems] = useState(cardData?.items || [])

    useEffect(() => {
        if (slug) {
            const url = `${BACKEND_URL}/multi-entries-by-slug/${slug}`
            fetch(url)
                .then(res => {
                    if (res.status === 404) {
                        setNotFound(true);
                        throw new Error(res.status + " Page not found ");
                    }

                    return res.json();
                })
                .then((result) => {
                    setData(result);
                    setImage(result.image)
                    setItems(result.items)
                }).catch((error) => {
                console.log(error, 'error');
            })
        }
    }, []);

    useEffect(() => {
        setData(cardData)
        setImage(cardData?.image)
        setItems(cardData?.items ?? [])
    }, [cardData])

    const pageRef = useRef();
    const isMobile = useIsMobile(pageRef);

    return (
        <>
            {data &&
                <div id="micro-page-wrapper" ref={pageRef} className={data.theme}>
                    <div className="multi-entry-page-wrapper">
                        <div className={`multi-entry`}>

                            {
                                (data.theme === 'black') ? <BlackTheme/> :
                                  (data.theme === 'red') ? <RedTheme/> :
                                    (data.theme === 'white') ?  <WhiteTheme/> :
                                      <OrangeTheme/>
                            }

                            {/* Header */}
                            <div className="multi-entry__overlay">
                                {image
                                    ?
                                    <div>
                                        <div className={isMobile ? 'logo-mobile' : 'logo'} style={{
                                            backgroundImage: `url(${data.image.url})`,
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                            position: 'relative',
                                            top: '-2px'
                                        }}>
                                        </div>
                                        <div className="text w-100 p-5 with-image">{data.name}</div>
                                    </div>

                                    :
                                    <div>
                                        <div className="text w-100 p-5 without-image">
                                            {data.name}
                                        </div>
                                    </div>
                                }
                            </div>

                            {/*Items */}
                            <div className="px-3">
                                {
                                    items.map((item) => {
                                        return (
                                            <a href={prependHttp(item.url)} target="_blank"
                                               className="multi-entry__clickable">
                                                <img src={`../assets/images/multidata-qr-code/${item.type}.svg`} alt="MS Word"
                                                     width="26"/>
                                                <span>{item.name}</span>
                                            </a>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default MultiEntry;
