import React from 'react'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { getCurrentLocale, getMessages } from '../../store/intl/intlSelectors'

const IntlProviderContainer = ({ locale, messages, children }) => (
  <IntlProvider locale={locale} messages={messages}>
    {children}
  </IntlProvider>
)

const mapState = (state) => ({
  locale: getCurrentLocale(state),
  messages: getMessages(state),
})

export default connect(mapState)(IntlProviderContainer)
