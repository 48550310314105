import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import _cloneDeep from 'lodash/cloneDeep'
import {colorPickerColors, defaultChosenColors, SIDES, SUBTYPES, TYPES} from '../../utils/constants'
import {setRecentColor, sortByType} from '../../utils/helpers'
import {createDesignObjectFromTemplate} from "../../components/utils/designObjectGenerator";

import {
  RESET_TEMPLATES,
  SET_ACTIVE_MICRO_PAGE_TYPE,
  ADD_ICON,
  APPLY_TEMPLATE_TO_DESIGN,
  RESET_DATA,
  RESET_DATA_START,
  SET_ACTIVE_DESIGN_START,
  SET_ACTIVE_DESIGN,
  SET_ACTIVE_LAYER,
  SET_ACTIVE_TEMPLATE,
  RESET_ACTIVE_TEMPLATE,
  SET_CURRENT_TEMPLATE_SUBTYPE,
  SET_EDITOR_MODE,
  SET_EDITOR_NAME,
  SET_ICONS,
  SET_IMAGE_COUNT,
  SET_TEMPLATES_COUNT,
  SET_IMAGES,
  SET_QR_ICONS,
  SET_RECENT_COLOR,
  SET_RECENT_FONT,
  SET_TEMPLATE_TYPE,
  SET_TEMPLATES,
  SET_TYPE_AND_CREATE_DESIGN_FROM_TEMPLATE_SET,
  SET_USER_DATA,
  SWITCH_SIDE,
  SET_ACTIVE_DYNAMIC_QR_ID,
  SET_IS_DYNAMIC_QR_FORMAT_ACTIVE,
  SET_DYNAMIC_QR_URL,
} from './cardActions'
import {Layer, State, Template} from "../../ts/types";


const initialState: State = {

  initialTemplates: [],

  activeTemplates: [],

  cardTemplates: [],
  labelTemplates: [],
  stickerTemplates: [],
  qrTemplates: [],


  // two-side templates
  currentCardTemplate: null,
  currentCardBackTemplate: null,

  currentLabelTemplate: null,
  currentLabelBackTemplate: null,

  // one-side templates
  currentStickerTemplate: null,

  currentQrTemplate: null,

  editor: {
    mode: null,
    templateType: TYPES.B_CARD, // todo: maybe not inside editor?
    templateSubtype: SUBTYPES.B_EU, // todo: maybe not inside editor?
    activeSide: SIDES.FRONT,
    activeLayerType: null,
    activeTemplate: null,
    design: null,
    activeMicroPageType: null,
    dynamicQrUrl: null,
    isDynamicQrFormatActive: false,
    activeDynamicQrCodeId: null,
  },

  updateStatus: 'UNTOUCHED',
  uploadedIcons: null,
  recentColors: defaultChosenColors,
  recentFonts: [],
  resetStatus: 'UNTOUCHED',
  userData: [],
  images: null,
  imageCount: 6,
  templatesCount: 6,
  icons: [],
  qrIcons: [],
}


const cardReducer = (state = initialState, action) => {
  switch (action.type) {

    case RESET_TEMPLATES: {

      return {
        ...state,
        activeTemplates: _cloneDeep([...state.initialTemplates.filter((template) => {
          return template.type === action.cardType
        })]),
      }
    }

    case SET_DYNAMIC_QR_URL: {
      return {
        ...state,
        editor: {
          ...state.editor,
          dynamicQrUrl: action.url,
        }
      }
    }

    case SET_IS_DYNAMIC_QR_FORMAT_ACTIVE: {
      return {
        ...state,
        editor: {
          ...state.editor,
          isDynamicQrFormatActive: action.value
        }
      }
    }

    case SET_ACTIVE_DYNAMIC_QR_ID: {
      return {
        ...state,
        editor: {
          ...state.editor,
          activeDynamicQrCodeId: action.id
        }
      }
    }

    case SET_ACTIVE_MICRO_PAGE_TYPE: {
      return {
        ...state,
        editor: {
          ...state.editor,
          activeMicroPageType: action.microPageType,
        }
      }
    }

    case SET_EDITOR_MODE: {
      return {
        ...state,
        editor: {
          ...state.editor,
          mode: action.mode,
        }
      }
    }

    case SET_EDITOR_NAME: {
      return {
        ...state,
        editor:{
          ...state.editor,
          design: {
            ...state.editor.design,
            name: action.name
          }
        }
      }
    }

    /*
      Set design to editor
     */
    case SET_ACTIVE_DESIGN: {
      const side = state.editor.activeSide;

      const activeTemplateId = state.editor.activeTemplate?.id;

      if (activeTemplateId) {
        let allTemplates = [...state.activeTemplates];

        const index = allTemplates.findIndex((template) => {
          return template.id === activeTemplateId;
        });

        let templates = allTemplates.filter((template) => {
          return template.id === activeTemplateId;
        })

        let updatedTemplate = templates[0];
        updatedTemplate.layers = [...action.design.layers[side]];

        const updatedTemplates = state.activeTemplates;
        updatedTemplates[index] = updatedTemplate;

        return {
          ...state,
          editor: {
            ...state.editor,
            design: {...action.design},
          },
          activeTemplates: _cloneDeep([...updatedTemplates]),
        }
      }


      return {
        ...state,
        editor: {
          ...state.editor,
          design: {...action.design},
        },
      }
    }

    case SET_ACTIVE_TEMPLATE: {
      let allTemplates = [...state.activeTemplates];
      const templateIndex = allTemplates.findIndex((template) => {
        return template.id === action.template.id;
      });

      allTemplates[templateIndex] = action.template

      return {
        ...state,
        activeTemplates: _cloneDeep([...allTemplates]),
        editor: {
          ...state.editor,
          activeTemplate: {...action.template}
        }
      }
    }

    case RESET_ACTIVE_TEMPLATE: {
      return {
        ...state,
        editor: {
          ...state.editor,
          activeTemplate: null
        }
      }
    }

    /*
        1. Set active layer
        2. Set recent colors, TODO: refactor?
     */
    case SET_ACTIVE_LAYER: {
      let activeDesign = state.editor.design;
      if (!activeDesign) {
        activeDesign = state.activeTemplates[0]
      }

      let side = state.editor.activeSide;

      // @ts-ignore
      let activeLayer = activeDesign.layers[side].find(layer => layer.type === action.layerType)

      if (!activeLayer) {
        // @ts-ignore
        activeLayer = activeDesign.layers[side][0];
      }
      
      let updatedRecentColors = state.recentColors

      if (activeLayer) {
        activeLayer = {...activeLayer}
        updatedRecentColors = setRecentColor(activeLayer, colorPickerColors, state.recentColors)
      }

      return {
        ...state,
        editor: {
          ...state.editor,
          activeLayerType: action.layerType
        },
        recentColors: updatedRecentColors,
        updateStatus: 'SUCCESS',
      }
    }

    case SET_ACTIVE_DESIGN_START: {
      return {
        ...state,
        updateStatus: 'WORKING',
      }
    }

    /*
      Apply template layers to appropriate design's side in editor
     */
    case APPLY_TEMPLATE_TO_DESIGN: {
      const side = state.editor.activeSide
      const template = {...action.template};
      let { activeLayerType } = state.editor

      if (!template.layers.find(layer => layer.type === activeLayerType)) {
        activeLayerType = null
      }

      if (side === SIDES.BACK) {
        return {
          ...state,
          editor: {
            ...state.editor,
            activeTemplate: template,
            activeLayerType,
            design: {
              ...action.template,
              id: state.editor.design?.id,
              layers: {
                front: state.editor.design?.layers?.front,
                back: [...template.layers]
              }
            }
          },
        }
      } else {
        return {
          ...state,
          editor:{
            ...state.editor,
            activeTemplate: {...template},
            activeLayerType,
            design: {
              ...action.template,
              id: state.editor.design?.id,
              layers: {
                front: [...template.layers],
                back: state.editor.design?.layers?.back || null
              }
            }
          },
        }
      }

    }


    case ADD_ICON: {
      return {
        ...state,
        uploadedIcons: action.updatedIcons,
        updateStatus: 'SUCCESS',
      }
    }

    case SET_RECENT_COLOR: {
      const updatedRecentColors = [...state.recentColors]

      if (!colorPickerColors.includes(action.color)) {
        if (updatedRecentColors.includes(action.color)) {
          // const colorIndex = updatedRecentColors.findIndex(color => color === action.color)
          //
          // updatedRecentColors.splice(colorIndex, 1)
          // updatedRecentColors.push(action.color)
        } else {
          updatedRecentColors.shift()
          updatedRecentColors.push(action.color)
        }
      }

      return {
        ...state,
        recentColors: updatedRecentColors,
        updateStatus: 'SUCCESS',
      }
    }

    case SET_RECENT_FONT: {
      const updatedRecentFonts = [...state.recentFonts]
      const fontIndex = updatedRecentFonts.findIndex(font => font === action.font)

      if (fontIndex === -1 && updatedRecentFonts.length < 5) {
        // @ts-ignore
        updatedRecentFonts.unshift(action.font)
      } else {
        updatedRecentFonts.splice(fontIndex, 1)
        // @ts-ignore
        updatedRecentFonts.unshift(action.font)
      }

      return {
        ...state,
        recentFonts: updatedRecentFonts,
        updateStatus: 'SUCCESS',
      }
    }

    case RESET_DATA_START: {
      return {
        ...state,
        resetStatus: 'WORKING',
      }
    }

    case RESET_DATA: {
      return {
        ...state,
        activeTemplates: action.templates,
        userData: [],
        uploadedIcons: null,
        recentFonts: [],
        resetStatus: 'SUCCESS',
      }
    }

    case SET_USER_DATA: {
      return {
        ...state,
        userData: action.userData,
      }
    }

    /*
      1. Switch side in editor
      2. TODO: sort templates by side: https://app.clickup.com/t/2xjrrk0
     */
    case SWITCH_SIDE: {

      return {
        ...state,
        editor: {
          ...state.editor,
          activeSide: action.sideType,
        },
      };

      let returnObject = {
        ...state,
        editor: {
          ...state.editor,
          activeSide: action.sideType,
        },
        activeTemplates: sortByType(state.activeTemplates, state.editor.design, action.sideType),
      }

      if (state.editor.templateType === TYPES.B_CARD) {
        if (action.sideType === SIDES.FRONT) {
          return {
            ...returnObject,
            editor: {
              ...state.editor,
              design: state.currentCardTemplate
            },
            // currentCardBackTemplate: state.currentTemplate
          }
        } else {
          return {
            ...returnObject,
            // currentTemplate: state.currentCardBackTemplate,
            editor: {
              ...state.editor,
              design: state.currentCardTemplate
            },
          }
        }
      } else if (state.editor.templateType === TYPES.LABEL) {
        if (action.sideType === SIDES.FRONT) {

          return {
            ...returnObject,
            // currentTemplate: state.currentCardBackTemplate,
            editor: {
              ...state.editor,
              design: state.currentLabelTemplate
            },
          }
        } else {
          return {
            ...returnObject,
            // currentTemplate: state.currentCardBackTemplate,
            editor: {
              ...state.editor,
              design: state.currentLabelBackTemplate
            },
          }
        }
      }

      return returnObject
    }

    /*
      This method does multiple things
       1. Set type
       2. Set templates by type
       2. Create Design from Template
     */
    case SET_TYPE_AND_CREATE_DESIGN_FROM_TEMPLATE_SET: {
      let returnObject = {
        ...state,
        editor: {
          ...state.editor,
          activeSide: SIDES.FRONT,
          templateType: action.templateType,
        },
      }

      // return returnObject;
      //
      // if (state.editor.templateType === TYPES.B_CARD) {
      //   Object.assign(returnObject, {cardTemplates: state.activeTemplates})
      //   if (state.editor.activeSide === SIDES.FRONT) {
      //     Object.assign(returnObject, {currentCardTemplate: state.editor.design})
      //   } else {
      //     Object.assign(returnObject, {currentCardBackTemplate: state.editor.design})
      //   }
      // } else if (state.editor.templateType === TYPES.LABEL) {
      //   Object.assign(returnObject, {labelTemplates: state.activeTemplates})
      //   if (state.editor.activeSide === SIDES.FRONT) {
      //     Object.assign(returnObject, {currentLabelTemplate: state.editor.design})
      //   } else {
      //     Object.assign(returnObject, {currentLabelBackTemplate: state.editor.design})
      //   }
      // } else if (state.editor.templateType === TYPES.STICKER) {
      //   Object.assign(returnObject, {stickerTemplates: state.activeTemplates})
      //   Object.assign(returnObject, {currentStickerTemplate: state.editor.design})
      // } else if (state.editor.templateType === TYPES.QR_ONLY) {
      //   Object.assign(returnObject, {qrTemplates: state.activeTemplates})
      //   Object.assign(returnObject, {currentQrTemplate: state.editor.design})
      // }

      if (action.templateType === TYPES.B_CARD) {
        return {
          ...returnObject,
          editor: {
            ...returnObject.editor,
            design: createDesignObjectFromTemplate(state.currentCardTemplate)
          },
          activeTemplates: state.cardTemplates
        }

      } else if (action.templateType === TYPES.LABEL) {
        return {
          ...returnObject,
          editor: {
            ...returnObject.editor,
            design: createDesignObjectFromTemplate(state.currentLabelTemplate)
          },
          activeTemplates: state.labelTemplates
        }
      } else if (action.templateType === TYPES.STICKER) {
        return {
          ...returnObject,
          editor: {
            ...returnObject.editor,
            design: createDesignObjectFromTemplate(state.currentStickerTemplate),
          },
          activeTemplates: state.stickerTemplates
        }
      } else if (action.templateType === TYPES.QR_ONLY) {
        return {
          ...returnObject,
          editor: {
            ...returnObject.editor,
            design: createDesignObjectFromTemplate(state.currentQrTemplate)
          },
          activeTemplates: state.qrTemplates
        }
      }

      return returnObject
    }

    /*
      1. Set templateType
      2. Switching activeTemplates according to templateType
     */
    case SET_TEMPLATE_TYPE: {
      // return {
      //   ...state,
      //   templateType: action.templateType,
      //   activeSide: SIDES.FRONT,
      // };

      let returnObject = {
        ...state,
        editor: {
          ...state.editor,
          activeSide: SIDES.FRONT,
          templateType: action.templateType,
        },
      }

      // @ts-ignore


      // if (state.editor.templateType === TYPES.B_CARD) {
      //   Object.assign(returnObject, {cardTemplates: state.activeTemplates})
      //   if (state.activeSide === SIDES.FRONT) {
      //     Object.assign(returnObject, {currentCardTemplate: state.currentTemplate})
      //   } else {
      //     Object.assign(returnObject, {currentCardBackTemplate: state.currentTemplate})
      //   }
      // } else if (state.editor.templateType === TYPES.LABEL) {
      //   Object.assign(returnObject, {labelTemplates: state.activeTemplates})
      //   if (state.activeSide === SIDES.FRONT) {
      //     Object.assign(returnObject, {currentLabelTemplate: state.currentTemplate})
      //   } else {
      //     Object.assign(returnObject, {currentLabelBackTemplate: state.currentTemplate})
      //   }
      // } else if (state.editor.templateType === TYPES.STICKER) {
      //   Object.assign(returnObject, {stickerTemplates: state.activeTemplates})
      //   Object.assign(returnObject, {currentStickerTemplate: state.currentTemplate})
      // } else if (state.editor.templateType === TYPES.QR_ONLY) {
      //   Object.assign(returnObject, {qrTemplates: state.activeTemplates})
      //   Object.assign(returnObject, {currentQrTemplate: state.currentTemplate})
      // }

      // change currentTemplate and activeTemplates
      if (action.templateType === TYPES.B_CARD) {
        Object.assign(returnObject, {activeTemplates: _cloneDeep([...state.cardTemplates])})
      } else if (action.templateType === TYPES.LABEL) {
        return {...returnObject, activeTemplates: _cloneDeep([...state.labelTemplates])}
      } else if (action.templateType === TYPES.STICKER) {
        return {...returnObject, activeTemplates: _cloneDeep([...state.stickerTemplates])}
      } else if (action.templateType === TYPES.QR_ONLY) {
        return {...returnObject, activeTemplates: _cloneDeep([...state.qrTemplates])}
      }

      return returnObject
    }

    case SET_CURRENT_TEMPLATE_SUBTYPE: {
      return {
        ...state,
        editor: {
          ...state.editor,
          templateSubtype: action.templateType,
        },
      }
    }

    case SET_IMAGES: {
      return {
        ...state,
        images: action.images,
      }
    }

    case SET_IMAGE_COUNT: {
      return {
        ...state,
        imageCount: action.imageCount,
      }
    }

    case SET_TEMPLATES_COUNT: {
      return {
        ...state,
        templatesCount: action.templatesCount,
      }
    }

    case SET_ICONS: {
      return {
        ...state,
        icons: action.icons,
      }
    }

    case SET_QR_ICONS: {
      return {
        ...state,
        qrIcons: action.qrIcons,
      }
    }

    case SET_TEMPLATES: {
      return {
        ...state,
        initialTemplates: _cloneDeep([...action.cardTemplates, ...action.labelTemplates, ...action.stickerTemplates, ...action.qrTemplates]),

        activeTemplates: [...action.cardTemplates],

        currentCardTemplate: [...action.cardTemplates][0],
        currentCardBackTemplate: [...action.cardTemplates][1],

        currentLabelTemplate: [...action.labelTemplates][0],
        currentLabelBackTemplate: [...action.labelTemplates][1],

        currentStickerTemplate: [...action.stickerTemplates][0],
        currentStickerBackTemplate: [...action.stickerTemplates][1],

        currentQrTemplate: [...action.qrTemplates][0],

        cardTemplates: [...action.cardTemplates],
        labelTemplates: [...action.labelTemplates],
        stickerTemplates: [...action.stickerTemplates],
        qrTemplates: [...action.qrTemplates],
      }
    }

    default:
      break
  }

  return state
}

/**
 * Configuration of redux-persist used for serialization of redux state to local storage.
 */
const persistConfig = {
  key: 'card',
  storage,
  blacklist: ['imageCount', 'templatesCount'],
}

// @ts-ignore
const persistableReducer = persistReducer(persistConfig, cardReducer)

export default persistableReducer
