import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/lib/integration/react'
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import './fonts/BebasNeue-Bold.ttf'
import './fonts/BebasNeue-Regular.ttf'
import './fonts/DancingScript-Regular.ttf'
import { store, persistor } from './store/initializator'
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import IntlProviderContainer from './components/utils/IntlProviderContainer'
import Modal from 'react-modal';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <IntlProviderContainer>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </IntlProviderContainer>
    </PersistGate>
  </Provider>,
  document.getElementById('root'));

Modal.setAppElement('#root');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
