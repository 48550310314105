import React, { useState, useEffect } from "react"
import { connect } from "react-redux";
import { ReactSVG } from 'react-svg'
import { getInitialTemplates } from '../../../store/card/cardSelectors';
import Template from "./Template"
import { TYPES } from "../../../utils/constants";

const TemplatesMenu = ({
    initialTemplates,
    currentTemplateType,
    openEditor,
  }) => {
  const [currentlyDisplayed, setCurrentlyDisplayed] = useState({})
  const [currentPage, setCurrentPage] = useState({})
  const [totalPages, setTotalPages] = useState({})

  useEffect(() => {
    let currentSubtypes
    switch (currentTemplateType) {
      case TYPES.B_CARD:
        currentSubtypes = {
          "B-EU": [],
          "B-US": [],
        }
        break

      case TYPES.LABEL:
        currentSubtypes = {
          "L-EU": [],
          "L-00": [],
          "L-11": [],
          "L-12": [],
        }
        break

      case TYPES.STICKER:
        currentSubtypes = {
          "S-11": [],
          "S-12": [],
          "S-23": [],
          "S-00": [],
        }
        break

      default:
        currentSubtypes = {
          qr_only: [],
        }
    }
    setCurrentPage({})
    setTotalPages({})
    const currentTemplates = initialTemplates.filter(template => template.type === currentTemplateType)
    Object.keys(currentSubtypes).forEach((currentSubtype) => {
      currentSubtypes[currentSubtype] = currentTemplateType !== TYPES.QR_ONLY ?
                                        currentTemplates.filter((t) => t.subtype === currentSubtype) :
                                        currentTemplates.filter((t) => t.type === currentSubtype)
      if (currentTemplateType !== TYPES.QR_ONLY) {
        setCurrentPage((prevCurrentPage) => {
          return {
            ...prevCurrentPage,
            [currentSubtype]: 0
          }
        })
        setTotalPages((prevTotalPages) => {
          return {
            ...prevTotalPages,
            [currentSubtype]: parseInt(currentSubtypes[currentSubtype].length / 4)
          }
        })
      } else {
        setCurrentPage({ qr_only: 0 })
        setTotalPages({ qr_only: parseInt(currentSubtypes[currentSubtype].length / 4) })
      }
    })
    setCurrentlyDisplayed(currentSubtypes)
  }, [currentTemplateType])

  return (
    <>
      {Object.keys(currentlyDisplayed).map((subtype, id) => {
        const templatesSection = currentlyDisplayed[subtype].slice(currentPage[subtype], currentPage[subtype] + 4).map((template, id) => {
          return (
            <div onClick={() => openEditor(template, currentTemplateType, subtype)} className="template-overlay">
              <div
                key={id}
                className={`template-item-container ${template.orientation === 'height' ? 'height-orientation' : ''} ${currentTemplateType}`}
              >
                <div
                  key={id}
                  className="template-item"
                >
                  <Template template={template} />
                </div>
              </div>
            </div>
          )
        })
        if (currentlyDisplayed[subtype].length > 0) {
          return (
            <div key={id} className="templates-subsection-wrapper">
              <div className="templates-subsection-title">{
                subtype === 'B-EU' ? "EURO vizitky 85x55mm" :
                subtype === 'B-US' ? "US vizitky 90x50mm" :
                subtype === 'L-11' ? "Square shape" :
                subtype === 'L-12' ? "Rectangular shape" :
                "Shape"
              }</div>
              <div className="templates-subsection">
                <div className="button-wrapper">
                  {currentPage[subtype] !== 0 &&
                    <button
                      className="moving-button"
                      onClick={() => setCurrentPage(prevPage => {
                        return {
                          ...prevPage,
                          [subtype]: prevPage[subtype]-1
                        }
                      })}
                    >
                    <ReactSVG
                      src="/images/icons/menu-arrow-left.svg"
                      alt="arrow left"
                    />
                    </button>
                  }
                </div>
                {templatesSection}
                <div className="button-wrapper">
                  {currentPage[subtype] < totalPages[subtype] &&
                    <button
                      className="moving-button"
                      onClick={() => setCurrentPage(prevPage => {
                        return {
                          ...prevPage,
                          [subtype]: prevPage[subtype]+1
                        }
                      })}
                    >
                      <ReactSVG
                        src="/images/icons/menu-arrow-right.svg"
                        alt="arrow right"
                      />
                    </button>
                  }
                </div>
              </div>
            </div>
          )
        }
      })}
    </>
  )
}

const mapState = (state) => ({
  initialTemplates: getInitialTemplates(state),
})

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(TemplatesMenu)
