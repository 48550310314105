import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateDesign, addIcon } from '../../../../store/card/cardActions'
import { getActiveLayer, getUploadedIcons } from '../../../../store/card/cardSelectors'

import { FormattedMessage } from 'react-intl'
import CropperComponent from './CropperComponent'

import './AddImageButton.scss'

class AddImageButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeLayer: props.activeLayer,
      isUploading: false,
      icon: null,
      name: null,
      updatedIcons: null,
    }
  }

  handleStylesChange = (type, value) => {
    const { onChangeTemplate } = this.props
    const { activeLayer } = this.props
    const changedLayer = { ...activeLayer }
    const changedLayerStyles = { ...changedLayer.styles }

    if (type === 'backgroundImage') {
      if (value === 'none') {
        changedLayerStyles[type] = value
      } else {
        changedLayerStyles[type] = `url(${value})`
      }

      changedLayerStyles['backgroundRepeat'] = 'no-repeat'
      changedLayerStyles['backgroundPosition'] = '0 0'
      changedLayerStyles['backgroundUploadedImage'] = value
    } else {
      changedLayerStyles[type] = value
    }

    changedLayer.styles = changedLayerStyles

    this.setState({
      activeLayer: changedLayer,
    })

    onChangeTemplate(changedLayer)
  }

  handleUpdateIcon = (icon) => {
    const { onAddIcon, handleIconChange } = this.props
    const { name, updatedIcons } = this.state

    updatedIcons.push({
      icon,
      name,
    })

    onAddIcon(updatedIcons)
    this.props.handleIconChange(name, 'Colorful')
    this.setState({
      isUploading: false,
    })
  }

  handleUpload = (event) => {
    const { activeLayer, uploadedIcons, uploadType } = this.props
    const layerType = activeLayer.type.split('-')[0]
    const reader = new FileReader()
    const file = event.target.files[0]
    let updatedIcons = uploadedIcons

    event.target.value = ''

    if (updatedIcons === null) {
      updatedIcons = []
    } else {
      updatedIcons = [...uploadedIcons]
    }

    if (layerType === 'icon' || layerType === 'qr') {
      reader.onloadend = () => {
        this.setState({
          isUploading: true,
          icon: reader.result,
          name: `qr_${file.name}-${updatedIcons.length}`,
          updatedIcons,
        })
      }
      reader.readAsDataURL(file)
    } else if (uploadType === 'backgroundImage') {
      reader.onloadend = () => {
        this.handleStylesChange('backgroundImage', reader.result)
      }
      reader.readAsDataURL(file)
    } else {
      reader.onloadend = () => {
        this.handleStylesChange('uploadedImage', reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  handleImageDelete = (type) => {
    if (type === 'backgroundImage') {
      this.handleStylesChange('backgroundImage', 'none')
    } else {
      this.handleStylesChange('uploadedImage', null)
    }
  }

  handleCancel = () => {
    this.setState({
      isUploading: false,
    })
  }

  handleBackroundPoisition = () => {
    document.getElementsByClassName('focused')[0].getElementsByClassName('svgElement')[0].style.zIndex = 90
    document.getElementById('overlay').style.display = 'block'
  }

  render() {
    const { activeLayer, uploadType } = this.props
    const { isUploading, icon } = this.state

    function isImageUploaded() {
      let result =
        (activeLayer.styles.uploadedImage && uploadType !== 'backgroundImage') ||
        (activeLayer.styles.backgroundImage !== 'none' && uploadType === 'backgroundImage' && activeLayer.styles.backgroundUploadedImage)
      return result
    }

    return isUploading ? (
      <CropperComponent icon={icon} onCancel={this.handleCancel} onUpdateIcon={this.handleUpdateIcon} />
    ) : (
      <div className={`add-image-button ${isImageUploaded() && 'image-selected'}`}>
        {activeLayer.type.split('-')[0] === 'background' && activeLayer.styles.uploadedImage && uploadType !== 'backgroundImage' && (
          <div className="image-preview">
            <div className="image-preview-content">
              <img onClick={() => this.handleBackroundPoisition()} src={activeLayer.styles.uploadedImage} alt="upload" />
            </div>
            <span onClick={() => this.handleImageDelete('uploadedImage')} className="delete-button">
              x
            </span>
          </div>
        )}

        {activeLayer.type.split('-')[0] === 'background' &&
          uploadType === 'backgroundImage' &&
          activeLayer.styles.backgroundImage !== 'none' &&
          activeLayer.styles.backgroundUploadedImage && (
            <div className="image-preview">
              <div className="image-preview-content">
                <img onClick={() => this.handleBackroundPoisition()} src={activeLayer.styles.backgroundUploadedImage} alt="upload" />
              </div>
              <span onClick={() => this.handleImageDelete('backgroundImage')} className="delete-button">
                x
              </span>
            </div>
          )}
        <div className="primary-button">
          <label className="file-upload">
            {activeLayer.type.split('-')[0] === 'icon' || activeLayer.type.split('-')[0] === 'qr' ? (
              <FormattedMessage id="addbutton.upload" />
            ) : isImageUploaded() ? (
              <FormattedMessage id="addbutton.change" />
            ) : uploadType === 'backgroundImage' ? (
              <FormattedMessage id="addbutton.uploadImage" />
            ) : (
              <FormattedMessage id="addbutton.uploadOverlay" />
            )}
            <input type="file" id="upload" accept=".jpg, .jpeg, .png, .svg" onChange={(e) => this.handleUpload(e)} />
          </label>
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  activeLayer: getActiveLayer(state),
  uploadedIcons: getUploadedIcons(state),
})

const mapDispatch = (dispatch) => ({
  onChangeTemplate: (layer) => dispatch(updateDesign(layer)),
  onAddIcon: (updatedIcons) => dispatch(addIcon(updatedIcons)),
})

export default connect(mapState, mapDispatch)(AddImageButton)
