import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import ClipLoader from "react-spinners/ClipLoader";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

import { APP_DOMAIN } from '../../../../utils/constants'

const SubscriptionPaymentForm = ({ email, price, currency, finalPrice }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${APP_DOMAIN}/subscription-confirmation`,
        receipt_email: email,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  return (
    <div className="payment-form-page">
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        <button className={`final-button`} disabled={isLoading || !stripe || !elements} id="submit">
          {`Confirm and pay ${finalPrice} ${currency === 'eur' ? '€' : currency === 'usd' ? '$' : currency === 'gbp' ? '£' : 'Kč'} by card`}
          {isLoading ?
            <div className="loader">
              <ClipLoader loading={isLoading} color="#FFF" size={15}/>
            </div>
            : 
            <svg width="14" height="9" viewBox="0 0 14 9" fill="none">
              <path d="M0.463623 4.17276H8.64544" stroke="white" strokeWidth="1.5" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.31812 0.0820312L13.2272 4.17294L8.31812 8.26385V0.0820312Z"
                fill="white"
              />
            </svg>
          }
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </div>
  );
}

export default SubscriptionPaymentForm