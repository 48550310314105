import React, { useState } from 'react'
import {Link, useHistory} from "react-router-dom";
import { connect } from 'react-redux'
import ClickAwayListener from 'react-click-away-listener'
import { logout } from '../../../store/auth/authActions'
import AnimateHeight from 'react-animate-height'
import { getFetchStatus } from '../../../store/auth/authSelectors'
import { FormattedMessage } from 'react-intl'

function MainMenu({onLogout, option}) {
  const [exitMenuOpen, setExitMenuOpen] = useState(false)
  const [isDevelopment, setIsDevelopment] = useState(
    window.location.href === 'https://qrcards.herokuapp.com/app/dashboard/home' || window.location.href === 'http://localhost:3000/app/dashboard/home' ? true : false
  )

  const history = useHistory()

  const handleLogout = () => {
    onLogout()
  }

  return (
    <div id='main-menu'>

      <h3><FormattedMessage id="dashboard.mainMenu.title" /></h3>

      <Link to="/app/dashboard/home">
        <div className={`option ${option === 'homepage' && 'selected'}`}><img src="/images/main-menu/home.svg"/>
          <FormattedMessage id="dashboard.mainMenu.homePage" />
        </div>
      </Link>

      <Link to="/app/dashboard/my-library">
        <div className={`option ${option === 'library' && 'selected'}`}><img src="/images/main-menu/library.svg"/>
          <FormattedMessage id="dashboard.mainMenu.myLibrary" />
        </div>
      </Link>

      <Link to="/app/dashboard/statistics">
        <div className={`option ${option === 'statistics' && 'selected'}`}><img src="/images/main-menu/statistics.svg"/>
          <FormattedMessage id="dashboard.mainMenu.qrStatistics" />
        </div>
      </Link>

      <Link to="/app/dashboard/subscription">
        <div className={`option ${option === 'subscription' && 'selected'}`}><img src="/images/main-menu/subscription.svg"/>
          <FormattedMessage id="dashboard.mainMenu.subscription" />
        </div>
      </Link>

      {isDevelopment &&
        <Link to="/app/dashboard/orders">
          <div className={`option ${option === 'orders' && 'selected'}`}><img src="/images/main-menu/orders.svg"/>
            <FormattedMessage id="dashboard.mainMenu.myOrders" />
          </div>
        </Link>
      }

      <Link to="/app/dashboard/my-account">
        <div className={`option ${option === 'account' && 'selected'}`}><img src="/images/main-menu/account.svg"/>
          <FormattedMessage id="dashboard.mainMenu.myAccount" />
        </div>
      </Link>

      {/* UserManagement and Support are only a drafts now */}
      {/* <div onClick={() => setOption('support')} className={`option ${option === 'support' && 'selected'}`}><img src="images/main-menu/account.svg"/>Support</div> */}
      {/* <div onClick={() => setOption('management')} className={`option ${option === 'management' && 'selected'}`}><img src="images/main-menu/account.svg"/>User management</div> */}
      <div onClick={() => setExitMenuOpen(!exitMenuOpen)} className={`option`}><img src="/images/main-menu/exit.svg"/>
        <FormattedMessage id="dashboard.mainMenu.exit" />
      </div>
        <AnimateHeight
          duration={300}
          height={exitMenuOpen ? 'auto' : 0}
        >
          <ClickAwayListener onClickAway={() => {exitMenuOpen && setExitMenuOpen(!exitMenuOpen)}}>
            <div className='extended-menu'>
                <div onClick={() => history.push("/")} className='option'><img src="/images/main-menu/arrowRight.svg" />
                  <FormattedMessage id="dashboard.mainMenu.backToWebsite" />
                </div>
                <div onClick={handleLogout} className='option logout'><img src="/images/main-menu/account2.svg" />
                  <FormattedMessage id="dashboard.mainMenu.logout" />
                </div>
            </div>
          </ClickAwayListener>
        </AnimateHeight>
    </div>
  )
}

const mapState = (state) => ({
  logoutStatus: getFetchStatus(state),
})

const mapDispatch = (dispatch) => ({
  onLogout: () => dispatch(logout()),
})

export default connect(mapState, mapDispatch)(MainMenu)
