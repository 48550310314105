import React, { useState, useEffect } from 'react'
import { ReactSVG } from 'react-svg'
import { useSelector, connect } from "react-redux";
import { FormattedMessage } from 'react-intl'
import { useHistory } from "react-router-dom";
import { SUBTYPES, TYPES, EDITOR_MODES} from "../../../utils/constants";
import { CSSTransition } from 'react-transition-group';
import TemplatesMenu from '../customization/TemplatesMenu';
import {
  setTemplateType,
  setIsDynamicQrFormatActive,
  setActiveDynamicQrId,
  resetActiveTemplate,
  setTemplateSubtype,
  applyTemplateToDesign,
  setEditorMode,
} from "../../../store/card/cardActions";
import { getQrTemplates } from "../../../store/card/cardSelectors";
import { getIsRedirected } from '../../../store/order/orderSelectors';
import {resetToInitial} from "../../../store/microPage/microPageActions";

function Homepage({
  onSetTemplateType,
  onSetIsDynamicQrFormatActive,
  onSetActiveDynamicQrId,
  onResetActiveTemplate,
  onSetTemplateSubtype,
  onApplyTemplateToDesign,
  onSetEditorMode,
  qrTemplates,
  isRedirected,
  onResetMicroPagesToInitial
 }) {
  const [toggleMenu, setToggleMenu] = useState(false)
  const [hideImage, setHideImage] = useState(false)
  const [type, setType] = useState("")
  const [isDevelopment, setIsDevelopment] = useState(
    window.location.href === 'https://qrcards.herokuapp.com/app/dashboard/home' || window.location.href === 'http://localhost:3000/app/dashboard/home' ? true : false
  )

  const history = useHistory();

  useEffect(() => {
    if (isRedirected) {
      history.push('/app/dashboard/subscription/order');
    }
	}, [])

  const showTemplates = (type) => {
    if (isDevelopment) {
      setToggleMenu(true)
      onSetTemplateType(type)
      setType(type)
    }
  }

  const openEditor = (template, type, subtype) => {
    // todo: call setIsDynamicQrFormatActive and onSetActiveDynamicQrId from one place
    onSetIsDynamicQrFormatActive(false)
    onSetActiveDynamicQrId(null);
    onSetTemplateType(type)
    onResetActiveTemplate()
    onSetTemplateSubtype(subtype)
    onApplyTemplateToDesign(template)
    onSetEditorMode(EDITOR_MODES.CREATE)
    onResetMicroPagesToInitial();
    history.push('/app/editor');
  }

  let lang = useSelector((store) => store.intl.currentLocale)
  return (
    <div id="homepage">
      <h1 style={{ backgroundColor: "#fafbfc" }}><FormattedMessage id="dashboard.home.h"/></h1>
      <div className="types-wrapper">
        <div onClick={() => showTemplates(TYPES.B_CARD)} className={`type biz-card ${type === "bcard" && "biz-card-active active"}`}>
          <CSSTransition
            in={toggleMenu}
            timeout={200}
            classNames="shrink-animation"
          >
            <div className="upper">
              <ReactSVG className="svg" src="/images/dashboard/homepage/card.svg"/>
              <p><FormattedMessage id="dashboard.home.bizCards"/></p>
              <div className='soon'><FormattedMessage id="dashboard.home.soon"/></div>
            </div>
          </CSSTransition>
          <CSSTransition
            in={toggleMenu}
            timeout={200}
            classNames="hide-animation"
            onEntered={() => setHideImage(true)}
          >
            <div className={`${hideImage && "hidden"} lower`}>
              <img className='soon-img' src={`/images/dashboard/homepage/card-${lang}.png`} alt="" />
            </div>
          </CSSTransition>
        </div>
        <div onClick={() => showTemplates(TYPES.STICKER)} className={`type sticker ${type === "sticker" && "sticker-active active"}`}>
          <CSSTransition
            in={toggleMenu}
            timeout={200}
            classNames="shrink-animation"
          >
            <div className="upper">
              <ReactSVG className="svg" src="/images/dashboard/homepage/sticker.svg"/>
              <p><FormattedMessage id="dashboard.home.stickers"/></p>
              <div className='soon'><FormattedMessage id="dashboard.home.soon"/></div>
            </div>
          </CSSTransition>
          <CSSTransition
            in={toggleMenu}
            timeout={200}
            classNames="hide-animation"
            onEntered={() => setHideImage(true)}
          >
            <div className={`${hideImage && "hidden"} lower`}>
              <img className='soon-img' src={`/images/dashboard/homepage/sticker-${lang}.png`} alt="" />
            </div>
          </CSSTransition>
        </div>
        <div onClick={() => showTemplates(TYPES.LABEL)} className={`type label ${type === "label" && "label-active active"}`}>
          <CSSTransition
            in={toggleMenu}
            timeout={200}
            classNames="shrink-animation"
          >
            <div className={`${toggleMenu && "shrink-animation"} upper`}>
              <ReactSVG className="svg" src="/images/dashboard/homepage/label.svg"/>
              <p><FormattedMessage id="dashboard.home.labels"/></p>
              <div className='soon'><FormattedMessage id="dashboard.home.soon"/></div>
            </div>
          </CSSTransition>
          <CSSTransition
            in={toggleMenu}
            timeout={200}
            classNames="hide-animation"
            onEntered={() => setHideImage(true)}
          >
            <div className={`${hideImage && "hidden"} lower`}>
              <img className='soon-img' src={`/images/dashboard/homepage/label-${lang}.png`} alt="" />
            </div>
          </CSSTransition>
        </div>
        <div onClick={() => openEditor(qrTemplates[0], TYPES.QR_ONLY, TYPES.QR_ONLY)} className={`type qr ${type === "qr_only" && "qr-active active"}`}>
          <CSSTransition
            in={toggleMenu}
            timeout={200}
            classNames="shrink-animation"
          >
            <div className={`${toggleMenu && "shrink-animation"} upper`}>
              <ReactSVG className="svg" src="/images/dashboard/homepage/qr.svg"/>
              <p><FormattedMessage id="dashboard.home.qrCodes"/></p>
            </div>
          </CSSTransition>
          <CSSTransition
            in={toggleMenu}
            timeout={200}
            classNames="hide-animation"
            onEntered={() => setHideImage(true)}
          >
            <div className={`${hideImage && "hidden"} lower`}>
              <img src={`/images/dashboard/homepage/qr-${lang}.png`} alt="" />
            </div>
          </CSSTransition>
        </div>
      </div>
      {type &&
        <CSSTransition
          classNames="templates-section-transition"
          in={toggleMenu}
          timeout={700}
        >
          <div className='templates-section'>
            <TemplatesMenu currentTemplateType={type} openEditor={openEditor} />
          </div>
        </CSSTransition>
      }
    </div>
  )
}

const mapState = (state) => ({
  qrTemplates: getQrTemplates(state),
  isRedirected: getIsRedirected(state),
})

const mapDispatch = (dispatch) => ({
  onSetTemplateType: (type) => dispatch(setTemplateType(type)),
  onSetIsDynamicQrFormatActive: (value) => dispatch(setIsDynamicQrFormatActive(value)),
  onSetActiveDynamicQrId: (id) => dispatch(setActiveDynamicQrId(id)),
  onResetActiveTemplate: () => dispatch(resetActiveTemplate()),
  onSetTemplateSubtype: (type) => dispatch(setTemplateSubtype(type)),
  onApplyTemplateToDesign: (template) => dispatch(applyTemplateToDesign(template)),
  onSetEditorMode: (mode) => dispatch(setEditorMode(mode)),
  onResetMicroPagesToInitial: () => dispatch(resetToInitial())
})

export default connect(mapState, mapDispatch)(Homepage)
