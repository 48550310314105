import React, { Component } from 'react'
import Slider from '@material-ui/core/Slider'
import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage'

import './CropperComponent.scss'
// import { BlockPicker } from 'react-color'
// import { type } from 'ramda'

class CropperComponent extends Component {
  state = {
    imageSrc: this.props.icon,
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: this.props.aspect ?? 5 / 5,
    croppedAreaPixels: null,
    croppedImage: null,
    cropShape: this.props.cropShape ?? 'rect',
    isChecked: this.props.cropShape === 'round',
    hideCheckbox: this.props.hideCheckbox,
    removeRadius: this.props.removeRadius,
  }

  scrollTop() {
    // (TODO: maybe use ref instead)
    const elements = document.getElementsByClassName('customize-section');

    if (elements.length > 0) {
      elements[0].scrollTo(0, 0);
    }

  }

  onCropChange = (crop) => {
    this.setState({ crop })
    this.scrollTop();
  }
  
  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels })
    //this.setCroppedImage()
  }

  onZoomChange = (zoom) => {
    this.setState({ zoom })
  }

  setCroppedImage = async () => {
    const croppedImage = await getCroppedImg(this.state.imageSrc, this.state.croppedAreaPixels, this.state.cropShape)
    let finalImage = croppedImage
    // if (this.state.cropShape === 'rect') {
    //   finalImage = await this.roundCorners(croppedImage)
    // }
    this.setState({ finalImage })
    this.props.onUpdateIcon(finalImage)
  }
  roundCorners = async (image) => {
    let img = new Image()
    img.src = image
    await img.decode()

    let canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    let ctx = canvas.getContext('2d')

    ctx.save()
    this.roundedImage(0, 0, img.width, img.height, img.width * 0.11, ctx)
    ctx.clip()
    ctx.drawImage(img, 0, 0, img.width, img.height)
    ctx.restore()
    return canvas.toDataURL('image/png')
  }
  roundedImage = (x, y, width, height, radius, ctx) => {
    ctx.beginPath()
    ctx.moveTo(x + radius, y)
    ctx.lineTo(x + width - radius, y)
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
    ctx.lineTo(x + width, y + height - radius)
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
    ctx.lineTo(x + radius, y + height)
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
    ctx.lineTo(x, y + radius)
    ctx.quadraticCurveTo(x, y, x + radius, y)
    ctx.closePath()
  }
  handleCropShape = (e) => {
    if (e.target.checked) {
      this.setState({
        cropShape: 'round',
        isChecked: true,
      })
    } else {
      this.setState({
        cropShape: 'rect',
        isChecked: false,
      })
    }
  }
  onUpdateIconClick = () => {
    this.setCroppedImage()
  }
  render() {
    const { onCancel } = this.props
    const { cropShape, isChecked, hideCheckbox, removeRadius } = this.state
    return (
      <div className="cropper-component">
        <span className="section-title">Customize</span>
        <div className="crop-container">
          <Cropper
            style={{
              borderRadius: removeRadius ? '' : '11%', 
            }}
            image={this.state.imageSrc}
            crop={this.state.crop}
            zoom={this.state.zoom}
            aspect={this.state.aspect}
            restrictPosition={false}
            onCropChange={this.onCropChange}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.onZoomChange}
            showGrid={false}
            cropShape={cropShape}
          />
        </div>
        <div className="controls">
          <Slider
            value={this.state.zoom}
            min={0.2}
            max={3}
            step={0.05}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => this.onZoomChange(zoom)}
            className="slider"
          />
        </div>
        {!hideCheckbox &&
          <div className="circle-crop-checkbox">
            <input
              checked={isChecked}
              className="checkbox"
              type="checkbox"
              id="circle-crop"
              name="circle-crop"
              onChange={(e) => this.handleCropShape(e)}
            />
            <label htmlFor="circle-crop">Crop icon into a circle</label>
          </div>}
        <button className="cancel-button" onClick={() => onCancel()}>
          Cancel
        </button>
        <button type="button" className="primary-button-right" onClick={this.onUpdateIconClick}>
          Upload
        </button>
      </div>
    )
  }
}

export default CropperComponent
