import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ReactComponent as AlignTop } from './images/align-top.svg'
import { ReactComponent as AlignMiddle } from './images/align-middle.svg'
import { ReactComponent as AlignBottom } from './images/align-bottom.svg'
import { ReactComponent as BoldOption } from './images/bold-option.svg'
import { ReactComponent as UnderlineOption } from './images/underline-option.svg'
import { updateDesign } from '../../../../store/card/cardActions'
import { getActiveLayer } from '../../../../store/card/cardSelectors'

import './AlignButtons.scss'

class VerticalAlignButtons extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeLayer: props.activeLayer,
      isDropdownOpen: false,
    }
  }

  handleStylesChange = (type, value) => {
    const { onChangeTemplate } = this.props
    const { activeLayer } = this.props
    const changedLayer = { ...activeLayer }
    const changedLayerStyles = { ...changedLayer.styles }

    if (changedLayerStyles[type] === 'underline') {
      changedLayerStyles[type] = 'none'
    } else if (changedLayerStyles[type] === 'bold') {
      changedLayerStyles[type] = 'normal'
    } else {
      changedLayerStyles[type] = value
    }

    changedLayer.styles = changedLayerStyles

    this.setState({
      activeLayer: changedLayer,
    })

    onChangeTemplate(changedLayer)
  }

  toggleDropdown = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen,
    })
  }

  render() {
    const { activeLayer } = this.props
    const { isDropdownOpen } = this.state
    const alignment = activeLayer.styles.verticalAlign
    const isBold = activeLayer.styles.fontWeight === 'bold'
    const isUnderlined = activeLayer.styles.textDecoration === 'underline'

    return (
      <div className="buttons-group">
        <div
          onClick={() => this.handleStylesChange('fontWeight', 'bold')}
          className={`button bold ${isBold ? 'active' : ''}`}
        >
          <BoldOption />
        </div>
        <div
          onClick={() => this.handleStylesChange('textDecoration', 'underline')}
          className={`button ${isUnderlined ? 'active' : ''}`}
        >
          <UnderlineOption />
        </div>
        <div className="button vertical">
          {alignment === 'top' ?
            <div onClick={() => this.toggleDropdown()} className={`button ${alignment === 'top' ? 'active' : ''}`}>
              <AlignTop />
              <img className="arrow" src="/assets/images/text/arrow-down-white.png" alt="arrow-up" />
            </div>
            : alignment === 'middle' ?
              <div onClick={() => this.toggleDropdown()} className={`button ${alignment === 'middle' ? 'active' : ''}`}>
                <AlignMiddle />
                <img className="arrow" src="/assets/images/text/arrow-down-white.png" alt="arrow-up" />
              </div>
              :
              <div onClick={() => this.toggleDropdown()} className={`button no-margin ${alignment === 'bottom' ? 'active' : ''}`}>
                <AlignBottom />
                <img className="arrow" src="/assets/images/text/arrow-down-white.png" alt="arrow-up" />
              </div>
          }
          {isDropdownOpen &&
            <div className="dropdown-buttons">
              <div onClick={() => {
                this.handleStylesChange('verticalAlign', 'top')
                this.toggleDropdown()
              }} className={`dropdown button ${alignment === 'top' ? 'active' : ''}`}>
                <AlignTop />
              </div>
              <div onClick={() => {
                this.handleStylesChange('verticalAlign', 'middle')
                this.toggleDropdown()
              }} className={`dropdown button ${alignment === 'middle' ? 'active' : ''}`}>
                <AlignMiddle />
              </div>
              <div onClick={() => {
                this.handleStylesChange('verticalAlign', 'bottom')
                this.toggleDropdown()
              }} className={`dropdown button no-margin ${alignment === 'bottom' ? 'active' : ''}`}>
                <AlignBottom />
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  activeLayer: getActiveLayer(state),
})

const mapDispatch = (dispatch) => ({
  onChangeTemplate: (layer) => dispatch(updateDesign(layer)),
})

export default connect(mapState, mapDispatch)(VerticalAlignButtons)
