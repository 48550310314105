import React, {useEffect, useRef, useState} from 'react';
import {BACKEND_URL} from '../../../../utils/constants'
import prependHttp from 'prepend-http';
import useIsMobile from "../../../hooks/useIsMobile";


function ProductPage({slug, cardData, isPreview}) {
    const [page, setPage] = useState(cardData || null)
    const [images, setImages] = useState(cardData?.images || [])
    const [slideIndex, setSlideIndex] = useState(1)
    const [notFound, setNotFound] = useState(false)


    useEffect(() => {
        if (slug) {
            const url = `${BACKEND_URL}/info-pages-by-slug/${slug}`
            fetch(url)
                .then(res => {
                    if (res.status === 404) {
                        setNotFound(true);
                        throw new Error(res.status + " Page not found ");
                    }

                    return res.json();
                })
                .then((result) => {
                    setPage(result);
                    setImages(result.images)
                }).catch((error) => {
            })
        }
    }, []);

    useEffect(() => {
        setPage(cardData)
        setImages(cardData?.images)
    }, [cardData])

    const nextSlide = () => {
        if (slideIndex !== images.length) {
            setSlideIndex(slideIndex + 1)
        } else if (slideIndex === images.length) {
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if (slideIndex !== 1) {
            setSlideIndex(slideIndex - 1)
        } else if (slideIndex === 1) {
            setSlideIndex(images.length)
        }
    }

    const moveDot = index => {
        setSlideIndex(index)
    }

    const BrownTheme = React.lazy(() => import('./themes/brownTheme'));
    const BlueTheme = React.lazy(() => import('./themes/blueTheme'));
    const BlackTheme = React.lazy(() => import('./themes/blackTheme'));
    const GreenTheme = React.lazy(() => import('./themes/greenTheme'));

    const pageRef = useRef();
    const isMobile = useIsMobile(pageRef);


    return (
        <>
            {page &&
                <div id="micro-page-wrapper" ref={pageRef} className={page.theme}>
                    <div className="product-page-wrapper">
                        <div id="microweb" className={`info-page-static`}>
                            <div className={`info-page-static`}>
                                <React.Suspense fallback={<></>}>
                                    {(page.theme === 'brown') && <BrownTheme/>}
                                    {(page.theme === 'blue') && <BlueTheme/>}
                                    {(page.theme === 'black') && <BlackTheme/>}
                                    {(page.theme === 'green') && <GreenTheme/>}
                                </React.Suspense>
                                {
                                    images?.length > 0 &&
                                    <div className={isMobile ? 'slider mobile' : 'slider'}>
                                        {images.map((image, index) => {
                                            return (
                                                <div
                                                    key={image.id}
                                                    className={slideIndex === index + 1 ? "slider__slide slider__slide--active-animation" : "slider__slide"}
                                                >
                                                    <img src={image.url} alt="Slider object"/>
                                                </div>
                                            )
                                        })}
                                        <div className="slider__button slider__button--left">
                                            <img onClick={prevSlide}
                                                 src={require("../assets/info-page-static/arrow-left.svg").default}
                                                 alt="Arrow left"/>
                                        </div>
                                        <div className="slider__button slider__button--right">
                                            <img onClick={nextSlide}
                                                 src={require("../assets/info-page-static/arrow-right.svg").default}
                                                 alt="Arrow left"/>
                                        </div>
                                        <div className="slider__dots">
                                            {Array.from({length: images.length}).map((item, index) => (
                                                <div
                                                    onClick={() => moveDot(index + 1)}
                                                    className={slideIndex === index + 1 ? "slider__dot slider__dot--active" : "slider__dot"}
                                                ></div>
                                            ))}
                                        </div>
                                    </div>
                                }
                                <p className="info-page-static__title">{page?.header}</p>
                                <div className="info-page-static__description">
                                    <div className="info-page-static__description-text">
                                        <p>
                                            {page.content}
                                        </p>
                                    </div>
                                </div>
                                <div className="info-page-static__button">
                                    <a className={`${isPreview ? 'disabled' : ''}`} href={page?.buttonLink && prependHttp(page.buttonLink)} target="_blank">
                                        <button className="action-button">
                                            {page.buttonText}
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            }
        </>
    )
}

export default ProductPage
