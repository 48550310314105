import React from "react";

function Errors({formState, name}) {
    return (
        <div className="error">
            {formState.errors?.[name]?.type === "required" && <p>This field is required</p>}
            {formState.errors?.[name]?.type === "pattern" && <p>{formState.errors?.[name].message}</p>}
        </div>
    )
}

export default Errors;
