// export type EditorModes = {
//     CREATE: string,
//     UPDATE: string
// }

import {SUBTYPES, TYPES} from "../utils/constants";

export enum EditorMode {
    CREATE = 'create',
    UPDATE = 'update'
}

type Opaque<T, K extends string> = T & { __typename: K }
export type Base64 = Opaque<string, "base64">

export enum Type {
    B_CARD = 'bcard',
    STICKER = 'sticker',
    LABEL = 'label',
    QR_ONLY = 'qr_only',
}

export enum Subtype {
    B_EU = 'B-EU',
    B_US = 'B-US',
    L_EU = 'L-EU',
    L_00 = 'L-00',
    L_11 = 'L-11',
    L_12 = 'L-12',
    S_11 = 'S-11',
    S_12 = 'S-12',
    S_23 = 'S-23',
    S_00 = 'S-00'
}

export enum MicroPageType {
    DIGITAL_CARD = 'DIGITAL_CARD',
    PRODUCT_PAGE = 'PRODUCT_PAGE',
    MULTI_ENTRY = 'MULTI_ENTRY',
    FEEDBACK_FORM = 'FEEDBACK_FORM'
}

export type DefaultDigitalCard = {
    image: CloudinaryImage
    fullName: string,
    title: string,
    about: string,
    phone: string,
    email: string,
    organization: string,
    address: string,
    website: string,
    items: any
    theme: string
}

export type DigitalCard = {
    _id: string,
    id: string,
    fullName: string,
    title: string,
    about: string,
    phone: string,
    email: string,
    organization: string,
    address: string,
    website: string,
    theme: string,
    published_at: any,
    slug: string,
    createdAt: any,
    updatedAt: any,
    __v: number,
    image: any,
    fullImage: any,
    items: any
}

export type DefaultProductPage = {
    header: string,
    content: string,
    buttonText: string,
    buttonLink: string,
    images: CloudinaryImage[]
    theme: string,

}

export type ProductPage = {
    images: any,
    _id: string,
    header: string,
    content: string,
    buttonText: string,
    buttonLink: string,
    theme: string,
    published_at: any,
    slug: string,
    createdAt: any,
    updatedAt: any,
    __v: number,
    id: string,
}

export type DefaultFeedbackForm = {
    name: string,
    items: any,
    image: CloudinaryImage,
    theme: string,
}

export type FeedbackForm = {
    image: any,
    _id: string,
    name: string,
    theme: string,
    published_at: any,
    slug: string,
    createdAt: any,
    updatedAt: any,
    items: any,
    __v: number,
    id: string,
}

export type DefaultMultiEntry = {
    name: string,
    image: CloudinaryImage,
    items: any,
    theme: string

}
export type MultiEntry = {
    _id: string,
    id: string,
    name: string,
    theme: string,
    published_at: any,
    slug: string,
    createdAt: any,
    updatedAt: any,
    items: any,
    __v: number,
    image: any,
}

export type Editor = {
    mode: null | EditorMode,
    templateType: string,
    templateSubtype: string,
    activeSide:  string,
    activeLayerType: null | string,
    activeTemplate: null | Template,
    design: null | Design,
    activeMicroPageType: null | MicroPageType,
    dynamicQrUrl: null | string,
    isDynamicQrFormatActive: boolean,
    activeDynamicQrCodeId: null | string,
}

export type Paginator = {
    count: number,
    limit: number,
    pages: number
}

export type DynamicQrCode = {
    id: string,
    url: string,
    microPage: null | { id: string, type: MicroPageType }
}

export type Design = {
    _id: string,
    id: string,
    name: string,
    layers: {
        front: Array<Layer>,
        back: Array<Layer>
    },
    cardType: any,
    sizeType: string,
    type: string,
    orientation: string,
    subtype: string,
    thumbnail?: any
    dynamicQrCode?: DynamicQrCode,
};

export type Template = {
    id: string,
    name: string,
    layers: Array<Layer>,
    cardType: any,
    sizeType: string,
    type: string,
    orientation: string,
    subtype: string,
    thumbnail?: any,
    visuals?: any,
    visual1?: any,
    visual2?: any,
    visual3?: any,
};

export type Layer = {
    type: string
}

export type State = {
    initialTemplates: any,

    activeTemplates: any,

    cardTemplates: any,
    labelTemplates: any,
    stickerTemplates: any,
    qrTemplates: any,

    // two-side templates
    currentCardTemplate: any,
    currentCardBackTemplate: any,

    currentLabelTemplate: any,
    currentLabelBackTemplate: any,

    // one-side templates
    currentStickerTemplate: any,

    currentQrTemplate: any,

    editor: Editor,

    updateStatus: string,
    uploadedIcons: any,
    recentColors: any,
    recentFonts: any,
    resetStatus: any,
    userData: [],
    images: any,
    templatesCount: number,
    imageCount: number,
    icons: any,
    qrIcons: any,
};

export type CloudinaryImage = {
    _id: string,
    "id": string
    name: string,
    hash: string,
    ext: string,
    mime: string,
    size: number,
    width: number,
    height: number,
    url: string,
    provider_metadata: any,
    "formats": any,
    "provider": string,
    "related": [],
    "createdAt": string,
    "updatedAt": string,
    "__v": number,
}
