import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  getActiveTemplates,
  getTemplateType,
  getEditorMode,
  getTemplatesCount,
} from '../../../store/card/cardSelectors'
import {
  setActiveLayer,
  applyTemplateToDesign,
  fetchTemplates,
  setTemplateType,
  setTemplatesCount,
} from '../../../store/card/cardActions'
import Template from './Template'
import { FormattedMessage } from 'react-intl'
import { EDITOR_MODES, TYPES } from "../../../utils/constants";
import './TemplatesList.scss'

class TemplatesList extends Component {
    componentDidMount() {
      const { activeTemplates, onFetchTemplates, onSetTemplatesCount, onChange } = this.props

      if (activeTemplates.length < 1) {
        onFetchTemplates();
      }

      onSetTemplatesCount(6)
      this.createObserver();
    }

    fetchMoreImages = () => {
      const { templatesCount, onSetTemplatesCount } = this.props

      onSetTemplatesCount(templatesCount + 4) // Fetch 4 more templates
    }

    handleIntersect = () => {
      this.fetchMoreImages()
    }

    createObserver = () => {
      const loadingEl = document.getElementById("template-list-loading");
      const observer = new IntersectionObserver(
          this.handleIntersect,
          {}
      );
      observer.observe(loadingEl);
    }

    handleSetCurrentTemplate = (template) => {
      this.props.onApplyTemplateToDesign(template)
      // this.props.onSetTemplateType(template.type)
      // if (this.props.activeTemplate.id !== template.id) this.props.onSetActiveLayer(null)
      this.props.onChange()
    }

  render() {
    const { activeTemplates, templateType } = this.props

    return (
      <div className="templates-list">
        <div className="templates-title">
          {templateType === TYPES.B_CARD && <FormattedMessage id="templatelist.bcards" />}
          {templateType === TYPES.STICKER && <FormattedMessage id="templatelist.stickers" />}
          {templateType === TYPES.LABEL && <FormattedMessage id="templatelist.labels" />}
          {templateType === TYPES.QR_ONLY && <FormattedMessage id="templatelist.qr" />}
        </div>
        <div className="templates-list-wrapper">
          {activeTemplates.map((template, id) => {

            return (
              <div
                key={id}
                onClick={() => this.handleSetCurrentTemplate(template)}
                className={`template-item-container ${templateType} ${template.orientation === 'height' ? 'height-orientation' : ''}`}
              >
                <div className="template-item">
                  <Template key={template.id} className="list-template" template={{...template}} />
                </div>
              </div>
            )
          })}
          <div id="template-list-loading">
          </div>
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  activeTemplates: getActiveTemplates(state),
  templateType: getTemplateType(state),
  editorMode: getEditorMode(state),
  templatesCount: getTemplatesCount(state),
})

const mapDispatch = (dispatch) => ({
  onApplyTemplateToDesign: (template) => dispatch(applyTemplateToDesign(template)),
  onSetActiveLayer: (layer) => dispatch(setActiveLayer(layer)),
  onFetchTemplates : () => dispatch(fetchTemplates()),
  onSetTemplateType: (type) => dispatch(setTemplateType(type)),
  onSetTemplatesCount: (templatesCount) => dispatch(setTemplatesCount(templatesCount)),
})

export default connect(mapState, mapDispatch)(TemplatesList)
