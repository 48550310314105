import React, {useEffect, useState} from "react";
import {connect} from 'react-redux'
// @ts-ignore
import {ReactComponent as Cross} from "../../../common/images/elements/cross.svg";
import './ProductPageForm.scss'
import {useForm} from "react-hook-form"
import {BACKEND_URL} from '../../../../../../utils/constants'
import PlusButton from '../PlusButton'
import CancelButton from '../CancelButton'
import CropperComponent from "../../../common/CropperComponent";
import {dataURLtoFile} from "../../../../../../utils/helpers";
import Loader from "../../../common/Loader"
import ThemePicker from "../ThemePicker";
import {setProductPage} from "../../../../../../store/microPage/microPageActions";
import {getProductPage} from "../../../../../../store/microPage/microPageSelector";
import {CloudinaryImage} from "../../../../../../ts/types";

function ProductPageForm(props) {

	// Mounted: populate from redux into validation (useForm)
	useEffect(() => {
		setValue('header', props?.page?.header)
		setValue('content', props?.page?.content)
		setValue('buttonText', props?.page?.buttonText)
		setValue('buttonLink', props?.page?.buttonLink)
		props.setDynamicQrType({ "ProductPage": props.page })
	}, [])

	const [images, setImages] = useState(props?.page?.images ?? [])
	const [isCropping, setIsCropping] = useState(false)
	const [croppingImage, setCroppingImage] = useState<CloudinaryImage|null>(null)
	const [croppingImageIndex, setCroppingImageIndex] = useState(null)

	const [theme, setTheme] = useState(props?.page?.theme ?? 'brown');

	const [loading, setLoading] = useState(false)
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		getValues,
		formState: {errors, isValid, isDirty, dirtyFields, touchedFields}
	} = useForm({
		mode: "onTouched" // "onChange"
	});

	function handleSaveProductPageData(data) {
		props.saveLocallyProductPage(data, 'update');
		props.setDynamicQrType({ "ProductPage": data })
	}

	 
	function handleUpdate(e, type) {
		const value = e.target.value

		handleSaveProductPageData({ ...props.page, [type]: value })
	}

	useEffect(() => {
		props.setDynamicQrType({ "ProductPage": { ...props.page, images } })
	}, [images])

	useEffect(() => {
		handleSaveProductPageData({ ...props.page, theme: theme, images })
	}, [theme, images, croppingImage]);


	function onError(data) {
		console.log(data, 'data');
	}

	// TODO: validation is not necessary? Or should i validate data onec user hit "Save" button?
	async function onSubmit(data) {

	}

	const uploadImages = async (files: FileList|null) => {

		if (files === null) {
			return
		}
		setLoading(true)

		const formData = new FormData();

		[...files].forEach((file) => formData.append(`files`, file, file.name))
		await fetch(`${BACKEND_URL}/upload`, {
			method: "POST",
			body: formData
		}).then((response) => {
			return response.json();
		}).then((body) => {
			const newImages = body;
			setImages([...images, ...newImages])
			setLoading(false)
		})
	}

	async function uploadImage(file: File) {
		const formData = new FormData();

		formData.append(`files`, file, 'file.name')
		await fetch(`${BACKEND_URL}/upload`, {
			method: "POST",
			body: formData
		}).then((response) => {
			return response.json();
		}).then((body) => {
			updateImage(body[0], croppingImageIndex)
			setLoading(false)
			setIsCropping(false);
		})
	}

	function updateImage(updatedImage, index) {
		const newItems = [...images];

		newItems[index] = updatedImage
		setImages(newItems)
		setCroppingImageIndex(null);
	}

	const handleImageDelete = (imageId) => {
		fetch(`${BACKEND_URL}/upload/files/${imageId}`, {
			method: "DELETE"
		}).catch((error) => {
			console.log(error);
		})
		setImages(images.filter((image) => image.id !== imageId));
	}

	function handleCropCancel(e) {
		setCroppingImage(null);
		setIsCropping(false)
	}

	function handleCropUpdate(imageDataURL) {
		setIsCropping(false);
		setLoading(true)
		const file = dataURLtoFile(imageDataURL)
		uploadImage(file)
	}

	function onCroppingImage(image: CloudinaryImage, index) {
		setCroppingImage(image);
		setCroppingImageIndex(index);
		setIsCropping(true);
	}

	const themesOptions = [
		{
			value: 'brown',
			icon: '/assets/icons/theme_product_page_brown.svg'
		},
		{
			value: 'green',
			icon: '/assets/icons/theme_product_page_green.svg'
		},
		{
			value: 'black',
			icon: '/assets/icons/theme_product_page_black.svg'
		},
		{
			value: 'blue',
			icon: '/assets/icons/theme_product_page_blue.svg'
		}
	]

	function handleThemeChange(theme) {
		setTheme(theme);
	}

	return (
		<div className="product-page-form">
			<form onSubmit={handleSubmit(onSubmit, onError)}>
				<div className="format-container">
					<div className="inputs-container">
						<div className="input-container">
							<input type="text" placeholder="header"
								   {...register("header", {required: true, minLength: 0})} onChange={(e) => handleUpdate(e, 'header')}/>
							<Cross className={getValues("header") ? '' : 'hidden'} onClick={() => setValue('header', '')}/>
						</div>
						<div className="error">
							{errors?.header?.type === "required" && <p>This field is required</p>}
							{errors?.header?.type === "minLength" && <p>Too short</p>}
						</div>
						<div className="input-container">
							<textarea className="textarea" placeholder="content"
									  {...register("content", {required: true})} onChange={(e) => handleUpdate(e, 'content')}/>
							<Cross className={getValues("content") ? '' : 'hidden'}
								   onClick={() => setValue('content', '')}/>
						</div>
						<div className="error">
							{errors?.content?.type === "required" && <p>This field is required</p>}
						</div>
					</div>
				</div>

				<div className="format-container">
					<div className="inputs-container">
						<div className="input-container">
							<input type="text" placeholder="Button text"
								   {...register("buttonText", {required: true})} onChange={(e) => handleUpdate(e, 'buttonText')}/>
							<Cross className={getValues("buttonText") ? '' : 'hidden'}
								   onClick={() => setValue('buttonText', '')}/>
						</div>
						<div className="error">
							{errors?.buttonText?.type === "required" && <p>This field is required</p>}
						</div>
						<div className="input-container">
							<input placeholder="Button link"
								   {...register("buttonLink", {required: true})} onChange={(e) => handleUpdate(e, 'buttonLink')}/>
							<Cross className={getValues("buttonLink") ? '' : 'hidden'}
								   onClick={() => setValue('buttonLink', '')}/>
						</div>
						<div className="error">
							{errors?.buttonLink?.type === "required" && <p>This field is required</p>}
						</div>
					</div>
				</div>

				{images.length < 1 &&
					<div className="mt-4 primary-button-wrapper">
						<div className="primary-button button-text">
							<label className="file-upload">
								Upload photos
								{loading &&
									<span>...</span>}
								<input type="file" id="upload" multiple accept=".jpg, .jpeg, .png, .svg"
									   onChange={(e) => uploadImages(e.target.files)}/>
							</label>
						</div>
					</div>
				}

					{(images.length > 0) &&
						<div>
							<div className="d-flex mt-4" id="images">
									<div style={{
											display: 'grid',
											gridTemplateColumns: '1fr 1fr'
									}}>
											{images.map((image, index) => {
												return (
													<div className="image d-inline-block m-3 position-relative" key={index}>
														<img style={{borderRadius: '0.8rem', objectFit: 'cover'}} height="60px" width="90px"
																	src={image.url} alt="image"/>
														<span className="delete-image" onClick={() => handleImageDelete(image.id)}>
															<CancelButton/>
														</span>

														<span className="change-button d-flex justify-content-center" onClick={() => onCroppingImage(image, index)}>
															<div className="primary-button button-text text-center" style={{
																		position: 'relative',
																		width: '81px',
																		height: '26px',
																		top: '-15px',
																		lineHeight: '27px'
															}}>
																Change
															</div>
														</span>
													</div>
												)
											})}
											{/* add next image button  */}
											<div className="inputs-container d-inline-block m-3">
												<div className="add-file-input">
													<label htmlFor="file-input">
														<PlusButton/>
													</label>

													<input id="file-input" multiple type="file"
																	onChange={(e) => uploadImages(e.target.files)}/>
									</div>
								</div>
							</div>
						</div>
						<div className="error">
							{errors?.picture?.type === "required" && <p>This field is required</p>}
						</div>
					</div>
				}

				{/* cropper */}
				{croppingImage && isCropping &&
					<CropperComponent
						cropShape={'rect'}
						icon={croppingImage.url}
						onCancel={handleCropCancel}
						onUpdateIcon={handleCropUpdate}
						hideCheckbox={true}
						aspect={4 / 3}
						removeRadius={true}
					/>
				}

				{/* Loader */}
				{loading &&
					<Loader loading={loading} fixHeight={false}/>
				}

				{/* Theme select */}
				<ThemePicker value={theme} options={themesOptions} onChange={handleThemeChange}/>
			</form>
		</div>
	);
}

const mapState = (state) => ({
	page: getProductPage(state),
})

const mapDispatch = (dispatch) => ({
	saveLocallyProductPage: (page, status) => dispatch(setProductPage(page, status)),
})

export default connect(mapState, mapDispatch)(ProductPageForm)
