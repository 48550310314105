import React from 'react';

function Info() {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="#000" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.1765 0.404297C6.20736 0.404297 0.556641 6.05502 0.556641 13.0241C0.556641 19.9933 6.20736 25.645 13.1765 25.645C20.1456 25.645 25.7974 19.9933 25.7974 13.0241C25.7974 6.05502 20.1456 0.404297 13.1765 0.404297ZM15.8037 19.9633C15.1541 20.2198 14.637 20.4142 14.2491 20.5488C13.8624 20.6834 13.4126 20.7507 12.9008 20.7507C12.1145 20.7507 11.5023 20.5584 11.0664 20.1749C10.6305 19.7913 10.4136 19.3052 10.4136 18.7144C10.4136 18.4847 10.4296 18.2496 10.4617 18.0103C10.4948 17.771 10.5472 17.5018 10.6188 17.1994L11.4318 14.3276C11.5034 14.0519 11.5653 13.7902 11.6145 13.5466C11.6636 13.3008 11.6871 13.0754 11.6871 12.8703C11.6871 12.5049 11.6113 12.2485 11.4606 12.1043C11.3079 11.96 11.0205 11.8895 10.592 11.8895C10.3826 11.8895 10.1668 11.9205 9.94566 11.9857C9.72664 12.053 9.53647 12.1139 9.38049 12.1737L9.59523 11.2891C10.1273 11.0722 10.6369 10.8863 11.123 10.7324C11.6092 10.5765 12.0686 10.4995 12.5013 10.4995C13.2823 10.4995 13.8848 10.6897 14.309 11.0658C14.731 11.4429 14.9436 11.9333 14.9436 12.5359C14.9436 12.6609 14.9286 12.881 14.8998 13.1951C14.8709 13.5103 14.8165 13.7977 14.7374 14.0615L13.9286 16.9248C13.8624 17.1545 13.8036 17.4174 13.7502 17.7112C13.6979 18.005 13.6722 18.2293 13.6722 18.38C13.6722 18.7603 13.7566 19.0199 13.9276 19.1578C14.0964 19.2956 14.3923 19.365 14.8111 19.365C15.0088 19.365 15.2299 19.3298 15.4799 19.2614C15.7278 19.193 15.9073 19.1321 16.0205 19.0798L15.8037 19.9633ZM15.6605 8.34138C15.2833 8.69181 14.8293 8.86703 14.2983 8.86703C13.7684 8.86703 13.3111 8.69181 12.9307 8.34138C12.5525 7.99094 12.3613 7.56466 12.3613 7.06679C12.3613 6.56998 12.5536 6.14263 12.9307 5.78899C13.3111 5.43428 13.7684 5.258 14.2983 5.258C14.8293 5.258 15.2844 5.43428 15.6605 5.78899C16.0376 6.14263 16.2267 6.56998 16.2267 7.06679C16.2267 7.56572 16.0376 7.99094 15.6605 8.34138Z"/>
        </svg>
    );
}

export default Info;
