import React, {useState} from "react";
import Select, {components} from "react-select";
import {ReactComponent as CrossSharp} from "../../common/images/elements/cross_sharp.svg";
import useComponentVisible from "../../../UserAccountsComponents/useComponentVisible";

function SocialSelect({item, onChange, differentIcons = false}) {

	const [selectRef, selectIsFocused, setSelectIsFocused] = useComponentVisible(null);

	const customStyles = {
		container: (provided, state) => ({
			...provided,
			width: '55px',
			padding: '0px',
			margin: '0xp',
			paddingRight: '6px',
		}),
		control: (provided, state) => ({
			...provided,
			backgroundColor: 'white',
			boxShadow: 'none',
			cursor: 'pointer',
			height: '45px',
			border: 'none'
		}),
		menuList: (provided, state) => ({
			...provided,
			height: "160px",
			display: "grid",
			gridTemplateColumns: "repeat(6, 1fr)",
			justifyContent: "center",
			alignItems: "center",
			overflowY: "scroll",
			scrollBehavior: "smooth"
		}),
		menu: (provided, state) => ({
			...provided,
			display: 'block',
			position: 'relative',
			top: '10px',
			width: '330px',
			zIndex: '10',
			boxShadow: 'none'
		}),
		option: (provided, {data, isDisabled, isFocused, isSelected}) => ({
			...provided,
			display: 'flex',
			justifyContent: 'center',
			alignItems: "center",
			backgroundColor: isSelected ? '#ebf3ff' : '',
			borderRadius: isSelected ? "50%" : '',
			width: '40px',
			height: '40px',
			cursor: 'pointer',
			padding: '7px',
			"&:hover": {
				backgroundColor: '#ebf3ff',
				borderRadius: "50%",
			}
		}),
		valueContainer: (provided, state) => ({
			...provided,
			paddingLeft: '0px'
		}),

	}

	const options = [
		{
			value: 'facebook',
			label: <img src="/assets/images/multidata-qr-code/facebook.svg" alt="facebook" />
		},
		{
			value: 'excel',
			label: <img src="/assets/images/multidata-qr-code/excel.svg" alt="excel" />
		},
		{
			value: 'file',
			label: <img src="/assets/images/multidata-qr-code/file.svg" alt="file" />
		},
		{
			value: 'google',
			label: <img src="/assets/images/multidata-qr-code/google.svg" alt="google" />
		},
			{
			value: 'googleplus',
			label: <img src="/assets/images/multidata-qr-code/googleplus.svg" alt="googleplus" />
		},
		{
			value: 'heart',
			label: <img src="/assets/images/multidata-qr-code/heart.svg" alt="heart" />
		},
		{
			value: 'chrome',
			label: <img src="/assets/images/multidata-qr-code/chrome.svg" alt="chrome" />
		},
		{
			value: 'Instagram',
			label: <img src="/assets/images/multidata-qr-code/Instagram.svg" alt="Instagram" />
		},
		{
			value: 'link',
			label: <img src="/assets/images/multidata-qr-code/link.svg" alt="link" />
		},
		{
			value: 'linkedin',
			label: <img src="/assets/images/multidata-qr-code/linkedin.svg" alt="linkedin" />
		},
		{
			value: 'manual',
			label: <img src="/assets/images/multidata-qr-code/manual.svg" alt="manual" />
		},
		{
			value: 'manual2',
			label: <img src="/assets/images/multidata-qr-code/manual2.svg" alt="manual2" />
		},
		{
			value: 'map',
			label: <img src="/assets/images/multidata-qr-code/map.svg" alt="map" />
		},
		{
			value: 'mapglobe',
			label: <img src="/assets/images/multidata-qr-code/mapglobe.svg" alt="mapglobe" />
		},
		{
			value: 'music',
			label: <img src="/assets/images/multidata-qr-code/music.svg" alt="music" />
		},
		{
			value: 'pdf',
			label: <img src="/assets/images/multidata-qr-code/pdf.svg" alt="pdf" />
		},
		{
			value: 'pdf2',
			label: <img src="/assets/images/multidata-qr-code/pdf2.svg" alt="pdf2" />
		},
		{
			value: 'phone',
			label: <img src="/assets/images/multidata-qr-code/phone.svg" alt="phone" />
		},
		{
			value: 'powerpoint',
			label: <img src="/assets/images/multidata-qr-code/powerpoint.svg" alt="powerpoint" />
		},
		{
			value: 'share',
			label: <img src="/assets/images/multidata-qr-code/share.svg" alt="share" />
		},
		{
			value: 'shoppingcart',
			label: <img src="/assets/images/multidata-qr-code/shoppingcart.svg" alt="shoppingcart" />
		},
		{
			value: 'spotify',
			label: <img src="/assets/images/multidata-qr-code/spotify.svg" alt="spotify" />
		},
		{
			value: 'star',
			label: <img src="/assets/images/multidata-qr-code/star.svg" alt="star" />
		},
		{
			value: 'Telegram',
			label: <img src="/assets/images/multidata-qr-code/Telegram.svg" alt="Telegram" />
		},
		{
			value: 'tiktok',
			label: <img src="/assets/images/multidata-qr-code/tiktok.svg" alt="tiktok" />
		},
		{
			value: 'twitter',
			label: <img src="/assets/images/multidata-qr-code/twitter.svg" alt="twitter" />
		},
		{
			value: 'word',
			label: <img src="/assets/images/multidata-qr-code/word.svg" alt="word" />
		},
		{
			value: 'www',
			label: <img src="/assets/images/multidata-qr-code/www.svg" alt="www" />
		},
		{
			value: 'www2',
			label: <img src="/assets/images/multidata-qr-code/www2.svg" alt="www2" />
		},
		{
			value: 'youtube',
			label: <img src="/assets/images/multidata-qr-code/youtube.svg" alt="youtube" />
		},
		{
			value: 'xicon',
			label: <img src='/assets/images/multidata-qr-code/xicon.svg' alt="xicon" />
		},
		{
			value: 'wechat',
			label: <img src='/assets/images/multidata-qr-code/wechat.svg' alt="wechat" />
		},
		{
			value: 'twitch',
			label: <img src='/assets/images/multidata-qr-code/twitch.svg' alt="twitch" />
		},
		{
			value: 'twitch2',
			label: <img src='/assets/images/multidata-qr-code/twitch2.svg' alt="twitch2" />
		},
		{
			value: 'tiktok',
			label: <img src='/assets/images/multidata-qr-code/tiktok.svg' alt="tiktok" />
		},
		{
			value: 'snapchat',
			label: <img src='/assets/images/multidata-qr-code/snapchat.svg' alt="snapchat" />
		},
		{
			value: 'reddit',
			label: <img src='/assets/images/multidata-qr-code/reddit.svg' alt="reddit" />
		},
		{
			value: 'github',
			label: <img src='/assets/images/multidata-qr-code/github.svg' alt="github" />
		},
		{
			value: 'dribbble',
			label: <img src='/assets/images/multidata-qr-code/dribbble.svg' alt="dribbble" />
		},
		{
			value: 'discord',
			label: <img src='/assets/images/multidata-qr-code/discord.svg' alt="discord" />
		},
		{
			value: 'airbnb',
			label: <img src='/assets/images/multidata-qr-code/airbnb.svg' alt="airbnb" />
		},
	]

	const defaultOptions = [
		{
			value: 'facebook',
			label: <img src='/assets/icons/facebook.svg' alt="facebook" />
		},
		{
			value: 'youtube',
			label: <img src='/assets/icons/youtube.svg' alt="youtube" />
		},
		{
			value: 'instagram',
			label: <img src='/assets/icons/instagram.svg' alt="instagram" />
		},
		{
			value: 'twitter',
			label: <img src='/assets/icons/twitter.svg' alt="twitter" />
		},
		{
			value: 'linkedin',
			label: <img src='/assets/icons/linkedin.svg' alt="linkedin" />
		},
		{
			value: 'chrome',
			label: <img src='/assets/icons/chrome.svg' alt="chrome" />
		},
		{
			value: 'spotify',
			label: <img src='/assets/icons/spotify.svg' alt="spotify" />
		},
		{
			value: 'star',
			label: <img src='/assets/icons/star.svg' alt="star" />
		},
		{
			value: 'favorite',
			label: <img src='/assets/icons/favorite.svg' alt="favorite" />
		},
		{
			value: 'xicon',
			label: <img src='/assets/icons/xicon.svg' alt="xicon" />
		},
		{
			value: 'wechat',
			label: <img src='/assets/icons/wechat.svg' alt="wechat" />
		},
		{
			value: 'twitch',
			label: <img src='/assets/icons/twitch.svg' alt="twitch" />
		},
		{
			value: 'twitch2',
			label: <img src='/assets/icons/twitch2.svg' alt="twitch2" />
		},
		{
			value: 'tiktok',
			label: <img src='/assets/icons/tiktok.svg' alt="tiktok" />
		},
		{
			value: 'tiktok2',
			label: <img src='/assets/icons/tiktok2.svg' alt="tiktok2" />
		},
		{
			value: 'telegram',
			label: <img src='/assets/icons/telegram.svg' alt="telegram" />
		},
		{
			value: 'snapchat',
			label: <img src='/assets/icons/snapchat.svg' alt="snapchat" />
		},
		{
			value: 'reddit',
			label: <img src='/assets/icons/reddit.svg' alt="reddit" />
		},
		{
			value: 'github',
			label: <img src='/assets/icons/github.svg' alt="github" />
		},
		{
			value: 'dribbble',
			label: <img src='/assets/icons/dribbble.svg' alt="dribbble" />
		},
		{
			value: 'discord',
			label: <img src='/assets/icons/discord.svg' alt="discord" />
		},
		{
			value: 'airbnb',
			label: <img src='/assets/icons/airbnb.svg' alt="airbnb" />
		},
]

	const initialOption = differentIcons ? 
												options.filter((option) => option.value === item.type)[0] ?? options[0] :
												defaultOptions.filter((option) => option.value === item.type)[0] ?? options[0];

	const [isOpen, setIsOpen] = useState(false);

	const SelectContainer = (props) => {
		return (
			<div onClick={() => setIsOpen(!isOpen)}>
				<components.SelectContainer {...props} />
			</div>
		)
	}

	const MenuList = (props) => {
		return (
			<components.MenuList {...props}>
				<CrossSharp className="mr-4 ml-4 p-1" style={{
					cursor: 'pointer'
				}} onClick={() => setIsOpen(false)}>X</CrossSharp>
				{props.children}
			</components.MenuList>
		)
	}
	const DropdownIndicator = (props) => {
		const isOpen = props.selectProps.menuIsOpen;

		return (
			<img className="mr-1" src="/assets/icons/arrow_down.svg"
				 width="8"
				 alt=""
				 style={{
					 transform: isOpen ? 'rotate(180deg)' : ''
				 }}
			/>
		)
	}

	return (
		<div
			ref={selectRef}
			onClick={() => setSelectIsFocused(true)}
		>
			<Select
				styles={customStyles}
				onChange={onChange}
				menuIsOpen={isOpen && selectIsFocused}
				isSearchable={false} 
				defaultValue={initialOption} 
				options={differentIcons ? options : defaultOptions}
				components={{
					SelectContainer: SelectContainer,
					MenuList: MenuList,
					IndicatorSeparator: () => null,
					DropdownIndicator: DropdownIndicator,
				}}>
			</Select>
		</div>
	)
}

export default SocialSelect;
