import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {connect} from "react-redux";
import {ReactComponent as Cross} from "../../../common/images/elements/cross.svg";
import Item from "./Item";
import './DigitalCardForm.scss'
import Errors from '../Errors'
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import AddImage from "../AddImage";
import Loader from "../../../common/Loader";
import { nanoid } from 'nanoid'
import ThemePicker from "../ThemePicker";
import LiveMicrowebPreview from "../LiveMicrowebPreview";
import Modal from "react-modal";
import {setDigitalCard} from "../../../../../../store/microPage/microPageActions";
import {getDigitalCard} from "../../../../../../store/microPage/microPageSelector";

function DigitalCardForm(props) {

    // Mounted: populate from redux into validation (useForm)
    useEffect(() => {
        setValue('fullName', props?.digitalCard?.fullName)
        setValue('title', props?.digitalCard?.title)
        setValue('about', props?.digitalCard?.about)
        setValue('phone', props?.digitalCard?.phone)
        setValue('email', props?.digitalCard?.email)
        setValue('organization', props?.digitalCard?.organization)
        setValue('address', props?.digitalCard?.address)
        setValue('website', props?.digitalCard?.website)
        props.setDynamicQrType({ "DigitalCard": props?.digitalCard })
    }, [])

    const [image, setImage] = useState(props?.digitalCard?.image)
    const [fullImage, setFullImage] = useState(props?.digitalCard?.fullImage)
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState(props?.digitalCard?.items ?? [])
    const [theme, setTheme] = useState(props?.digitalCard?.theme ?? 'red');

    function handleSaveDigitalCardData(data) {
		props.saveLocallyDigitalCard(data, 'update');
		props.setDynamicQrType({ "DigitalCard": data })
	}

	 
	function handleUpdate(e, type) {
        const value = e.target.value

        handleSaveDigitalCardData({ ...props.digitalCard, [type]: value })
	}

    useEffect(() => {
        handleSaveDigitalCardData({ ...props.digitalCard, items, theme, image, fullImage })
    }, [items, theme, image, fullImage]);

    const validation = useForm({
        mode: "onTouched" // "onChange"
    })
    const {register, watch, setValue, formState, handleSubmit} = validation;

    async function onSubmit(data) {
    }

    async function onError(data) {
        console.log(data, 'onError');
    }

    async function addEmptyItem() {
        await setItems([...items, {
            uid: nanoid(),
            type: 'facebook',
            url: '',
        }])
    }

    function updateItem(updatedItem) {
        const newItems = [...items];

        const index = newItems.findIndex((item) => item.uid === updatedItem.uid)

        newItems[index] = updatedItem
        setItems(newItems)
    }

    function deleteItem(id) {
        setItems([...items.filter((item) => item.uid !== id)])
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const newItems = Array.from(items);
        const [reorderedItem] = newItems.splice(result.source.index, 1)
        newItems.splice(result.destination.index, 0, reorderedItem)

        setItems(newItems);
    }

    function onImageChange(image, fullImage) {
        setImage(image);
        setFullImage(fullImage)
    }

    function onLoadingChange(value) {
        setLoading(value)
    }

    const themesOptions = [
        {
            value: 'red',
            icon: '/assets/icons/theme_digital_card_red.svg'
        },
        {
            value: 'green',
            icon: '/assets/icons/theme_digital_card_green.svg'
        },
        {
            value: 'black',
            icon: '/assets/icons/theme_product_page_black.svg'
        },
        {
            value: 'blue',
            icon: '/assets/icons/theme_digital_card_blue.svg'
        }
    ]

    function handleThemeChange(theme) {
        setTheme(theme);
    }

    Modal.setAppElement('#root');

    return (
        <div className="digital-card-form">
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div className="format-container">
                    <div className="inputs-container">
                        {/* fullName */}
                        <div className="input-container">
                            <input type="text" placeholder="Fullname"
                                   {...register("fullName", {required: true})} onChange={(e) => handleUpdate(e, 'fullName')}/>
                            <Cross className={watch("fullName") ? '' : 'hidden'}
                                   onClick={() => setValue('fullName', '')}/>
                        </div>
                        <Errors formState={formState} name="fullName"/>

                        {/* title */}
                        <div className="input-container">
                            <input type="text" placeholder="Title or job position"
                                   {...register("title", {})} onChange={(e) => handleUpdate(e, 'title')}/>
                            <Cross className={watch("title") ? '' : 'hidden'}
                                   onClick={() => setValue('title', '')}/>
                        </div>
                        <Errors formState={formState} name="title"/>

                        {/* about */}
                        <div className="input-container">
                            <input type="text" placeholder="About me or introduction text"
                                   {...register("about", {})} onChange={(e) => handleUpdate(e, 'about')}/>
                            <Cross className={watch("about") ? '' : 'hidden'}
                                   onClick={() => setValue('about', '')}/>
                        </div>
                        <Errors formState={formState} name="about"/>

                        {/* phone */}
                        <div className="input-container">
                            <input type="text" placeholder="Phone"
                                   {...register("phone", {
                                       pattern: {
                                           value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                                           message: 'Please enter a valid phone',
                                       }
                                   })
                                   }
                                   onChange={(e) => handleUpdate(e, 'phone')}
                            />
                            <Cross className={watch("phone") ? '' : 'hidden'}
                                   onClick={() => setValue('phone', '')}/>
                        </div>
                        <Errors formState={formState} name="phone"/>

                        {/* email */}
                        <div className="input-container">
                            <input type="text" placeholder="Email"
                                   {...register("email", {
                                           pattern: {
                                               value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                               message: 'Please enter a valid email',
                                           }
                                       }
                                   )
                                   }
                                   onChange={(e) => handleUpdate(e, 'email')}
                            />
                            <Cross className={watch("email") ? '' : 'hidden'} onClick={() => setValue('email', '')}/>
                        </div>
                        <Errors formState={formState} name="email"/>

                        {/* organization */}
                        <div className="input-container">
                            <input type="text" placeholder="Organization"
                                   {...register("organization", {})} onChange={(e) => handleUpdate(e, 'organization')}/>
                            <Cross className={watch("organization") ? '' : 'hidden'}
                                   onClick={() => setValue('organization', '')}/>
                        </div>
                        <Errors formState={formState} name="organization"/>

                        {/* address */}
                        <div className="input-container">
                            <textarea className="textarea" placeholder="Address"
                                      {...register("address", {})} onChange={(e) => handleUpdate(e, 'address')}/>
                            <Cross className={watch("address") ? '' : 'hidden'}
                                   onClick={() => setValue('address', '')}/>
                        </div>
                        <Errors formState={formState} name="address"/>

                        {/* website */}
                        <div className="input-container">
                            <input type="text" placeholder="Website"
                                   {...register("website", {})} onChange={(e) => handleUpdate(e, 'website')}/>
                            <Cross className={watch("website") ? '' : 'hidden'}
                                   onClick={() => setValue('website', '')}/>
                        </div>
                        <Errors formState={formState} name="website"/>


                    </div>

                </div>
                <div className="buttons-block">
                    {/* Upload Image */}
                    <AddImage
                        initialImage={fullImage}
                        initialCroppedImage={image}
                        shape={'round'}
                        onImageChange={onImageChange}
                        onLoadingChange={onLoadingChange}
                        hideCheckbox={true}
                    />

                    {/* TODO: make useable component */}
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <div className="mt-2">
                            <Droppable droppableId="characters">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {items.map((item, key) => {
                                            return (
                                                    <Draggable  draggableId={item.uid} index={key} key={item.uid} style={{position: 'relative'}}>
                                                        {(provided) => (
                                                            <div className="p-2 mt-0" {...provided.draggableProps}  ref={provided.innerRef}>
                                                                <div className="position-relative">
                                                                    <img {...provided.dragHandleProps} src="/assets/icons/drag.svg" alt="" width="22px"
                                                                        style={
                                                                            {
                                                                                position: 'absolute',
                                                                                right: '-10px',
                                                                                zIndex: '1',
                                                                            }
                                                                        }
                                                                    />
                                                                </div>

                                                                <Item
                                                                    key={item.uid}
                                                                    item={item}
                                                                    itemField="url"
                                                                    onUpdate={updateItem}
                                                                    onDelete={deleteItem}
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                            )
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </DragDropContext>
                </div>
                <div className="button-section">

                    {/* Add social button */}
                    <button type="button" className="button mt-3" onClick={() => addEmptyItem()}>
                        {items.length > 0 ? "Add another social network" : "Add social network"}
                    </button>

                    {/* Loader */}
                    {loading &&
                        <Loader/>
                    }

                    {/* Theme select */}
                    <ThemePicker value={theme} options={themesOptions} onChange={handleThemeChange}/>
                </div>
            </form>
        </div>
    )
}

const mapState = (state) => ({
    digitalCard: getDigitalCard(state),
})

const mapDispatch = (dispatch) => ({
    saveLocallyDigitalCard: (data, status) => dispatch(setDigitalCard(data, status)),
})

export default connect(mapState, mapDispatch)(DigitalCardForm)
