import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { getFetchStatus, getUserSettings } from '../../../store/auth/authSelectors'
import { deleteUser, login, updatePassword, changeEmail } from '../../../store/auth/authActions'
import Modal from 'react-modal'
import { APP_DOMAIN } from '../../../utils/constants'

import './ConfirmationModal.scss'

const Tick = () => (
  <div style={{ 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center",
    width: "100%",
    height: "100%",
    }}
  >
    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.8 2.03636L10.7636 0L4.94545 5.81818L2.03636 2.90909L0 4.94545L4.94545 9.89091L12.8 2.03636Z" fill="#90C42C" />
    </svg>
  </div>
)

const ConfirmationModal = ({
  type,
  step,
  isModalOpen,
  setIsModalOpen,
  userSettings,
  loginStatus,
  updatePasswordStatus,
  deleteUserStatus,
  changeEmailStatus,
  onLogin,
  onUpdatePassword,
  onDeleteUser,
  onChangeEmail,
}) => {
  const [error, setError] = useState("")
  const [modalContent, setModalContent] = useState([
    {
      title:
        type === "email" ? "Verify password" :
        type === "password" ? "Change password" : "Delete your account?"
      ,
      description:
        type === "email" ? "Enter your current password" :
        type === "password" ? "Enter your existing password first" : "Oh, we are sorry to see you go. Are you sure you want to delete your account?",
      initialValues:
        type === "email" ? { password: '' } :
        type === "password" ? { password: '' } : null,
      inputType:
        type === "email" ? [{
          type: "password",
          name: "password",
          placeholder: "Current password",
        }] :
        type === "password" ? [{
          type: "password",
          name: "password",
          placeholder: "New Password",
        }] : null,
      buttons:
        type === "email" ? [{
          color: "green",
          text: "Continue",
        }] :
        type === "password" ? [{
          color: "green",
          text: "Continue", 
        }] : [{
          color: "green",
          type: "button",
          text: "No, do NOT delete",
        }, {
          color: "orange",
          text: "Yes, delete now",
        }],
    },
    {
      title: 
        type === "email" ? "Change your email/login" :
        type === "password" ? "Enter new password\n(fill in two times)" : "Enter your password",
      description:
        type === "email" ? "Enter your new email address. We'll send you a confirmation link there." :
        type === "password" ? null : "WARNING: THIS WILL DELETE ALL DATA RELATED TO YOUR ACCOUNT AND TAKE YOUR QR CODES OUT OF SERVICE.",
      checkMark:
        type === "email" ? false :
        type === "password" ? false : false,
      initialValues:
        type === "email" ? { email: '' } :
        type === "password" ? { password: '', password2: '' } : { password: '' },
      inputType: 
        type === "email" ? [{
          type: "email",
          name: "email",
          placeholder: "Your email",
        }] :
        type === "password" ? [
          {
            type: "password",
            name: "password",
            placeholder: "New Password",
          },
          {
            type: "password",
            name: "password2",
            placeholder: "New Password confirmation",
          }] : [{
            type: "password",
            name: "password",
            placeholder: "Current password",
          }],
      buttons:
        type === "email" ? [{
          color: "green",
          text: "Continue",
        }] :
        type === "password" ? [{
          color: "green",
          text: "Set new password", 
        }] : [{
          color: "red",
          text: "Delete my account",
        }],
    },
    {
      title: 
        type === "email" ? "Check your mailbox" :
        type === "password" ? "Password has been changed" : "Your account has been deleted",
      description:
        type === "email" ? "Please check your email and open the link we have sent you to continue. " :
        type === "password" ? null : "We no longer store any of your data.",
      checkMark:
        type === "email" ? true :
        type === "password" ? true : true,
      buttons:
        type === "email" ? [{
          color: "green",
          text: "Close",
        }] :
        type === "password" ? [{
          color: "green",
          text: "Close", 
        }] : [{
          color: "green",
          text: "Close",
        }],
    },
    {
      title: 
        type === "email" ? "Email has been changed" :
        type === "password" ? null : null,
      description:
        type === "email" ? "You can now log in with your new email." :
        type === "password" ? null : null,
      checkMark:
        type === "email" ? true :
        type === "password" ? false : false,
      buttons:
        type === "email" ? [{
          color: "green",
          text: "Close",
        }] :
        type === "password" ? null : null,
    }
  ])
  const [modalStep, setModalStep] = useState(step || 0)

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    setError('')
    const { email } = userSettings
    // Change password actions
    if (type === 'password') {
      if (modalStep === 0) {
        await onLogin(email, values.password)
        if (loginStatus === 'SUCCESS') {
          resetForm() 
          setModalStep(1)
        }
      } else if (modalStep === 1) {
        if (values.password === values.password2) {
          await onUpdatePassword(userSettings, values.password)
          if (updatePasswordStatus === 'SUCCESS') {
            resetForm()
            setModalStep(2)
          }
        }
      } else if (modalStep === 2) {
        setModalStep(0) 
        setIsModalOpen(false)
      }
    } else if (type === 'delete') {
      if (modalStep === 0) {
        setModalStep(1)
      } else if (modalStep === 1) {
        await onDeleteUser(userSettings, values.password)
        setSubmitting(true)
        if (deleteUserStatus === 'SUCCESS') {
          resetForm()
          setModalStep(2)
          setIsModalOpen(false)
          window.location.href = `${APP_DOMAIN}/login?accountDeleted=true`
        }
      } else if (modalStep === 2) {
        setModalStep(0)
        setIsModalOpen(false)
        window.history.pushState({}, document.title, "/login");
      }
    } else if (type === 'email') {
      if (modalStep === 0) {
        await onLogin(email, values.password)
        if (loginStatus === 'SUCCESS') {
          resetForm() 
          setModalStep(1)
        }
      } else if (modalStep === 1) {
        await onChangeEmail(userSettings, values.email)
        if (changeEmailStatus === 'SUCCESS') {
          resetForm()
          setModalStep(2)
        }
      } else if (modalStep === 2) {
        setModalStep(0)
        setIsModalOpen(false)
      } else if (modalStep === 3) {
        setModalStep(0)
        setIsModalOpen(false)
        window.history.pushState({}, document.title, "/login");
      }
    }
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '2rem',
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      borderRadius: '2rem',
      width: '50rem',
      height: '48rem',
      overflow: "hidden",
      border: 'solid 1px',
      background: 'white',
    },
  }

  const currentModalContent = modalContent[modalStep]
  const { title, description, inputType, checkMark, buttons, initialValues } = currentModalContent

  return (
    <Modal
      isOpen={isModalOpen}
      style={customStyles}
      className='confirmation-modal'
    >
      <div className="logo-wrapper">
        <img src="/lib/images/logo.svg" alt="" />
        <div className='close-wrapper' onClick={() => setIsModalOpen(false)}>
          <img src="/assets/images/close.png" alt="close" />
        </div>
      </div>
      <div className='informative-section'>
        {checkMark && <div className='checkmark'><Tick /></div>}
        {title && <p className='informative-text'>{title}</p>}
        {description && <p className='informative-subtext'>{description}</p>}
      </div>
      <Formik
        initialValues={initialValues ? initialValues : {}}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form className='form' style={{
            width: buttons.length > 1 ? "100%" : "80%", 
          }}>
            {inputType && inputType.map((input) => {
              return (
                <div className={`input-wrapper ${input.type}`}>
                  <img className='blue-image' src={`/assets/icons/${input.type === "email" ? "email-letter" : "key"}.svg`} alt="" />
                  <Field className={`${input.type}-input`} type={input.type} name={input.name} placeholder={input.placeholder} />
                  <ErrorMessage className="error-msg" name={input.type} component="div" />
                </div>
              )
            })}
            <div className='buttons-section'>
              {buttons && buttons.map((button, index) => {
                return (
                  <button 
                    key={index}
                    className={`${button.color}-button`} 
                    type={button.type || "submit"}
                    disabled={isSubmitting}
                    onClick={button.type ? () => setIsModalOpen(false) : null}
                  >
                    {button.text}
                  </button>
                )
              })}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

const mapState = (state) => ({
  userSettings: getUserSettings(state),
  loginStatus: getFetchStatus(state),
  updatePasswordStatus: getFetchStatus(state),
  deleteUserStatus: getFetchStatus(state),
  changeEmailStatus: getFetchStatus(state),
})

const mapDispatch = (dispatch) => ({
  onLogin: (email, password) => dispatch(login(email, password)),
  onUpdatePassword: (userData, password) => dispatch(updatePassword(userData, password)),
  onDeleteUser: (userData, password) => dispatch(deleteUser(userData, password)),
  onChangeEmail: (userData, email) => dispatch(changeEmail(userData, email)),
})

export default connect(mapState, mapDispatch)(ConfirmationModal)