import { BACKEND_URL } from '../utils/constants'
import { store } from "../store/initializator";
import { toast } from 'react-toastify';

const getToken = () => {
  // @ts-ignore
  return store.getState().auth.userData.jwt;
}

const getAuthHeader = (token) => {
  return token ? { Authorization: `Bearer ${token}` } : {}
}

export const create = async (token, config) => {
  const url = `${BACKEND_URL}/dynamic-qr-codes/create`
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      config,
    }),
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader(token),
    },
  })
  if (response.ok) {
    return response.json()
  } else {
    throw response.json()
  }
}
export const createScan = async (id, token = "") => {
  const url = `${BACKEND_URL}/scans`
  let ip

  await fetch(`https://api.ipify.org?format=json`).then((res) => { return res.json() }).then((payload) => { console.log(payload); ip = payload.ip })

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      id,
      ip,
    }),
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader(token),
    },
  })
  if (response.ok) {
    return response.json()
  } else {
    throw response.json()
  }
}
export const remove = async (token, id) => {
  const url = `${BACKEND_URL}/dynamic-qr-codes/delete`
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      id,
    }),
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader(token),
    },
  })
  if (response.ok) {
    return response.json()
  } else {
    throw response.json()
  }
}

export const get = async (id) => {
  const url = `${BACKEND_URL}/dynamic-qr-codes/get`
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      id,
    }),
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader(getToken()),
    },
  })
  if (response.ok) {
    return response.json()
  } else {
    throw response.json()
  }
}

export const getDynamicQrCodeById = async (id) => {
  const url = `${BACKEND_URL}/dynamic-qr-codes/${id}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader(getToken()),
    },
  })
  if (response.ok) {
    return response.json()
  } else {
    throw response.json()
  }
}

export const getStats = async (token, date1 = null, date2 = null, country = null, limit) => {
  const url = `${BACKEND_URL}/scans/get?limit=${limit ? limit : -1}&date1=${date1}&date2=${date2}&country=${country}`
  // const url = `http://localhost:1337/scans/get?date1=${date1}&date2=${date2}&country=${country}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader(token),
    },
  })
  if (response.ok) {
    return response.json()
  } else {
    throw response.json()
  }
}
export const update = async (id, content, thumbnailUrl) => {
  const url = `${BACKEND_URL}/dynamic-qr-codes/update`
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      id: id,
      url: content,
      thumbnailUrl,
    }),
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader(getToken()),
    },
  })
  if (response.ok) {
    return response.json()
  } else {
    throw response.json()
  }
}

// TODO: type microPage
export const attachMicroPageToDynamicQrCode = async (dynamicQrCodeId, microPage, microPageType) => {

  const url = `${BACKEND_URL}/dynamic-qr-codes/${dynamicQrCodeId}/attach-micro-page`

  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify({
      microPageType: microPageType,
      microPageId: microPage.id
    }),
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader(getToken()),
    },
  })
  if (response.ok) {
    return response.json()
  } else {
    toast.error('Attaching MicroPage failed')
    throw response.json()
  }
}
