import React from "react";
import BarLoader from "react-spinners/BarLoader";

function Loader({loading, fixHeight, color ='#38517c'}) {

    const override = {
        width: '100%',
        display: 'inline-block'
    };

    return (

      // TODO: we have 2 version of loading and switching between them by "fixHeight" -> refactor
      <div>
          {fixHeight
            ?
                <div className="mt-3 px-4" id="loader" style={{
                    visibility: loading ? 'initial' : 'hidden'
                }}>
                    <BarLoader color={color} loading={true} css={override}/>
                </div>

            :
                <div className="mt-3 px-4" id="loader">
                    <BarLoader color={color} loading={true} css={override}/>
                </div>
          }
      </div>

    )
}

export default Loader;
