import React from "react";

function RatingStar({item, number, onChangeHandler, focusHandler, focusedNumber}) {

    function isActive(item) {
        return item.answer >= number;
    }

    function isFocused() {
        return focusedNumber && number <= focusedNumber;
    }

    return (
        <div className={`star ${isFocused() || (!focusedNumber && isActive(item)) ? "active" : ""}`} onClick={(e) => {
            onChangeHandler(item, number)
        }} onMouseOver={(e) => {focusHandler(number)}} onMouseLeave={() => focusHandler(null)}>
            <div className="rating" htmlFor={number} style={{cursor: 'pointer'}}>
                <div className="rating__number">
                    { number }
                </div>
                <div className="rating__star">
                    <svg width="40" height="40" viewBox="0 0 54 51"
                         className={isFocused() || (!focusedNumber && isActive(item)) ? 'star-fill' : 'star-empty'}
                         xmlns="http://www.w3.org/2000/svg">
                        <path className="star-stroke"
                            d="M52.0906 19.3218L52.0926 19.3276C52.2183 19.7049 52.1336 20.1407 51.7955 20.494L40.3687 31.6336L40.0377 31.9562L40.1161 32.4117L42.8219 48.1393C42.8861 48.554 42.7189 48.9602 42.3921 49.2035C42.1667 49.3503 41.9332 49.4187 41.7264 49.4187C41.5784 49.4187 41.3971 49.3757 41.2239 49.284L41.2217 49.2828L27.0951 41.8558L26.6862 41.6408L26.2774 41.8558L12.1512 49.2826C12.1511 49.2826 12.151 49.2827 12.1509 49.2828C11.7759 49.4795 11.3378 49.4449 10.9932 49.196L10.9932 49.1959L10.9839 49.1894C10.6371 48.9456 10.4743 48.5426 10.5481 48.1368L10.5481 48.1368L10.5495 48.1286L13.2564 32.3948L13.3348 31.9393L13.0038 31.6166L1.56722 20.4677L1.56139 20.462L1.55547 20.4564C1.25419 20.1734 1.14658 19.7541 1.28182 19.3387L1.2847 19.3298L1.2874 19.3209C1.40647 18.9271 1.74683 18.6395 2.15534 18.5838L2.15535 18.5839L2.16323 18.5827L17.9647 16.2819L18.4214 16.2154L18.6258 15.8016L25.6958 1.49263C25.696 1.49207 25.6963 1.49152 25.6966 1.49097C25.8875 1.11054 26.261 0.878668 26.6862 0.878668C27.1115 0.878668 27.485 1.1106 27.676 1.49112C27.6762 1.49162 27.6765 1.49212 27.6767 1.49263L34.7466 15.8016L34.9511 16.2154L35.4078 16.2819L51.2092 18.5827L51.2092 18.5828L51.2171 18.5838C51.6249 18.6394 51.9573 18.9122 52.0906 19.3218Z"
                            strokeWidth="1.75734"/>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default RatingStar;
