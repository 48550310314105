// @flow
import { combineReducers } from 'redux'

import cardReducer from './card/cardReducer'
import intlReducer from './intl/intlReducer'
import authReducer from './auth/authReducer'
import orderReducer from './order/orderReducer'
import microPageReducer from "./microPage/microPageReducer"
import myLibraryReducer from "./myLibrary/myLibraryReducer"

const rootReducer = combineReducers({
  card: cardReducer,
  intl: intlReducer,
  auth: authReducer,
  order: orderReducer,
  microPage: microPageReducer,
  myLibrary: myLibraryReducer,
})

export default rootReducer
