import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getUserSettings } from '../../../store/auth/authSelectors'
import { changeUserSettings } from '../../../store/auth/authActions'
import { getCurrentLocale } from '../../../store/intl/intlSelectors'
import { setCurrentLocale } from '../../../store/intl/intlActions'
import { LOCALES_WITH_NAME, CURRENCIES } from '../../../utils/constants'
import { SHIPPING_COUNTRIES, SHIPPING_COUNTRY_CITIES } from '../../../utils/countries'
import ConfirmationModal from './ConfirmationModal'
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap'
import { FormattedMessage } from 'react-intl'

function GeneralSettings(props) {
	const [userSettings, setUserSettings] = useState(props.userSettings)
	const [isModalShown, setIsModalShown] = useState(false)
	const [modalType, setModalType] = useState("")
	const [langDropdownOpen, setLangDropdownOpen] = useState(false)
	const [currencyDropdownOpen, setCurrencyDropdownOpen] = useState(false)
	const [currencyDropdownSavingAnimation, setCurrencyDropdownSavingAnimation] = useState("")
	const [languageDropdownSavingAnimation, setLanguageDropdownSavingAnimation] = useState("")
	const [countries, setCountries] = useState([])
	const [regions, setRegions] = useState([])

	useEffect(() => {
		Object.values(SHIPPING_COUNTRIES)
					.map(countriesArr_ => countriesArr_.split('|')
					.map(country => setCountries(allCountries => [...allCountries, country].sort())))
		setRegion()
	}, [])

	// useEffect(() => {
	// 	setCurrencyDropdownSavingAnimation("saving")
	// 	setTimeout(() => {
	// 		setCurrencyDropdownSavingAnimation("")
	// 	}, 3000)
	// }, [userSettings.currency])

	// useEffect(() => {
	// 	setLanguageDropdownSavingAnimation("saving")
	// 	setTimeout(() => {
	// 		setLanguageDropdownSavingAnimation("")
	// 	}, 3000)
	// }, [userSettings.language])

	useEffect(() => {
		if (document.getElementById("shipping-region")) {
			const object = {
				target: {
					value: regions[0] || ""
				}
			}
			handleChangeUserSettings(object, "shippingRegion")
			document.getElementById("shipping-region").classList.add("saving")
			setTimeout(() => {
				document.getElementById("shipping-region").classList.remove("saving")
			}, 3000)
		}
	}, [userSettings.shippingCountry])

	const setRegion = () => {
		const country_ = userSettings.shippingCountry || countries[0] || "Afghanistan"
		Object.entries(SHIPPING_COUNTRY_CITIES).forEach(([country, citiesArr]) => {
			if (country_ === country) {
				const regionsArr = citiesArr.split('||')[1]
				setRegions(regionsArr.split('|').map(region => region !== '' && region))
			}
		})
	}

	const handleSaveUserSettings = (e, applyParent = false, updatedUserSettings, type) => {
		let inputElement

    if (type === 'language' || type === 'currency') {
      inputElement = document.getElementById(type)
    } else {
      inputElement = applyParent ? e.currentTarget.parentNode : e.currentTarget;
    }
		// Class is used for saving animation
		inputElement.classList.add("saving");

		if (updatedUserSettings) {
			props.onChangeUserSettings(updatedUserSettings)
		} else props.onChangeUserSettings(userSettings)

		setTimeout(() => {
			inputElement.classList.remove("saving");
		}, 3000);
	}

	const handleChangeUserSettings = (e, type, name) => {
		const value = e.target.value
		const updatedUserSettings = { ...userSettings }

		if (type === 'shippingAddress' && !updatedUserSettings.shippingAddress) {
			updatedUserSettings.shippingAddress = {}
		} else if (type === 'billingAddress' && !updatedUserSettings.billingAddress) {
			updatedUserSettings.billingAddress = {}
		}

		if (name === 'address') {
      updatedUserSettings[type].address = value
    } else if (name === 'address2') {
      updatedUserSettings[type].address2 = value
    } else if (name === 'city') {
      updatedUserSettings[type].city = value
    } else if (name === 'zip') {
      updatedUserSettings[type].zip = value
    } else if (name === 'country') {
      updatedUserSettings[type].country = value
    } else {
      updatedUserSettings[type] = value
    }

		setUserSettings(updatedUserSettings)

		if (type === "language" || type === "currency") {
			handleSaveUserSettings(e, false, updatedUserSettings, type)
		}

    if (type === "language") {
      props.onSetCurrentLocale(value)
    }
	}

	const handleShowConfirmationModal = (type) => {
		setIsModalShown(!isModalShown)
		setModalType(type)
	}

	const getCurrentLocaleName = (currentLocale) => {
		return LOCALES_WITH_NAME.find(locale => locale.code === currentLocale)?.name
	}

	return (
      <>
        {isModalShown &&
          <ConfirmationModal
            type={modalType}
            isModalOpen={isModalShown}
            setIsModalOpen={setIsModalShown}
        />}
        <div id='GeneralSettings'>
          <section className='left'>
            <span><FormattedMessage id="dashboard.generalSettings.yourName" /></span>
            <input
              className='rect'
              placeholder='Your name'
              type="text"
              value={userSettings.name || ''}
              onChange={(e) => handleChangeUserSettings(e, 'name')}
              onBlur={(e) => handleSaveUserSettings(e)}
            />
            <span><FormattedMessage id="dashboard.generalSettings.shippingAddress" /></span>
            <div className='inputs-block'>
              <input
                className='rect first-row'
                placeholder='Address 1'
                type="text"
                value={userSettings.shippingAddress?.address || ''}
                onChange={(e) => handleChangeUserSettings(e, 'shippingAddress', 'address')}
                onBlur={(e) => handleSaveUserSettings(e, true)}
              />
              <input
                className='rect first-row'
                placeholder='Address 2 (optional)'
                type="text"
                value={userSettings.shippingAddress?.address2 || ''}
                onChange={(e) => handleChangeUserSettings(e, 'shippingAddress', 'address2')}
                onBlur={(e) => handleSaveUserSettings(e, true)}
              />
              <div className='second-row-wrapper'>
                <input
                  className='rect bigger'
                  placeholder='City'
                  type="text"
                  value={userSettings.shippingAddress?.city || ''}
                  onChange={(e) => handleChangeUserSettings(e, 'shippingAddress', 'city')}
                  onBlur={(e) => handleSaveUserSettings(e, true)}
                />
                <input
                  className='rect smaller'
                  placeholder='ZIP'
                  type="text"
                  value={userSettings.shippingAddress?.zip || ''}
                  onChange={(e) => handleChangeUserSettings(e, 'shippingAddress', 'zip')}
                  onBlur={(e) => handleSaveUserSettings(e, true)}
                />
              </div>
            </div>
            <div className='shipping-row-wrapper'>
              <div className='shipping-second-row'>
                <span><FormattedMessage id="dashboard.generalSettings.shippingCountry" /></span>
                <select
                  id="shipping-countries"
                  onChange={(e) => { handleChangeUserSettings(e, 'shippingCountry'); setRegion(); }}
                  onBlur={(e) => handleSaveUserSettings(e, false)}
                  className="shipping-countries-dropdown"
									value={userSettings.shippingCountry}
                >
                  {countries.map((country, index) => {
                    return (
                      <option
                        key={index}
                        value={country}
                        className='shipping-countries-value'
                      >
                        {country}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className='shipping-second-row'>
                <span><FormattedMessage id="dashboard.generalSettings.shippingRegion" /></span>
								{/* Countries like Vatican don't have region, so its "regions" are stored like [false] */}
                {regions[0] &&
                  <select
                    id='shipping-region'
                    onChange={(e) => handleChangeUserSettings(e, 'shippingRegion')}
                    onBlur={(e) => handleSaveUserSettings(e, false)}
                    className="shipping-countries-dropdown"
										value={userSettings.shippingRegion}
                  >
                    {regions.map((region, index) => {
                      return (
                        <option
                          key={index}
                          value={region}
                        >
                          {region}
                        </option>
                      )
                    })}
                  </select>
                }
              </div>
            </div>
            <span><FormattedMessage id="dashboard.generalSettings.phoneNumber" /></span>
            <input
              className='rect'
              placeholder='+420'
              type="text"
              value={userSettings.phoneNumber || ''}
              onChange={(e) => handleChangeUserSettings(e, 'phoneNumber')}
              onBlur={(e) => handleSaveUserSettings(e)}
            />
            <span><FormattedMessage id="dashboard.generalSettings.language" /></span>
            <Dropdown onBlur={(e) => handleSaveUserSettings(e, false)} className="lang-dropdown" isOpen={langDropdownOpen} toggle={() => setLangDropdownOpen(!langDropdownOpen)}>
              <DropdownToggle id="language" className={`rect ${languageDropdownSavingAnimation}`} tag="div" data-toggle="dropdown" aria-expanded={langDropdownOpen}>
                <span>{getCurrentLocaleName(userSettings.language || props.currentLocale)}</span>
                <img className="arrow" src="lib/images/arrow-gray.svg" alt="" />
              </DropdownToggle>
              <DropdownMenu>
                {LOCALES_WITH_NAME.map((locale, index) => (
                  <DropdownItem
                    key={index}
										value={locale.code}
										onClick={(e) => handleChangeUserSettings(e, 'language')}
                  >
                    {locale.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <ul>
              <li onClick={() => handleShowConfirmationModal('email')}><FormattedMessage id="dashboard.generalSettings.changeEmail" /> ({userSettings.email})</li>
              <li onClick={() => handleShowConfirmationModal('password')}><FormattedMessage id="dashboard.generalSettings.changePassword" /></li>
              <li onClick={() => handleShowConfirmationModal('delete')}><FormattedMessage id="dashboard.generalSettings.deleteAccount" /></li>
            </ul>
          </section>
          <section className='right'>
            <span><FormattedMessage id="dashboard.generalSettings.companyName" /></span>
            <input
              className='rect'
              placeholder='Company name'
              type="text"
              value={userSettings.companyName || ''}
              onChange={(e) => handleChangeUserSettings(e, 'companyName')}
              onBlur={(e) => handleSaveUserSettings(e)}
            />
            <span><FormattedMessage id="dashboard.generalSettings.billingAddress" /></span>
            <div className='inputs-block'>
              <input
                className='rect first-row'
                placeholder='Address'
                type="text"
                value={userSettings.billingAddress?.address || ''}
                onChange={(e) => handleChangeUserSettings(e, 'billingAddress', 'address')}
                onBlur={(e) => handleSaveUserSettings(e, true)}
              />
              <input
                className='rect first-row'
                placeholder='Address 2 (optional)'
                type="text"
                value={userSettings.billingAddress?.address2 || ''}
                onChange={(e) => handleChangeUserSettings(e, 'billingAddress', 'address2')}
                onBlur={(e) => handleSaveUserSettings(e, true)}
              />
              <div className='second-row-wrapper'>
                <input
                  className='rect bigger'
                  placeholder='City'
                  type="text"
                  value={userSettings.billingAddress?.city || ''}
                  onChange={(e) => handleChangeUserSettings(e, 'billingAddress', 'city')}
                  onBlur={(e) => handleSaveUserSettings(e, true)}
                />
                <input
                  className='rect smaller'
                  placeholder='ZIP'
                  type="text"
                  value={userSettings.billingAddress?.zip || ''}
                  onChange={(e) => handleChangeUserSettings(e, 'billingAddress', 'zip')}
                  onBlur={(e) => handleSaveUserSettings(e, true)}
                />
              </div>
            </div>
            <span style={{ marginBottom: '7.2rem'}}><FormattedMessage id="dashboard.generalSettings.billingSameAsShipping" /></span>
            <span><FormattedMessage id="dashboard.generalSettings.VAT" /></span>
            <input
              className='rect'
              placeholder=''
              type="text"
              value={userSettings.vatNumber || ''}
              onChange={(e) => handleChangeUserSettings(e, 'vatNumber')}
              onBlur={(e) => handleSaveUserSettings(e)}
            />
            <span><FormattedMessage id="dashboard.generalSettings.currency" /></span>
            <Dropdown
							className="currency-dropdown"
							isOpen={currencyDropdownOpen}
							toggle={() => setCurrencyDropdownOpen(!currencyDropdownOpen)}
						>
              <DropdownToggle id="currency" className={`rect ${currencyDropdownSavingAnimation}`} tag="div" data-toggle="dropdown" aria-expanded={currencyDropdownOpen}>
                <span>{userSettings.currency || CURRENCIES[0]}</span>
                <img className="arrow" src="lib/images/arrow-gray.svg" alt="" />
              </DropdownToggle>
              <DropdownMenu>
                {CURRENCIES.map((currency, index) => (
                  <DropdownItem
                    key={index}
                    value={currency}
                    onClick={(e) => handleChangeUserSettings(e, 'currency')}
									>
                    {currency}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </section>
        </div>
      </>
	)
}

const mapState = (state) => ({
	userSettings: getUserSettings(state),
	currentLocale: getCurrentLocale(state),
})

const mapDispatch = (dispatch) => ({
	onChangeUserSettings: (userSettings) => dispatch(changeUserSettings(userSettings)),
	onSetCurrentLocale: (type) => dispatch(setCurrentLocale(type)),
})

export default connect(mapState, mapDispatch)(GeneralSettings)
