import { validateEmail, validateGPS, validateUrl, validateIban, validatePhone } from '../help/validators'
import _isEmpty from 'lodash/isEmpty'

const QRCodeType = {
  CryptoWallet: 'QRCryptoWallet',
  Messenger: 'QRMessenger',
  SocialNetwork: 'QRSocialNetwork',
  WebsiteURL: 'QRWebsiteURL',
  Location: 'QRLocation',
  CloudFile: 'QRCloudFile',
  WifiNetwork: 'QRWifiNetwork',
  PlainText: 'QRPlainText',
  Email: 'QREmail',
  VCard: 'QRVCard',
  GoogleAddress: 'QRGoogleAddress',
  Phone: 'QRPhone',
  Payment: 'QRPayment',
  ProductPage: 'QRProductPage',
  VCardDynamic: 'QRVCardDynamic',
  MultiEntry: 'MultiEntry',
  Feedback: 'QRFeedback',
}

function generateAdress(type, qrConfig) {
  let qrCodeAddress = ''
  let error = ''

  const {
    walletType = '',
    address = '',
    url = '',
    messengerId = '',
    latitude = '',
    longitude = '',
    ssid = '',
    password = '',
    text = '',
    firstname = '',
    surname = '',
    title = '',
    organization = '',
    web = '',
    email = '',
    phone = '',
    zipCode = '',
    street = '',
    city = '',
    country = '',
    googleFormat = false,
    iban = '',
    amount = '',
    currency = '',
    paymentReference = '',
    message = '',
    encryption = '',
    header = '',
    buttonHeader = '',
  } = qrConfig

  function setError(err) {
    error = err
  }
  switch (type) {
    case QRCodeType.CryptoWallet:
      let finalWalletType = walletType?.content?.replace(/\s/g, '').toLowerCase()
      if (finalWalletType === 'other') {
        qrCodeAddress = `${address.content}`
      } else {
        qrCodeAddress = `${finalWalletType}:${address.content}?amount=${amount.content}`
      }
      break
    case QRCodeType.Messenger:
      qrCodeAddress = `${messengerId.content}`
      break
    case QRCodeType.SocialNetwork:
      validateUrl(url.content, setError)
      qrCodeAddress = url.content
      break
    case QRCodeType.WebsiteURL:
      validateUrl(url.content, setError)
      qrCodeAddress = url.content
      break
    case QRCodeType.CloudFile:
      validateUrl(url.content, setError)
      qrCodeAddress = url.content
      break
    case QRCodeType.Location:
      validateGPS(latitude.content, longitude.content, setError)
      if (!googleFormat.content) {
        qrCodeAddress = `GEO:${latitude.content},${longitude.content}`
      } else {
        qrCodeAddress = `http://www.google.com/maps?f=q&q=${latitude.content}%2C${longitude.content}`
      }
      break
    case QRCodeType.WifiNetwork:
      let finalEncryption
      if (encryption.content === '') {
        finalEncryption = 'nopass'
      } else {
        //WPA or WEP
        finalEncryption = encryption.content?.slice(0, 3) || 'WPA'
      }
      qrCodeAddress = `WIFI:S:${ssid.content};T:${finalEncryption};P:${password.content}`
      break
    case QRCodeType.PlainText:
      qrCodeAddress = `${qrConfig.text.content}`
      break
    case QRCodeType.VCard:
      validatePhone(phone.content, setError)
      validateEmail(email.content, setError)
      qrCodeAddress =
        'BEGIN:VCARD\n' +
        'VERSION:3.0\n' +
        `N:${surname.content};${firstname.content}\n` +
        `FN:${firstname.content} ${surname.content}\n` +
        (title.content ? `TITLE:${title.content}\n` : ``) +
        (organization.content ? `ORG:${organization.content}\n` : ``) +
        (web.content ? `URL:${web.content}\n` : ``) +
        (email.content ? `EMAIL;TYPE=INTERNET:${email.content}\n` : ``) +
        (phone.content ? `TEL;TYPE=voice,work,pref::${phone.content}\n` : ``) +
        'END:VCARD`'
      break
    case QRCodeType.GoogleAddress:
      qrCodeAddress = `http://www.google.com/maps/place/${street.content} ${zipCode.content} ${city.content} ${country.content}`
      break
    case QRCodeType.Email:
      validateEmail(email.content, setError)
      qrCodeAddress = `${email.content}`
      break
    case QRCodeType.Phone:
      validatePhone(phone.content, setError)
      qrCodeAddress = `TEL:${phone.content}`
      break
    case QRCodeType.Payment:
      let trimmedIban = iban.content.replace(/\s/g, '')
      validateIban(trimmedIban, setError)
      qrCodeAddress = `SPD*1.0*ACC:${trimmedIban}*AM:${amount.content}*CC:${currency.content}*X-VS:${paymentReference.content}*MSG:${message.content}`
      break
    case QRCodeType.ProductPage:
      qrCodeAddress = `${header.content}`
      break
    default:
      // qrCodeAddress = `${text.content}`
      qrCodeAddress = "www.qrcards.com"
  }

  try {
    let contents = Object.values(qrConfig).map((item) => item.content)
    contents = contents.filter((item) => typeof item === 'string')

    // check if it is okay
    if (!_isEmpty(qrConfig)) {
      if (contents.every((item) => item === '')) {
        setError('Chybí data ve vašem QR kodu.')
      } else {
        if (error === 'Chybí data ve vašem QR kodu.') setError('') // wtf?
      }
    }
  } catch (e) {
    console.log(e)
  }

  return [qrCodeAddress, error]
}

export { QRCodeType, generateAdress }
