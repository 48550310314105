import React, { useState } from 'react'
import EditFormat from './EditFormat'
import IconsList from './IconsList'

function FormatTab({ activeLayer, setDynamicQrType }) {
  const [isFormatShown, setIsFormatShown] = useState(true);
  return (
    <>
      <EditFormat isFormatShown={isFormatShown} setIsFormatShown={setIsFormatShown} activeLayer={activeLayer} setDynamicQrType={setDynamicQrType} />
    </>
  )
}

export default FormatTab
