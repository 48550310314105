import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { persistStore } from 'redux-persist'

import reducer from './reducers'
import loggerMiddleware from './middleware/loggerMiddleware'

// Get data coming from server (set in HTML file, in <script> part)
// @ts-ignore
const initialData = window.initialData // eslint-disable-line

// Set up Redux Dev Tools
const options = {
  serialize: true,  // For displaying Symbols in Redux Dev Tools
}

const composeWithDevTools =
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ // eslint-disable-line no-underscore-dangle
        // @ts-ignore
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(options)  // eslint-disable-line no-underscore-dangle
    : compose

// Create store
export const store = createStore(
  reducer,
  initialData,
  composeWithDevTools(applyMiddleware(thunk, loggerMiddleware)),
)

// Create persistor
export const persistor = persistStore(store)
