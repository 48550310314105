import React, {useEffect, useState, useRef} from 'react';

const useResize = (myRef) => {
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)

    const handleResize = () => {
        setSize()
    }

    function setSize() {
        // TODO: refactor. I have to face resize initialy
        if (myRef.current) {
            setWidth(myRef.current.offsetWidth)
            setHeight(myRef.current.offsetHeight)
        } else {
            setTimeout(() => {
                setSize()
            }, 100);
        }
    }

    useEffect(() => {
    // fake resize to get initial width
        handleResize()
    }, [])

    useEffect(() => {

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [myRef])

    return { width, height }
}

export default useResize;
