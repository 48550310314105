import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ReactSVG } from 'react-svg'
import { css } from 'glamor'
import { AutoTextSize } from 'auto-text-size'

import {
  getActiveSide,
  getTemplateType,
  getResetStatus,
  getUpdateStatus,
  getUploadedIcons
} from '../../../store/card/cardSelectors'

import QRCode from "./common/QRCode/QRCode"

class Template extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  render() {
    const { uploadedIcons, className, template, templateType } = this.props

    return (
      <div className={`template ${className ? className : ''} ${templateType ? templateType : ''}`} style={template.styles}>
        {
          template.layers.map(layer => {
            const layerType = layer.type.split('-')[0]

            if (layerType === 'backgroundImage') {
              return (
                <img
                  key={layer.type}
                  src={`/assets/images/${layer.imageName}`}
                  style={layer.styles}
                  alt={layer.imageName}
                />
              )
            }

            if (layerType === 'background') {
              if (layer.styles.backgroundSvg && layer.styles.backgroundSvg !== 'none') {
                let svgStyles
                const svgImage = layer.styles.backgroundSvg
                let imageName = svgImage.substring(svgImage.lastIndexOf('/') + 1).split('_')[0]
                const gradientOrientationValue = Number(layer.gradientOrientation.split('deg')[0])
                const currentLinearGradientElement = document.getElementsByClassName(`${imageName}-gradient`).item(0)
                const svgElement = document.getElementsByClassName(imageName).item(0)
                const leftGradientColor = layer.leftPosition > layer.rightPosition ? layer.rightGradientColor : layer.leftGradientColor
                const rightGradientColor = layer.leftPosition > layer.rightPosition ? layer.leftGradientColor : layer.rightGradientColor
                const leftPosition = layer.leftPosition > layer.rightPosition ? layer.rightPosition : layer.leftPosition
                const rightPosition = layer.leftPosition > layer.rightPosition ? layer.leftPosition : layer.rightPosition

                if (layer.colorPickerType === 'Linear Gradient') {
                  svgStyles = css({
                    ' path': {
                      fill: `url(#${imageName}-gradient)`,
                    },
                    ' rect': {
                      fill: `url(#${imageName}-gradient)`,
                    },
                    ' polygon': {
                      fill: `url(#${imageName}-gradient)`,
                    },
                    ' circle': {
                      fill: `url(#${imageName}-gradient)`,
                    },
                    ' ellipse': {
                      fill: `url(#${imageName}-gradient)`,
                    },
                    ' linearGradient': {
                      ' .left': {
                        stopColor: leftGradientColor,
                      },
                      ' .right': {
                        stopColor: rightGradientColor,
                      },
                    },
                  })
                } else if (layer.colorPickerType === 'Image') {
                  svgStyles = css({
                    ' path': {
                      fill: 'transparent',
                    },
                    ' rect': {
                      fill: 'transparent',
                    },
                    ' polygon': {
                      fill: 'transparent',
                    },
                    ' circle': {
                      fill: 'transparent',
                    },
                    ' ellipse': {
                      fill: 'transparent',
                    },
                    ' svg': {
                      mask: `url(${layer.styles.backgroundSvg}) center center / cover`,
                      backgroundImage: `url(${layer.styles.backgroundUploadedImage})`,
                      backgroundColor: layer.styles.color,
                      backgroundPosition: layer.styles.backgroundPosition,
                    },
                  })
                } else {
                  svgStyles = css({
                    ' path': {
                      fill: layer.styles.color,
                    },
                    ' rect': {
                      fill: layer.styles.color,
                    },
                    ' polygon': {
                      fill: layer.styles.color,
                    },
                    ' circle': {
                      fill: layer.styles.color,
                    },
                    ' ellipse': {
                      fill: layer.styles.color,
                    },
                  })
                }

                return (
                  <div
                    key={layer.type}
                    className={`template-layer svg-background`}
                  >
                    <ReactSVG
                      src={layer.styles.backgroundSvg}
                      style={layer.styles}
                      {...svgStyles}
                      renumerateIRIElements={false}
                      className="svgElement"
                    />
                    {layer.styles.uploadedImage &&
                      <img className="resizable-image" src={layer.styles.uploadedImage} alt="resizable" style={layer.overlayStyles} />
                    }
                  </div>
                )
              } else {
                return (
                  <div
                    key={layer.type}
                    className={`template-layer background`}
                    style={layer.styles}
                  >
                    {layer.styles.uploadedImage &&
                      <img className="resizable-image" src={layer.styles.uploadedImage} alt="resizable" style={layer.overlayStyles} />
                    }
                  </div>
                )
              }
            }

            if (layerType === 'text') {
              return (
                <div
                  key={layer.type}
                  className={`template-layer`}
                  style={layer.styles}
                >
                  <div className="editable-area">
                    <AutoTextSize
                      className="editable-text"
                      style={{
                        textAlign: layer.styles.textAlign,
                        color: layer.styles.color,
                        fontSize: layer.styles.fontSize,
                        lineHeight: layer.styles.fontSize,
                        alignSelf: layer.styles.verticalAlign === 'top' ? 'start' :
                                    layer.styles.verticalAlign === 'middle' ? 'center' : 'end'
                      }}
                      disabled
                      spellCheck="false"
                      type="text"
                    >
                      {layer.text}
                    </AutoTextSize>
                  </div>
                </div>
              )
            }

            if (layerType === 'icon' && layer.type.split("-").slice(0,2).join("-") !== "icon-qr") {
              let svgStyles
              let isUploadedIcon

              if (layer.iconName) {
                let iconName = layer.iconName.substring(layer.iconName.lastIndexOf('/') + 1).split('_')[0]

                if (layer.colorPickerType === 'Gradient') {
                  svgStyles = css({
                    ' path': {
                      fill: `url(#${iconName}-gradient)`,
                    },
                    ' rect': {
                      fill: `url(#${iconName}-gradient)`,
                    },
                    ' polygon': {
                      fill: `url(#${iconName}-gradient)`,
                    },
                    ' circle': {
                      fill: `url(#${iconName}-gradient)`,
                    },
                    ' ellipse': {
                      fill: `url(#${iconName}-gradient)`,
                    },
                    ' linearGradient': {
                      ' .left': {
                        stopColor: layer.leftGradientColor,
                      },
                      ' .right': {
                        stopColor: layer.rightGradientColor,
                      },
                    },
                  })
                } else if (layer.colorPickerType === 'Solid color') {
                  svgStyles = css({
                    ' path': {
                      fill: layer.styles.color,
                    },
                    ' rect': {
                      fill: layer.styles.color,
                    },
                    ' polygon': {
                      fill: layer.styles.color,
                    },
                    ' circle': {
                      fill: layer.styles.color,
                    },
                    ' ellipse': {
                      fill: layer.styles.color,
                    },
                  })
                }


                isUploadedIcon = layer.iconName.split('.')[1] !== undefined
              }

              if (uploadedIcons && isUploadedIcon) {
                const currentIcon = uploadedIcons.find(icon => icon.name === layer.iconName)

                if (currentIcon) {
                  return (
                    <div
                      key={layer.type}
                      className={`template-layer icon`}
                      style={layer.styles}
                    >
                      {layer.iconName && layer.iconName.split('.')[1] === 'svg' ?
                        // <ReactSVG src={svg} {...svgStyles} />
                        <img src={currentIcon.icon} alt={currentIcon.name} />
                        :
                        <img src={currentIcon.icon} alt={currentIcon.name} />
                      }
                    </div>
                  )
                }
              }

              return (
                <div
                  key={layer.type}
                  className={`template-layer icon ${layer.type}`}
                  style={layer.styles}
                >
                  {/* <img alt="" src={layer.iconName} {...svgStyles} /> */}
                  {layer.iconName && <ReactSVG src={layer.iconName} {...svgStyles} renumerateIRIElements={false} />}

                </div>
              )
            }

            if (layerType === 'qr') {
              let qrIconLayer = template.layers.find(itemLayer => itemLayer.type.split("-").slice(0,2).join("-") === "icon-qr" && layer.type.split("-").pop() == itemLayer.type.split("-").pop())
              return (
                <div
                  key={layer.type}
                  className={`template-layer qr`}
                  style={layer.styles}
                >
                  <QRCode data={layer} handleSetActiveLayer={this.handleSetActiveLayer} qrIconLayer={qrIconLayer} uploadedIcons={uploadedIcons} />
                </div>
              )
            }
          })
        }
      </div>
    )
  }
}

const mapState = (state) => ({
  updateStatus: getUpdateStatus(state),
  uploadedIcons: getUploadedIcons(state),
  resetStatus: getResetStatus(state),
  templateType: getTemplateType(state),
  side: getActiveSide(state)
})

export default connect(mapState)(Template)
