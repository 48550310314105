import React, {useEffect, useState} from "react";
import parse from "html-react-parser";
import './ThemePicker.scss';

function ThemePicker({value, options, onChange}) {

    const themeSelectCss = {
        width: '20%',
        paddingTop: '4px',
        paddingBottom: '4px',

        background: '#FFFFFF',
        border: '0.729064px solid rgba(23, 35, 57, 0.2375)',
        boxSizing: 'border-box',
        boxShadow: '0px 1.45813px 2.91626px rgba(23, 35, 57, 0.08)',
        borderRadius: '5.83251px'
    };

    function handleChange(theme) {
        onChange(theme.value);
    }

    function activeClass(value, option) {
        if (value === option.value) {
            return 'active-border'
        }
    }

    return (
        <div id="themes" className="theme-picker d-flex mt-4 justify-content-between">
            {options.map((option, index) => {
                return (
                    <button key={index} type="button" style={themeSelectCss} className={activeClass(value, option)} onClick={() => handleChange(option)}>
                        <div>
                            <img src={option.icon} width="35" />
                        </div>
                    </button>
                )
            })}
        </div>
    )
}

export default ThemePicker;
