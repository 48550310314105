import React from 'react'
import { FormattedMessage } from 'react-intl'

function Orders() {
  return (
    <div id='Orders'>
      <h1><FormattedMessage id="dashboard.myOrders.title" /></h1>
      <div className='payment-card-wrapper'>
        <div className='payment-card'>
          <div className='rect'>
            <span><FormattedMessage id="dashboard.myOrders.cardNumber" /></span>
            <img className='visa' src="assets/images/visa.png" alt="" />
          </div>
          <span className='red'><FormattedMessage id="dashboard.myOrders.deleteCardLabel" /></span>
        </div>
        <p>
          <svg className='plus' width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="7.61822" cy="7.88287" r="7.25494" fill="#38517C" />
            <path d="M3.92383 7.88281H11.0041" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.61914 11.4229L7.61914 4.34257" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <FormattedMessage id="dashboard.myOrders.addCardLabel" />
        </p>
      </div>
      <h1 className='history-title'><FormattedMessage id="dashboard.myOrders.historyTitle" /></h1>
      <div className='history-wrapper'>
        <div className='row rect first-row'>
          <span><FormattedMessage id="dashboard.myOrders.orderStatus" /></span>
          <span><FormattedMessage id="dashboard.myOrders.orderNum" /></span>
          <span><FormattedMessage id="dashboard.myOrders.product" /></span>
          <span><FormattedMessage id="dashboard.myOrders.date" /></span>
          <span><FormattedMessage id="dashboard.myOrders.value" /></span>
        </div>
        <div className='row-wrapper'>
          <div className='row rect'>
            <span className='orange'>Waiting for payment</span>
            <span>-</span>
            <span>Sticker</span>
            <span>18. 1. 2021</span>
            <span>69 eur</span>
          </div>
          <button className="round-button">Pay now</button>
          <span className='red'>Cancel order</span>
        </div>
        <div className='row-wrapper'>
          <div className='row rect'>
            <span className='green'>Successful</span>
            <span>T2584018-100820</span>
            <span>Subscription payment</span>
            <span>14. 3. 2021</span>
            <span>29 eur</span>
          </div>
          <button className="round-button">
            Actions
            <svg className='arrow' width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.16186 0C7.02547 0 7.48299 1.02104 6.90819 1.66558L4.68086 4.16312C4.28321 4.60902 3.58586 4.60902 3.18821 4.16313L0.960887 1.66558C0.386082 1.02104 0.843596 0 1.70721 0L6.16186 0Z" fill="white" />
            </svg>
          </button>
        </div>
        <div className='row-wrapper'>
          <div className='row rect'>
            <span className='green'>Shipped</span>
            <span>T2584018-100820</span>
            <span>Label</span>
            <span>13. 1. 2021</span>
            <span>64 eur</span>
          </div>
          <button className="round-button">
            Actions
            <svg className='arrow' width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.16186 0C7.02547 0 7.48299 1.02104 6.90819 1.66558L4.68086 4.16312C4.28321 4.60902 3.58586 4.60902 3.18821 4.16313L0.960887 1.66558C0.386082 1.02104 0.843596 0 1.70721 0L6.16186 0Z" fill="white" />
            </svg>
          </button>
        </div>

      </div>
    </div>
  )
}

export default Orders

