import isEmail from 'validator/lib/isEmail'
import isURL from 'validator/lib/isURL'
import isIBAN from 'validator/lib/isIBAN'
import isMobilePhone from 'validator/lib/isMobilePhone'
import isFloat from 'validator/lib/isFloat'

export function validatePhone(str, setError) {
  if (isMobilePhone(str)) return true

  setError('Telefonní číslo nemá správny formát.')
  return false
}
export function validateIban(str, setError) {
  if (isIBAN(str)) return true

  setError('IBAN nemá správný formát.')
  return false
}
export function validateUrl(str, setError) {
  if (isURL(str)) return true

  setError('Vámi zadaný text nevypadá jako odkaz.')
  return false
}

export function validateEmail(str, setError) {
  if (isEmail(str)) return true

  setError('Vámi zadaný text nevypadá jako email.')
  return false
}

export function validateGPS(lat, lng, setError) {
  let errors = []

  if (!isFloat(lat, { min: -80, max: 80 })) {
    errors.push('latitude')
  }
  if (!isFloat(lng, { min: -180, max: 180 })) {
    errors.push('longitude')
  }
  if (errors.length > 0) {
    setError(`Vámi zadaný text nemá formát ${errors.join(', ')}.`)
    return false
  } else {
    return true
  }
}
