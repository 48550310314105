import React from "react";
import SaveDesign from "../SaveDesign/SaveDesign";
import {Route} from "react-router";
import ExportButton from "../ExportButton/ExportButton";

const ButtonPanel = () => {
    return (
        <div id="button-panel" className="d-flex col-sm-6 justify-content-end">
            <Route path="/app/editor">
                <SaveDesign/>
                {/* @ts-ignore */}
                <ExportButton/>
            </Route>
        </div>
    )
}

export default ButtonPanel
