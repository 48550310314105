import React, {useEffect, useState} from "react";
import './DigitalCardItem.scss'
import Errors from "../Errors";
import {ReactComponent as Cross} from "../../../common/images/elements/cross.svg";
import {useForm, useWatch} from "react-hook-form";
import SocialSelect from '../SocialSelect';

function Item({ item, itemField, onUpdate, onDelete, ...rest }) {

    const {
        setValue,
        getValues,
        register,
        watch,
        formState,
        control,
    } = useForm({
        mode: "onTouched" // "onChange"
    });

    useEffect(() => {
        setValue('id', item.id)
        setValue(itemField, item[itemField])
        setValue('type', item.type)
    }, [])

    // watch url changes
    const field = useWatch({
        defaultValue: item[itemField],
        control,
        name: itemField
    })

    useEffect(() => {
        onUpdate(
            {
                ...item,
                [itemField]: field
            }
        );

    }, [field])


    function handleSelectChange(option) {
        onUpdate({...item, type: option.value})
    }

    return (
        <div className="d-flex justify-content-center mt-4">
            <div className="">
                <SocialSelect
                    item={item}
                    onChange={handleSelectChange}
                >
                </SocialSelect>
            </div>
            <div className="format-container w-100 py-0 my-0" style={{height: '48px'}}>
                <div className="inputs-container">
                    <div className="input-container">

                        <input name={itemField} type="text" placeholder="Link to your social media"
                               {...register(itemField, {required: true, maxLength: 20})}/>

                        {/* Cross/Trash button */}
                        {
                            watch(itemField) !== ''
                                ?
                                <Cross onClick={() => setValue(itemField, '')}/>
                                :
                                <div className="float-right trash-button" onClick={() => onDelete(item.id)}>
                                    <img className='blue-image' src="/assets/icons/delete.svg" width="15" alt=""/>
                                </div>
                        }
                    </div>
                    {/*TODO: temporarily hidenn, it cause the input is bigger and the SocialSelect is wrongly placed*/}
                    {/*<Errors formState={formState} name={itemField}/>*/}
                </div>
            </div>
        </div>
    )
}

export default Item;
