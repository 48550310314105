import React, { useEffect, useState, memo } from "react";
// import { csv } from "d3-fetch";
import { scaleLinear } from "d3-scale";
import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule,
  ZoomableGroup,
  Marker,
} from "react-simple-maps";
import getCountryISO3 from "country-iso-2-to-3";
import mapData from "./mapData.json";

const geoUrl = "https://www.react-simple-maps.io/features.json";

function HeatMapGraph({ setTooltipContent, stats }) {
  const [data, setData] = useState([]);
  let max = stats[0] ? stats[0].count : 0;
  let min = stats[0] ? stats[0].count : 0;
  stats.forEach((stat) => {
    if (stat.count > max) max = stat.count;
    if (stat.count < max) min = stat.count;
  });
  const colorScale = scaleLinear()
    .domain([min, max])
    .range(["rgba(56, 185, 241, 0.3)", "rgba(56, 185, 241, 1)"]);

  useEffect(() => {
    setData(stats);
  }, [stats]);
  return (
    <div data-tip="" className="heatmap-graph-wrapper">
      <ComposableMap
        className="heatmap-graph"
        projectionConfig={{
          rotate: [-10, 0, 0],
          scale: 147,
        }}
      >
        <ZoomableGroup center={[15, 50]} zoom={8} maxZoom={20}>
          {/* <ZoomableGroup> */}
          {/* <Sphere stroke="#E4E5E6" strokeWidth={0.5} />
      <Graticule stroke="#E4E5E6" strokeWidth={0.5} /> */}
          {
            // <Geographies geography="https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json">
            <Geographies geography={mapData}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const d = data.find((s) => getCountryISO3(s._id) === geo.id);
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={d ? colorScale(d["count"]) : "#848484"}
                      strokeWidth={0.1}
                      stroke="#E4E5E6"
                      onMouseEnter={() => {
                        setTooltipContent(
                          `${geo.properties.name}: ${d ? d["count"] : "0"}`
                        );
                      }}
                      onMouseLeave={() => {
                        setTooltipContent("");
                      }}
                      style={{
                        // default: {
                        //   fill: "#D6D6DA",
                        //   outline: "none",
                        // },
                        hover: {
                          // fill: "#F53",
                          opacity: 0.9,
                          outline: "none",
                        },
                        // pressed: {
                        //   fill: "#E42",
                        //   outline: "none",
                        // },
                      }}
                    />
                  );
                })
              }
            </Geographies>
          }
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
}

export default HeatMapGraph;
