import {
  apiLogin,
  apiLoginGoogle,
  apiSignup,
  apiForgotPassword,
  apiResetPassword,
  apiLoginFacebook,
  apiUpdateUser,
  apiUpdatePassword,
  apiDeleteUser,
  apiChangeEmail,
  apiChangeSubscriptionStatus,
  apiGetUser,
  apiSendConfirmationEmail,
} from '../../api/api'

import { getUserData, getUserSettings } from './authSelectors'

/*
  const rollbar = new Rollbar({
  accessToken: "410f5891ac834964b241cd457a965b3a",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
      environment: "production"
  }
})
*/

export const LOGIN_START = 'LOGIN_START'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const SIGNUP_START = 'SIGNUP_START'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAIL = 'SIGNUP_FAIL'

export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'

export const USER_SETTINGS_CHANGE_START = 'USER_SETTINGS_CHANGE_START'
export const USER_SETTINGS_CHANGE_SUCCESS = 'USER_SETTINGS_CHANGE_SUCCESS'
export const USER_SETTINGS_CHANGE_FAIL = 'USER_SETTINGS_CHANGE_FAIL'

export const UPDATE_PASSWORD_START = 'UPDATE_PASSWORD_START'
export const UPDATE_PASSWORD_SUCCES = 'UPDATE_PASSWORD_SUCCES'
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL'

export const DELETE_USER_START = 'DELETE_USER_START'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'

export const CHANGE_EMAIL_START = 'CHANGE_EMAIL_START'
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS'
export const CHANGE_EMAIL_FAIL = 'CHANGE_EMAIL_FAIL'

export const ERROR_CLEAR = 'ERROR_CLEAR'
export const LOGOUT = 'LOGOUT'

export const LOGIN_PROVIDER_SET = 'LOGIN_PROVIDER_SET'
export const IS_SUBSCRIPTION_ACTIVE_SET = 'IS_SUBSCRIPTION_ACTIVE_SET'

const loginStart = () => ({ type: LOGIN_START })
const loginSuccess = (data, isRememberChecked) => ({ type: LOGIN_SUCCESS, data, isRememberChecked })
// const loginFail = (message) => ({ type: LOGIN_FAIL, message })
const logoutSuccess = () => ({ type: LOGOUT })

const signupStart = () => ({ type: SIGNUP_START })
const signupSuccess = (data) => ({ type: SIGNUP_SUCCESS, data })
const signupFail = (message) => ({ type: SIGNUP_FAIL, message })

const forgotPasswordStart = () => ({ type: FORGOT_PASSWORD_START })
const forgotPasswordSuccess = (data) => ({ type: FORGOT_PASSWORD_SUCCESS, data })
// const forgotPasswordFail = (message) => ({ type: FORGOT_PASSWORD_FAIL, message })

const resetPasswordStart = () => ({ type: RESET_PASSWORD_START })
const resetPasswordSuccess = (data) => ({ type: RESET_PASSWORD_SUCCESS, data })
// const resetPasswordFail = (message) => ({ type: RESET_PASSWORD_FAIL, message })

const changeUserSettingsStart = () => ({ type: USER_SETTINGS_CHANGE_START })
const changeUserSettingsSuccess = (user) => ({ type: USER_SETTINGS_CHANGE_SUCCESS, user })
const changeUserSettingsFail = () => ({ type: USER_SETTINGS_CHANGE_FAIL })

const updatePasswordStart = () => ({ type: UPDATE_PASSWORD_START })
const updatePasswordSuccess = () => ({ type: UPDATE_PASSWORD_SUCCES })
const updatePasswordFail = (message) => ({ type: UPDATE_PASSWORD_FAIL, message })

const deleteUserStart = () => ({ type: DELETE_USER_START })
const deleteUserSuccess = () => ({ type: DELETE_USER_SUCCESS })
const deleteUserFail = (message) => ({ type: DELETE_USER_FAIL, message })

const changeEmailStart = () => ({ type: CHANGE_EMAIL_START })
const changeEmailSuccess = () => ({ type: CHANGE_EMAIL_SUCCESS })
const changeEmailFail = (message) => ({ type: CHANGE_EMAIL_FAIL, message })

export const setIsSubscriptionActive = (isSubscriptionActive) => ({ type: IS_SUBSCRIPTION_ACTIVE_SET, isSubscriptionActive })

export const clearError = () => ({ type: ERROR_CLEAR })

export const setLoginProvider = (loginProvider) => ({ type: LOGIN_PROVIDER_SET, loginProvider })

export const logout = () => (dispatch, getState) => {
  dispatch(logoutSuccess())
}

export const login = (email, password, isRememberChecked) => (dispatch, getState) => {
  dispatch(loginStart())

  return apiLogin(email, password)
    .then((data) => {
      if (data.user.emailConfirmed) {
        dispatch(loginSuccess(data, isRememberChecked))
      } else {
        const message = 'Your email is not confirmed'
        dispatch(signupFail(message))
      }
     })
    .catch(async error => {
      const errorObj = await error
      const message = errorObj.message[0].messages[0].message
      dispatch(signupFail(message))
    })
}

export const loginGoogle = (search) => (dispatch, getState) => {
  dispatch(loginStart())

  return apiLoginGoogle(search)
    .then((data) => {
      dispatch(loginSuccess(data))
    })
    .catch(async error => {
      const message = 'Signup by google account has failed. Email is already taken or server error has occured.'
      dispatch(signupFail(message))
    })
}

export const loginFacebook = (search) => (dispatch, getState) => {
  dispatch(loginStart())

  return apiLoginFacebook(search)
    .then((data) => {
      dispatch(loginSuccess(data))
    })
    .catch(async error => {
      const message = 'Signup by facebook account has failed. Email is already taken or server error has occured.'
      dispatch(signupFail(message))
    })
}

export const signup = (email, password, currency) => (dispatch, getState) => {
  dispatch(signupStart())

  return apiSignup(email, password, currency)
    .then((data) => dispatch(signupSuccess(data)))
    .catch(async error => {
      const errorObj = await error
      const message = errorObj.message[0].messages[0].message
      dispatch(signupFail(message))
    })
}

export const forgotPassword = (email) => (dispatch, getState) => {
  dispatch(forgotPasswordStart())

  return apiForgotPassword(email)
    .then((data) => dispatch(forgotPasswordSuccess(data)))
    .catch(async error => {
      const errorObj = await error
      const message = errorObj.message[0].messages[0].message
      dispatch(signupFail(message))
    })
}

export const resetPassword = (code, password, passwordConfirm) => (dispatch, getState) => {
  dispatch(resetPasswordStart())

  return apiResetPassword(code, password, passwordConfirm)
    .then((data) => dispatch(resetPasswordSuccess(data)))
    .catch(async error => {
      const errorObj = await error
      const message = errorObj.message[0].messages[0].message
      dispatch(signupFail(message))
    })
}

export const updatePassword = (userData, password) => (dispatch, getState) => {
  dispatch(updatePasswordStart())
  console.log(userData)
  return apiUpdatePassword(userData, password)
    .then((data) => dispatch(updatePasswordSuccess(data)))
    .catch(async error => {
      const errorObj = await error
      console.log(errorObj)
      const message = errorObj.message[0].messages[0].message
      dispatch(updatePasswordFail(message))
    })
}

export const deleteUser = (userData, password) => (dispatch, getState) => {
  dispatch(loginStart())

  return apiLogin(userData.email, password)
    .then((data) => {
      dispatch(loginSuccess(data))
      dispatch(deleteUserStart())

      return apiDeleteUser(userData, password)
        .then((data) => dispatch(deleteUserSuccess(data)))
        .catch(async error => {
          const errorObj = await error
          const message = errorObj.message[0].messages[0].message
          dispatch(deleteUserFail(message))
        })
    })
    .catch(async error => {
      const errorObj = await error
      const message = errorObj.message[0].messages[0].message
      dispatch(signupFail(message))
    })
}

export const changeEmail = (userData, email) => (dispatch, getState) => {
  dispatch(changeEmailStart())

  return apiChangeEmail(userData, email)
    .then((data) => dispatch(changeEmailSuccess(data)))
    .catch(async error => {
      const message = error
      dispatch(changeEmailFail(message))
  })
}

export const changeUserSettings = (userSettings) => (dispatch, getState) => {
  dispatch(changeUserSettingsStart())

  return apiUpdateUser(userSettings)
    .then((data) => dispatch(changeUserSettingsSuccess(data)))
    .catch(async error => {
      await error
      dispatch(changeUserSettingsFail())
    })
}

export const changeSubscriptionStatus = (isCancelled) => (dispatch, getState) => {
  const userSettings = getUserSettings(getState())
  const { email, id } = userSettings

  return apiChangeSubscriptionStatus(id, email, isCancelled)
    .then(() => dispatch(setIsSubscriptionActive(!isCancelled)))
    .catch(async error => {
      await error
    })
}

export const refreshUser = () => async (dispatch, getState) => {
  const userSettings = getUserSettings(getState())
  const { id } = userSettings

  dispatch(changeUserSettingsStart())

  await apiGetUser(id)
    .then((user) => dispatch(changeUserSettingsSuccess(user)))
    .catch(async error => {
      await error
    })
}

export const checkSubscriptionExpiration = () => async (dispatch, getState) => {
  const userSettings = {...getUserSettings(getState())}
  const { id } = userSettings
  let updatedUserSettings

  await apiGetUser(id)
    .then((user) => {
      updatedUserSettings = user
    })
    .catch(async error => {
      await error
    })

  const { subscriptionExpiration } = updatedUserSettings

  if (subscriptionExpiration && new Date() > new Date(subscriptionExpiration)) {
    userSettings.isSubscriptionActive = false
    userSettings.subscriptionExpiration = null
    userSettings.subscriptionPlan = 'DYNAMIC QR 1'

    dispatch(changeUserSettings(userSettings))
  } else {
    dispatch(changeUserSettingsSuccess(updatedUserSettings))
  }
}

export const sendConfirmationEmail = (email, lang) => async () => {
  await apiSendConfirmationEmail(email, lang)
    .catch(async error => {
      await error
    })
}


