import React from "react";
import { Bar } from "react-chartjs-2";
import { FormattedMessage } from "react-intl";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from "chart.js";
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

export const options = {
  scales: {
      y: {
          ticks: {
              beginAtZero: true,
              callback: function(label, index, labels) {
                  // when the floored value is the same as the value we have a whole number
                  if (Math.floor(label) == label) {
                      return label;
                  }

              },
          },
          grid: {
            borderWidth: 2,
            borderColor: "#D2D2D2"
          }
        },
        x: {
          grid: {
            borderWidth: 2,
            borderColor: "#D2D2D2"
          }
          
        }
  },
}
function Scans({ stats }) {
  function generateLabels(minDate, maxDate) {
    const months = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const labels = [];
    for (let y = minDate[2]; y <= maxDate[2]; y++) {
      if ((y % 4 === 0 && y % 100 !== 0) || y % 400 === 0) {
        // i is leap year
        months[1] = 29;
      }
      let stopMonth = false;
      for (let m = minDate[1]; m <= 12; m++) {
        if (stopMonth) break;
        for (let d = 1; d <= months[m - 1]; d++) {
          if (y === minDate[2] && m === minDate[1] && d < minDate[0]) continue;
          if (y === maxDate[2] && m === maxDate[1] && d === maxDate[0] + 1) {
            stopMonth = true;
            break;
          }
          labels.push(`${d}/${m}/${y}`);
        }
      }
    }
    return labels;
  }
  let minDate = []; // [day, month, year]
  let maxDate = [];
  // find minDate and maxDate
  stats.date.forEach((date, i) => {
    let values = date._id.split("/").map((num) => Number(num));
    if (i == 0) {
      minDate = values;
      maxDate = values;
    }
    // find MIN
    if (values[2] < minDate[2]) {
      minDate = values;
    } else if (values[2] === minDate[2]) {
      if (values[1] < minDate[1]) {
        minDate = values;
      } else if (values[1] === minDate[1]) {
        if (values[0] < minDate[0]) {
          minDate = values;
        }
      }
    }
    // find MAX
    if (values[2] > maxDate[2]) {
      maxDate = values;
    } else if (values[2] === maxDate[2]) {
      if (values[1] > maxDate[1]) {
        maxDate = values;
      } else if (values[1] === maxDate[1]) {
        if (values[0] > maxDate[0]) {
          maxDate = values;
        }
      }
    }
  });
  const labels = generateLabels(minDate, maxDate);
  let total = 0;
  const statsArray = labels.map((label) => {
    let count = 0;
    let labelValues = label.split("/").map((num) => Number(num));
    stats.date.forEach((date) => {
      let dateValues = date._id.split("/").map((num) => Number(num));
      if (
        labelValues[0] === dateValues[0] &&
        labelValues[1] === dateValues[1] &&
        labelValues[2] === dateValues[2]
      ) {
        count = date.count;
        total += count;
      }
    });
    return count;
  });

  const data = {
    labels,
    datasets: [
      {
        label: "Scans",
        data: statsArray,
        backgroundColor: "rgba(56, 185, 241, 1)",
      },
    ],
  };
  return (
    <>
      <h2 className="number-of-scans"><FormattedMessage id="dashboard.statistics.scans"/>: {total}</h2>
      <div className="bar-graph">
        <Bar options={options} data={data} />
      </div>
    </>
  );
}

export default Scans;
