import React, { Component } from 'react'
import { connect } from 'react-redux'

import ColorPicker from '../common/ColorPicker'
import ColorGradient from '../common/ColorGradient'
import AddImageButton from '../common/AddImageButton'
import { updateDesign } from '../../../../store/card/cardActions'
import { getActiveLayer } from '../../../../store/card/cardSelectors'
import { ReactSVG } from 'react-svg'


import SuggestedImages from './SuggestedImages'
import arrow_icon from '../common/images/elements/arrow_active.svg'


import './BackgroundCustomizationSection.scss'

class BackgroundCustomizationSection extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeLayer: props.activeLayer,
      dropdownOpen: false,
      currentColorType: props.activeLayer.colorPickerType,
      userGradientType: 'Linear Gradient',
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeLayer !== this.props.activeLayer) {
      this.setState({
        currentColorType: this.props.activeLayer.colorPickerType,
      })
    }
  }

  setColorType = (type) => {
    const { onChangeTemplate, activeLayer } = this.props
    const changedLayer = { ...activeLayer }
    const changedLayerStyles = {
      ...changedLayer.styles,
    }

    this.setState({
      currentColorType: type,
    })

    changedLayer.colorPickerType = type

    if (type === 'Solid color') {
      changedLayerStyles['backgroundImage'] = 'none'
    } else if (type === 'Image' && activeLayer.styles.backgroundUploadedImage !== 'none') {
      changedLayerStyles['backgroundImage'] = `url(${activeLayer.styles.backgroundUploadedImage})`
    } else if (!changedLayerStyles.backgroundSvg || changedLayerStyles.backgroundSvg === 'none') {
      changedLayerStyles[
        'backgroundImage'
      ] = `linear-gradient(${activeLayer.gradientOrientation}, ${activeLayer.leftGradientColor} , ${activeLayer.rightGradientColor})`
    }

    changedLayer.styles = changedLayerStyles

    this.setState({
      changedLayer,
    })

    onChangeTemplate(changedLayer)
  }

  handleToggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }
  handleGradientChange = () => {
    const { currentColorType } = this.state
    if (!currentColorType.includes('Gradient')) {
      // Initially set previous gradient type which user has entered
      this.setColorType(this.state.userGradientType)
      return
    }
    if(currentColorType === "Linear Gradient") {
      this.setColorType('Radial Gradient')
      this.setState({ userGradientType: 'Radial Gradient'})
    } else {
      this.setColorType('Linear Gradient')
      this.setState({ userGradientType: 'Linear Gradient'})
    }
  }

  render() {
    const { currentColorType } = this.state
    const { activeLayer } = this.props

    return (
      <div className="background-customization-section">
        {/* <div className="background-color customization-dropdown">
          <ButtonDropdown className="dropdown" isOpen={dropdownOpen} toggle={() => this.handleToggle()}>
            <DropdownToggle caret>{currentColorType}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => this.setColorType('Solid color')}>
                <FormattedMessage id="colors.solid" />
              </DropdownItem>
              <DropdownItem onClick={() => this.setColorType('Gradient')}>
                <FormattedMessage id="colors.linear" />
              </DropdownItem>
              {activeLayer.advanced && (
                <DropdownItem onClick={() => this.setColorType('Image')}>
                  <FormattedMessage id="image.image" />
                </DropdownItem>
              )}
            </DropdownMenu>
          </ButtonDropdown>
        </div> */}
        <div className={`buttons ${!activeLayer.advanced ? "not-advanced" : ""}`}>
          <button onClick={() => this.setColorType('Solid color')} className={`${currentColorType === 'Solid color' && 'selected'}`}>Solid color</button>
          <button onClick={this.handleGradientChange} className={`gradient ${currentColorType.includes("Gradient") && 'selected'}`}>
            Gradient
          </button>
          <button onClick={() => this.setColorType('Image')} className={`image ${currentColorType === 'Image' && 'selected'}`}>Image</button>
        </div>
        {currentColorType === 'Image' && activeLayer.advanced ?
          <>
            <AddImageButton uploadType="backgroundImage" />
            <SuggestedImages />
          </>
          : currentColorType === 'Solid color' ?
            <ColorPicker type="background" />
            :
            <ColorGradient type="background" />
        }
        {activeLayer.advanced && <AddImageButton />}
      </div>
    )
  }
}

const mapState = (state) => ({
  activeLayer: getActiveLayer(state),
})

const mapDispatch = (dispatch) => ({
  onChangeTemplate: (layer) => dispatch(updateDesign(layer)),
})

export default connect(mapState, mapDispatch)(BackgroundCustomizationSection)
