import RatingStar from "./RatingStar";
import React, {useState} from "react";

function RatingStars({item, onChangeHandler}) {

    const [numbers] = useState([1, 2, 3, 4, 5]);
    const [focusedNumber, setFocusedNumber] = useState(null)

    function focusHandler(number) {
        setFocusedNumber(number);
    }

    return (
        <div className="d-flex justify-content-center">
            <div className={`feedback__item-rating`}>
                {numbers.map((number, index) => {
                    return (
                        <RatingStar key={index} item={item} number={number} onChangeHandler={onChangeHandler} focusHandler={focusHandler} focusedNumber={focusedNumber}/>
                    )
                })}
            </div>
        </div>
    )
}

export default RatingStars;
