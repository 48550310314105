import React, {Component} from 'react'
import {connect} from 'react-redux'
import {ReactSVG} from 'react-svg'
import {css} from 'glamor'
import { autoTextSize } from 'auto-text-size'

import {setActiveLayer, updateDesign} from '../../../store/card/cardActions'
import {
  getActiveDesign,
  getActiveLayerType,
  getActiveSide,
  getResetStatus,
  getTemplateType,
  getUpdateStatus,
  getUploadedIcons,
} from '../../../store/card/cardSelectors'

import QRCode from './common/QRCode/QRCode'
import './Design.scss'
import '../../../utils/interact'
import '../../../utils/backgroundDrag'
import {TYPES} from "../../../utils/constants";

class Design extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isElementFocused: true,
    }
    this.inputRef = React.createRef()
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  handleLoad = (updatedDesign) => {
    let design

    if (updatedDesign && updatedDesign.layers) {
      design = updatedDesign
    } else {
      design = this.props.design
    }

    let side = this.props.side;

    design.layers[side].forEach((layer) => {
      const layerType = layer.type.split('-')[0]

      if (layerType === 'background') {
        if (layer.styles.backgroundSvg && layer.styles.backgroundSvg !== 'none') {
          const svgImage = layer.styles.backgroundSvg
          let imageName = svgImage.substring(svgImage.lastIndexOf('/') + 1).split('_')[0]
          const gradientOrientationValue = Number(layer.gradientOrientation.split('deg')[0])
          const currentLinearGradientElement = document.getElementById(`${imageName}-gradient`)
          const svgElement = document.getElementsByClassName(imageName).item(0)
          const leftPosition = layer.leftPosition > layer.rightPosition ? layer.rightPosition : layer.leftPosition
          const rightPosition = layer.leftPosition > layer.rightPosition ? layer.leftPosition : layer.rightPosition

          if (currentLinearGradientElement && svgElement) {
            const svgWidth = svgElement.getAttribute('width')
            const svgHeight = svgElement.getAttribute('height')

            currentLinearGradientElement.setAttribute('gradientTransform', `rotate(${gradientOrientationValue}, ${svgWidth / 2},${svgHeight / 2})`)
            currentLinearGradientElement.getElementsByClassName('left').item(0).setAttribute('offset', `${leftPosition}%`)
            currentLinearGradientElement.getElementsByClassName('right').item(0).setAttribute('offset', `${rightPosition}%`)
          }
        }
      }

      if (layerType === 'icon') {
        const gradientOrientationValue = Number(layer.gradientOrientation.split('deg')[0])

        if (layer.iconName) {
          let iconName = layer.iconName.substring(layer.iconName.lastIndexOf('/') + 1).split('_')[0]
          const currentIconElement = document.getElementsByClassName(iconName).item(0)
          let currentLinearGradientElement = document.getElementById(`${iconName}-gradient`)
          const leftPosition = layer.leftPosition > layer.rightPosition ? layer.rightPosition : layer.leftPosition
          const rightPosition = layer.leftPosition > layer.rightPosition ? layer.leftPosition : layer.rightPosition

          if (currentLinearGradientElement && currentIconElement) {
            let svgWidth = currentIconElement.getAttribute('width')
            let svgHeight = currentIconElement.getAttribute('height')

            if (svgWidth && svgHeight && svgWidth.indexOf('px') !== -1 && svgHeight.indexOf('px') !== -1) {
              svgWidth = svgWidth.split('px')[0]
              svgHeight = svgHeight.split('px')[0]
            }

            currentLinearGradientElement.setAttribute(
              'gradientTransform',
              `rotate(${gradientOrientationValue}, ${svgWidth / 2},${svgHeight / 2})`,
            )
            currentLinearGradientElement.getElementsByClassName('left').item(0).setAttribute('offset', `${leftPosition}%`)
            currentLinearGradientElement.getElementsByClassName('right').item(0).setAttribute('offset', `${rightPosition}%`)
          }
        }
      }
    })
  }

  componentDidUpdate(prevProps) {
    const {name: prevDesignName} = prevProps.design
    const {name: designName} = this.props.design

    if (prevDesignName !== designName) {
      this.focusOnQrCode()
    }

    if (prevProps.design !== this.props.design) {
      this.setState({
        design: this.props.design,
      })
    }

    this.handleLoad(this.props.design)
  }

  focusOnQrCode() {
    if (this.props.templateType === TYPES.QR_ONLY) {
      this.props.onSetActiveLayer('qr-1')
      this.setState({
        isElementFocused: true,
        activeLayerType: 'qr-1',
      })
    }
  }

  componentDidMount() {
    this.focusOnQrCode()
    document.addEventListener('mousedown', this.handleClickOutside)
    window.addEventListener('load', this.handleLoad)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    window.removeEventListener('load', this.handleLoad)
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  handleClickOutside(event) {
    const { onSetActiveLayer, activeLayerType, design, isHidden } = this.props
    let isClickOutside = true

    let side = this.props.side;

    const layer = design.layers[side][design.layers[side].findIndex((layer) => layer.type === activeLayerType)]

    event.composedPath().forEach((element) => {
      if (element.className === 'customize-section' || element.className === 'templates-list-wrapper' || isHidden) {
        isClickOutside = false
      }
    })

    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && isClickOutside && activeLayerType) {
      const focusedElement = document.getElementsByClassName(activeLayerType)[0]

      if (document.getElementsByClassName('focused')[0].getElementsByClassName('svgElement')[0]) {
        document.getElementsByClassName('focused')[0].getElementsByClassName('svgElement')[0].style.zIndex = layer.styles.zIndex
        document.getElementById('overlay').style.display = 'none'
      }
      
      if (focusedElement) {
        const textarea = focusedElement.getElementsByClassName('editable-text')[0]

        if (textarea) {
          textarea.blur()
        }
      }

      onSetActiveLayer(null)
    }
  }

  handleSetActiveLayer = (layer) => {
    const {onSetActiveLayer} = this.props

    onSetActiveLayer(layer.type)

    this.setState({
      isElementFocused: true,
      activeLayerType: layer.type,
    })
  }

  handleInputChange = ({target: {innerText}}) => {
    // let value = e.target.innerText;
    const { activeLayerType, design } = this.props
    const layerId = design.findIndex((layer) => layer.type === activeLayerType)
    const updatedTemplate = [...design]
    const changedLayer = {...updatedTemplate[layerId]}

    changedLayer.text = innerText
    updatedTemplate[layerId] = changedLayer

    this.setState({
      design: updatedTemplate,
    })
  }

  checkTextSize = () => {
    if (this.inputRef.current) {
      const containerEl = document.getElementById('textarea-wrapper')
      const innerEl = containerEl.firstChild

      const options = {
        innerEl: innerEl,
        containerEl: containerEl,
        multiline: false,
        minFontSizePx: 10,
        maxFontSizePx: parseInt(innerEl.classList[1].split("-")[1].replace("px", "")),
      }

      autoTextSize(options)
    }
  }


  handleInputUpdate = ({target: {innerText, style}}) => {
    // let value = e.target.innerText;
    const { activeLayerType, design, onChangeTemplate, onSetActiveLayer } = this.props
    let side = this.props.side;
    const layerId = design.layers[side].findIndex((layer) => layer.type === activeLayerType)
    const updatedTemplate = {...design}
    const changedLayer = {...updatedTemplate.layers[side][layerId]}
    const changedLayerStyles = {
      ...changedLayer.styles,
    }

    if (style.fontSize.includes('px')) {
      changedLayerStyles.fontSize = (Number(style.fontSize.split('px')[0])/10).toFixed(1) + 'rem'
    }

    changedLayer.styles = changedLayerStyles
    changedLayer.text = innerText
    changedLayer.isTextUpdated = true
  
    onChangeTemplate(changedLayer)
  }


  render() {
    const { onSetActiveLayer, uploadedIcons, className, templateType, activeLayerType, design } = this.props
    const { isElementFocused } = this.state

    const side = this.props.side;

    return (
      <div>
        <div id="design-to-preview">
          <div
            id="template"
            className={`template ${className ? className : ''} ${templateType ? templateType : ''}`}
            ref={this.setWrapperRef}
            style={design.styles}
          >
            {!className && templateType !== TYPES.QR_ONLY && design.subtype &&
              <div className={`template-border ${design.subtype.split('-')[1] === '00' ? 'circle' : ''}`}
                   style={design.styles}/>}
            <div id="overlay"/>
            <div id="preview-layers">

              {design.layers[side].map((layer) => {
                const layerType = layer.type.split('-')[0]

                if (layerType === 'backgroundImage') {
                  return (
                    <img
                      key={layer.type}
                      onClick={() => onSetActiveLayer(null)}
                      src={`${layer.styles.backgroundUploadedImage}`}
                      style={layer.styles}
                      alt={layer.imageName}
                      className={`${layer.qrbackground ? 'qrbackground' : ''}`}
                    />
                  )
                }

                if (layerType === 'background') {
                  if (layer.styles.backgroundSvg && layer.styles.backgroundSvg !== 'none') {
                    let svgStyles
                    const svgImage = layer.styles.backgroundSvg
                    let imageName = svgImage.substring(svgImage.lastIndexOf('/') + 1).split('_')[0]
                    const gradientOrientationValue = Number(layer.gradientOrientation.split('deg')[0])
                    const currentLinearGradientElement = document.getElementById(`${imageName}-gradient`)
                    const svgElement = document.getElementsByClassName(imageName).item(0)
                    const leftGradientColor = layer.leftPosition > layer.rightPosition ? layer.rightGradientColor : layer.leftGradientColor
                    const rightGradientColor = layer.leftPosition > layer.rightPosition ? layer.leftGradientColor : layer.rightGradientColor
                    const leftPosition = layer.leftPosition > layer.rightPosition ? layer.rightPosition : layer.leftPosition
                    const rightPosition = layer.leftPosition > layer.rightPosition ? layer.leftPosition : layer.rightPosition

                    if (currentLinearGradientElement && svgElement) {
                      const svgWidth = svgElement.getAttribute('width')
                      const svgHeight = svgElement.getAttribute('height')

                      currentLinearGradientElement.setAttribute(
                        'gradientTransform',
                        `rotate(${gradientOrientationValue}, ${svgWidth / 2},${svgHeight / 2})`,
                      )

                      currentLinearGradientElement.getElementsByClassName('left').item(0).setAttribute('offset', `${leftPosition}%`)
                      currentLinearGradientElement.getElementsByClassName('left').item(0).setAttribute('offset', `${rightPosition}%`)
                    }

                    if (layer.colorPickerType === 'Linear Gradient') {
                      svgStyles = css({
                        ' path': {
                          fill: `url(#${imageName}-gradient)`,
                        },
                        ' rect': {
                          fill: `url(#${imageName}-gradient)`,
                        },
                        ' polygon': {
                          fill: `url(#${imageName}-gradient)`,
                        },
                        ' circle': {
                          fill: `url(#${imageName}-gradient)`,
                        },
                        ' ellipse': {
                          fill: `url(#${imageName}-gradient)`,
                        },
                        ' linearGradient': {
                          ' .left': {
                            stopColor: leftGradientColor,
                          },
                          ' .right': {
                            stopColor: rightGradientColor,
                          },
                        },
                      })
                    } else if (layer.colorPickerType === 'Image') {
                      svgStyles = css({
                        ' path': {
                          fill: 'transparent',
                        },
                        ' rect': {
                          fill: 'transparent',
                        },
                        ' polygon': {
                          fill: 'transparent',
                        },
                        ' circle': {
                          fill: 'transparent',
                        },
                        ' ellipse': {
                          fill: 'transparent',
                        },
                        ' svg': {
                          mask: `url(${layer.styles.backgroundSvg}) center center / cover`,
                          backgroundImage: `url(${layer.styles.backgroundUploadedImage})`,
                          backgroundColor: layer.styles.color,
                          backgroundPosition: layer.styles.backgroundPosition,
                        },
                      })
                    } else {
                      svgStyles = css({
                        ' path': {
                          fill: layer.styles.color,
                        },
                        ' rect': {
                          fill: layer.styles.color,
                        },
                        ' polygon': {
                          fill: layer.styles.color,
                        },
                        ' circle': {
                          fill: layer.styles.color,
                        },
                        ' ellipse': {
                          fill: layer.styles.color,
                        },
                      })
                    }

                    return (
                      <div
                        key={layer.type}
                        onClick={() => this.handleSetActiveLayer(layer)}
                        className={`template-layer svg-background ${isElementFocused && layer.type === activeLayerType ? 'focused' : ''} ${
                          layer.qrbackground ? 'qrbackground' : ''
                        }`}
                      >
                        <ReactSVG
                          src={layer.styles.backgroundSvg}
                          style={layer.styles}
                          renumerateIRIElements={false}
                          className="svgElement"
                          {...svgStyles}
                        />
                        {layer.styles.uploadedImage && (
                          <img
                            className="resizable-image"
                            src={layer.styles.uploadedImage}
                            alt="resizable"
                            style={{
                              top: layer.overlayStyles.top,
                              left: layer.overlayStyles.left,
                              zIndex: layer.styles.zIndex
                            }}
                          />
                        )}
                      </div>
                    )
                  } else {
                    return (
                      <div
                        key={layer.type}
                        onClick={() => this.handleSetActiveLayer(layer)}
                        className={`template-layer background ${isElementFocused && layer.type === activeLayerType ? 'focused' : ''} ${
                          layer.qrbackground ? 'qrbackground' : ''
                        }`}
                        style={layer.styles}
                      >
                        {/* {layer.styles.backgroundUploadedImage && layer.styles.backgroundUploadedImage !== 'none' &&
                          <img
                            key={layer.type}
                            src={`${layer.styles.backgroundUploadedImage}`}
                            style={{ transform: 'scale(1.5)' }}
                            alt={layer.imageName}
                          />
                        } */}
                        {layer.styles.uploadedImage && (
                          <img
                            className="resizable-image"
                            src={layer.styles.uploadedImage}
                            alt="resizable"
                            style={{
                              top: layer.overlayStyles.top,
                              left: layer.overlayStyles.left,
                              zIndex: layer.styles.zIndex
                            }}
                          />
                        )}
                      </div>
                    )
                  }

                  return (
                    <div
                      key={layer.type}
                      onClick={() => this.handleSetActiveLayer(layer)}
                      className={`template-layer background ${isElementFocused && layer.type === activeLayerType ? 'focused' : ''} ${
                        layer.qrbackground ? 'qrbackground' : ''
                      }`}
                      style={layer.styles}
                    >
                      {/* {layer.styles.backgroundUploadedImage && layer.styles.backgroundUploadedImage !== 'none' &&
                      <img
                        key={layer.type}
                        src={`${layer.styles.backgroundUploadedImage}`}
                        style={{ transform: 'scale(1.5)' }}
                        alt={layer.imageName}
                      />
                    } */}
                    </div>
                  )
                }

                if (layerType === 'text') {
                  const TextAreaOnFirst =
                    <div
                      className="editable-area 1"
                    >
                      {/* make sure we now how contentEditable works: https://stackoverflow.com/questions/49639144/why-does-react-warn-against-an-contenteditable-component-having-children-managed*/}
                      <span
                        suppressContentEditableWarning={true}
                        contentEditable
                        className="editable-text"
                        id="textarea"
                        style={{
                          textAlign: layer.styles.textAlign,
                          color: layer.styles.color,
                          fontSize: layer.styles.fontSize,
                          lineHeight: layer.styles.fontSize,
                          fontWeight: layer.styles.fontWeight,
                          textDecoration: layer.styles.textDecoration,
                          alignSelf: layer.styles.verticalAlign === 'top' ? 'start' :
                            layer.styles.verticalAlign === 'middle' ? 'center' : 'end'
                        }}
                        type="text"
                        spellCheck="false"
                      >
                    {layer.text}
                  </span>
                    </div>

                  const TextAreaOnSecond =
                    <>
                      <div
                        className="editable-area 2"
                        id="textarea-wrapper"
                        style={{cursor: 'text'}}
                        onClick={() => this.inputRef.current.focus()}
                      >
                        {/* make sure we now how contentEditable works: https://stackoverflow.com/questions/49639144/why-does-react-warn-against-an-contenteditable-component-having-children-managed*/}
                        <span
                          suppressContentEditableWarning={true}
                          contentEditable
                          ref={this.inputRef}
                          className={`editable-text fontSize-${Number(layer.styles.fontSize.split('rem')[0]) * 10}`}
                          style={{
                            textAlign: layer.styles.textAlign,
                            color: layer.styles.color,
                            fontSize: layer.styles.fontSize,
                            lineHeight: layer.styles.fontSize,
                            fontWeight: layer.styles.fontWeight,
                            textDecoration: layer.styles.textDecoration,
                            alignSelf: layer.styles.verticalAlign === 'top' ? 'start' :
                              layer.styles.verticalAlign === 'middle' ? 'center' : 'end'
                          }}
                          type="text"
                          spellCheck="false"
                          onChange={(e) => {
                            this.handleInputChange(e);
                          }}
                          onBlur={(e) => this.handleInputUpdate(e)}
                          onInput={this.checkTextSize}
                        >
                      {layer.text}
                    </span>
                      </div>
                    </>

                  return (
                    <div
                      key={layer.type}
                      className={`template-layer text ${layer.qrbackground ? 'qrbackground' : ''} ${layer.type} ${
                        isElementFocused && layer.type === activeLayerType ? 'focused' : ''
                      }`}
                      style={layer.styles}
                      onClick={() => {
                        this.handleSetActiveLayer(layer)
                      }}
                    >
                      {isElementFocused && layer.type === activeLayerType ? TextAreaOnSecond : TextAreaOnFirst}
                    </div>
                  )
                }

                if (layerType === 'icon' && layer.type.split("-").slice(0,2).join("-") !== "icon-qr") {
                  let svgStyles
                  let isUploadedIcon
                  let changedIconLayer = {...layer}
                  const gradientOrientationValue = Number(layer.gradientOrientation.split('deg')[0])

                  if (layer.iconName) {
                    let iconName = layer.iconName.substring(layer.iconName.lastIndexOf('/') + 1).split('_')[0]
                    const currentIconElement = document.getElementsByClassName(`${iconName}`).item(0)
                    let currentLinearGradientElement = document.getElementById(`${iconName}-gradient`)
                    const gradientId = currentLinearGradientElement && currentLinearGradientElement.id

                    if (currentLinearGradientElement && currentIconElement) {
                      let svgWidth = currentIconElement.getAttribute('width')
                      let svgHeight = currentIconElement.getAttribute('height')

                      if (svgWidth && svgHeight && svgWidth.indexOf('px') !== -1 && svgHeight.indexOf('px') !== -1) {
                        svgWidth = svgWidth.split('px')[0]
                        svgHeight = svgHeight.split('px')[0]
                      }

                      currentLinearGradientElement.setAttribute(
                        'gradientTransform',
                        `rotate(${gradientOrientationValue}, ${svgWidth / 2},${svgHeight / 2})`,
                      )
                    }

                    if (layer.colorPickerType === 'Gradient') {
                      svgStyles = css({
                        ' path': {
                          fill: `url(#${iconName}-gradient)`,
                        },
                        ' rect': {
                          fill: `url(#${iconName}-gradient)`,
                        },
                        ' polygon': {
                          fill: `url(#${iconName}-gradient)`,
                        },
                        ' circle': {
                          fill: `url(#${iconName}-gradient)`,
                        },
                        ' ellipse': {
                          fill: `url(#${iconName}-gradient)`,
                        },
                        ' linearGradient': {
                          ' .left': {
                            stopColor: layer.leftGradientColor,
                          },
                          ' .right': {
                            stopColor: layer.rightGradientColor,
                          },
                        },
                      })
                    } else if (layer.colorPickerType === 'Solid color') {
                      svgStyles = css({
                        ' path': {
                          fill: layer.styles.color,
                        },
                        ' rect': {
                          fill: layer.styles.color,
                        },
                        ' polygon': {
                          fill: layer.styles.color,
                        },
                        ' circle': {
                          fill: layer.styles.color,
                        },
                        ' ellipse': {
                          fill: layer.styles.color,
                        },
                      })
                    }


                    isUploadedIcon = layer.iconName.split('.')[1] !== undefined

                    if ((layer.colorPickerType === 'Colorful' && iconName !== 'qr') ||
                      ((layer.colorPickerType  === 'Solid color' ||  layer.colorPickerType === 'Gradient') && iconName === 'qr')) {
                      changedIconLayer.iconName = null
                    }

                    if (changedIconLayer.iconName) {
                      isUploadedIcon = changedIconLayer.iconName.split('.')[1] !== undefined

                      if (uploadedIcons && isUploadedIcon) {
                        const currentIcon = uploadedIcons.find((icon) => icon.name === changedIconLayer.iconName)
    
                        if (currentIcon) {
                          return (
                            <div
                              key={layer.type}
                              onClick={() => this.handleSetActiveLayer(layer)}
                              className={`template-layer icon ${isElementFocused && layer.type === activeLayerType ? 'focused' : ''} ${
                                layer.qrbackground ? 'qrbackground' : ''
                              }`}
                              style={layer.styles}
                            >
                              {changedIconLayer.iconName && changedIconLayer.iconName.split('.')[1] === 'svg' ? (
                                // <ReactSVG src={svg} {...svgStyles} />
                                <img src={currentIcon.icon} alt={currentIcon.name}/>
                              ) : (
                                <img src={currentIcon.icon} alt={currentIcon.name}/>
                              )}
                            </div>
                          )
                        }
                      }
                    }
                  }

                  return (
                    <div
                      key={layer.type}
                      onClick={() => this.handleSetActiveLayer(layer)}
                      className={`template-layer icon ${layer.type} ${isElementFocused && layer.type === activeLayerType ? 'focused' : ''} ${
                        layer.qrbackground ? 'qrbackground' : ''
                      }`}
                      style={layer.styles}
                    >
                      {changedIconLayer.iconName &&
                        <ReactSVG
                          src={changedIconLayer.iconName}
                          renumerateIRIElements={false}
                          {...svgStyles} />
                      }
                    </div>
                  )
                }

                if (layerType === 'qr') {
                  let qrIconLayer = design.layers[side].find(itemLayer => itemLayer.type.split("-").slice(0,2).join("-") === "icon-qr" && layer.type.split("-").pop() == itemLayer.type.split("-").pop())
                  return (
                    <div
                      key={layer.type}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.handleSetActiveLayer(layer)
                      }}
                      className={`template-layer qr ${layer.type} ${isElementFocused && layer.type === activeLayerType ? 'focused' : ''} ${
                        layer.qrbackground ? 'qrbackground' : ''
                      }`}
                      style={layer.styles}
                    >
                      <QRCode
                        data={layer}
                        handleSetActiveLayer={this.handleSetActiveLayer}
                        isElementFocused={isElementFocused} activeLayerType={activeLayerType}
                        qrIconLayer={qrIconLayer}
                        uploadedIcons={uploadedIcons}
                      />
                    </div>
                  )
                }
              })}
            </div>

          </div>
        </div>
        {/*<div id="platno-to-preview" style={{
          width: '500px',
          height: '300px',
          position: 'relative',
          left: '250px'
        }}>

        </div>*/}
      </div>
    )
  }
}

const mapState = (state) => ({
  activeLayerType: getActiveLayerType(state),
  design: getActiveDesign(state),
  updateStatus: getUpdateStatus(state),
  uploadedIcons: getUploadedIcons(state),
  resetStatus: getResetStatus(state),
  templateType: getTemplateType(state),
  side: getActiveSide(state)
})

const mapDispatch = (dispatch) => ({
  onSetActiveLayer: (layer) => dispatch(setActiveLayer(layer)),
  onChangeTemplate: (layer) => dispatch(updateDesign(layer)),
})

export default connect(mapState, mapDispatch)(Design)
