import React from 'react'

//works only for RELATIVE path and (0 0 100 100 ViewBox)
function resize(x, y, scale, path) {
  let arr = path.match(/[\d\.]+|\D+/g).map((item, index) => {
    let parsed = parseFloat(item)
    let isNumber = !isNaN(parsed)
    if (!isNumber) return item
    if (isNumber) parsed = parsed * scale
    if (index === 1) parsed += x
    if (index === 3) parsed += y
    return parsed
  })
  return arr.join('')
}

const SharpCorner = (x, y, scale, key) => (
  <g key={key}>
    <path
      d={resize(
        x,
        y,
        scale,
        'M66.25,100h-32.47c-18.62,0,-33.76,-15.32,-33.76,-34.14l-0.02,-65.86h66.25c18.61,0,33.75,15.32,33.75,34.14v31.72v34.14h-33.75zm18.75,-65.86c0,-10.554,-8.411,-19.14,-18.75,-19.14h-51.246l0.016,50.855c0,10.559,8.416,19.145,18.76,19.145h51.22v-50.86z',
      )}
    />
    <path
      d={resize(
        x,
        y,
        scale,
        'M59.7475,71.4374h-19.506c-1.003,0,-1.977,-0.127,-2.902,-0.375c-2.049,-0.538,-3.875,-1.626,-5.322,-3.101c-0.265,-0.266,-0.517,-0.55,-0.751,-0.84c-1.664,-2.043,-2.662,-4.672,-2.662,-7.526l-0.012,-31.015h31.155c6.416,0,11.63,5.313,11.63,11.841v19.168v0.006l0.073,11.836c0,0,-7.69,0.006,-11.703,0.006z',
      )}
    />
  </g>
)
const SharpFrame = (x, y, scale, key) => (
  <g key={key}>
    <path
      d={resize(
        x,
        y,
        scale,
        'M66.25,100h-32.47c-18.62,0,-33.76,-15.32,-33.76,-34.14l-0.02,-65.86h66.25c18.61,0,33.75,15.32,33.75,34.14v31.72v34.14h-33.75zm18.75,-65.86c0,-10.554,-8.411,-19.14,-18.75,-19.14h-51.246l0.016,50.855c0,10.559,8.416,19.145,18.76,19.145h51.22v-50.86z',
      )}
    />
  </g>
)
const SharpBall = (x, y, scale, key) => (
  <g key={key}>
    <path
      d={resize(
        x,
        y,
        scale,
        'M59.7475,71.4374h-19.506c-1.003,0,-1.977,-0.127,-2.902,-0.375c-2.049,-0.538,-3.875,-1.626,-5.322,-3.101c-0.265,-0.266,-0.517,-0.55,-0.751,-0.84c-1.664,-2.043,-2.662,-4.672,-2.662,-7.526l-0.012,-31.015h31.155c6.416,0,11.63,5.313,11.63,11.841v19.168v0.006l0.073,11.836c0,0,-7.69,0.006,-11.703,0.006z',
      )}
    />
  </g>
)
const SharpCornerReverse = (x, y, scale, key) => (
  <g key={key}>
    <path
      d={resize(
        x,
        y,
        scale,
        'M66.25,0h-32.47c-18.62,0,-33.76,15.32,-33.76,34.14l-0.02,65.86h66.25c18.61,0,33.75,-15.32,33.75,-34.14v-31.72v-34.14h-33.75zm18.75,65.86c0,10.554,-8.411,19.14,-18.75,19.14h-51.246l0.016,-50.855c0,-10.559,8.416,-19.145,18.76,-19.145h51.22v50.86z',
      )}
    />
    <path
      d={resize(
        x,
        y,
        scale,
        'M59.7476,28.5624h-19.506c-1.003,0,-1.977,0.127,-2.902,0.375c-2.048,0.538,-3.875,1.626,-5.322,3.101c-0.265,0.266,-0.517,0.55,-0.751,0.84c-1.664,2.043,-2.662,4.673,-2.662,7.526l-0.012,31.015h31.155c6.416,0,11.63,-5.313,11.63,-11.841v-19.168v-0.006l0.072,-11.836c0,0,-7.689,-0.006,-11.702,-0.006z',
      )}
    />
  </g>
)
const SharpFrameReverse = (x, y, scale, key) => (
  <g key={key}>
    <path
      d={resize(
        x,
        y,
        scale,
        'M66.25,0h-32.47c-18.62,0,-33.76,15.32,-33.76,34.14l-0.02,65.86h66.25c18.61,0,33.75,-15.32,33.75,-34.14v-31.72v-34.14h-33.75zm18.75,65.86c0,10.554,-8.411,19.14,-18.75,19.14h-51.246l0.016,-50.855c0,-10.559,8.416,-19.145,18.76,-19.145h51.22v50.86z',
      )}
    />
  </g>
)
const SharpBallReverse = (x, y, scale, key) => (
  <g key={key}>
    <path
      d={resize(
        x,
        y,
        scale,
        'M59.7476,28.5624h-19.506c-1.003,0,-1.977,0.127,-2.902,0.375c-2.048,0.538,-3.875,1.626,-5.322,3.101c-0.265,0.266,-0.517,0.55,-0.751,0.84c-1.664,2.043,-2.662,4.673,-2.662,7.526l-0.012,31.015h31.155c6.416,0,11.63,-5.313,11.63,-11.841v-19.168v-0.006l0.072,-11.836c0,0,-7.689,-0.006,-11.702,-0.006z',
      )}
    />
  </g>
)
const RoundCorner = (x, y, scale, key) => (
  <g key={key}>
    <path
      d={resize(
        x,
        y,
        scale,
        'M65.8592,0h-31.718c-15.458,0,-28.554,10.215,-32.741,24.176c-0.433,1.444,-0.771,2.928,-1.006,4.446c-0.259,1.674,-0.394,3.388,-0.394,5.133v32.477c0,18.622,15.32,33.765,34.141,33.765l15.859,0.003l15.859,-0.004c18.82,0,34.141,-15.143,34.141,-33.765v-32.476c0,-18.613,-15.32,-33.755,-34.141,-33.755zm19.141,66.232c0,10.345,-8.586,18.763,-19.145,18.763l-15.855,0.003l-15.855,-0.004c-10.559,0,-19.145,-8.418,-19.145,-18.762v-32.477c0,-3.231,0.838,-6.274,2.313,-8.932c1.42,-2.557,3.429,-4.757,5.848,-6.422c3.11,-2.141,6.897,-3.399,10.979,-3.399h31.719c10.555,0,19.141,8.413,19.141,18.753v32.477z',
      )}
    />
    <path
      d={resize(
        x,
        y,
        scale,
        'M40.2936,71.4322c-6.467,0,-11.728,-5.158,-11.728,-11.491v-19.888c0,-1.979,0.514,-3.841,1.415,-5.47c0.869,-1.568,2.1,-2.916,3.583,-3.932c1.904,-1.311,4.225,-2.083,6.724,-2.083h19.425c6.461,0,11.722,5.151,11.722,11.485v19.888c0,6.333,-5.26,11.491,-11.721,11.491h-9.713h-9.707v0z',
      )}
    />
  </g>
)
const RoundFrame = (x, y, scale, key) => (
  <g key={key}>
    <path
      d={resize(
        x,
        y,
        scale,
        'M65.8592,0h-31.718c-15.458,0,-28.554,10.215,-32.741,24.176c-0.433,1.444,-0.771,2.928,-1.006,4.446c-0.259,1.674,-0.394,3.388,-0.394,5.133v32.477c0,18.622,15.32,33.765,34.141,33.765l15.859,0.003l15.859,-0.004c18.82,0,34.141,-15.143,34.141,-33.765v-32.476c0,-18.613,-15.32,-33.755,-34.141,-33.755zm19.141,66.232c0,10.345,-8.586,18.763,-19.145,18.763l-15.855,0.003l-15.855,-0.004c-10.559,0,-19.145,-8.418,-19.145,-18.762v-32.477c0,-3.231,0.838,-6.274,2.313,-8.932c1.42,-2.557,3.429,-4.757,5.848,-6.422c3.11,-2.141,6.897,-3.399,10.979,-3.399h31.719c10.555,0,19.141,8.413,19.141,18.753v32.477z',
      )}
    />
  </g>
)
const RoundBall = (x, y, scale, key) => (
  <g key={key}>
    <path
      d={resize(
        x,
        y,
        scale,
        'M40.2936,71.4322c-6.467,0,-11.728,-5.158,-11.728,-11.491v-19.888c0,-1.979,0.514,-3.841,1.415,-5.47c0.869,-1.568,2.1,-2.916,3.583,-3.932c1.904,-1.311,4.225,-2.083,6.724,-2.083h19.425c6.461,0,11.722,5.151,11.722,11.485v19.888c0,6.333,-5.26,11.491,-11.721,11.491h-9.713h-9.707v0z',
      )}
    />
  </g>
)
const CircleCorner = (x, y, scale, key) => (
  <g key={key}>
    <path
      d={resize(
        x,
        y,
        scale,
        'M50,0c-27.613,0,-50,22.387,-50,50c0,27.613,22.387,50,50,50c27.613,0,50,-22.387,50,-50c0,-27.613,-22.387,-50,-50,-50zm0,85c-19.299,0,-35,-15.701,-35,-35c0,-19.299,15.701,-35,35,-35c19.299,0,35,15.701,35,35c0,19.299,-15.701,35,-35,35z',
      )}
    />
    <path
      d={resize(
        x,
        y,
        scale,
        'M49.5716,70.9999c11.835,0,21.428,-9.594,21.428,-21.428c0,-11.835,-9.593,-21.429,-21.428,-21.429c-11.835,0,-21.429,9.594,-21.429,21.429c0,11.834,9.594,21.428,21.429,21.428z',
      )}
    />
  </g>
)
const CircleFrame = (x, y, scale, key) => (
  <g key={key}>
    <path
      d={resize(
        x,
        y,
        scale,
        'M50,0c-27.613,0,-50,22.387,-50,50c0,27.613,22.387,50,50,50c27.613,0,50,-22.387,50,-50c0,-27.613,-22.387,-50,-50,-50zm0,85c-19.299,0,-35,-15.701,-35,-35c0,-19.299,15.701,-35,35,-35c19.299,0,35,15.701,35,35c0,19.299,-15.701,35,-35,35z',
      )}
    />
  </g>
)
const CircleBall = (x, y, scale, key) => (
  <g key={key}>

    <path
      d={resize(
        x,
        y,
        scale,
        'M49.5716,70.9999c11.835,0,21.428,-9.594,21.428,-21.428c0,-11.835,-9.593,-21.429,-21.428,-21.429c-11.835,0,-21.429,9.594,-21.429,21.429c0,11.834,9.594,21.428,21.429,21.428z',
      )}
    />
  </g>
)
const ClassicCorner = (x, y, scale, key) => (
  <g key={key}>
    <path d={resize(x, y, scale, 'M85,0h-70h-15v15v70v15h15h70h15v-15v-70v-15h-15zm0,85h-70v-70h70v70z')} />
    <path d={resize(x, y, scale, 'M71.4284,28.5714h-42.857v42.857h42.857v-42.857z')} />
  </g>
)
const SquareFrame = (x, y, scale, key) => (
  <g key={key}>
    <path d={resize(x, y, scale, 'M85,0h-70h-15v15v70v15h15h70h15v-15v-70v-15h-15zm0,85h-70v-70h70v70z')} />
  </g>
)
const SquareBall = (x, y, scale, key) => (
  <g key={key}>
    <path d={resize(x, y, scale, 'M71.4284,28.5714h-42.857v42.857h42.857v-42.857z')} />
  </g>
)
const RoundGrid = {
  fit: (x, y, scale, key) => (
    <g key={key}>
      <path d={resize(x, y, scale, 'M100,0h-100v100h100v-100z')} />
    </g>
  ),
  alone: (x, y, scale, key) => (
    <g key={key}>
      <path
        d={resize(
          x,
          y,
          scale,
          'M50,0c27.614,0,50,22.386,50,50c0,27.614,-22.386,50,-50,50c-27.614,0,-50,-22.386,-50,-50c0,-27.614,22.386,-50,50,-50z',
        )}
      />
    </g>
  ),
  top: (x, y, scale, key) => (
    <g key={key}>
      <path
        d={resize(
          x,
          y,
          scale,
          'M100,50c0,11.653,0,50,0,50c0,0,-34.04,0,-50,-0.002c-12.466,0.002,-50,0,-50,0c0,0,0,-34.849,0,-49.999c0,-27.613,22.386,-49.999,50,-49.999c27.611,0,50,22.386,50,50z',
        )}
      />
    </g>
  ),
  bottom: (x, y, scale, key) => (
    <g key={key}>
      <path
        d={resize(
          x,
          y,
          scale,
          'M0,50c0,-11.654,0,-50,0,-50c0,0,34.04,0,50,0c12.466,0,50,0,50,0c0,0,0,34.851,0,50c0,27.611,-22.389,50,-50,50c-27.614,0,-50,-22.389,-50,-50z',
        )}
      />
    </g>
  ),
  right: (x, y, scale, key) => (
    <g key={key}>
      <path
        d={resize(
          x,
          y,
          scale,
          'M50,100c-11.654,0,-50,0,-50,0c0,0,0,-34.04,0,-50c0,-12.466,0,-50,0,-50c0,0,34.851,0,50,0c27.6,0,50,22.386,50,50c0,27.611,-22.4,50,-50,50z',
        )}
      />
    </g>
  ),
  left: (x, y, scale, key) => (
    <g key={key}>
      <path
        d={resize(
          x,
          y,
          scale,
          'M50,0c11.654,0,50,0,50,0c0,0,0,34.036,0,50c0,12.461,0,50,0,50c0,0,-34.843,0,-50,0c-27.623,0,-50,-22.396,-50,-50c0,-27.607,22.377,-50,50,-50z',
        )}
      />
    </g>
  ),
  bottomleft: (x, y, scale) => (
    <g>
      <path d={resize(x, y, scale, 'M0,0v100h100c0,-55.229,-44.771,-100,-100,-100z')} />
    </g>
  ),
  topleft: (x, y, scale) => (
    <g>
      <path d={resize(x, y, scale, 'M100,0h-100v100c55.229,0,100,-44.771,100,-100z')} />
    </g>
  ),
  bottomright: (x, y, scale, key) => (
    <g key={key}>
      <path d={resize(x, y, scale, 'M0,100h100v-100c-55.229,0,-100,44.771,-100,100z')} />
    </g>
  ),
  topright: (x, y, scale, key) => (
    <g key={key}>
      <path d={resize(x, y, scale, 'M100,100v-100h-100c0,55.229,44.771,100,100,100z')} />
    </g>
  ),
}
const CircleGrid = {
  fit: (x, y, scale, key) => (
    <g key={key}>
      <path d={resize(x, y, scale, 'M100,0h-100v100h100v-100z')} />
    </g>
  ),
  alone: (x, y, scale, key) => (
    <g key={key}>
      <path
        d={resize(
          x,
          y,
          scale,
          'M50,0c27.614,0,50,22.386,50,50c0,27.614,-22.386,50,-50,50c-27.614,0,-50,-22.386,-50,-50c0,-27.614,22.386,-50,50,-50z',
        )}
      />
    </g>
  ),
  top: (x, y, scale, key) => (
    <g key={key}>
      <path d={resize(x, y, scale, 'M100,100h-100c0,0,31.285,-65.356,3.928,-99.998c0,0,96.072,-1.713,96.072,99.998z')} />
    </g>
  ),
  bottom: (x, y, scale, key) => (
    <g key={key}>
      <path d={resize(x, y, scale, 'M0,0h99.998c0,0,-31.292,65.364,-3.935,99.998c0,0,-96.063,1.715,-96.063,-99.998z')} />
    </g>
  ),
  right: (x, y, scale, key) => (
    <g key={key}>
      <path d={resize(x, y, scale, 'M0,100v-100c0,0,65.358,31.285,100,3.928c0,0,1.725,96.072,-100,96.072z')} />
    </g>
  ),
  left: (x, y, scale, key) => (
    <g key={key}>
      <path d={resize(x, y, scale, 'M100.002,0v100c0,0,-65.363,-31.293,-100,-3.935c0,0,-1.72,-96.065,100,-96.065z')} />
    </g>
  ),
  bottomleft: (x, y, scale, key) => (
    <g key={key}>
      <path d={resize(x, y, scale, 'M99.9925,100h-99.993v-99.793v-0.207h24.5c41.708,0,75.493,44.773,75.493,100z')} />
    </g>
  ),
  topleft: (x, y, scale, key) => (
    <g key={key}>
      <path d={resize(x, y, scale, 'M0,100v-100h99.793h0.207v24.5c0,41.714,-44.773,75.5,-100,75.5z')} />
    </g>
  ),
  bottomright: (x, y, scale, key) => (
    <g key={key}>
      <path d={resize(x, y, scale, 'M99.9924,0v100h-99.785h-0.207v-24.507c0,-41.707,44.765,-75.493,99.992,-75.493z')} />
    </g>
  ),
  topright: (x, y, scale, key) => (
    <g key={key}>
      <path d={resize(x, y, scale, 'M0,0h100v99.786v0.207l-24.507,0.007c-41.7,0,-75.493,-44.772,-75.493,-100z')} />
    </g>
  ),
}
const SharpGrid = {
  fit: (x, y, scale, key) => (
    <g key={key}>
      <path d={resize(x, y, scale, 'M100,0h-100v100h100v-100z')} />
    </g>
  ),
  alone: (x, y, scale, key) => (
    <g key={key}>
      <path
        d={resize(
          x,
          y,
          scale,
          'M100,65.625c0,9.506,-3.355,17.611,-10.06,24.316c-6.705,6.705,-14.81,10.059,-24.315,10.059h-31.25c-9.505,0,-17.61,-3.354,-24.315,-10.059c-6.705,-6.705,-10.06,-14.81,-10.06,-24.316v-31.25c0,-9.505,3.352,-17.611,10.06,-24.316c6.705,-6.705,14.81,-10.059,24.315,-10.059h31.25c9.505,0,17.61,3.353,24.315,10.059c6.705,6.706,10.06,14.811,10.06,24.316v31.25z',
        )}
      />
    </g>
  ),
  top: (x, y, scale, key) => (
    <g key={key}>
      <path d={resize(x, y, scale, 'M100,40.625v59.375h-100v-59.375l50,-40.625l50,40.625z')} />
    </g>
  ),
  bottom: (x, y, scale, key) => (
    <g key={key}>
      <path d={resize(x, y, scale, 'M100,59.375v-59.375h-100v59.375l50,40.625l50,-40.625z')} />
    </g>
  ),
  right: (x, y, scale, key) => (
    <g key={key}>
      <path d={resize(x, y, scale, 'M59.375,0h-59.375v100h59.375l40.625,-50l-40.625,-50z')} />
    </g>
  ),
  left: (x, y, scale, key) => (
    <g key={key}>
      <path d={resize(x, y, scale, 'M40.625,0h59.375v100h-59.375l-40.625,-50l40.625,-50z')} />
    </g>
  ),
  bottomleft: (x, y, scale, key) => (
    <g key={key}>
      <path
        d={resize(
          x,
          y,
          scale,
          'M100,34.375c0,-9.505,-3.355,-17.61,-10.06,-24.315c-6.705,-6.705,-14.81,-10.06,-24.315,-10.06h-65.625v100h100v-65.625z',
        )}
      />
    </g>
  ),
  topleft: (x, y, scale, key) => (
    <g key={key}>
      <path d={resize(x, y, scale, 'M100,0h-100v100h65.625c9.505,0,17.61,-3.354,24.315,-10.059c6.705,-6.705,10.06,-14.81,10.06,-24.316v-65.625z')} />
    </g>
  ),
  bottomright: (x, y, scale, key) => (
    <g key={key}>
      <path
        d={resize(
          x,
          y,
          scale,
          'M100,34.375v-34.375h-65.625c-9.505,0,-17.61,3.352,-24.315,10.06c-6.705,6.705,-10.06,14.81,-10.06,24.315v65.625h100v-65.625z',
        )}
      />
    </g>
  ),
  topright: (x, y, scale, key) => (
    <g key={key}>
      <path d={resize(x, y, scale, 'M100,0h-100v65.625c0,9.505,3.352,17.61,10.06,24.315c6.705,6.705,14.81,10.06,24.315,10.06h65.625v-100z')} />
    </g>
  ),
}

export { SharpCorner, SharpBall, SharpBallReverse, SharpFrame, SharpFrameReverse, RoundBall, RoundFrame, CircleBall, CircleFrame, SquareBall, SquareFrame, SharpCornerReverse, RoundCorner, CircleCorner, ClassicCorner, RoundGrid, CircleGrid, SharpGrid }
