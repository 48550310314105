import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'

import DesignTab from './designTab/DesignTab'
import FormatTab from './formatTab/FormatTab'

import design_icon from '../common/images/design-icon.svg'
import qrcontent_icon from '../common/images/qrcontent-icon.svg'
import arrow_icon from '../common/images/elements/arrow_active.svg'
import { ReactSVG } from 'react-svg'
import { FormattedMessage } from 'react-intl'

import './QRCodeCustomizationSection.scss'

function QRCodeCustomizationSection({ activeLayer, setDynamicQrType, setActiveMicroPageType }) {
  const [formatTabSelected, setFormatTab] = useState(activeLayer.object.code === "")
  const lang = useSelector((store) => store.intl.currentLocale)

  const toggleTab = () => {
    setFormatTab(!formatTabSelected)
    setDynamicQrType("")
    setActiveMicroPageType(activeLayer.object.type)
  }

  const Tabs = useMemo(() => {
    let first = formatTabSelected
    return (
      <div className="tab-toggler" onClick={toggleTab}>
        <ReactSVG src={arrow_icon} className={`arrow ${first ? 'left' : 'right'}`}></ReactSVG>
        <ReactSVG src={first ? design_icon : qrcontent_icon} className={`icon ${first ? 'right' : 'left'}`}></ReactSVG>
        <span className={`${first ? 'right' : 'left'}`}>
          {first ? (
            <FormattedMessage id="qr.design" />
          ) : (
            <>
              <FormattedMessage id="qr.content" />
              {`: ${(activeLayer && activeLayer.object && activeLayer.object.name[lang === 'en' ? 0 : 1]) || ''}`}
            </>
          )}
        </span>
      </div>
    )
  }, [formatTabSelected])

  return (
    <div className="qrcode-customization-section">
      {Tabs}
      {formatTabSelected ? <FormatTab activeLayer={activeLayer} setDynamicQrType={setDynamicQrType} /> : <DesignTab activeLayer={activeLayer} />}
    </div>
  )
}
export default QRCodeCustomizationSection
