import {SIDES, TYPES} from "../../utils/constants";
import {Design, Template, Type, Subtype} from "../../ts/types"

export const getDynamicQrUrl = (state) => state.card.editor.dynamicQrUrl

export const getIsDynamicQrFormatActive = (state) => state.card.editor.isDynamicQrFormatActive

export const getActiveDynamicQrCodeId = (state) => state.card.editor.activeDynamicQrCodeId

export const getActiveMicroPageType = (state) => state.card.editor.activeMicroPageType

export const getActiveTemplates = (state) => {
  const templateSubtype = getTemplateSubtype(state)
  const templateType = getTemplateType(state)
  const cardSide = getActiveSide(state)
  const templatesCount = getTemplatesCount(state)

  if (templateType !== TYPES.QR_ONLY) {
    if (cardSide === SIDES.BACK) {
      return state.card.activeTemplates.filter(template => template.subtype === templateSubtype)
    }
    return state.card.activeTemplates.filter(template => template.subtype === templateSubtype)
  }

  return state.card.activeTemplates.slice(0, templatesCount)
}
export const getNameOfCurrentDesignInEditor = (state) => state.card.editor.design ? state.card.editor.design.name : '';
export const getEditorMode = (state) => state.card.editor.mode;
export const getInitialTemplates = (state) => state.card.initialTemplates;

export const getFirstTemplateByTypeAndSubtype = (state) => {
  const subtype = getTemplateSubtype(state)
  const type = getTemplateType(state)

  let templatesByType;

  if (type === TYPES.B_CARD) {
    templatesByType = state.card.cardTemplates;
  }

  if (type === TYPES.STICKER) {
    templatesByType = state.card.stickerTemplates;
  }

  if (type === TYPES.LABEL) {
    templatesByType = state.card.labelTemplates;
  }

  if (type === TYPES.QR_ONLY) {
    templatesByType = state.card.qrTemplates;
    return templatesByType[0];
  }

  return templatesByType.filter(template => template.subtype === subtype)[0]

}

export const getActiveDesign = (state): Design => state.card.editor.design

export const getActiveTemplate = (state): Template => state.card.editor.activeTemplate

export const getActiveLayerType = (state) => state.card.editor.activeLayerType

export const getUpdateStatus = (state) => state.card.updateStatus

export const getResetStatus = (state) => state.card.resetStatus

export const getUploadedIcons = (state) => state.card.uploadedIcons

export const getRecentColors = (state) => state.card.recentColors

export const getRecentFonts = (state) => state.card.recentFonts

export const getUserData = (state) => state.card.userData

export const getActiveSide = (state) => state.card.editor.activeSide

export const getTemplateType = (state): Type => state.card.editor.templateType

export const getTemplateSubtype = (state): Subtype => state.card.editor.templateSubtype

export const getImageCount = (state): number => state.card.imageCount

export const getTemplatesCount = (state): number => state.card.templatesCount

export const getIcons = (state) => state.card.icons

export const getQrIcons = (state) => state.card.qrIcons ?? []

export const getCardTemplates = (state) => state.card.cardTemplates

export const getStickerTemplates = (state) => state.card.stickerTemplates

export const getLabelTemplates = (state) => state.card.labelTemplates

export const getQrTemplates = (state) => state.card.qrTemplates

export const getCurrentActiveVisual = (state) => {
  const currentTemplate = {...getActiveTemplate(state)}

  const side = getActiveSide(state)

  const activeVisualId = currentTemplate.visuals

  if (activeVisualId && currentTemplate[activeVisualId]) {
    const activeVisual = [...currentTemplate[activeVisualId]]

    if (activeVisual.length > 0) {
      return activeVisual
    }
  }

  return null
}

export const getActiveLayer = (state) => {
  const activeLayerType = getActiveLayerType(state)
  const activeDesign = getActiveDesign(state)

  const side = getActiveSide(state);

  let layerId;
  if (activeLayerType) {
    layerId = activeDesign.layers[side].findIndex(layer => layer.type === activeLayerType)
  }

  // fallback is not found
  if (layerId === -1) {
    layerId = 0
  }
  return activeDesign.layers[side][layerId]
}

export const getImages = (state) => {
  const images = state.card.images
  const imageCount = getImageCount(state)

  return images.slice(0, imageCount)
}

export const getTemplatesByTemplateType = (templates, type) => {
  let filteredTemplates = []

  templates.forEach((template) => {
     if (template.type === type) {
       // @ts-ignore
       filteredTemplates.push(template)
     }
  });

  return filteredTemplates
}
