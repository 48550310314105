import React, { useMemo, useState } from 'react'
import { QrCode } from 'javascript-qrcode'
import * as R from 'ramda'
import { css } from 'glamor'
import { ReactSVG } from 'react-svg'



//for generating special grids
import getNeighbourConf from './getNeighbourConf'
import qrSmoothTypeConf from './qrSmoothTypeConf'

//corners and grids
import {SharpBall, SharpBallReverse, SharpFrame, SharpFrameReverse, RoundBall, RoundFrame, CircleBall, CircleFrame, SquareBall, SquareFrame, SharpCorner, SharpCornerReverse, RoundCorner, CircleCorner, ClassicCorner, RoundGrid, CircleGrid, SharpGrid } from './Elements'

import './QRCode.scss'
import { active } from 'glamor'

function QRCode({
  data: {
    object,
    icon,
    designType,
    customDesignConfig = {body: {
      shape: "square",
      color: "#000000"
    },
    frame: {
      shape:"square",
      color: "#000000"
    },
    ball: {
      shape:"square",
      color: "#000000"
    }},
    colorPickerType,
    solidColor,
    leftGradientColor,
    rightGradientColor,
    gradientOrientation,
    radialGradientOrientation,
    leftPosition,
    rightPosition,
  }, qrIconLayer, isElementFocused, activeLayerType, handleSetActiveLayer, uploadedIcons
}) {
  const [colorId, setColorId] = useState('')
  //define basic info
  var code = 'www.qrcards.com'
  if (object && object.code) code = object.code

  const error = object?.error || ''

  const qr = new QrCode(code, ['H'])
  const qrData = qr.getData()
  const qrIdPointSize = qrData[0].join('').split('0')[0].length
  const squareWidth = 100 / qrData.length
  const qrSize = qrData.length

  //checking if the area is in a corner
  const isPointInIDArea = (rowIndex, colIndex) =>
  // left top corner
  (rowIndex < qrIdPointSize && colIndex < qrIdPointSize) ||
  // right top corner
  (rowIndex < qrIdPointSize && colIndex >= qrSize - qrIdPointSize) ||
  // left bottom corner
  (rowIndex >= qrSize - qrIdPointSize && colIndex < qrIdPointSize)

  //component for COLORS
  const backgroundColorElement = useMemo(() => {
    let newId = Date.now()
    setColorId(newId)

    if (colorPickerType.includes('Radial')) {
      //radial
      let isLeft = radialGradientOrientation === 'left'
      //left
      return (
        <radialGradient key="rg" id={newId} x1="0%" y1="0%" x2="0%" y2="100%" gradientUnits="userSpaceOnUse">
          {/* <stop offset="10%" stopColor={isLeft ? leftGradientColor : rightGradientColor} />
          <stop offset="95%" stopColor={isLeft ? rightGradientColor : leftGradientColor} /> */}
          {Number(leftPosition) > Number(rightPosition) ? (
            <>
              <stop offset={`${rightPosition}%`} stopColor={isLeft ? rightGradientColor : leftGradientColor} />
              <stop offset={`${leftPosition}%`} stopColor={isLeft ? leftGradientColor : rightGradientColor} />
            </>
          ) : (
            <>
              <stop offset={`${leftPosition}%`} stopColor={isLeft ? rightGradientColor : leftGradientColor} />
              <stop offset={`${rightPosition}%`} stopColor={isLeft ? leftGradientColor : rightGradientColor} />
            </>
          )}
        </radialGradient>
      )
    } else if (colorPickerType.includes('Linear')) {
      //linear
      let anglePI = (360 - Number(gradientOrientation ? gradientOrientation.replace('deg', '') : 0)) * (Math.PI / 180)
      let angleCoords = {
        x1: Math.round(50 + Math.sin(anglePI) * 50) + '%',
        y1: Math.round(50 + Math.cos(anglePI) * 50) + '%',
        x2: Math.round(50 + Math.sin(anglePI + Math.PI) * 50) + '%',
        y2: Math.round(50 + Math.cos(anglePI + Math.PI) * 50) + '%',
      }
      let { x1, y1, x2, y2 } = angleCoords
      return (
        <linearGradient key="lg" id={newId} x1={x1} y1={y1} x2={x2} y2={y2} gradientUnits="userSpaceOnUse">
          {Number(leftPosition) > Number(rightPosition) ? (
            <>
              <stop offset={`${rightPosition}%`} stopColor={rightGradientColor} />
              <stop offset={`${leftPosition}%`} stopColor={leftGradientColor} />
            </>
          ) : (
            <>
              <stop offset={`${leftPosition}%`} stopColor={leftGradientColor} />
              <stop offset={`${rightPosition}%`} stopColor={rightGradientColor} />
            </>
          )}
        </linearGradient>
      )
    } else {
      //solid
      return (
        <linearGradient key="sc" id={newId} x1="0%" y1="0%" x2="0%" y2="100%" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stopColor={solidColor} />
        </linearGradient>
      )
    }
  }, [
    colorPickerType,
    solidColor,
    leftGradientColor,
    rightGradientColor,
    gradientOrientation,
    radialGradientOrientation,
    leftPosition,
    rightPosition,
  ])

  //component for INNER GRID
  const rectanglesElement = useMemo(() => {
    const neighbourConf = getNeighbourConf(qrData)
    const currShape = neighbourConf.map((row) =>
      row.map((neighborsColConf) => qrSmoothTypeConf.find((shape) => R.equals(shape.conf, neighborsColConf))),
    )
    let specialElements
    if (colorPickerType === "Custom") {
      let designType = customDesignConfig.body.shape
      specialElements = designType === 'sharp' ? SharpGrid : designType === 'round' ? RoundGrid : designType === 'circle' ? CircleGrid : null
    } else {
      specialElements = designType === 'sharp' ? SharpGrid : designType === 'round' ? RoundGrid : designType === 'circle' ? CircleGrid : null

    }

    return (
      <>
        {currShape.map((row, rowIndex) =>
          row.map((cell, colIndex) => {
            if (isPointInIDArea(rowIndex, colIndex)) {
              return null
            }
            //fill special square
            if (cell && specialElements)
              return specialElements[cell.type](colIndex * squareWidth, rowIndex * squareWidth, squareWidth / 100, `${rowIndex}${colIndex}`)
            //fill normal square
            if (cell) return SharpGrid.fit(colIndex * squareWidth, rowIndex * squareWidth, squareWidth / 100, `${rowIndex}${colIndex}`)
          }),
        )}
      </>
    )
  }, [object.code, designType, customDesignConfig.body.shape, colorPickerType])

  //component for CORNERS
  const cornersElement = useMemo(() => {
    let scale = (squareWidth * 7) / 100
    let shift = 100 - squareWidth * 7

    return designType === 'sharp' ? (
      <>
        {SharpCorner(0, 0, scale, 's1')}
        {SharpCornerReverse(shift, 0, scale, 's2')}
        {SharpCornerReverse(0, shift, scale, 's3')}
      </>
    ) : designType === 'round' ? (
      <>
        {RoundCorner(0, 0, scale, 'r1')}
        {RoundCorner(shift, 0, scale, 'r2')}
        {RoundCorner(0, shift, scale, 'r3')}
      </>
    ) : designType === 'circle' ? (
      <>
        {CircleCorner(0, 0, scale, 'c1')}
        {CircleCorner(shift, 0, scale, 'c2')}
        {CircleCorner(0, shift, scale, 'c3')}
      </>
    ) : (
      <>
        {ClassicCorner(0, 0, scale, 'cl1')}
        {ClassicCorner(shift, 0, scale, 'cl2')}
        {ClassicCorner(0, shift, scale, 'cl3')}
      </>
    )
  }, [designType, object.code, colorPickerType])
  //component for CUSTOM FRAMES
  const framesElement = useMemo(() => {
    let scale = (squareWidth * 7) / 100
    let shift = 100 - squareWidth * 7

    return customDesignConfig.frame.shape === 'sharp' ? (
      <>
        {SharpFrame(0, 0, scale, 's1')}
        {SharpFrameReverse(shift, 0, scale, 's2')}
        {SharpFrameReverse(0, shift, scale, 's3')}
      </>
    ) : customDesignConfig.frame.shape === 'round' ? (
      <>
        {RoundFrame(0, 0, scale, 'r1')}
        {RoundFrame(shift, 0, scale, 'r2')}
        {RoundFrame(0, shift, scale, 'r3')}
      </>
    ) : customDesignConfig.frame.shape === 'circle' ? (
      <>
        {CircleFrame(0, 0, scale, 'c1')}
        {CircleFrame(shift, 0, scale, 'c2')}
        {CircleFrame(0, shift, scale, 'c3')}
      </>
    ) : (
      <>
        {SquareFrame(0, 0, scale, 'cl1')}
        {SquareFrame(shift, 0, scale, 'cl2')}
        {SquareFrame(0, shift, scale, 'cl3')}
      </>
    )
  }, [customDesignConfig.frame.shape, object.code, colorPickerType])
  //component for CUSTOM BALLS
  const ballsElement = useMemo(() => {
    let scale = (squareWidth * 7) / 100
    let shift = 100 - squareWidth * 7

    return customDesignConfig.ball.shape === 'sharp' ? (
      <>
        {SharpBall(0, 0, scale, 's1')}
        {SharpBallReverse(shift, 0, scale, 's2')}
        {SharpBallReverse(0, shift, scale, 's3')}
      </>
    ) : customDesignConfig.ball.shape === 'round' ? (
      <>
        {RoundBall(0, 0, scale, 'r1')}
        {RoundBall(shift, 0, scale, 'r2')}
        {RoundBall(0, shift, scale, 'r3')}
      </>
    ) : customDesignConfig.ball.shape === 'circle' ? (
      <>
        {CircleBall(0, 0, scale, 'c1')}
        {CircleBall(shift, 0, scale, 'c2')}
        {CircleBall(0, shift, scale, 'c3')}
      </>
    ) : (
      <>
        {SquareBall(0, 0, scale, 'cl1')}
        {SquareBall(shift, 0, scale, 'cl2')}
        {SquareBall(0, shift, scale, 'cl3')}
      </>
    )
  }, [customDesignConfig.ball.shape, object.code, ])

  let svgStyles
  let changedQrIconLayer = {...qrIconLayer}
  let currentIcon = null

  if (qrIconLayer) {
    const iconColorPickerType = qrIconLayer.colorPickerType
    const gradientOrientationValue = Number(qrIconLayer.gradientOrientation.split('deg')[0])
    if (qrIconLayer.iconName) {
      let iconName = qrIconLayer.iconName.substring(qrIconLayer.iconName.lastIndexOf('/') + 1).split('_')[0]
      const currentIconElement = document.getElementsByClassName(`${iconName}`).item(0)
      let currentLinearGradientElement = currentIconElement && currentIconElement.getElementsByClassName(`${iconName}-gradient`).item(0)
      const gradientId = currentLinearGradientElement && currentLinearGradientElement.id

      if (currentLinearGradientElement && currentIconElement) {
        let svgWidth = currentIconElement.getAttribute('width')
        let svgHeight = currentIconElement.getAttribute('height')

        if (svgWidth && svgHeight && svgWidth.indexOf('px') !== -1 && svgHeight.indexOf('px') !== -1) {
          svgWidth = svgWidth.split('px')[0]
          svgHeight = svgHeight.split('px')[0]
        }

        currentLinearGradientElement.setAttribute(
          'gradientTransform',
          `rotate(${gradientOrientationValue}, ${svgWidth / 2},${svgHeight / 2})`,
        )
      }

      if (qrIconLayer.colorPickerType === 'Gradient') {
        svgStyles = css({
          ' path': {
            fill: `url(#${iconName}-gradient)`,
          },
          ' rect': {
            fill: `url(#${iconName}-gradient)`,
          },
          ' polygon': {
            fill: `url(#${iconName}-gradient)`,
          },
          ' circle': {
            fill: `url(#${iconName}-gradient)`,
          },
          ' ellipse': {
            fill: `url(#${iconName}-gradient)`,
          },
          ' linearGradient': {
            ' .left': {
              stopColor: qrIconLayer.leftGradientColor,
            },
            ' .right': {
              stopColor: qrIconLayer.rightGradientColor,
            },
          },
        })
      } else if (qrIconLayer.colorPickerType === 'Solid color') {
        svgStyles = css({
          ' path': {
            fill: qrIconLayer.styles.color,
          },
          ' rect': {
            fill: qrIconLayer.styles.color,
          },
          ' polygon': {
            fill: qrIconLayer.styles.color,
          },
          ' circle': {
            fill: qrIconLayer.styles.color,
          },
          ' ellipse': {
            fill: qrIconLayer.styles.color,
          },
        })
      }

      if ((iconColorPickerType === 'Colorful' && iconName !== 'qr') || ((iconColorPickerType === 'Solid color' ||  iconColorPickerType === 'Gradient') && iconName === 'qr')) {
        changedQrIconLayer.iconName = null
      }

      if (changedQrIconLayer.iconName) {
        const isUploadedIcon = changedQrIconLayer.iconName.split('.')[1] !== undefined

        if (uploadedIcons && isUploadedIcon) {
          currentIcon = uploadedIcons.find((icon) => icon.name === changedQrIconLayer.iconName)
        }
      }
    }
  }

  return (
    <div className="qr-code" error={error}>
      {colorPickerType === "Custom" ? <>
      <svg viewBox="0 0 100 100" className="qr-svg">
        <g fill={customDesignConfig.body.color}>
          {rectanglesElement}
        </g>
        <g fill={customDesignConfig.ball.color}>
          {ballsElement}
        </g>
        <g fill={customDesignConfig.frame.color}>
          {framesElement}
        </g>
      </svg>
      </> :
      <svg viewBox="0 0 100 100" className="qr-svg">
        <defs>{backgroundColorElement}</defs>

          <g fill={`url(#${colorId})`}>
            {rectanglesElement}
            {cornersElement}
          </g>
        </svg>
        }
        {qrIconLayer ?
        <div
          onMouseOver={(e)=>e.stopPropagation()}
          key={qrIconLayer.type}
          onClick={(e) => {e.stopPropagation();handleSetActiveLayer(qrIconLayer)}}
          className={`template-layer icon icon-qr ${qrIconLayer.type} ${isElementFocused && qrIconLayer.type === activeLayerType ? 'focused' : ''} ${
            qrIconLayer.qrbackground ? 'qrbackground' : ''
          }`}
          style={{...qrIconLayer.styles} }
          >
            {currentIcon ? 
              <img src={currentIcon.icon} alt={currentIcon.name}/>
            : changedQrIconLayer.iconName ?
              <ReactSVG src={changedQrIconLayer.iconName} {...svgStyles} renumerateIRIElements={false} />
            : null
            }
          </div> : <></>
        }
      {/* </div> */}
    </div>
  )
}

export default QRCode
