import { DEFAULT_LOCALE, LOCALES, SIDES} from './constants'

export function getInitialLanguage() {
  let storedLanguage = localStorage.getItem('language')

  let currentLocale

  //from browser local-storage storage (selected by user)
  if (storedLanguage) {
    currentLocale = storedLanguage
  }
  //from browser default setting
  else {
    const language = (navigator.languages && navigator.languages[0]) || navigator.language
    const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0]
    currentLocale = languageWithoutRegionCode || language || DEFAULT_LOCALE
  }

  //if lang is not supported then set to default
  if (!LOCALES.includes(currentLocale)) currentLocale = DEFAULT_LOCALE
  return currentLocale
}

export const resetCurrentTemplate = (template, templateType, initialTemplates) => {
  const cardTemplate = {...template}
  const templateId = cardTemplate.id

  let defaultTemplate = findInitialTemplateById(initialTemplates, templateId);

  cardTemplate.layers.forEach((layer, id) => {
    const changedLayer = { ...layer }
    const changedLayerStyles = {
      ...changedLayer.styles,
    }

    if (changedLayerStyles.color) {
      changedLayerStyles.color = defaultTemplate.layers[id].styles.color
    }

    if (changedLayerStyles.backgroundColor) {
      changedLayerStyles.backgroundColor = defaultTemplate.layers[id].styles.backgroundColor
    }

    if (changedLayerStyles.backgroundImage) {
      changedLayerStyles.backgroundImage = defaultTemplate.layers[id].styles.backgroundImage
    }

    if (changedLayer.leftGradientColor) {
      changedLayer.leftGradientColor = defaultTemplate.layers[id].leftGradientColor
    }

    if (changedLayer.rightGradientColor) {
      changedLayer.rightGradientColor = defaultTemplate.layers[id].rightGradientColor
    }

    if (changedLayer.colorPickerType) {
      changedLayer.colorPickerType = defaultTemplate.layers[id].colorPickerType
    }

    if (changedLayer.solidColor) {
      changedLayer.solidColor = defaultTemplate.layers[id].solidColor
    }

    if (changedLayer.visuals) {
      changedLayer.visuals = defaultTemplate.layers[id].visuals
    }

    if (changedLayer.colorPickerType === 'Custom') {      
      changedLayer.customDesignConfig.ball.color = defaultTemplate.layers[id].customDesignConfig.ball.color
      changedLayer.customDesignConfig.body.color = defaultTemplate.layers[id].customDesignConfig.body.color
      changedLayer.customDesignConfig.frame.color = defaultTemplate.layers[id].customDesignConfig.frame.color
    }

    changedLayer.styles = changedLayerStyles
    cardTemplate.layers[id] = changedLayer
  })

  cardTemplate.visuals = defaultTemplate.visuals
  cardTemplate.visual1 = defaultTemplate.visual1
  cardTemplate.visual2 = defaultTemplate.visual2
  cardTemplate.visual3 = defaultTemplate.visual3

  return cardTemplate
}

export const resetAllTemplates = (templates, templateType, initialTemplates) => {
  let changedTemplates = [...templates]

  templates.forEach((template, id) => {
    changedTemplates[id] = resetCurrentTemplate(changedTemplates[id], templateType, initialTemplates)
  })

  return changedTemplates
}

export const resetVisualUserData = (visual, initialVisual) => {
  visual.forEach((layer, id) => {
    const changedLayer = { ...layer }

    if (changedLayer.text) {
      changedLayer.text = initialVisual[id].text
    }

    if (changedLayer.isTextUpdated) {
      changedLayer.isTextUpdated = false
    }

    if (changedLayer.iconName) {
      changedLayer.iconName = initialVisual[id].iconName
    }

    if (changedLayer.object) {
      changedLayer.object = { ...initialVisual[id].object }
    }

    if (changedLayer.icon) {
      changedLayer.icon = initialVisual[id].icon
    }

    visual[id] = changedLayer
  })

  return visual
}

export const resetUserData = (template, templateType, initialTemplates) => {
  // TODO: use templateType to effectively filter initialTemplates once new structure is finished
  const cardTemplate = {...template}
  const templateId = cardTemplate.id
  let defaultTemplate = findInitialTemplateById(initialTemplates, templateId);
  let actualVisual = defaultTemplate[cardTemplate.visuals]

  cardTemplate.layers.forEach((layer, id) => {
    const changedLayer = { ...layer }
    const visualData = actualVisual.find(layer => layer.type === changedLayer.type)

    if (changedLayer.text) {
      changedLayer.text = visualData.text
    }

    if (changedLayer.isTextUpdated) {
      changedLayer.isTextUpdated = false
    }

    if (changedLayer.iconName) {
      changedLayer.iconName = visualData.iconName
    }

    if (changedLayer.object) {
      changedLayer.object = { ...defaultTemplate.layers[id].object }
    }

    if (changedLayer.icon) {
      changedLayer.icon = visualData.icon
    }

    cardTemplate.layers[id] = changedLayer
  })

  cardTemplate.visual1 = resetVisualUserData(cardTemplate.visual1, defaultTemplate.visual1)
  cardTemplate.visual2 = resetVisualUserData(cardTemplate.visual2, defaultTemplate.visual2)
  cardTemplate.visual3 = resetVisualUserData(cardTemplate.visual3, defaultTemplate.visual3)

  return cardTemplate
}

export const setRecentColor = (layer, colors, recentColors) => {
  const styles = layer.styles

  if (styles.color && !colors.includes(styles.color.toLowerCase())) {
    if (!recentColors.includes(styles.color.toLowerCase())) {
      recentColors.shift()
      recentColors.push(styles.color.toLowerCase())
    }
  }

  if (styles.backgroundColor && !colors.includes(styles.backgroundColor.toLowerCase())) {
    if (!recentColors.includes(styles.backgroundColor.toLowerCase())) {
      recentColors.shift()
      recentColors.push(styles.backgroundColor.toLowerCase())
    }
  }

  if (layer.leftGradientColor && !colors.includes(layer.leftGradientColor.toLowerCase())) {
    if (!recentColors.includes(layer.leftGradientColor.toLowerCase())) {
      recentColors.shift()
      recentColors.push(layer.leftGradientColor.toLowerCase())
    }
  }

  if (layer.rightGradientColor && !colors.includes(layer.rightGradientColor.toLowerCase())) {
    if (!recentColors.includes(layer.rightGradientColor.toLowerCase())) {
      recentColors.shift()
      recentColors.push(layer.rightGradientColor.toLowerCase())
    }
  }

  return recentColors
}

export const createUserDataObject = (layer, templateType) => {
  const layerType = layer.type.split('-')[0]
  const userData = {
    type: layer.type,
    templateType,
  }
  // TODO: For future use. Right now only qr data are saved.
  // if (layerType === 'text') {
  //   userData.text = layer.text
  // } else if (layerType === 'icon') {
  //   userData.iconName = layer.iconName
  // } else if (layerType === 'qr') {
  //   userData.object = layer.object
  //   userData.icon = layer.icon
  // }

  if (layerType === 'qr') {
    userData.object = layer.object
    userData.icon = layer.icon
  }

  return userData
}

// TODO: refactor and include sorting by cardType: https://app.clickup.com/t/2ubuhd6
export const sortByType = (templates, currentTemplate, side) => {
  return templates;
  let unsortedTemplates = [...templates]
  let sortedTemplates;

  // sortedTemplates.push(templates.find((template) => template.cardType.includes('E')))

  if (side === SIDES.BACK) {
    sortedTemplates = templates.filter((template) => template.side === SIDES.BACK)
  } else {
    sortedTemplates = templates.filter((template) => template.side === SIDES.BACK)
  }

  // sortedTemplates = sortedTemplates.concat(unsortedTemplates)
  sortedTemplates = sortedTemplates.concat(unsortedTemplates.filter((template) => template.side !== side))

  // const types = currentTemplate.cardType
  // types.forEach((type) => {
  //   sortedTemplates = sortedTemplates.concat(unsortedTemplates.filter((template) => template.cardType.includes(type)))
  //   unsortedTemplates = unsortedTemplates.filter((template) => !template.cardType.includes(type))
  // })

  return sortedTemplates
}

export const setColorPalette = (visual) => {
  const updatedColorPalette = []
  const colorPaletteLayer = { ...visual[0] }

  visual.forEach((layer) => {
    if (layer.styles) {
      if (layer.styles.color && layer.type.split('-')[0] !== 'text') {
        if (!updatedColorPalette.includes(layer.styles.color) && updatedColorPalette.length < 4) {
          updatedColorPalette.push(layer.styles.color)
        }
      }

      if (layer.styles.backgroundColor) {
        if (!updatedColorPalette.includes(layer.styles.backgroundColor) && updatedColorPalette.length < 4) {
          updatedColorPalette.push(layer.styles.backgroundColor)
        }
      }
    }

    if (layer.leftGradientColor) {
      if (!updatedColorPalette.includes(layer.leftGradientColor) && updatedColorPalette.length < 4) {
        updatedColorPalette.push(layer.leftGradientColor)
      }
    }

    if (layer.rightGradientColor) {
      if (!updatedColorPalette.includes(layer.rightGradientColor) && updatedColorPalette.length < 4) {
        updatedColorPalette.push(layer.rightGradientColor)
      }
    }

    if (layer.solidColor) {
      if (!updatedColorPalette.includes(layer.solidColor) && updatedColorPalette.length < 4) {
        updatedColorPalette.push(layer.solidColor)
      }
    }
  })

  colorPaletteLayer.colors = [...updatedColorPalette]
  visual[0] = colorPaletteLayer

  return visual
}

const isObj = (x) => typeof x === 'object' && x !== null

const isEmptyObj = obj => obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype

export const mergeLayer = (visualLayer, changedLayer) => {
  let result = {}

  if ([visualLayer, changedLayer].every(isObj)) {
    Object.keys(visualLayer).forEach((key) => {
      const value = visualLayer[key]
      const other = changedLayer[key]

      if (isObj(value)) {
        result[key] = mergeLayer(value, other)
      } else {
        result[key] = other
      }
    })
  }

  return result
}

// helper function: generate a new file from base64 String
export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1)
    n -= 1 // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime })
}

const findInitialTemplateById = (initialTemplates, templateId) => {
  return initialTemplates.find((template) => template.id === templateId);
}

export const createSubscriptionPlansObject = (data) => {
  let subscriptionPlans = {}

  data.forEach(priceObject => {
    const { nickname, currency, unit_amount, recurring, id } = priceObject
    const price = unit_amount / 100
    const quantity = Number(nickname.split(' ').pop())
    const text = (currency === 'eur' ? '€' : currency === 'usd' ? '$' : currency === 'gbp' ? '£' : '') + price + (currency === 'czk' ? 'Kč' : '')
    const period = recurring.interval === 'month' ? 'monthly' : 'yearly'
    const planKey = nickname.replaceAll(' ', '_')

    const subscriptionPlan = {
      name: nickname,
      text,
      price,
      quantity,
      stripeId: id,
    }

    if (subscriptionPlans[planKey] && subscriptionPlans[planKey][period]) {
      subscriptionPlans[planKey][period][currency] = subscriptionPlan
    } else if (subscriptionPlans[planKey]) {
      subscriptionPlans[planKey][period] = {
        [currency]: subscriptionPlan,
      }
    } else {
      subscriptionPlans[planKey] = {
        [period]: {
          [currency]: subscriptionPlan,
        }
      }
    }
  })

  const orderedSubscriptionPlans = Object.keys(subscriptionPlans).sort(
    (a, b) => a.substring(a.lastIndexOf('_') + 1) - b.substring(b.lastIndexOf('_') + 1)
  ).reduce(
    (obj, key) => { 
      obj[key] = subscriptionPlans[key]; 
      return obj;
    }, 
    {}
  )

   return orderedSubscriptionPlans
}

export const prepareDataForExport = (exportedData) => {
  let preparedData = []

  exportedData.forEach(data => {
    let newRow = {}

    data.forEach(row => {
      if (row.answer === false) newRow[row.question] = 'No'
      else if (row.answer === true) newRow[row.question] = 'Yes'
      else newRow[row.question] = row.answer
    })

    preparedData.push(newRow)
  })

  return preparedData
}
