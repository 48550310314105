import {MicroPageType} from "../../ts/types";
import {
    apiCreateDigitalCard,
    apiCreateFeedbackForm,
    apiCreateMultiEntry,
    apiCreateProductPage, apiUpdateDigitalCard, apiUpdateFeedbackForm, apiUpdateMultiEntry, apiUpdateProductPage
} from "../../api/micropage";
import {attachMicroPageToDynamicQrCode} from "../../api/qrcode";
export const SET_PRODUCT_PAGE = "SET_PRODUCT_PAGE";
export const SET_DIGITAL_CARD = "SET_DIGITAL_CARD";
export const SET_FEEDBACK_FORM= "SET_FEEDBACK_FORM";
export const SET_MULTI_ENTRY = "SET_MULTI_ENTRY";
export const RESET_TO_INITIAL = "RESET_TO_INITIAL";
export const SET_MICRO_PAGE_SUCCESS = "SET_MICRO_PAGE_SUCCESS";


/*
    You cannot create microPage without dynamicQrCode, so you always have to pass activeDynamicQrCodeId argument
 */
export const createMicroPageByType = (microPageType: string, activeDynamicQrCodeId: string) => async (dispatch, getState) => {
    switch (microPageType) {
        case MicroPageType.PRODUCT_PAGE: {
            const microPage = await apiCreateProductPage(getState().microPage.productPage)
            attachMicroPageToDynamicQrCode(activeDynamicQrCodeId, microPage, MicroPageType.PRODUCT_PAGE)
        }
        break;
        case MicroPageType.FEEDBACK_FORM: {
            const microPage = await apiCreateFeedbackForm(getState().microPage.feedbackForm)
            attachMicroPageToDynamicQrCode(activeDynamicQrCodeId, microPage, MicroPageType.FEEDBACK_FORM)
        }
        break;
        case MicroPageType.DIGITAL_CARD: {
            const microPage = await apiCreateDigitalCard(getState().microPage.digitalCard)
            attachMicroPageToDynamicQrCode(activeDynamicQrCodeId, microPage, MicroPageType.DIGITAL_CARD)

        }
        break;
        case MicroPageType.MULTI_ENTRY: {
            const microPage = await apiCreateMultiEntry(getState().microPage.multiEntry)
            attachMicroPageToDynamicQrCode(activeDynamicQrCodeId, microPage, MicroPageType.MULTI_ENTRY)
        }
        break;
    }
}

export const updateMicroPageByType = (microPageType: string, activeDynamicQrCodeId: string) => async (dispatch, getState) => {
    switch (microPageType) {
        case MicroPageType.PRODUCT_PAGE: {
            const microPage = await apiUpdateProductPage(getState().microPage.productPage);

            // TODO:
            // if activeDynamicQrCodeId is different then dynamicQrCode or design currenty edited
                // createNewMicroPage
                // delete old DynamicQrcode and his microPage
            // attachMicroPageToDynamicQrCode(activeDynamicQrCodeId, microPage)
        }
        break;

        case MicroPageType.FEEDBACK_FORM: {
            const microPage = await apiUpdateFeedbackForm(getState().microPage.feedbackForm)
            // attachMicroPageToDynamicQrCode(activeDynamicQrCodeId, microPage)
        }
        break;
        case MicroPageType.DIGITAL_CARD: {
            const microPage = await apiUpdateDigitalCard(getState().microPage.digitalCard)
            // attachMicroPageToDynamicQrCode(activeDynamicQrCodeId, microPage)
        }
        break;
        case MicroPageType.MULTI_ENTRY: {
            const microPage = await apiUpdateMultiEntry(getState().microPage.multiEntry)
            // attachMicroPageToDynamicQrCode(activeDynamicQrCodeId, microPage)
        }
        break;
    }
}

export const setMicroPageSuccess = () => ({
    type: SET_MICRO_PAGE_SUCCESS,
})

export const setProductPage = (page, status) => ({
    type: SET_PRODUCT_PAGE,
    page,
    status
})

export const setDigitalCard = (page, status) => ({
    type: SET_DIGITAL_CARD,
    page,
    status,
})

export const setFeedbackForm = (page, status) => ({
    type: SET_FEEDBACK_FORM,
    page,
    status,
})

export const setMultiEntry = (page, status) => ({
    type: SET_MULTI_ENTRY,
    page,
    status
})

export const resetToInitial = (qrType) => ({
    type: RESET_TO_INITIAL,
    qrType
})


