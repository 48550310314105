import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import SubscriptionPlan from './SubscriptionPlan'
import { getSubscriptionPlans } from '../../../../store/order/orderSelectors'
import { getUserSettings } from '../../../../store/auth/authSelectors'
import { getDynamicQrCodesCount } from '../../../../store/myLibrary/myLibrarySelectors'

function SubscriptionOverview({
  setPeriod,
  period,
  activePlan,
  handleActivePlan,
  cancelledPlan,
  setIsCancelling,
  subscriptionPlans,
  userSettings,
  dynamicQrCodesCount,
  isSubscriptionActive,
  subscriptionExpiration,
}) {
  const history = useHistory()
  const currency = userSettings.currency
  const subscriptionPlansList = Object.values(subscriptionPlans).map((plan, i) => {
    const planByPeriod = plan[period][currency.toLowerCase()]
    
    return (
      <SubscriptionPlan
        key={i}
        onClick={() => handleActivePlan(planByPeriod.name)}
        onHandleCancel={setIsCancelling}
        quantity={planByPeriod.quantity}
        price={planByPeriod.text}
        period={period}
        activePlan={activePlan}
        isActive={activePlan === planByPeriod.name}
        isCancelled={cancelledPlan === planByPeriod.name}
        isWithLabel={i > 1 ? true : false}
        labelType={i === 2 ? "popular" : i === 3 ? "best" : ""}
        subscriptionExpiration={subscriptionExpiration}
      />
    )
  })

  return (
    <div id='Subscription'>
      <h1>
        <FormattedMessage id="dashboard.subscription.plans" />
      </h1>
      <div className="buttons-wrapper">
        <button onClick={() => setPeriod('monthly')} className={`switch-button ${period === 'monthly' && 'selected'}`}>
          <FormattedMessage id="dashboard.subscription.monthly" />
        </button>
        <button onClick={() => setPeriod('yearly')} className={`switch-button ${period === 'yearly' && 'selected'}`}>
          <FormattedMessage id="dashboard.subscription.yearly" />
          <p>
            <FormattedMessage id="dashboard.subscription.save" /> 40%
          </p>
        </button>
      </div>
      <div className="choose-text">
        <FormattedMessage id="dashboard.subscription.choose" />
      </div>
      <div className="blocks-wrapper">
        {subscriptionPlansList}
      </div>
      <p className='note'>
        <svg className='lock' width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.991 6.24305H10.5821V3.95305C10.4185 1.78574 8.61923 0.0273438 6.37013 0.0273438C4.16192 0.0273438 2.32174 1.78574 2.19906 3.95305V6.24305H1.79013C0.890491 6.24305 0.195312 7.02002 0.195312 7.96055V15.1577C0.195312 16.0982 0.931383 16.8752 1.79013 16.8752H6.28834H10.9501C11.8498 16.8752 12.5449 16.0982 12.5449 15.1577V7.96055C12.5858 7.02002 11.8498 6.24305 10.991 6.24305ZM3.34406 4.32109C3.42585 2.72627 4.77531 1.45859 6.37013 1.45859C7.96495 1.45859 9.31441 2.72627 9.3962 4.32109V6.24305H6.28834H3.34406V4.32109Z" fill="#38517C" />
        </svg>
        <span>
          <FormattedMessage id="dashboard.subscription.transactions" />
          <span className="excluding">
            <FormattedMessage id="dashboard.subscription.exclVat" />
          </span>
        </span>
      </p>
      <div className="rect manage">
        <div className="manage-text">
          <span>
            <FormattedMessage id="dashboard.subscription.currentlyUse.part1" />
            {' '}
            {dynamicQrCodesCount}
            {' '}
            <FormattedMessage id="dashboard.subscription.currentlyUse.part2" />
            {
              activePlan === 'DYNAMIC QR 1' || (!isSubscriptionActive && !subscriptionExpiration) ? ' 1 ' :
              activePlan === 'DYNAMIC QR 5' ? ' 5 ' :
              activePlan === 'DYNAMIC QR 20' ? ' 20 ' :
              activePlan === 'DYNAMIC QR 100' ? ' 100 ' : ' 500 '
            }
            <FormattedMessage id="dashboard.subscription.currentlyUse.part3" />
          </span>
          <button onClick={() => history.push('/app/dashboard/my-library?filter=dynamic_qr')} className="round-button">
            <FormattedMessage id="dashboard.subscription.manage" />
          </button>
        </div>
      </div>
    </div>
  )
}

const mapState = (state) => ({
  subscriptionPlans: getSubscriptionPlans(state),
  userSettings: getUserSettings(state),
  dynamicQrCodesCount: getDynamicQrCodesCount(state),
})

export default connect(mapState)(SubscriptionOverview)
