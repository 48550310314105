import React, {useEffect, useState, useRef} from 'react';
import useResize from "./useResize";

const useIsMobile = (myRef) => {

    const MOBILE_WIDTH = 700;

    const [isMobile, setIsMobile] = useState(false);

    const { width } = useResize(myRef);

    useEffect(() => {
        setIsMobile(width <= MOBILE_WIDTH)
    }, [width])

    return isMobile;
}

export default useIsMobile;
