
const boolToNum = (val) => (val === true ? 1 : 0)
// generate empty matrix
const getEmptyCubeMatrix = ({ size }) => Array.from({ length: size }).map(() => Array.from({ length: size }).map(() => []))
const getNeighbourConf = (matrix) => {
  const matrixSize = matrix.length
  const aroundCountMatrix = getEmptyCubeMatrix({ size: matrixSize })
  matrix.forEach((row, rowIndex) => {
    row.forEach((cellValue, colIndex) => {
      if (cellValue === 0) {
        aroundCountMatrix[rowIndex][colIndex] = []
        return
      }
      const upRowIndex = rowIndex - 1
      const leftColIndex = colIndex - 1
      const rightColIndex = colIndex + 1
      const bottomRowIndex = rowIndex + 1

      const topValue = matrix[upRowIndex] ? (matrix[upRowIndex][colIndex] ? 1 : 0) : 0
      const rightValue = matrix[rowIndex] ? (matrix[rowIndex][rightColIndex] ? 1 : 0) : 0
      const bottomValue = matrix[bottomRowIndex] ? (matrix[bottomRowIndex][colIndex] ? 1 : 0) : 0
      const leftValue = matrix[rowIndex] ? (matrix[rowIndex][leftColIndex] ? 1 : 0) : 0

      const neighbors = {
        top: topValue !== 0 || false,
        right: rightValue !== 0 || false,
        bottom: bottomValue !== 0 || false,
        left: leftValue !== 0 || false,
      }
      const neighborsConf = [boolToNum(neighbors.top), boolToNum(neighbors.right), boolToNum(neighbors.bottom), boolToNum(neighbors.left)]

      aroundCountMatrix[rowIndex][colIndex] = neighborsConf
    })
  })
  return aroundCountMatrix
}

export default getNeighbourConf
