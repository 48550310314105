import CancelButton from "./CancelButton";
import CropperComponent from "../../common/CropperComponent";
import React, {useState} from "react";
import {BACKEND_URL} from "../../../../../utils/constants";
import {dataURLtoFile} from "../../../../../utils/helpers";
import {Base64} from "../../../../../ts/types";

function AddImage({shape, onImageChange, onLoadingChange, hideCheckbox, aspect, removeRadius, initialImage, initialCroppedImage}) {
  const [image, setImage] = useState<Base64 | null>(initialImage)
  const [croppedImage, setCroppedImage] = useState(initialCroppedImage);
  const [isCropping, setIsCropping] = useState(false);
  const [loading, setLoading] = useState(false)

  function handleCropCancel(e) {
    setIsCropping(false)
  }

  async function uploadImage(file) {
    const formData = new FormData();

    formData.append(`files`, file, 'file.name')
    await fetch(`${BACKEND_URL}/upload`, {
      method: "POST",
      body: formData
    }).then((response) => {
      return response.json();
    }).then((body) => {
      setCroppedImage(body[0])
      onImageChange(body[0], image)
      setLoading(false)
      onLoadingChange(false);
      setIsCropping(false);
    })
  }

  function handleCropUpdate(imageDataURL) {
    setCroppedImage(null);
    setIsCropping(false);
    setLoading(true)
    onLoadingChange(true);
    const file = dataURLtoFile(imageDataURL)
    uploadImage(file)
  }

  function changeCroppedImage() {
    setIsCropping(true);
  }

  const addImageToCropper = async (files) => {
    setIsCropping(true);
    const file = files[0];
    const reader = new FileReader()

    reader.onloadend = () => {
      // @ts-ignore
      setImage(reader.result)
    }
    reader.readAsDataURL(file)
  }

  const handleImageDelete = (imageId) => {
    fetch(`${BACKEND_URL}/upload/files/${imageId}`, {
      method: "DELETE"
    }).catch((error) => {
      console.log(error);
    })
    setImage(null);
    setCroppedImage(null);
    onImageChange(null, null);
  }

  return (
    <>
      {/* Upload button */}
      {!croppedImage &&
        <div className="mt-4 primary-button-wrapper">
          <div className="primary-button button-text">
            <label className="file-upload">
              Upload logo
              {loading &&
                <span>...</span>}
              <input type="file" id="upload" accept=".jpg, .jpeg, .png, .svg"
                     onChange={(e) => addImageToCropper(e.target.files)}/>
            </label>
          </div>
        </div>
      }
      {/* Displayed photo */}
      {croppedImage &&
        <div className="d-flex justify-content-center mt-5" id="image">
          {croppedImage &&
            <div className="d-flex align-items-center flex-column position-relative image-border">
              <img style={
                {
                  borderRadius: (shape === 'round' ? '7.2rem' : '1.5rem')
                }
              } width="115px" src={croppedImage.url}
                   alt="image"/>
              <span className="delete-logo" onClick={() => handleImageDelete(croppedImage.id)}>
                                    <div style={{
                                      position: 'absolute',
                                      top: '-13px',
                                      right: '-13px',
                                    }}>
                                        <CancelButton/>
                                    </div>
                        </span>

              {image &&

              <span onClick={changeCroppedImage}>
                            <div className="primary-button button-text text-center" style={{
                              position: 'relative',
                              width: '81px',
                              height: '26px',
                              top: '-15px',
                              lineHeight: '27px'
                            }}>
                                Change
                            </div>
                        </span>
              }

            </div>
          }
        </div>
      }
      {/* Cropper */}
      {
        isCropping && image &&
        <CropperComponent
          cropShape={shape}
          icon={image}
          onCancel={handleCropCancel}
          onUpdateIcon={handleCropUpdate}
          hideCheckbox={hideCheckbox}
          aspect={aspect}
          removeRadius={removeRadius}
        />

      }
    </>

  )
}

export default AddImage;
