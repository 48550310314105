import React from 'react';


function Case() {
    return (
        <svg width="22" height="19" viewBox="0 0 22 19" fill="#000" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.74161 4.51936C1.23741 5.02359 0.985352 5.62713 0.985352 6.32984V15.8638C0.985352 16.5668 1.23741 17.1703 1.74161 17.6744C2.24585 18.1787 2.84938 18.4307 3.55222 18.4307H4.28556V3.76294H3.55222C2.84922 3.76294 2.24573 4.01512 1.74161 4.51936Z"/>
            <path d="M15.6532 1.9301C15.6532 1.6245 15.5464 1.36482 15.3324 1.15089C15.1187 0.936962 14.8588 0.830078 14.5533 0.830078H7.95272C7.64719 0.830078 7.38747 0.936962 7.1735 1.15089C6.95957 1.3647 6.85265 1.62446 6.85265 1.9301V3.76351H4.26562V18.4312H18.2408V3.76351H15.6532V1.9301ZM14.1866 3.76351H8.31941V2.29679H14.1866V3.76351Z"/>
            <path d="M20.7643 4.51936C20.26 4.01516 19.6565 3.76294 18.9537 3.76294H18.2202V18.4307H18.9537C19.6565 18.4307 20.26 18.1788 20.7643 17.6744C21.2684 17.1703 21.5205 16.5667 21.5205 15.8638V6.32984C21.5205 5.62713 21.2683 5.02359 20.7643 4.51936Z"/>
        </svg>
    );
}

export default Case;
