import React, { Component } from 'react'
import { connect } from 'react-redux'

import ColorPicker from './ColorPicker'
import { updateDesign } from '../../../../store/card/cardActions'
import { getActiveLayer } from '../../../../store/card/cardSelectors'

import { ReactComponent as ArrowIcon } from './images/arrow-icon.svg'
import './ColorGradient.scss'

class ColorGradient extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeLayer: props.activeLayer,
      currentGradientElement: 'right',
      leftGradientColor: props.activeLayer.leftGradientColor,
      rightGradientColor: props.activeLayer.rightGradientColor,
      currentGradientOrientation: props.activeLayer.gradientOrientation,
      currentRadialGradientOrientation: props.activeLayer.radialGradientOrientation,
      leftPosition: props.activeLayer.leftPosition || 0,
      rightPosition: props.activeLayer.rightPosition || 100
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { activeLayer } = this.props
    if(this.state.activeLayer.type !== activeLayer.type) {
      this.setState({
        activeLayer,
        currentGradientElement: 'right',
        leftGradientColor: activeLayer.leftGradientColor,
        rightGradientColor: activeLayer.rightGradientColor,
        currentGradientOrientation: activeLayer.gradientOrientation,
        currentRadialGradientOrientation: activeLayer.radialGradientOrientation,
        leftPosition: activeLayer.leftPosition || 0,
        rightPosition: activeLayer.rightPosition || 100
      })
    }
  }

  handleChangeCurrentElement = (e) => {
    this.setState({
      currentGradientElement: e.target.name,
    })
  }

  handleGradientChange = (color) => {
    const { currentGradientElement, currentGradientOrientation, leftGradientColor, rightGradientColor, leftPosition, rightPosition } = this.state
    const { type } = this.props
    let leftGradient = leftGradientColor
    let rightGradient = rightGradientColor
    if (color) {
      if (currentGradientElement === 'left') {
        this.setState({
          leftGradientColor: color,
        })
        leftGradient = color
      } else {
        this.setState({
          rightGradientColor: color,
        })
        rightGradient = color
      }

      if (type === 'icon') {
        this.handleStylesChange('color', this.generateGradient(currentGradientOrientation, leftGradient, leftPosition, rightGradient, rightPosition), currentGradientOrientation, leftPosition, rightPosition)
      } else {
        this.handleStylesChange('backgroundImage', this.generateGradient(currentGradientOrientation, leftGradient, leftPosition, rightGradient, rightPosition), currentGradientOrientation, leftPosition, rightPosition)
      }
    }
  }

  handleChangeGradientOrientation = () => {
    const { currentGradientOrientation, leftGradientColor, rightGradientColor, currentRadialGradientOrientation, leftPosition, rightPosition } = this.state
    const { type, gradientType } = this.props

    if (gradientType === 'radial') {
      let gradientOrientation = currentRadialGradientOrientation

      if (currentRadialGradientOrientation === 'left') {
        gradientOrientation = 'right'
        this.setState({
          currentRadialGradientOrientation: 'right',
        })
      } else {
        gradientOrientation = 'left'
        this.setState({
          currentRadialGradientOrientation: 'left',
        })
      }

      this.handleStylesChange('radialGradientOrientation', gradientOrientation, currentGradientOrientation, leftPosition, rightPosition)
    } else {
      let gradientOrientation = currentGradientOrientation
      let gradientOrientationValue = Number(gradientOrientation ? gradientOrientation.split('deg')[0] : 0)

      gradientOrientation = gradientOrientationValue + 22.5 + 'deg'

      this.setState({
        currentGradientOrientation: gradientOrientation,
      })

      if (type === 'icon') {
        this.handleStylesChange('color', this.generateGradient(gradientOrientation, leftGradientColor, leftPosition, rightGradientColor, rightPosition), gradientOrientation, leftPosition, rightPosition)
      } else {
        this.handleStylesChange('backgroundImage', this.generateGradient(gradientOrientation, leftGradientColor, leftPosition, rightGradientColor, rightPosition), gradientOrientation, leftPosition, rightPosition)
      }
    }
  }

  handleStylesChange = (valueType, value, gradientOrientation, leftPosition, rightPosition) => {
    const { onChangeTemplate, activeLayer, type } = this.props
    const { leftGradientColor, rightGradientColor } = this.state
    const changedLayer = { ...activeLayer }
    const changedLayerStyles = { ...changedLayer.styles }

    if (valueType === 'radialGradientOrientation') {
      changedLayer[valueType] = value
    } else if (!activeLayer.styles.backgroundSvg || activeLayer.styles.backgroundSvg === 'none') {
      changedLayerStyles[valueType] = value
    }

    changedLayer.styles = changedLayerStyles
    changedLayer.styles.color = leftGradientColor
    changedLayer.leftGradientColor = leftGradientColor
    changedLayer.rightGradientColor = rightGradientColor
    changedLayer.gradientOrientation = gradientOrientation
    changedLayer.leftPosition = leftPosition
    changedLayer.rightPosition = rightPosition

    if (type !== 'icon' && type !== 'qr' && (!activeLayer.styles.backgroundSvg || changedLayer.styles.backgroundSvg === 'none')) {
      changedLayer.styles.backgroundColor = leftGradientColor
    }

    this.setState({
      activeLayer: changedLayer,
    })

    onChangeTemplate(changedLayer)
  }
  updatePosition = (e) => {
    this.setState({
      [`${e.target.name}Position`]: Number(e.target.value)
    })

    const { leftGradientColor, leftPosition, rightGradientColor, rightPosition, currentGradientOrientation } = this.state
    const { type } = this.props

    if (type === 'icon') {
      this.handleStylesChange('color', this.generateGradient(currentGradientOrientation, leftGradientColor, leftPosition, rightGradientColor, rightPosition), currentGradientOrientation, leftPosition, rightPosition)
    } else {
      this.handleStylesChange('backgroundImage',
        this.generateGradient(currentGradientOrientation, leftGradientColor, leftPosition, rightGradientColor, rightPosition),
        currentGradientOrientation, leftPosition, rightPosition
      )
    }
  }

  generateGradient = (gradientOrientation, leftGradientColor, leftPosition, rightGradientColor, rightPosition) => {
    leftPosition = Number(leftPosition)
    rightPosition = Number(rightPosition)
    if (leftPosition > rightPosition) {
      //swap colors and their positions
      return `linear-gradient(${gradientOrientation}, ${rightGradientColor} ${rightPosition}%, ${leftGradientColor} ${leftPosition}%)`
    } else {
      return `linear-gradient(${gradientOrientation}, ${leftGradientColor} ${leftPosition}%, ${rightGradientColor} ${rightPosition}%)`
    }
  }

  render() {
    const { leftGradientColor, rightGradientColor, currentGradientElement, leftPosition, rightPosition } = this.state
    return (
      <div className="color-gradient">
        <div className="row-wrapper">
          <div className="gradient-wrapper">
            <div className="gradient-element">
              <input
                name="left"
                onChange={this.updatePosition}
                value={leftPosition}
                min={0}
                max={100}
                className={`range ${currentGradientElement === 'left' && 'selected'}`}
                onMouseDown={this.handleChangeCurrentElement}
                style={{'--color': `${leftGradientColor}`}}
                type="range"
              />
              <div
                className="gradient"
                style={{ backgroundImage: this.generateGradient('to right', leftGradientColor, leftPosition, rightGradientColor, rightPosition) }}
              />
              <input
                name="right"
                onChange={this.updatePosition}
                value={rightPosition}
                min={0}
                max={100}
                className={`range ${currentGradientElement === 'right' && 'selected'}`}
                onMouseDown={this.handleChangeCurrentElement}
                style={{'--color': `${rightGradientColor}`}}
                type="range"
              />
            </div>
          </div>
          <div className="gradient-orientation">
            <div onClick={this.handleChangeGradientOrientation} className="button">
              <ArrowIcon />
            </div>
          </div>
        </div>
        <ColorPicker
          onGradientChange={(color) => this.handleGradientChange(color)}
          pickerType="gradient"
          currentGradientElement={currentGradientElement}
        />
      </div>
    )
  }
}

const mapState = (state) => ({
  activeLayer: getActiveLayer(state),
})

const mapDispatch = (dispatch) => ({
  onChangeTemplate: (layer) => dispatch(updateDesign(layer)),
})

export default connect(mapState, mapDispatch)(ColorGradient)
