import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Button} from 'reactstrap'
import {
    getActiveSide, getActiveTemplate,
    getTemplateType,
} from '../../../store/card/cardSelectors'
import {setActiveLayer, switchSide} from '../../../store/card/cardActions'
import {SIDES, TYPES} from '../../../utils/constants'
import {FormattedMessage} from 'react-intl'
import Design from './Design'
import './Editor.scss'
import DigitalCard from '../microweb/digitalCard/DigitalCard'
import VisualPicker from "../../common/VisualPicker/VisualPicker";

const Editor = ({ cardSide, onSwitchCardSide, templateType, onSetActiveLayer, activeTemplate, isHidden }) => {

    const [hasOneSide, setHasOneSide] = useState(false)
    useEffect(() => {
      setHasOneSide(false)
      if(templateType === TYPES.QR_ONLY|| templateType === TYPES.STICKER) {
        setHasOneSide(true)
      }
    }, [templateType])

   return (
    <div id="template-editor" className={`template-section ${isHidden ? 'is-hidden' : ''}`}>
      <div className="sides-buttons">
        {hasOneSide === false && (
          <>
            <Button onClick={() => {onSwitchCardSide(SIDES.FRONT); onSetActiveLayer(null)}} className={`${cardSide === SIDES.FRONT ? 'active' : ''}`} size="lg">
              <FormattedMessage id="templateSection.front" />
            </Button>
            <Button onClick={() => {onSwitchCardSide(SIDES.BACK); onSetActiveLayer(null)}} className={`${cardSide === SIDES.BACK ? 'active' : ''}`} size="lg">
              <FormattedMessage id="templateSection.rear" />
            </Button>
          </>
        )}
      </div>
      <Design isHidden={isHidden}/>
        {activeTemplate &&
            <VisualPicker/>
        }
    </div>
  )
}

const mapState = (state) => ({
  cardSide: getActiveSide(state),
  templateType: getTemplateType(state),
  activeTemplate: getActiveTemplate(state)
})

const mapDispatch = (dispatch) => ({
  onSwitchCardSide: (sideType) => dispatch(switchSide(sideType)),
  onSetActiveLayer: (layer) => dispatch(setActiveLayer(layer)),
})

export default connect(mapState, mapDispatch)(Editor)
