import {
  apiGetMyLibrary,
  apiCreateDesign,
  apiUpdateDesign,
  apiDeleteDesign,
} from '../../api/api'
import { getDesigns } from './myLibrarySelectors'
import { setActiveDesign } from '../card/cardActions'

export const DESIGNS_SET = 'DESIGNS_SET'


export const setDesigns = (designs) => ({ type: DESIGNS_SET,  designs})


export const fetchMyLibrary = () => (dispatch) => {
  return apiGetMyLibrary()
    .then((data) => dispatch(setDesigns(data)))
    .catch(async error => {
      await error
    })
}

export const addDesign = (design, name) => (dispatch, getState) => {
  const designs = [...getDesigns(getState())]

  return apiCreateDesign(design, name)
    .then((createdDesign) => {
      designs.push(createdDesign)
      dispatch(setDesigns(designs))
      dispatch(setActiveDesign(createdDesign))
    })
    .catch(async error => {
      await error
    })
}

export const updateDesign = (design, name) => (dispatch, getState) => {
  const designs = [...getDesigns(getState())]
  const designId = designs.findIndex(d => d.id === design.id)

  return apiUpdateDesign(design, name)
    .then((updatedDesign) => {
      designs[designId] = updatedDesign
      dispatch(setDesigns(designs))
      dispatch(setActiveDesign(updatedDesign))
    })
    .catch(async error => {
      await error
    })
}

export const deleteDesign = (design) => (dispatch, getState) => {
  const designs = [...getDesigns(getState())]
  const designId = designs.findIndex(d => d.id === design.id)

  return apiDeleteDesign(design)
    .then((data) => {
      designs.splice(designId, 1)
      dispatch(setDesigns(designs))
    })
    .catch(async error => {
      await error
    })
}
