import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { Provider, ErrorBoundary } from '@rollbar/react';
import { getActiveTemplates } from '../store/card/cardSelectors';
import EditorPage from "./pages/EditorPage";
import DashboardPage from "./pages/DashboardPage";
import {
  fetchImages,
  fetchIcons,
  fetchQrIcons,
  fetchTemplates,
} from "../store/card/cardActions";
import { fetchOrders, fetchPrices, fetchSubscriptionPrices } from "../store/order/orderActions";
import { getCurrentLocale } from "../store/intl/intlSelectors";
import { getUserSettings } from "../store/auth/authSelectors";
import { APP_ENVIRONMENT, ROLLBAR_ACCESS_TOKEN } from "../utils/constants";
import RedirectPage from "./pages/RedirectPage";
import "./App.scss";
import MicroPageWrapper from "./pages/microweb/MicroPageWrapper";
import { ToastContainer } from "react-toastify";
import { checkSubscriptionExpiration, refreshUser } from "../store/auth/authActions";
const HomePage = lazy(() => import("./pages/pages/HomePage"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const SignupPage = lazy(() => import("./pages/SignupPage"));
const ExportPage = lazy(() => import("./pages/customization/ExportPage"));
const ResetPasswordPage = lazy(() => import("./pages/ResetPasswordPage"));
const NewPasswordPage = lazy(() => import("./pages/NewPasswordPage"));
const Confirmation = lazy(() => import("./pages/customization/exportComponents/Confirmation"));

// microwebs
const ProductPage = lazy(() => import("./pages/microweb/productPage/ProductPage"));
const FeedbackForm = lazy(() => import("./pages/microweb/feedbackForm/FeedbackForm"));
const DigitalCard = lazy(() => import("./pages/microweb/digitalCard/DigitalCard"));
const MultiEntry = lazy(() => import("./pages/microweb/multiEntry/MultiEntry"));
const ThankYouPage = lazy(() => import("./pages/microweb/ThankYouPage"));

// static microwebs
const ProductPageStatic = lazy(() => import("./pages/staticMicroweb/ProductPageStatic"));
const FeedbackFormStatic = lazy(() => import("./pages/staticMicroweb/FeedbackFormStatic"));
const DigitalCardStatic = lazy(() => import("./pages/staticMicroweb/DigitalCardStatic"));
const MultiEntryStatic = lazy(() => import("./pages/staticMicroweb/MultiEntryStatic"));
const ActionPageStatic = lazy(() => import("./pages/staticMicroweb/ActionPageStatic"));
//import './App.scss' we dont want to style /home => moved to customizationPage

const rollbarConfig = {
  accessToken: ROLLBAR_ACCESS_TOKEN,
  environment: APP_ENVIRONMENT,
};

class App extends Component {
  componentDidMount() {
    this.props.onFetchImages(null, this.props.lang);
    this.props.onFetchIcons();
    this.props.onFetchQrIcons();
    this.props.onFetchOrders();
    this.props.onFetchPrices();
    this.props.onFetchSubscriptionPrices();
    this.props.onCheckSubscriptionExpiration()
    
    if (this.props.templates.length < 1) {
      this.props.onFetchTemplates();
    }
  }

  render() {
    return (
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <ToastContainer />
            <Switch>
              <Route path="/app/editor" component={EditorPage} />
              <Route path="/app/dashboard" component={DashboardPage} />
              <Route exact path="/export" component={ExportPage} />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/signup" component={SignupPage} />
              <Route exact path="/reset-password" component={ResetPasswordPage} />
              <Route exact path="/new-password" component={NewPasswordPage} />
              <Route exact path="/confirmation" component={Confirmation} />
              <Route exact path="/subscription-confirmation" render={() => <Confirmation subscription onRefreshUser={this.props.onRefreshUser} />} />
              <Route path="/r" component={RedirectPage} />
              {/* Microwebs */}
              <Route path="/pp/:slug" render={(props) => <MicroPageWrapper {...props} ><ProductPage /></MicroPageWrapper>}/>
              <Route path="/ff/:slug" render={(props) => <MicroPageWrapper {...props} ><FeedbackForm /></MicroPageWrapper>}/>
              <Route path="/dc/:slug" render={(props) => <MicroPageWrapper {...props} ><DigitalCard /></MicroPageWrapper>}/>
              <Route path="/me/:slug" render={(props) => <MicroPageWrapper {...props} ><MultiEntry /></MicroPageWrapper>}/>
              {/* Thankyou page */}
              <Route path="/thankyou" component={ThankYouPage}/>
              {/* Static Microwebs */}
              <Route path="/productpage" component={ProductPageStatic} />
              <Route path="/feedback" component={FeedbackFormStatic} />
              <Route path="/bizzcard" component={DigitalCardStatic} />
              <Route path="/multientry" component={MultiEntryStatic} />
              <Route path="/actionpage" component={ActionPageStatic} />
              <Route path="/cs" render={() => <HomePage locale="cs" />}  />
              <Route path="/" render={() => <HomePage locale="en" />}  />
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </Provider>
    );
  }
}

const mapState = (state) => ({
  templates: getActiveTemplates(state),
  lang: getCurrentLocale(state),
  user: getUserSettings(state),
});

const mapDispatch = (dispatch) => ({
  onFetchImages: (query, lang) => dispatch(fetchImages(query, lang)),
  onFetchIcons: () => dispatch(fetchIcons()),
  onFetchQrIcons: () => dispatch(fetchQrIcons()),
  onFetchOrders: () => dispatch(fetchOrders()),
  onFetchPrices: () => dispatch(fetchPrices()),
  onFetchTemplates : () => dispatch(fetchTemplates()),
  onFetchSubscriptionPrices: () => dispatch(fetchSubscriptionPrices()),
  onCheckSubscriptionExpiration: () => dispatch(checkSubscriptionExpiration()),
  onRefreshUser: () => dispatch(refreshUser()),
});

export default connect(mapState, mapDispatch)(App);
