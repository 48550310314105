import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {connect, useSelector} from "react-redux";
import {ReactComponent as Cross} from "../../../common/images/elements/cross.svg";
import Item from "../multiEntry/Item";
import PlusButton from "../PlusButton";
import Errors from "../Errors";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import AddImage from "../AddImage";
import Loader from "../../../common/Loader";
import {nanoid} from "nanoid";
import ThemePicker from "../ThemePicker";
import {setMultiEntry} from "../../../../../../store/microPage/microPageActions";
import {getMultiEntry} from "../../../../../../store/microPage/microPageSelector";

function MultiEntryForm(props) {
    const [image, setImage] = useState(props?.multiEntry?.image)

    const [loading, setLoading] = useState(false)
    const [focusedItemId, setFocusedItemId] = useState(null);
    const token = useSelector((store) => store.auth.userData.jwt)

    const [items, setItems] = useState(props?.multiEntry?.items ?? [])

    const [theme, setTheme] = useState(props?.multiEntry?.theme ?? 'black');

    const validation = useForm()
    const {register, watch, setValue, formState, handleSubmit} = validation;

    function handleSaveMultiEntryData(data) {
        props.saveLocallyMultiEntry(data, 'update');
        props.setDynamicQrType({ "MultiEntry": data })
    }

    // Mounted: populate from redux into validation (useForm)
    useEffect(() => {
        setValue('name', props?.multiEntry?.name ?? '')
        props.setDynamicQrType({ "MultiEntry": props.multiEntry })
    }, [])

    
    function handleUpdate(e) {
        const value = e.target.value

        handleSaveMultiEntryData({ ...props.multiEntry, name: value })
    }

    useEffect(() => {
        handleSaveMultiEntryData({ ...props.multiEntry, items, theme, image })
    }, [items, theme, image]);


    async function onSubmit(data) {

    }

    async function onError(data) {
        console.log(data, 'onError');
    }

    function addEmptyItem() {
        setItems([...items, {
            id: nanoid(),
            type: 'facebook',
            name: 'text',
            url: 'text',
        }])
    }

    // TODO: reuse
    function updateItem(updatedItem) {
        const newItems = [...items];

        const index = newItems.findIndex((item) => item.id === updatedItem.id)

        newItems[index] = updatedItem
        setItems(newItems)
    }

    function deleteItem(id) {
        setItems([...items.filter((item) => item.id !== id)])
    }

    // TODO: reuse
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const newItems = Array.from(items);
        const [reorderedItem] = newItems.splice(result.source.index, 1)
        newItems.splice(result.destination.index, 0, reorderedItem)

        setItems(newItems);
    }

    function onImageChange(image) {
        setImage(image);
    }

    function onLoadingChange(value) {
        setLoading(value)
    }

    const themesOptions = [
        {
            value: 'black',
            icon: '/assets/icons/theme_multi_entry_black.svg'
        },
        {
            value: 'red',
            icon: '/assets/icons/theme_multi_entry_red.svg'
        },
        {
            value: 'white',
            icon: '/assets/icons/theme_multi_entry_white.svg'
        },
        {
            value: 'orange',
            icon: '/assets/icons/theme_multi_entry_orange.svg'
        }
    ]

    function handleThemeChange(theme) {
        setTheme(theme);
    }

    return (
        <div className="multientry-form">
            <form onSubmit={handleSubmit(onSubmit, onError)}>

                <div className="format-container">
                    <div className="inputs-container">
                        {/* fullName */}
                        <div className="input-container">
                            <input type="text" placeholder="Add header of your page"
                                   {...register("name", {required: true, minLength: 0})} onChange={(e) => handleUpdate(e)}/>
                            <Cross className={watch("name") ? '' : 'hidden'}
                                   onClick={() => setValue('name', '')}/>
                        </div>
                        <Errors formState={formState} name="name"/>

                    </div>
                </div>

                {/* TODO: make useable component, only dragHandle style is different  */}
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <div className="mt-4">
                        <Droppable droppableId="characters">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {items.map((item, key) => {
                                        return (
                                            <Draggable draggableId={item.id} index={key} key={item.id}
                                                       style={{position: 'relative'}}>
                                                {(provided) => (
                                                    <div className="p-2 mt-4" {...provided.draggableProps}
                                                         ref={provided.innerRef}>
                                                        <div  className="position-relative">
                                                            <img {...provided.dragHandleProps}
                                                                 src="/assets/icons/drag.svg" alt="" width="22px"
                                                                 style={
                                                                     {
                                                                         position: 'absolute',
                                                                         right: '-10px',
                                                                         top: '2px',
                                                                         zIndex: '1',
                                                                     }
                                                                 }
                                                            />
                                                        </div>

                                                        <Item
                                                            key={item.id}
                                                            item={item}
                                                            onUpdate={updateItem}
                                                            onDelete={deleteItem}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                </DragDropContext>

                {/* add item */}
                <div className="inputs-container d-inline-block mt-3">
                    <div className="add-file-input" onClick={(e) => addEmptyItem()}>
                        <div
                            className="button my-1 add-file-button"
                            src="/assets/icons/plus.svg">
                            <PlusButton/>
                        </div>
                    </div>
                </div>

                {/* Upload Image */}
                <AddImage
                    onImageChange={onImageChange}
                    shape={'rect'}
                    onLoadingChange={onLoadingChange}
                    hideCheckbox={true}
                    aspect={3 / 2}
                    removeRadius={true}
                />

                {/* Loader */}
                {loading &&
                    <Loader/>
                }

                {/* Theme select */}
                <ThemePicker value={theme} options={themesOptions} onChange={handleThemeChange}/>
            </form>
        </div>
    )
}

const mapState = (state) => ({
	multiEntry: getMultiEntry(state),
})

const mapDispatch = (dispatch) => ({
    saveLocallyMultiEntry: (page, status) => dispatch(setMultiEntry(page, status)),
})

export default connect(mapState, mapDispatch)(MultiEntryForm)
