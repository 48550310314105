import React, { useState } from "react";
import Device from "./Device";
import Location from "./Location";
import Scans from "./Scans";
import "./Row.scss";

function Row({ stats }) {
  const [moreInfo, setMoreInfo] = useState(false);
  return (
    <div className="statistics-row">
      <div className="template-section">
        <img src={stats.thumbnailUrl} alt={stats.qrcodeID} />
      </div>
      <div className="stats">
        <Scans stats={stats} />
        {moreInfo ? (
          <div className={`more-stats ${moreInfo ? "active" : ""}`}>
            <Location stats={stats.country} />
            <Device stats={stats.device} />
          </div>
        ) : (
          <></>
        )}
        <svg
          className={`arrow ${moreInfo ? "flipped" : ""}`}
          onClick={() => setMoreInfo((prev) => !prev)}
          width="19"
          height="12"
          viewBox="0 0 19 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.14062 1.64648L9.65937 9.16523L17.1781 1.64648"
            stroke="#38517C"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  );
}

export default Row;
