// import { PROJECT_DATA_FETCH_START, PROJECT_DATA_FETCH_SUCCESS, PROJECT_DATA_FETCH_FAIL } from '../project/projectActions'

const loggerMiddleware = (store) => (next) => (action) => {
  // switch (action.type) {
  //   case PROJECT_DATA_FETCH_SUCCESS:
  //     console.log('CODE: loggerMiddleware msg=PROJECT_DATA_FETCH_SUCCESS')
  //     break
  //   case PROJECT_DATA_FETCH_START:
  //     console.log('CODE: loggerMiddleware msg=PROJECT_DATA_FETCH_START')
  //     break
  //
  //   case PROJECT_DATA_FETCH_FAIL:
  //     console.log('CODE: loggerMiddleware msg=PROJECT_DATA_FETCH_FAIL')
  //     break
  //
  //   default:
  //     break
  // }

  return next(action)
}

export default loggerMiddleware
