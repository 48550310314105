import React, {useEffect, useState} from "react";
import html2canvas from "html2canvas";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import SaveButton from "../../ui/SaveButton/SaveButton";
import './SaveDesign.scss';
import { apiUploadFile } from "../../../api/api";
import {connect} from "react-redux";
import {
    getActiveDesign,
    getActiveDynamicQrCodeId,
    getActiveMicroPageType,
    getDynamicQrUrl,
    getEditorMode,
    getNameOfCurrentDesignInEditor,
    getTemplateSubtype,
    getTemplateType
} from "../../../store/card/cardSelectors";
import {setActiveDesign, setEditorMode} from "../../../store/card/cardActions";
import {EDITOR_MODES} from "../../../utils/constants";
import useComponentVisible from "../../pages/UserAccountsComponents/useComponentVisible";
import AutosizeInput from 'react-input-autosize';
import {createMicroPageByType, updateMicroPageByType, setMicroPageSuccess} from "../../../store/microPage/microPageActions";
import 'react-toastify/dist/ReactToastify.css';
import {update} from "../../../api/qrcode";
import domtoimage from 'dom-to-image';
import { addDesign, updateDesign } from "../../../store/myLibrary/myLibraryActions";

function SaveDesign({
	currentName,
	activeDesign,
	currentBackTemplate,
	editorMode,
	onSetEditorMode,
	activeMicroPageType,
	onCreateMicroPageByType,
	onUpdateMicroPageByType,
	onSetActiveDesign,
	activeDynamicQrCodeId,
	dynamicQrUrl,
	activeType,
	activeSubtype,
	onAddDesign,
	onUpdateDesign,
	onSetMicroPageSuccess,
}) {

	const [name, setName] = useState<string>(currentName);
	const [nameEditing, setNameEditing] = useState<boolean>(false);
	const [ref, isFocused, setIsFocused] = useComponentVisible(null);

	useEffect(() => {
		if (name === '') {
			setName(currentName);
		}

		if (editorMode === EDITOR_MODES.CREATE) {
			setName(currentName);
		}
	}, [currentName])

	const renderImg = async () => {
		const el = document.getElementById('template');
		const scale = 1
		let myDataUrl;
		// if (el) {
		// 		try {
		// 			await toJpeg(el, {
		// 				quality: 0.9,
		// 				// @ts-ignore
		// 				height: el.offsetHeight * scale,
		// 				// @ts-ignore
		// 				width: el.offsetWidth * scale,
		// 				style: {
		// 					transformOrigin: 'top left',
		// 					transform: `scale(${scale})`,
		// 					backgroundColor: 'white',
		// 				},
		// 			})
		// 				.then(function (dataUrl) {
		// 					myDataUrl = dataUrl;
		// 			})
		// 		} catch(error) {
		// 			console.log(error)
		// 		}
		// 	}

		if (el) {
			const canvas = await html2canvas(el);
			myDataUrl = canvas.toDataURL("image/jpeg", 1.0);
		}

		//OLD VERSION
		// let myDataUrl;
		// let el = document.getElementById('template');
		// const scale = 1

		// await domtoimage.toJpeg(document.getElementById('template'), {
		// 	quality: 0.9,
		// 	// @ts-ignore
		// 	height: el.offsetHeight * scale,
		// 	// @ts-ignore
		// 	width: el.offsetWidth * scale,
		// 	style: {
		// 		transformOrigin: 'top left',
		// 		transform: `scale(${scale})`,
		// 		backgroundColor: 'white',
		// 	},
		// })
		// .then(function (dataUrl) {
		// 	myDataUrl = dataUrl;
		// });

		return fetch(myDataUrl)
			.then(async (res) => {
				const blob = await res.blob();

				var file = new File([blob], "name", {
					type: "image/jpg",
				});

				return await apiUploadFile(file);
			});
	}

	const editName = () => {
		setNameEditing(true)
		setIsFocused(true);
	}

	/*
		Handles Design, DynamicQrCode and MicroPage
	*/
	const handleSave = async () => {
		// START - temporarily enable nullable thumbnail, fix in once thumbnail is ready: https://app.clickup.com/t/302u12b
		let node = document.getElementById('template');

		let image;
		if (!node) {
			image = activeDesign?.thumbnail ?? {
					id: null,
			}
		} else {
			image = await renderImg();
		}
		// END

		const updatedActiveDesign = {
			...activeDesign,
			thumbnail: image.id,
			dynamicQrCode: activeDynamicQrCodeId
		}

		if (activeDynamicQrCodeId !== null) {
			// update url of dynQr
			await update(activeDynamicQrCodeId, dynamicQrUrl, image.url)
			if (activeMicroPageType !== null) {
				onCreateMicroPageByType(activeMicroPageType, activeDynamicQrCodeId)
			} else {
					// URL is already in DynQr
			}
		}

		if (editorMode === EDITOR_MODES.UPDATE) {
			onUpdateDesign(updatedActiveDesign, name)
			onSetMicroPageSuccess()
		} else {
			onAddDesign(updatedActiveDesign, name)
			onSetEditorMode(EDITOR_MODES.UPDATE)
			onSetMicroPageSuccess()
		}
	}

	return (
		<div id="saving" className="justify-content-end">
			<div className="save-design">
				{nameEditing && isFocused ?
					<button className="design-button d-flex d-flex justify-content-center align-items-center px-5" ref={ref}>
						{/* CSS in the input have some issues https://www.npmjs.com/package/react-input-autosize */}
						<img className="pr-4" src="/images/pencil.svg"/>
						<AutosizeInput
							inputStyle={{
								fontSize: '1.4rem',
								fontWeight: 'bold',
								color: '#38517c'
							}}
							name="form-field-name"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>

						{/*<input className="input-text" type="text" value={name} onChange={(e) => setName(e.target.value)}/>*/}
					</button>
					:
					<button
						className="design-button d-flex d-flex justify-content-center align-items-center px-5"
						onClick={() => editName()} style={{
							border: 'none',
							whiteSpace: 'nowrap'
						}}
					>
						<img className="pr-4" src="/images/folder.svg"/>
						{name}
					</button>
				}
				<SaveButton editorMode={editorMode} onClickHandler={handleSave}/>
			</div>
		</div>
	);
}

const mapState = (state) => ({
	currentName: getNameOfCurrentDesignInEditor(state),
	activeDesign: getActiveDesign(state),
	currentBackTemplate: state.card.currentBackTemplate,
	editorMode: getEditorMode(state),
	activeMicroPageType: getActiveMicroPageType(state),
	activeDynamicQrCodeId: getActiveDynamicQrCodeId(state),
	dynamicQrUrl: getDynamicQrUrl(state),
	activeType: getTemplateType(state),
	activeSubtype: getTemplateSubtype(state),
})

const mapDispatch = (dispatch) => ({
	onSetEditorMode: (mode) => dispatch(setEditorMode(mode)),
	onCreateMicroPageByType: (type, activeDynamicQrCodeId) => dispatch(createMicroPageByType(type, activeDynamicQrCodeId)),
	onUpdateMicroPageByType: (type, activeDynamicQrCodeId) => dispatch(updateMicroPageByType(type, activeDynamicQrCodeId)),
	onSetActiveDesign: (design) => dispatch(setActiveDesign(design)),
	onAddDesign: (design, name) => dispatch(addDesign(design, name)),
	onUpdateDesign: (design, name) => dispatch(updateDesign(design, name)),
	onSetMicroPageSuccess: () => dispatch(setMicroPageSuccess()),
})

export default connect(mapState, mapDispatch)(SaveDesign)
