import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateDesign } from '../../../../../store/card/cardActions'
import { ReactSVG } from 'react-svg'


import ColorPicker from '../../common/ColorPicker'
import ColorGradient from '../../common/ColorGradient'
import SelectDesign from './SelectDesign'
import arrow_icon from '../../common/images/elements/arrow_active.svg'

import './DesignTab.scss'

import { FormattedMessage } from 'react-intl'

function DesignTab({ activeLayer }) {
  const [colorPickerType, setColorPickerType] = useState(activeLayer.colorPickerType)
  const [customType, setCustomType] = useState("body")
  const [userGradientType, setUserGradientType] = useState('Linear Gradient')
  const dispatch = useDispatch()


  const handleColorType = (type) => {
    const editedActiveLayer = { ...activeLayer }
    editedActiveLayer.colorPickerType = type
    // create initial custom design config
    if(type === "Custom" && !activeLayer.customDesignConfig) {
      editedActiveLayer.customDesignConfig = {
        body: {
          shape:activeLayer.designType,
          color: activeLayer.solidColor
        },
        frame: {
          shape:activeLayer.designType,
          color: activeLayer.solidColor
        },
        ball: {
          shape:activeLayer.designType,
          color: activeLayer.solidColor
        }
      }
    }

    setColorPickerType(type)
    dispatch(updateDesign(editedActiveLayer))
  }
  function handleGradientChange() {
    if (!activeLayer.colorPickerType.includes('Gradient')) {
      // Initially set previous gradient type which user has entered
      handleColorType(userGradientType)
      return
    }
    if (colorPickerType === "Linear Gradient") {
      handleColorType('Radial Gradient')
      setUserGradientType('Radial Gradient')
    } else {
      handleColorType('Linear Gradient')
      setUserGradientType('Linear Gradient')
    }
  }

  return (
    <div className="design-tab">
      <div className="buttons">
        <button onClick={() => handleColorType('Solid color')} className={`${colorPickerType === 'Solid color' && 'selected'}`}><FormattedMessage id="colors.solid" /></button>
        <button onClick={handleGradientChange} className={`gradient ${colorPickerType.includes("Gradient") && 'selected'}`}>Gradient {colorPickerType === "Linear Gradient" ? <span><FormattedMessage id="colors.linear" /> <ReactSVG src={arrow_icon} /></span> : colorPickerType === "Radial Gradient" ? <span><FormattedMessage id="colors.radial" /> <ReactSVG src={arrow_icon} /></span> : "" }</button>
        <button onClick={() => handleColorType('Custom')} className={`custom ${colorPickerType === 'Custom' && 'selected'}`}><FormattedMessage id="colors.custom" /></button>
      </div>
      {colorPickerType === "Custom" ?
      <div className="buttons">
        <button onClick={()=> setCustomType("body")} className={`${customType === 'body' && 'selected'}`}>Body</button>
        <button onClick={()=> setCustomType("frame")} className={`${customType === 'frame' && 'selected'}`}>Eye frame</button>
        <button onClick={()=> setCustomType("ball")} className={`${customType === 'ball' && 'selected'}`}>Eye ball</button>
      </div> : <></>
      }

      {colorPickerType.includes('Solid') || colorPickerType === "Custom" ? (
        <ColorPicker customType={customType}/>
      ) : colorPickerType.includes('Linear') ? (
        <ColorGradient type="qr" />
      ) : (
        <ColorGradient type="qr" gradientType="radial" />
      )}

      <SelectDesign colorPickerType={colorPickerType} customType={customType} activeLayer={activeLayer} />
    </div>
  )
}

export default DesignTab
