import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import Row from "./statisticsComponents/Row";
import { getStats } from "../../../api/qrcode";
import { useSelector } from "react-redux";
import Calendar from "react-calendar";
import countries from "./countries.json";
import { set } from "lodash";

function QrStatistics() {
  const [stats, setStats] = useState([]);
  const [date1, setDate1] = useState();
  const [date2, setDate2] = useState();
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [country, setCountry] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const token = useSelector((store) => store.auth.userData.jwt);
  const wrapperRef = useRef(null)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return function cleanup() {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef && wrapperRef.current.className !== event.target.className && event.target.className !== 'item' && event.target.className !== '' &&
    event.target.className.split(" ")[0] !== 'react-calendar__tile' && event.target.className.split(" ")[0] !== 'react-calendar__navigation__arrow') {
      setIsOpen1(false);
      setIsOpen2(false);
      setIsOpen3(false);
    }
  }

  useEffect(() => {
    (async () => {
      console.log(countryCode)
      setStats(await getStats(token, date1, date2, countryCode, 5));
      setStats(await getStats(token, date1, date2, countryCode));
    })();
  }, [date1, date2, countryCode]);

  function generateDate(timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-GB');
  }

  return (
    <div id="Statistics">
      <h1><FormattedMessage id="dashboard.mainMenu.qrStatistics"/></h1>
      <div className="filters">
        <div className="filter-wrapper">
          <div className="filter">
            <div
              onClick={() => {
                setIsOpen1((prev) => !prev);
                setIsOpen2(false);
                setIsOpen3(false);
              }}
              className="button"
              ref={wrapperRef}
            >
              {country ? <FormattedMessage id="dashboard.statistics.country"/> : <FormattedMessage id="dashboard.statistics.filter"/>}:{" "}
              {country ? country : <FormattedMessage id="dashboard.statistics.country"/>}
              <img
                className="arrow"
                src="/assets/icons/arrow-gray.svg"
                alt=""
              />
            </div>
            <img
              onClick={() => {
                setCountry(null);
                setCountryCode(null);
              }}
              className="cross"
              src="/assets/icons/cross.svg"
              alt=""
            />
          </div>
          {isOpen1 ? (
            <div className="list-wrapper">
              <div className="list">
                {countries.map((country) => (
                  <div
                    key={country.Code}
                    onClick={() => {
                      setCountry(country.Name);
                      setCountryCode(country.Code);
                      setIsOpen1(false);
                    }}
                    className="item"
                  >
                    {country.Name}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="filter-wrapper">
          <div className="filter">
            <div
              onClick={() => {
                setIsOpen2((prev) => !prev)
                setIsOpen1(false);
                setIsOpen3(false);
             }}
              className="button"
              ref={wrapperRef}
            >
              <FormattedMessage id="dashboard.statistics.filter"/>: {date1 ? generateDate(date1) : <FormattedMessage id="dashboard.statistics.from"/>}{" "}
              <img
                className="arrow"
                src="/assets/icons/arrow-gray.svg"
                alt=""
              />
            </div>
            <img
              onClick={() => setDate1(null)}
              className="cross"
              src="/assets/icons/cross.svg"
              alt=""
            />
          </div>
          {isOpen2 ? (
            <Calendar
              defaultValue={date1 ? new Date(date1) : ""}
              maxDate={date2 ? new Date(date2) : ""}
              onChange={(e) => {
                setDate1(e.getTime());
                setIsOpen2(false);
              }}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="filter-wrapper">
          <div className="filter">
            <div
              onClick={() => {
                setIsOpen3((prev) => !prev)
                setIsOpen1(false);
                setIsOpen2(false);
              }}
              className="button"
              ref={wrapperRef}
            >
              <FormattedMessage id="dashboard.statistics.filter"/>: {date2 ? generateDate(date2) : <FormattedMessage id="dashboard.statistics.to"/>}{" "}
              <img
                className="arrow"
                src="/assets/icons/arrow-gray.svg"
                alt=""
              />
            </div>
            <img
              onClick={() => setDate2(null)}
              className="cross"
              src="/assets/icons/cross.svg"
              alt=""
            />
          </div>
          {isOpen3 ? (
            <Calendar
              defaultValue={date2 ? new Date(date2) : ""}
              minDate={date1 ? new Date(date1) : ""}
              onChange={(e) => {
                setDate2(e.getTime());
                setIsOpen3(false);
              }}
            />
          ) : (
            <></>
          )}
        </div>
        {/* <Calendar onChange={(e) => setDate1(e.getTime())} />
        <Calendar onChange={(e) => setDate2(e.getTime())} /> */}
      </div>
      {stats.map((qrcode, index) => (
        <Row stats={stats[index]} key={qrcode.qrcodeID} />
      ))}
    </div>
  );
}

export default QrStatistics;
