import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import VerticalAlignButtons from './VerticalAlignButtons'
import HorizontalAlignButtons from './HorizontalAlignButtons'
import ColorPicker from '../common/ColorPicker'
import { updateDesign, setRecentFonts } from '../../../../store/card/cardActions'
import { getActiveLayer, getRecentFonts } from '../../../../store/card/cardSelectors'
import { fonts } from '../../../../utils/constants'

import './TextCustomizationSection.scss'

const TextCustomizationSection = (props) => {
  const [dropdownOpen, setOpen] = useState(false)
  const [currentFont, setFont] = useState(props.activeLayer.styles.fontFamily)
  const [activeLayer, setActiveLayer] = useState(props.activeLayer)
  const [recentFonts, setRecentFonts] = useState(props.recentFonts)
  const [fontSize, setFontSize] = useState(props.activeLayer.styles.fontSize && Number(props.activeLayer.styles.fontSize.split('rem')[0]) * 10)
  let fontItems = []
  let recentFontItems = []
  let sortedFonts = fonts.sort()

  const toggle = () => setOpen(!dropdownOpen)

  const handleFontChange = (font) => {
    const fontIndex = recentFonts.findIndex(fontName => fontName === font)

    setFont(font)
    props.onSetRecentFonts(font)

    if (fontIndex === -1 && recentFonts.length < 5) {
      recentFonts.unshift(font)
    } else {
      recentFonts.splice(fontIndex, 1)
      recentFonts.unshift(font)
    }

    setRecentFonts(recentFonts)
    handleStylesChange('fontFamily', font)
  }

  useEffect(() => {
    function handleFontChange(status) {
      setFont(props.activeLayer.styles.fontFamily)
    }

    handleFontChange()
  })

  useEffect(() => {
    handleStylesChange('fontSize', (fontSize/10).toFixed(1) + 'rem')
  },[fontSize])

  useEffect(()=> {
    if (props.activeLayer.styles.fontSize) {
      setFontSize(Number(props.activeLayer.styles.fontSize.split('rem')[0]) * 10)
      setFont(props.activeLayer.styles.fontFamily)
    }
  },[props.activeLayer])

  const handleFontSizeChange = (counter) => {

    if (counter === 'up') {
      setFontSize(prev => prev + 1)
    } else {
      setFontSize(prev => prev - 1)
    }

  }
  const handleFontSizeInput = (e) => {
    setFontSize(parseInt(e.target.value))
  }

  const handleStylesChange = (type, value) => {
    const { activeLayer, onChangeTemplate } = props
    const changedLayer = {...activeLayer}
    const changedLayerStyles = {...changedLayer.styles}

    changedLayerStyles[type] = value
    changedLayer.styles = changedLayerStyles

    setActiveLayer(changedLayer)

    onChangeTemplate(changedLayer)
  }

  recentFonts.forEach(font => (
    recentFontItems.push(<DropdownItem key={font} style={{ fontFamily: font }} onClick={() => handleFontChange(font)}>{font}</DropdownItem>)
  ))

  sortedFonts.forEach(font => (
    fontItems.push(<DropdownItem key={font} style={{ fontFamily: font }} onClick={() => handleFontChange(font)}>{font}</DropdownItem>)
  ))

  return (
    <div className="text-customization-section">
      <div className="customization-dropdown">
        <ButtonDropdown className="dropdown" isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle style={{ fontFamily: currentFont }} caret>
            {currentFont}
          </DropdownToggle>
          <DropdownMenu>
            {recentFontItems}
            <DropdownItem divider />
            {fontItems}
            <DropdownItem onClick={() => handleFontChange('Open Sans')}>Reset to default</DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
        <div className="font-size-component">
          <input onChange={handleFontSizeInput} value={fontSize} type="text" />
          <span>px</span>
          <img onClick={() => handleFontSizeChange('up')} className="arrow up" src="/assets/images/text/bigger-blue-arrow.svg" alt="arrow-up" />
          <img onClick={() => handleFontSizeChange('down')} className="arrow down" src="/assets/images/text/bigger-blue-arrow.svg" alt="arrow-down" />
        </div>
      </div>
      <div className="customization-sections">
        <div className="buttons-block">
          <VerticalAlignButtons />
          <HorizontalAlignButtons />
        </div>
        <ColorPicker />
      </div>
      {/* <Button size="lg">Upload overlay image</Button> */}
    </div>
  )
}

const mapState = (state) => ({
  activeLayer: getActiveLayer(state),
  recentFonts: getRecentFonts(state),
})

const mapDispatch = (dispatch) => ({
  onChangeTemplate: (layer) => dispatch(updateDesign(layer)),
  onSetRecentFonts: (font) => dispatch(setRecentFonts(font)),
})

export default connect(mapState, mapDispatch)(TextCustomizationSection)
