import React from "react";
import {connect} from "react-redux";
import {changeVisual} from "../../../store/card/cardActions";
import {getActiveTemplate} from "../../../store/card/cardSelectors";

function VisualPicker({activeTemplate, onChangeVisual}) {
  const visuals = [activeTemplate.visual1, activeTemplate.visual2, activeTemplate.visual3]

  return (
    <div className="color-variants">
      {visuals && visuals.length > 1
        ? visuals.map((visual, id) => {
          if (visual) {
            return (
              <div
                key={id}
                className={`colors ${activeTemplate.visuals === 'visual' + (id + 1) ? 'active' : ''}`}
                onClick={() => onChangeVisual(visual, 'visual' + (id + 1))}
              >
                <div className="colors-content">
                  {visual[0].colors && visual[0].colors.length > 1
                    ? visual[0].colors.map((color) => <span className="color-part" style={{ backgroundColor: color }}></span>)
                    : null}
                </div>
              </div>
            )
          }})
        : null}
    </div>
  );
}

const mapState = (state) => ({
  activeTemplate: getActiveTemplate(state),
})

const mapDispatch = (dispatch) => ({
  onChangeVisual: (visual, id) => dispatch(changeVisual(visual, id)),
})

export default connect(mapState, mapDispatch)(VisualPicker)
