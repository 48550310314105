import {Design, MicroPageType} from "../ts/types";
import {apiGetDigitalCard, apiGetFeedbackForm, apiGetMultiEntry, apiGetProductPage} from "../api/micropage";
import {store} from "../store/initializator";
import {setDigitalCard, setFeedbackForm, setMultiEntry, setProductPage} from "../store/microPage/microPageActions";
import {
    setActiveDesign,
    setActiveDynamicQrId,
    setDynamicQrUrl,
    setIsDynamicQrFormatActive
} from "../store/card/cardActions";

const loadMicroPageByTypeAndId = async (type: MicroPageType, id: string) => {
    switch (type) {
        case MicroPageType.PRODUCT_PAGE:
            return await apiGetProductPage(id)
        case MicroPageType.FEEDBACK_FORM:
            return await apiGetFeedbackForm(id)
        case MicroPageType.DIGITAL_CARD:
            return await apiGetDigitalCard(id)
        case MicroPageType.MULTI_ENTRY:
            return await apiGetMultiEntry(id)
    }
}

const loadDesignIntoEditor = async (design: Design) => {
    store.dispatch(setActiveDesign(design))

    if (design.dynamicQrCode) {
        store.dispatch(setActiveDynamicQrId(design.dynamicQrCode.id))
        store.dispatch(setDynamicQrUrl(design.dynamicQrCode.url))
        store.dispatch(setIsDynamicQrFormatActive(true))
        if (design.dynamicQrCode?.microPage) {
            const id = design.dynamicQrCode.microPage.id;
            const type = design.dynamicQrCode.microPage.type;

            // TODO; centerlize switch logic
            switch (type) {
                case MicroPageType.PRODUCT_PAGE: {
                    store.dispatch(setProductPage(
                        await loadMicroPageByTypeAndId(type, id), 'load'
                    ));
                }
                    break;
                case MicroPageType.FEEDBACK_FORM: {
                    store.dispatch(setFeedbackForm(
                        await loadMicroPageByTypeAndId(type, id), 'load'
                    ));
                }
                    break;
                case MicroPageType.DIGITAL_CARD: {
                    store.dispatch(setDigitalCard(
                        await loadMicroPageByTypeAndId(type, id), 'load'
                    ));
                }
                    break;
                case MicroPageType.MULTI_ENTRY: {
                    store.dispatch(setMultiEntry(
                        await loadMicroPageByTypeAndId(type, id), 'load'
                    ));
                }
                    break;
            }
        }
    } else {
        store.dispatch(setActiveDynamicQrId(null))
        store.dispatch(setIsDynamicQrFormatActive(false))
    }
}

export {
    loadDesignIntoEditor
}
