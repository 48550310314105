import { getDesigns } from '../myLibrary/myLibrarySelectors'

export const getUserData = (state) => state.auth.userData

export const getUserSettings = (state) => state.auth.userData.user

export const getRememberedUserEmail = (state) => state.auth.userEmail

export const getIsRememberChecked = (state) => state.auth.isRememberChecked

export const getFetchStatus = (state) => state.auth.fetchStatus

export const getLoginProvider = (state) => state.auth.loginProvider

export const getSubscriptionPlan = (state) => state.auth.userData.user.subscriptionPlan

export const getIsSubscriptionActive = (state) => state.auth.userData.user.isSubscriptionActive

export const getSubscriptionExpiration = (state) => state.auth.userData.user.subscriptionExpiration

export const getUserDesigns = (state) => state.auth.userData.user.designs

export const getIsDynamicQrCodesAllowed = (state) => {
  const subscriptionPlan = getSubscriptionPlan(state)
  const designs = getDesigns(state)
  const subscriptionExpiration = getSubscriptionExpiration(state)
  const dynamicQrCodesCount = subscriptionExpiration ? Number(subscriptionPlan.substring(subscriptionPlan.lastIndexOf(' ') + 1)) : 1
  let activeDynamicQrCodesCount = 0

  designs.forEach(design => {
    if (design.dynamicQrCode !== null) activeDynamicQrCodesCount++
  });

  return activeDynamicQrCodesCount < dynamicQrCodesCount
}
