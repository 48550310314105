import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Link } from "react-router-dom";

import { getUserSettings } from '../../../../store/auth/authSelectors'
import { changeUserSettings } from '../../../../store/auth/authActions'
import { SHIPPING_COUNTRIES, SHIPPING_COUNTRY_CITIES } from '../../../../utils/countries'
import { setIsRedirected } from '../../../../store/order/orderActions';
import { getSubscriptionPeriod, getSubscriptionPlans, getSubscriptionPlanType } from '../../../../store/order/orderSelectors'
import { ReactComponent as Arrow } from '../../customization/common/images/elements/arrow.svg'
import SubscriptionPlan from './SubscriptionPlan'

// import './SubscriptionOrderPage.scss'

function SubscriptionOrderPage({ userSettings, onChangeUserSettings, subscriptionPlans, onSetIsRedirected, subscriptionPlanType, subscriptionPeriod }) {
  const [orderFormData, setOrderFormData] = useState(userSettings)
  const [countries, setCountries] = useState([])
	const [regions, setRegions] = useState([])
  const [isBusinessPurchase, toggleBusinessPurchase] = useState(false)
  const [period, setPeriod] = useState(subscriptionPeriod)
  const [currency, setCurrency] = useState(orderFormData.currency.toLowerCase())
  const [subscriptionPlan, setSubscriptionPlan] = useState(subscriptionPlans[subscriptionPlanType.replaceAll(' ', '_')][period][currency])

  useEffect(() => {
    onSetIsRedirected(false)
    
		Object.values(SHIPPING_COUNTRIES)
					.map(countriesArr_ => countriesArr_.split('|')
					.map(country => setCountries(allCountries => [...allCountries, country].sort())))
		setRegion()
	}, [])

  const setRegion = () => {
		const country_ = orderFormData.shippingCountry || countries[0] || "Afghanistan"
		Object.entries(SHIPPING_COUNTRY_CITIES).forEach(([country, citiesArr]) => {
			if (country_ === country) {
				const regionsArr = citiesArr.split('||')[1]
				setRegions(regionsArr.split('|').map(region => region !== '' && region))
			}
		})
	}

  const handleChangeFormData = (e, type, name) => {
		const value = e.target.value
		const updatedOrderFormData = { ...orderFormData }

		if (type === 'shippingAddress' && !updatedOrderFormData.shippingAddress) {
			updatedOrderFormData.shippingAddress = {}
		} else if (type === 'billingAddress' && !updatedOrderFormData.billingAddress) {
			updatedOrderFormData.billingAddress = {}
		}

		if (name === 'address') {
      updatedOrderFormData[type].address = value
    } else if (name === 'address2') {
      updatedOrderFormData[type].address2 = value
    } else if (name === 'city') {
      updatedOrderFormData[type].city = value
    } else if (name === 'zip') {
      updatedOrderFormData[type].zip = value
    } else if (name === 'country') {
      updatedOrderFormData[type].country = value
    } else {
      updatedOrderFormData[type] = value
    }

		setOrderFormData(updatedOrderFormData)
	}

  const handleSaveUserSettings = (e, applyParent = false) => {
		// Handling shipping country change
		const inputElement = applyParent ? e.currentTarget.parentNode : e.currentTarget;
		// Class is used for saving animation
		inputElement.classList.add("saving");

		userSettings = orderFormData
		onChangeUserSettings(userSettings)
		setTimeout(() => {
			inputElement.classList.remove("saving");
		}, 3000);
	}

  return (
    <div id="SubscriptionOrderPage">
      <section className="subscription-order-page">
        <div className="sections-wrapper">
          <section className="left-section">
            <div className="back-button">
              <Link to="/app/dashboard/subscription">
                <svg width="17" height="27" viewBox="0 0 17 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.0703 2.50586L3.99947 13.5767L15.0703 24.6476" stroke="#172339" strokeWidth="5"/>
                </svg>
              </Link>
            </div>
            <h3>
              QRcards <FormattedMessage id="dashboard.subscription.subscription" />
            </h3>
            <div className="subscription-order-form">
              <span><FormattedMessage id="dashboard.generalSettings.yourName" /></span>
              <input
                className='rect'
                placeholder='Your name'
                type="text"
                value={orderFormData.name || ''}
                onChange={(e) => handleChangeFormData(e, 'name')}
                onBlur={(e) => handleSaveUserSettings(e)}
              />
              <span><FormattedMessage id="dashboard.generalSettings.shippingAddress" /></span>
              <div className='inputs-block'>
                <input
                  className='rect first-row'
                  placeholder='Address 1'
                  type="text"
                  value={orderFormData.shippingAddress?.address || ''}
                  onChange={(e) => handleChangeFormData(e, 'shippingAddress', 'address')}
                  onBlur={(e) => handleSaveUserSettings(e, true)}
                />
                <input
                  className='rect first-row'
                  placeholder='Address 2 (optional)'
                  type="text"
                  value={orderFormData.shippingAddress?.address2 || ''}
                  onChange={(e) => handleChangeFormData(e, 'shippingAddress', 'address2')}
                  onBlur={(e) => handleSaveUserSettings(e, true)}
                />
                <div className='second-row-wrapper'>
                  <input
                    className='rect bigger'
                    placeholder='City'
                    type="text"
                    value={orderFormData.shippingAddress?.city || ''}
                    onChange={(e) => handleChangeFormData(e, 'shippingAddress', 'city')}
                    onBlur={(e) => handleSaveUserSettings(e, true)}
                  />
                  <input
                    className='rect smaller'
                    placeholder='ZIP'
                    type="text"
                    value={orderFormData.shippingAddress?.zip || ''}
                    onChange={(e) => handleChangeFormData(e, 'shippingAddress', 'zip')}
                    onBlur={(e) => handleSaveUserSettings(e, true)}
                  />
                </div>
              </div>
              <div className='shipping-row-wrapper'>
                <div className='shipping-second-row'>
                  <span><FormattedMessage id="dashboard.generalSettings.shippingCountry" /></span>
                  <select
                    id="shipping-countries"
                    onChange={(e) => { handleChangeFormData(e, 'shippingCountry'); setRegion(); }}
                    onBlur={(e) => handleSaveUserSettings(e, false)}
                    className="shipping-countries-dropdown"
                    value={orderFormData.shippingCountry}
                  >
                    {countries.map((country, index) => {
                      return (
                        <option
                          key={index}
                          value={country}
                          className='shipping-countries-value'
                        >
                          {country}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className='shipping-second-row'>
                  <span><FormattedMessage id="dashboard.generalSettings.shippingRegion" /></span>
                  {regions[0] &&
                    <select
                      id='shipping-region'
                      onChange={(e) => handleChangeFormData(e, 'shippingRegion')}
                      onBlur={(e) => handleSaveUserSettings(e, false)}
                      className="shipping-countries-dropdown"
                      value={orderFormData.shippingRegion}
                    >
                      {regions.map((region, index) => {
                        return (
                          <option
                            key={index}
                            value={region}
                          >
                            {region}
                          </option>
                        )
                      })}
                    </select>
                  }
                </div>
              </div>
              <span
                onClick={() => toggleBusinessPurchase(!isBusinessPurchase)}
                className='business-button'
              >
                <FormattedMessage id="dashboard.generalSettings.purchasing" />
                <Arrow className={`arrow ${isBusinessPurchase ? 'direction-up' : 'direction-down'}`}/>
              </span>
              <div className={`inputs-block ${!isBusinessPurchase ? 'hidden' : ''}`}>
                <input
                  className='rect first-row'
                  placeholder='Company name'
                  type="text"
                  value={orderFormData.companyName || ''}
                  onChange={(e) => handleChangeFormData(e, 'companyName')}
                  onBlur={(e) => handleSaveUserSettings(e, true)}
                />
                <div className='second-row-wrapper'>
                  <input
                    className='rect'
                    placeholder='VAT ID'
                    type="text"
                    value={orderFormData.vatNumber || ''}
                    onChange={(e) => handleChangeFormData(e, 'vatNumber')}
                    onBlur={(e) => handleSaveUserSettings(e, true)}
                  />
                </div>
              </div>
              <div className="agreements">
                <div className="checkbox confirmation">
                  <input type="checkbox" />
                  <span>
                    <FormattedMessage id="dashboard.subscription.termsText1" />
                    {' '}
                    <Link to={`/terms-of-use`}><FormattedMessage id="dashboard.subscription.terms" /></Link>
                    {' '}
                    <FormattedMessage id="dashboard.subscription.termsText2" />
                    {' '}
                    <Link to={`/privacy-policy`}><FormattedMessage id="dashboard.subscription.privacy" /></Link>
                  </span>
                </div>
                <div className="checkbox confirmation">
                  <input type="checkbox" />
                  <span>
                    <FormattedMessage id="dashboard.subscription.informed" />
                  </span>
                </div>
              </div>
              <button
                onClick={() => window.location.href = `/app/dashboard/subscription/order/payment?isBusinessPurchase=${isBusinessPurchase}`}
                className={`rect final-button`}
              >
                <FormattedMessage id="dashboard.subscription.pay" />
                {` ${subscriptionPlan.price} ${currency === 'eur' ? '€' : currency === 'usd' ? '$' : currency === 'gbp' ? '£' : 'Kč'} + `}
                <FormattedMessage id="dashboard.subscription.vat" />
                {' '}
                <FormattedMessage id="dashboard.subscription.card" />
                <svg width="14" height="9" viewBox="0 0 14 9" fill="none">
                  <path d="M0.463623 4.17276H8.64544" stroke="white" strokeWidth="1.5" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.31812 0.0820312L13.2272 4.17294L8.31812 8.26385V0.0820312Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </section>
          <section className="right-section">
            <h3>
              <FormattedMessage id="dashboard.subscription.selectedProduct" />
            </h3>
            <div id="Subscription">
              <div className="blocks-wrapper confirmation">
                <SubscriptionPlan
                  quantity={subscriptionPlan.quantity}
                  price={subscriptionPlan.text}
                  period={period}
                  isConfirmation
                />
              </div>
            </div>
            <div className="info-section">
              <div className="info">
                <img src="/images/icons/check-icon.svg" alt="check" />
                <span>
                  <FormattedMessage id="dashboard.subscription.range" />
                </span>
              </div>
              <div className="info">
                <img src="/images/icons/check-icon.svg" alt="check" />
                <span>
                  <FormattedMessage id="dashboard.subscription.unlimited" />
                </span>
              </div>
              <div className="info">
                <img src="/images/icons/check-icon.svg" alt="check" />
                <span>
                  <FormattedMessage id="dashboard.subscription.advanced" />
                </span>
              </div>
              <div className="info">
                <img src="/images/icons/check-icon.svg" alt="check" />
                <span>
                  <FormattedMessage id="dashboard.subscription.templates" />
                </span>
              </div>
              <div className="info">
                <img src="/images/icons/check-icon.svg" alt="check" />
                <span>
                  <FormattedMessage id="dashboard.subscription.verification" />
                </span>
              </div>
              <div className="info">
                <img src="/images/icons/check-icon.svg" alt="check" />
                <span>
                  <FormattedMessage id="dashboard.subscription.dynamic" />
                </span>
              </div>
              <div className="info">
                <img src="/images/icons/check-icon.svg" alt="check" />
                <span>
                  <FormattedMessage id="dashboard.subscription.editor" />
                </span>
              </div>
              <div className="info">
                <img src="/images/icons/check-icon.svg" alt="check" />
                <span>
                  <FormattedMessage id="dashboard.subscription.builder" />
                </span>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  )
}

const mapState = (state) => ({
  userSettings: getUserSettings(state),
  subscriptionPlans: getSubscriptionPlans(state),
  subscriptionPlanType: getSubscriptionPlanType(state),
  subscriptionPeriod: getSubscriptionPeriod(state),
})

const mapDispatch = (dispatch) => ({
  onChangeUserSettings: (userSettings) => dispatch(changeUserSettings(userSettings)),
  onSetIsRedirected: (isRedirected) => dispatch(setIsRedirected(isRedirected)),
})

export default connect(mapState, mapDispatch)(SubscriptionOrderPage)
