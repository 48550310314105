import cloudFile_icon from '../../../common/images/formats/cloudFile.svg'
import document_icon from '../../../common/images/formats/cloudFile.svg'
import email_icon from '../../../common/images/formats/email.svg'
import googleAdress_icon from '../../../common/images/formats/googleAdress.svg'
import location_icon from '../../../common/images/formats/location.svg'
import payment_icon from '../../../common/images/formats/payment.svg'
import phone_icon from '../../../common/images/formats/phone.svg'
import plainText_icon from '../../../common/images/formats/plainText.svg'
import socials_icon from '../../../common/images/formats/socials.svg'
import multiEntry_icon from '../../../common/images/formats/multiEntry.svg'
import feedback_icon from '../../../common/images/formats/feedback_form.svg'
import product_icon from '../../../common/images/formats/product.svg'
import qr_icon from '../../../common/images/formats/qr_code.svg'
import vCard_icon from '../../../common/images/formats/vCard.svg'
import wallet_icon from '../../../common/images/formats/wallet.svg'
import webUrl_icon from '../../../common/images/formats/webUrl.svg'
import wifi_icon from '../../../common/images/formats/wifi.svg'

import {generateAdress, QRCodeType} from './codeGenerator'

class QRCodeFormat {
  constructor(icon, name) {
    this.isDynamic = false
    this.dynamicID = ''
    this.icon = icon
    this.name = name
    this.type = ''
    this.config = ''
    this.code = ''
    this.error = 'Data ve vašem QR kodu jsou původní, nezměněné. Nezapomněli jste je změnit?'
  }
  setConfig(type, config) {
    this.type = type
    this.config = config
    return this
  }
  setDynamicURL(url) {
    this.dynamicURL = url
    return this
  }
  setDynamic() {
    this.isDynamic = true
    return this
  }
  generateCode() {
    const result = generateAdress(this.type, this.config)
    this.code = result[0]
    this.error = result[1]

    return this
  }
}

const webUrl = new QRCodeFormat(webUrl_icon, ['Web page', 'Webová Stránka'])
  .setConfig(QRCodeType.WebsiteURL, { url: { content: '', placeholder: ['URL', 'Odkaz'] } })
  .generateCode()
const googleAdress = new QRCodeFormat(googleAdress_icon, ['Address', 'Adresa'])
  .setConfig(QRCodeType.GoogleAddress, {
    street: { content: '', placeholder: ['Street', 'Ulice a č.p.'] },
    zipCode: { content: '', placeholder: ['ZIP code', 'PSČ'] },
    city: { content: '', placeholder: ['City', 'Město'] },
    country: { content: '', placeholder: ['Country', 'Země'] },
  })
  .generateCode()
const socials = new QRCodeFormat(socials_icon, ['Social media', 'Socialní síť'])
  .setConfig(QRCodeType.SocialNetwork, { url: { content: '', placeholder: ['URL', 'Odkaz'] } })
  .generateCode()
const wallet = new QRCodeFormat(wallet_icon, ['Wallet', 'Kryptoměny'])
  .setConfig(QRCodeType.CryptoWallet, { address: { content: '', placeholder: ['Address', 'Peněženka'] } })
  .generateCode()
const vCard = new QRCodeFormat(vCard_icon, ['VCard', 'Vizitka (vCard)'])
  .setConfig(QRCodeType.VCard, {
    firstname: { content: '', placeholder: ['Firstname', 'Jméno'] },
    surname: { content: '', placeholder: ['Surname', 'Příjmení'] },
    title: { content: '', placeholder: ['Title', 'Funkce'] },
    organization: { content: '', placeholder: ['Organization', 'Organizace'] },
    web: { content: '', placeholder: ['Web', 'Web'] },
    email: { content: '', placeholder: ['Email', 'Email'] },
    phone: { content: '', placeholder: ['Phone', 'Telefon'] },
  })
  .generateCode()
/* const messenger = new QRCodeFormat(payment_icon, 'Messenger')
  .setConfig(QRCodeType.Messenger, { messengerId: { content: '', placeholder: 'Messenger ID' } })
  .generateCode() */
const locations = new QRCodeFormat(location_icon, ['Location', 'Lokace'])
  .setConfig(QRCodeType.Location, {
    latitude: { content: '', placeholder: ['Latitude', 'Zeměpisná šířka'] },
    longitude: { content: '', placeholder: ['Longitude', 'Zeměpisná výška'] },
    googleFormat: { content: false },
  })
  .generateCode()
const cloudFile = new QRCodeFormat(cloudFile_icon, ['Cloud file', 'Soubor'])
  .setConfig(QRCodeType.CloudFile, { url: { content: '', placeholder: ['URL', 'Odkaz'] } })
  .generateCode()
const email = new QRCodeFormat(email_icon, ['Email', 'Email'])
  .setConfig(QRCodeType.Email, { email: { content: '', placeholder: ['Email', 'Email'] } })
  .generateCode()
const phone = new QRCodeFormat(phone_icon, ['Phone', 'Telefon'])
  .setConfig(QRCodeType.Phone, { phone: { content: '', placeholder: ['Phone', 'Telefon'] } })
  .generateCode()
const wifi = new QRCodeFormat(wifi_icon, ['Wifi', 'Wifi'])
  .setConfig(QRCodeType.WifiNetwork, {
    ssid: { content: '', placeholder: ['SSID', 'Jméno sítě'] },
    encryption: {
      content: 'WPA - default encryption',
      placeholder: ['Encryption', 'Zabezpečení'],
    },
    password: { content: '', placeholder: ['Password', 'Heslo sítě'] },
  })
  .generateCode()
const plainText = new QRCodeFormat(plainText_icon, ['Plain text', 'Obyčejný text'])
  .setConfig(QRCodeType.PlainText, { text: { content: '', placeholder: ['Text', 'Text'] } })
  .generateCode()

const payment = new QRCodeFormat(payment_icon, ['Payment', 'Bank. platba'])
  .setConfig(QRCodeType.Payment, {
    iban: { content: '', placeholder: ['IBAN', 'IBAN'] },
    amount: { content: '', placeholder: ['Amount', 'Částka'] },
    currency: { content: '', placeholder: ['Currency (EUR or CZK)', 'Měna (EUR or CZK)'] },
    paymentReference: { content: '', placeholder: ['Payment reference', 'Variabilní symbol'] },
    message: { content: '', placeholder: ['Message', 'Zpráva'] },
  })
  .generateCode()

// Premium types
const webUrlPremium = new QRCodeFormat(webUrl_icon, ['Web page', 'Webová Stránka'])
  .setConfig(QRCodeType.WebsiteURL, { url: { content: '', placeholder: ['URL', 'Odkaz'] } })
  .setDynamic()
  .generateCode()

const multiEntryPremium = new QRCodeFormat(multiEntry_icon, ['Multi-Entry QR', 'Multi-Entry QR'])
  .setConfig(QRCodeType.MultiEntry, {})
  .setDynamic()
  .generateCode()

const socialsPremium = new QRCodeFormat(socials_icon, ['Social media', 'Socialní síť'])
  .setConfig(QRCodeType.SocialNetwork, { url: { content: '', placeholder: ['URL', 'Odkaz'] } })
  .setDynamic()
  .generateCode()

const feedbackFormPremium = new QRCodeFormat(feedback_icon, ['Feedback form', 'Feedback form'])
  .setConfig(QRCodeType.Feedback, {})
  .setDynamic()
  .generateCode()

const documentPremium = new QRCodeFormat(document_icon, ['Document', 'Document'])
    .setConfig(QRCodeType.SocialNetwork, { url: { content: '', placeholder: ['URL', 'Odkaz'] } })
    .setDynamic()
    .generateCode()

const productPagePremium = new QRCodeFormat(product_icon, ['Product page', 'Product page'])
    .setConfig(QRCodeType.ProductPage, {})
    .setDynamic()
    .generateCode()

const vCardPremium = new QRCodeFormat(vCard_icon, ['Digital vCard', 'Digital vCard'])
    .setConfig(QRCodeType.VCardDynamic, {})
    .setDynamic()
    .generateCode()

const existingQrPremium = new QRCodeFormat(qr_icon, ['Use existing QR', 'Use existing QR'])
    .setConfig(QRCodeType.SocialNetwork, { url: { content: '', placeholder: ['URL', 'Odkaz'] } })
    .setDynamic()
    .generateCode()

export { generateAdress }
export const list = [webUrl, socials, wallet, payment, googleAdress, locations, vCard, cloudFile, email, phone, wifi, plainText]
export const premiumList = {
    dynamic: [
      webUrlPremium,
      socialsPremium,
      documentPremium,
      existingQrPremium
    ],
    dynamicWithLanding: [
      multiEntryPremium,
      feedbackFormPremium,
      productPagePremium,
      vCardPremium,
    ]
}