import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
  ORDER_START,
  ORDER_SUCCESS,
  ORDER_FAIL,
  ORDER_INPUT_CHANGE,
  USER_VALUES_UPDATE,
  ORDERS_SET,
  PRICES_SET,
  SUBSCRIPTION_PLANS_SET,
  SUBSCRIPTION_PLAN_TYPE_SET,
  SUBSCRIPTION_PERIOD_SET,
  REDIRECTION_SET,
} from './orderActions'


const initialState = {
  fetchStatus: 'UNTOUCHED',
  orderStatus: 'UNTOUCHED',
  paymentStatus: 'UNTOUCHED',
  orders: null,
  error: '',
  type: '',
  email: '',
  name: '',
  phone: '',
  country: '',
  countryState: '',
  company: '',
  vat: '',
  shippingAddress: {},
  billingAddress: {},
  fileFormat: '',
  paymentOption: '',
  material: 'Matt',
  quantity: 0,
  rounded: false,
  price: 0,
  termsAgreement: false,
  emailAgreement: false,
  expressDelivery: false,
  prices: null,
  subscriptionPlans: null,
  subscriptionPlanType: null,
  subscriptionPeriod: null,
  isRedirected: false,
}


const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_INPUT_CHANGE: {
      return {
        ...state,
        [action.name]: action.value,
        // price: getCurrentPrice(state),
      }
    }

    case USER_VALUES_UPDATE: {
      return {
        ...state,
        email: action.values.email,
        name: action.values.name,
        company: action.values.companyName,
        country: action.values.shippingAddress.country ? action.values.shippingAddress.country : '',
        shippingAddress: action.values.shippingAddress,
        billingAddress: action.values.billingAddress,
        phone: action.values.phoneNumber,
        vat: action.values.vatNumber,
      }
    }

    case ORDER_START:
      return {
        ...state,
        fetchStatus: 'WORKING',
      }

    case ORDER_SUCCESS: {
      return {
        ...state,
        fetchStatus: 'SUCCESS',
      }
    }

    case ORDER_FAIL:
      return {
        ...state,
        fetchStatus: 'ERROR',
      }

    case ORDERS_SET:
      return {
        ...state,
        orders: action.orders,
      }

    case PRICES_SET:
      return {
        ...state,
        prices: action.prices,
      }

    case SUBSCRIPTION_PLANS_SET:
      return {
        ...state,
        subscriptionPlans: action.subscriptionPlans,
      }
    
    case SUBSCRIPTION_PLAN_TYPE_SET:
    return {
      ...state,
      subscriptionPlanType: action.subscriptionPlanType,
    }

    case SUBSCRIPTION_PERIOD_SET:
    return {
      ...state,
      subscriptionPeriod: action.subscriptionPeriod,
    }

    case REDIRECTION_SET:
      return {
        ...state,
        isRedirected: action.isRedirected,
      }

    default:
      break
  }

  return state
}

/**
 * Configuration of redux-persist used for serialization of redux state to local storage.
 */
const persistConfig = {
  key: 'order',
  storage,
  blacklist: [],
}

const persistableReducer = persistReducer(persistConfig, orderReducer)

export default persistableReducer
