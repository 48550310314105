export const getProductPage = (state) => {
    return state.microPage.productPage
}

export const getDigitalCard = (state) => {
    return state.microPage.digitalCard
}

export const getFeedbackForm = (state) => {
    return state.microPage.feedbackForm
}

export const getMultiEntry = (state) => {
    return state.microPage.multiEntry
}

export const getMicroPageUpdateStatus = (state) => {
    return state.microPage.updateStatus
}