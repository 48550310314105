import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import SubscriptionPlan from './SubscriptionPlan'

function SubscriptionConfirmation({ handleCancelledPlan, period, isRenewing, handleRenewedPlan, setIsCancelling, subscriptionPlan, subscriptionExpiration }) {
  return (
    <div id='Subscription'>
      <div className="subscription-confirmation">
        <h1>
          {isRenewing ?
              <FormattedMessage id="dashboard.subscription.renewPlan" />
            :
              <FormattedMessage id="dashboard.subscription.cancelPlan" />
          }
        </h1>
        <div className="choose-text">
          <FormattedMessage id="dashboard.subscription.available" /> {new Date(subscriptionExpiration).toLocaleDateString('cs')}
        </div>
        <div className="blocks-wrapper confirmation">
          <SubscriptionPlan
            quantity={subscriptionPlan.quantity}
            price={subscriptionPlan.text}
            period={period}
            isConfirmation
          />
        </div>
        {!isRenewing &&
          <div onClick={setIsCancelling} className="round-button back">
            <FormattedMessage id="dashboard.subscription.back" />
          </div>
        }
        <div onClick={isRenewing ? handleRenewedPlan : handleCancelledPlan} className={`round-button ${isRenewing ? 'back' : 'red'}`}>
          {isRenewing ?
            <FormattedMessage id="dashboard.subscription.renewThisPlan" />
          :
            <FormattedMessage id="dashboard.subscription.cancelThisPlan" />
          }
        </div>
        {isRenewing &&
          <div onClick={setIsCancelling} className={`round-button ${isRenewing ? 'red' : 'back'}`}>
            <FormattedMessage id="dashboard.subscription.back" />
          </div>
        }
      </div>
    </div>
  )
}

export default SubscriptionConfirmation
