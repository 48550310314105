import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateDesign } from '../../../../../store/card/cardActions'

import { ReactComponent as Square } from '../../common//images/corners/square.svg'
import { ReactComponent as Circle } from '../../common/images/corners/circle.svg'
import { ReactComponent as Round } from '../../common/images/corners/round.svg'
import { ReactComponent as Sharp } from '../../common/images/corners/sharp.svg'
import { ReactComponent as SquareBody } from '../../common//images/customQR/bodies/square.svg'
import { ReactComponent as CircleBody } from '../../common/images/customQR/bodies/circle.svg'
import { ReactComponent as RoundBody } from '../../common/images/customQR/bodies/round.svg'
import { ReactComponent as SharpBody } from '../../common/images/customQR/bodies/sharp.svg'
import { ReactComponent as SquareFrame } from '../../common//images/customQR/frames/square.svg'
import { ReactComponent as CircleFrame } from '../../common/images/customQR/frames/circle.svg'
import { ReactComponent as RoundFrame } from '../../common/images/customQR/frames/round.svg'
import { ReactComponent as SharpFrame } from '../../common/images/customQR/frames/sharp.svg'
import { ReactComponent as SquareBall } from '../../common//images/customQR/balls/square.svg'
import { ReactComponent as CircleBall } from '../../common/images/customQR/balls/circle.svg'
import { ReactComponent as RoundBall } from '../../common/images/customQR/balls/round.svg'
import { ReactComponent as SharpBall } from '../../common/images/customQR/balls/sharp.svg'
import './SelectDesign.scss'

function SelectDesign({activeLayer, customType, colorPickerType}) {
  const [selectedDesign, setDesign] = useState(activeLayer.designType);
  const [customDesignConfig, setCustomDesignConfig] = useState(activeLayer.customDesignConfig || {body: {
    shape:activeLayer.designType,
    color: activeLayer.solidColor
  },
  frame: {
    shape:activeLayer.designType,
    color: activeLayer.solidColor
  },
  ball: {
    shape:activeLayer.designType,
    color: activeLayer.solidColor
  }});
  const dispatch = useDispatch()

  // whole qr code design
  const handleDesign = (type) => {
    const editedActiveLayer = {...activeLayer}
    editedActiveLayer.designType = type

    setDesign(type)
    dispatch(updateDesign(editedActiveLayer))
  }
  // design of ball, frame, body
  const handleCustomDesign = (part, type) => {
    const editedActiveLayer = {...activeLayer}
    editedActiveLayer.customDesignConfig[part].shape = type

    setCustomDesignConfig(editedActiveLayer.customDesignConfig)
    dispatch(updateDesign(editedActiveLayer))
  }

  return (<>
  {colorPickerType !== "Custom" ?
    <div className="select-design">
      <div onClick={() => handleDesign('square')} className={`button ${selectedDesign === 'square' && 'active'}`}>
        <Square />
      </div>
      <div onClick={() => handleDesign('sharp')} className={`button ${selectedDesign=== 'sharp' && 'active'}`}>
        <Sharp />
      </div>
      <div onClick={() => handleDesign('circle')} className={`button ${selectedDesign === 'circle' && 'active'}`}>
        <Circle />
      </div>
      <div onClick={() => handleDesign('round')} className={`button ${selectedDesign === 'round' && 'active'}`}>
        <Round />
      </div>
    </div> : <></>
  }
    {customType === "body" && colorPickerType === "Custom" ?
    <div className="select-design">
      <div onClick={() => handleCustomDesign('body','square')} className={`button ${customDesignConfig.body.shape === 'square' && 'active'}`}>
        <SquareBody />
      </div>
      <div onClick={() => handleCustomDesign('body','sharp')} className={`button ${customDesignConfig.body.shape=== 'sharp' && 'active'}`}>
        <SharpBody />
      </div>
      <div onClick={() => handleCustomDesign('body','circle')} className={`button ${customDesignConfig.body.shape === 'circle' && 'active'}`}>
        <CircleBody />
      </div>
      <div onClick={() => handleCustomDesign('body','round')} className={`button ${customDesignConfig.body.shape === 'round' && 'active'}`}>
        <RoundBody />
      </div>
    </div> : <></>
    }
    {customType === "frame" && colorPickerType === "Custom" ?
    <div className="select-design">
      <div onClick={() => handleCustomDesign('frame','square')} className={`button ${customDesignConfig.frame.shape === 'square' && 'active'}`}>
        <SquareFrame />
      </div>
      <div onClick={() => handleCustomDesign('frame','sharp')} className={`button ${customDesignConfig.frame.shape=== 'sharp' && 'active'}`}>
        <SharpFrame />
      </div>
      <div onClick={() => handleCustomDesign('frame','circle')} className={`button ${customDesignConfig.frame.shape === 'circle' && 'active'}`}>
        <CircleFrame />
      </div>
      <div onClick={() => handleCustomDesign('frame','round')} className={`button ${customDesignConfig.frame.shape === 'round' && 'active'}`}>
        <RoundFrame />
      </div>
    </div> : <></>
    }
    {customType === "ball" && colorPickerType === "Custom" ?
    <div className="select-design">
      <div onClick={() => handleCustomDesign('ball','square')} className={`button ${customDesignConfig.ball.shape === 'square' && 'active'}`}>
        <SquareBall />
      </div>
      <div onClick={() => handleCustomDesign('ball','sharp')} className={`button ${customDesignConfig.ball.shape=== 'sharp' && 'active'}`}>
        <SharpBall />
      </div>
      <div onClick={() => handleCustomDesign('ball','circle')} className={`button ${customDesignConfig.ball.shape === 'circle' && 'active'}`}>
        <CircleBall />
      </div>
      <div onClick={() => handleCustomDesign('ball','round')} className={`button ${customDesignConfig.ball.shape === 'round' && 'active'}`}>
        <RoundBall />
      </div>
    </div> : <></>
    }
  </>
  )
}

export default SelectDesign
