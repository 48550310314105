import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

function SubscriptionPlan({ quantity, price, period, isWithLabel, labelType, onClick, isActive, onHandleCancel, isCancelled, isConfirmation, activePlan, subscriptionExpiration }) {
  return (
    <div className="subscription-plan">
      <div className={`block rect ${isWithLabel || isActive ? 'with-label' : ''}`}>
        {(isWithLabel || isActive) &&
          <div className='label'>
            {
              isCancelled ?
                <span><FormattedMessage id="dashboard.subscription.expiring" />{' '}{new Date(subscriptionExpiration).toLocaleDateString('cs')}</span>
              : isActive ?
                <FormattedMessage id="dashboard.subscription.active" />
              : labelType === 'popular' && activePlan === "DYNAMIC QR 1" ?
                <FormattedMessage id="home.presale.popular" />
              : labelType === 'best' && activePlan === "DYNAMIC QR 1" ?
                <FormattedMessage id="home.presale.best" />
              : null
            }
          </div>
        }
        <h2>
          {quantity}
          {' '}
          {quantity === 1 ?
              <FormattedMessage id="home.presale.code" />
            :
              <FormattedMessage id="home.presale.codes" />
          }
        </h2>
        <div className="price">
          <span className='value'>{price}</span>
          <span className="period">/
            {
              period === 'monthly' ?
                <FormattedMessage id="home.presale.monthly" />
              :
                <FormattedMessage id="home.presale.yearly" />
            }
          </span>
        </div>
        <div
          onClick={isConfirmation ? null : !isActive && !isCancelled ? onClick : onHandleCancel}
          className={`round-button green-button ${isActive && !isCancelled ? "cancel" : isConfirmation ? "confirmation" :""}`}
        >
          {
            isCancelled ?
              <FormattedMessage id="dashboard.subscription.renew" />
            : isConfirmation ?
              <FormattedMessage id="dashboard.subscription.selected" />
            : !isActive ?
              <FormattedMessage id="home.presale.select" />
            :
              <>
                <FormattedMessage id="dashboard.subscription.cancel" />
                <svg className='bin' width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.5084 4.08515V4.0355C16.5084 2.2797 15.448 0.856445 14.1403 0.856445H3.11031C1.80172 0.856445 0.741211 2.27988 0.741211 4.0355V4.08515H16.5084Z" fill="white" />
                  <path d="M16.5929 6.79591C16.3288 6.48688 15.9427 6.30859 15.5358 6.30859H1.71523C1.30813 6.30859 0.921701 6.48688 0.657546 6.79591C0.393214 7.10548 0.277833 7.5147 0.341266 7.91703L2.35079 20.6281C2.45751 21.3043 3.03989 21.8017 3.72475 21.8017H13.5259C14.2102 21.8017 14.7928 21.3045 14.8999 20.6281L16.9094 7.91703C16.973 7.5147 16.8573 7.10548 16.5929 6.79591ZM4.72642 19.9123C4.7031 19.9145 4.67942 19.9155 4.65645 19.9155C4.24882 19.9155 3.9018 19.6037 3.86575 19.1897L3.03477 9.68855C2.99625 9.25159 3.32013 8.86604 3.75709 8.82823C4.19069 8.78123 4.57995 9.11306 4.61793 9.55055L5.44927 19.0518C5.48726 19.4888 5.16391 19.8747 4.72642 19.9123ZM9.42009 19.1211C9.42009 19.5597 9.0637 19.9155 8.62497 19.9155C8.18589 19.9155 7.83021 19.5597 7.83021 19.1211V9.58324C7.83021 9.14416 8.18606 8.78847 8.62497 8.78847C9.06387 8.78847 9.42009 9.14451 9.42009 9.58324V19.1211ZM13.2829 19.1895C13.2471 19.6037 12.9004 19.9153 12.4924 19.9153C12.4698 19.9153 12.4456 19.9143 12.4223 19.9122C11.9851 19.8745 11.6613 19.4877 11.7 19.0517L12.5306 9.55037C12.5687 9.11288 12.9552 8.783 13.3916 8.82805C13.8293 8.86587 14.1521 9.25159 14.1137 9.68837L13.2829 19.1895Z" fill="white" />
                </svg>
              </>
          }
        </div>
        <div className='parameters'>
          <p><FormattedMessage id="dashboard.subscription.features" /></p>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionPlan
