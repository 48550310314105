import React from "react";
import './CancelButton.scss'

function CancelButton() {
    return (
        <svg className="cancel-button" width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.5977 13.3379C25.5977 20.2415 20.0012 25.8379 13.0977 25.8379C6.1941 25.8379 0.597656 20.2415 0.597656 13.3379C0.597656 6.43433 6.1941 0.837891 13.0977 0.837891C20.0012 0.837891 25.5977 6.43433 25.5977 13.3379Z" fill="white" stroke="#BCBFC6"/>
            <path d="M8.78125 9.02148L17.4137 17.6539" stroke="#38537C" stroke-width="2" strokeLinejoin="round" strokeLinejoin="round"/>
            <path d="M17.4141 9.02148L8.78162 17.6539" stroke="#38537C" stroke-width="2" strokeLinejoin="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default CancelButton;
