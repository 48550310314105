import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import ClipLoader from "react-spinners/ClipLoader";
import { EDITOR_MODES } from "../../../utils/constants";
import './SaveButton.scss';
import { getUpdateStatus } from "../../../store/card/cardSelectors";
import { getMicroPageUpdateStatus } from "../../../store/microPage/microPageSelector";

const SaveButton = ({editorMode, onClickHandler, updateStatus, microPageUpdateStatus}) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);

  const handler = async () => {
    setLoading(true);
    setTimeout(async () => {
      await onClickHandler();
      setLoading(false);
      setIsSaved(true)
    }, 100);
  }

  useEffect(() => {
    if (updateStatus === 'WORKING' || microPageUpdateStatus === 'WORKING') setIsSaved(false)
  }, [updateStatus, microPageUpdateStatus])
  

  return (
    <button className={`save-button ${isSaved ? 'saved': ''}`} onClick={() => handler()}>
      <span className="text">
        {editorMode === EDITOR_MODES.UPDATE ? (isSaved ? <FormattedMessage id="qr.saved"/> : <FormattedMessage id="qr.update"/>) : (isSaved ? <FormattedMessage id="qr.saved"/> : <FormattedMessage id="qr.save"/>)}
      </span>
      <div className="icon">
        {isSaved ?
          <img src="/assets/icons/save-button-check.svg" alt="check" />
        :
          <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.3182 0H9.22727V3.27273C9.22727 3.57382 8.98291 3.81818 8.68182 3.81818H3.77273C3.47164 3.81818 3.22727 3.57382 3.22727 3.27273V0H1.59091C0.988182 0 0.5 0.488182 0.5 1.09091V10.9091C0.5 11.5118 0.988182 12 1.59091 12H11.4091C12.0118 12 12.5 11.5118 12.5 10.9091V2.18182L10.3182 0ZM10.3182 10.9091H2.68182V7.63636C2.68182 7.03364 3.17 6.54545 3.77273 6.54545H9.22727C9.83 6.54545 10.3182 7.03364 10.3182 7.63636V10.9091Z" fill="white"/>
          </svg>
        }
      </div>
      <div className="loader">
        <ClipLoader loading={loading} color="#FFF" size={15}/>
      </div>
    </button>
  );
}

const mapState = (state) => ({
	updateStatus: getUpdateStatus(state),
  microPageUpdateStatus: getMicroPageUpdateStatus(state),
})

export default connect(mapState)(SaveButton);
