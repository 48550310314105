import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import SubscriptionPlan from './SubscriptionPlan'
import SubscriptionOverview from './SubscriptionOverview'
import SubscriptionConfirmation from './SubscriptionConfirmation'
import { getIsSubscriptionActive, getSubscriptionPlan, getUserSettings, getSubscriptionExpiration } from '../../../../store/auth/authSelectors'
import { setSubscriptionPlanType, setSubscriptionPeriod } from '../../../../store/order/orderActions'
import { changeSubscriptionStatus } from '../../../../store/auth/authActions'
import { getSubscriptionPlans } from '../../../../store/order/orderSelectors'

function Subscription({
  subscriptionPlanType,
  onSetSubscriptionPeriod,
  onSetSubscriptionPlanType,
  isSubscriptionActive,
  onChangeSubscriptionStatus,
  userSettings,
  subscriptionPlans,
  subscriptionExpiration,
}) {
  const [period, setPeriod] = useState('monthly')
  const [isCancelling, setIsCancelling] = useState('')
  const [currency, setCurrency] = useState(userSettings.currency.toLowerCase())
  const [subscriptionPlan, setSubscriptionPlan] = useState(subscriptionPlanType !== 'DYNAMIC QR 1' && subscriptionPlans[subscriptionPlanType.replaceAll(' ', '_')][period][currency])

  const history = useHistory()

  const handleActivePlan = (planType) => {
    onSetSubscriptionPeriod(period)
    onSetSubscriptionPlanType(planType)
    history.push('/app/dashboard/subscription/order')
  }

  const handleSubscriptionStatus = (isCancelled) => {
    onChangeSubscriptionStatus(isCancelled)
    setIsCancelling(false)
  }

  return (
    isCancelling ?
      <SubscriptionConfirmation
        period={period}
        isRenewing={!isSubscriptionActive ? true : false}
        setIsCancelling={() => setIsCancelling(false)}
        handleCancelledPlan={() => handleSubscriptionStatus(true)}
        handleRenewedPlan={() => handleSubscriptionStatus(false)}
        subscriptionPlan={subscriptionPlan}
        subscriptionExpiration={subscriptionExpiration}
      />
    :
      <SubscriptionOverview
        period={period}
        setPeriod={setPeriod}
        activePlan={subscriptionExpiration ? subscriptionPlanType : 'DYNAMIC QR 1'}
        cancelledPlan={!isSubscriptionActive && subscriptionExpiration && subscriptionPlanType}
        handleActivePlan={handleActivePlan}
        setIsCancelling={() => setIsCancelling(true)}
        isSubscriptionActive={isSubscriptionActive}
        subscriptionExpiration={subscriptionExpiration}
      />
  )
}

const mapState = (state) => ({
  subscriptionPlanType: getSubscriptionPlan(state),
  isSubscriptionActive: getIsSubscriptionActive(state),
  subscriptionExpiration: getSubscriptionExpiration(state),
  userSettings: getUserSettings(state),
  subscriptionPlans: getSubscriptionPlans(state),
})

const mapDispatch = (dispatch) => ({
  onSetSubscriptionPlanType: (type) => dispatch(setSubscriptionPlanType(type)),
  onSetSubscriptionPeriod: (period) => dispatch(setSubscriptionPeriod(period)),
  onChangeSubscriptionStatus: (isCancelled) => dispatch(changeSubscriptionStatus(isCancelled)),
})

export default connect(mapState, mapDispatch)(Subscription)
