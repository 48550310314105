import {useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";
import {ReactSVG} from "react-svg";
import question_icon from "../../common/images/question-icon.svg";
import React from "react";

function PremiumList({list, handleFormat, setIsFormatShown, activeLayer, type}) {
  const lang = useSelector((store) => store.intl.currentLocale)
  return (
    <div>
      <div className="qrcode-type">
				<span>
					<FormattedMessage id={`qr.${type}`}/>
				</span>
        <ReactSVG src={question_icon} className="question-mark"></ReactSVG>
      </div>
      <div className="grid-container">
        {list.map((item, index) => {
          return (
            <div
              key={index}
              className={`selection ${item.name[0] === 'Use existing QR' ? 'disabled' : ''} ${item.name === activeLayer.object.name ? 'selected' : ''}`}
              onClick={() => {
                handleFormat(item, true)
                setIsFormatShown(true)
              }}
            >
              <ReactSVG src={item.icon}></ReactSVG>
              <span>{item.name[lang === 'en' ? 0 : 1]}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PremiumList;
