import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ReactComponent as AlignLeft } from './images/align-left.svg'
import { ReactComponent as AlignCenter } from './images/align-center.svg'
import { ReactComponent as AlignRight } from './images/align-right.svg'
import { updateDesign } from '../../../../store/card/cardActions'
import { getActiveLayer } from '../../../../store/card/cardSelectors'
import './AlignButtons.scss'

class HorizontalAlignButtons extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeLayer: props.activeLayer,
    }
  }

  handleStylesChange = (type, value) => {
    const { onChangeTemplate } = this.props
    const { activeLayer } = this.props
    const changedLayer = {...activeLayer}
    const changedLayerStyles = {...changedLayer.styles}

    changedLayerStyles[type] = value
    changedLayer.styles = changedLayerStyles

    this.setState({
      activeLayer: changedLayer,
    })

    onChangeTemplate(changedLayer)
  }

  render() {
    const { activeLayer } = this.props
    const alignment = activeLayer.styles.textAlign

    return (
      <div className="horizontal buttons-group">
        <div onClick={() => this.handleStylesChange('textAlign', 'left')} className={`button ${alignment === 'left' ? 'active' : ''}`}>
          <AlignLeft />
        </div>
        <div onClick={() => this.handleStylesChange('textAlign', 'center')} className={`button ${alignment === 'center' ? 'active' : ''}`}>
          <AlignCenter />
        </div>
        <div onClick={() => this.handleStylesChange('textAlign', 'right')} className={`button no-margin ${alignment === 'right' ? 'active' : ''}`}>
          <AlignRight />
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  activeLayer: getActiveLayer(state),
})

const mapDispatch = (dispatch) => ({
  onChangeTemplate: (layer) => dispatch(updateDesign(layer)),
})

export default connect(mapState, mapDispatch)(HorizontalAlignButtons)
