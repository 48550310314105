import {
  apiCreateOrder,
  apiGetOrders,
  apiGetPrices,
  apiGetSubscriptionPrices,
} from '../../api/api'

import { getOrderData, getShippingAddress } from './orderSelectors'
import { getUserSettings } from '../auth/authSelectors'
import { createSubscriptionPlansObject } from '../../utils/helpers'

export const ORDER_START = 'ORDER_START'
export const ORDER_SUCCESS = 'ORDER_SUCCESS'
export const ORDER_FAIL = 'ORDER_FAIL'
export const ORDER_INPUT_CHANGE = 'ORDER_INPUT_CHANGE'
export const USER_VALUES_UPDATE = 'USER_VALUES_UPDATE'
export const ORDERS_SET = 'ORDERS_SET'
export const PRICES_SET = 'PRICES_SET'
export const SUBSCRIPTION_PLANS_SET = 'SUBSCRIPTION_PLANS_SET'
export const SUBSCRIPTION_PLAN_TYPE_SET = 'SUBSCRIPTION_PLAN_TYPE_SET'
export const SUBSCRIPTION_PERIOD_SET = 'SUBSCRIPTION_PERIOD_SET'
export const REDIRECTION_SET = 'REDIRECTION_SET'

const orderStart = () => ({ type: ORDER_START })
const orderSuccess = () => ({ type: ORDER_SUCCESS })
const orderFail = () => ({ type: ORDER_FAIL })

const orderInputChange = (name, value) => ({ type: ORDER_INPUT_CHANGE, name, value })
const userValuesUpdate = (values) => ({ type: USER_VALUES_UPDATE, values  })
const setOrders = (orders) => ({ type: ORDERS_SET, orders })
const setPrices = (prices) => ({ type: PRICES_SET, prices })
const setSubscriptionPlans = (subscriptionPlans) => ({ type: SUBSCRIPTION_PLANS_SET, subscriptionPlans })
export const setSubscriptionPlanType = (subscriptionPlanType) => ({ type: SUBSCRIPTION_PLAN_TYPE_SET, subscriptionPlanType })
export const setSubscriptionPeriod = (subscriptionPeriod) => ({ type: SUBSCRIPTION_PERIOD_SET, subscriptionPeriod })
export const setIsRedirected = (isRedirected) => ({ type: REDIRECTION_SET, isRedirected })


export const createOrder = () => (dispatch, getState) => {
  const orderData = getOrderData(getState())

  dispatch(orderStart())

  return apiCreateOrder(orderData)
    .then((data) => dispatch(orderSuccess()))
    .catch(async error => {
      await error
      dispatch(orderFail())
    })
}

export const changeOrderInput = (name, value, type) => (dispatch, getState) => {
  let shippingAddress = getShippingAddress(getState())

  if (name === 'shippingAddress') {
    shippingAddress[type] = value
    dispatch(orderInputChange(name, shippingAddress))
  } else {
    dispatch(orderInputChange(name, value))
  }
}

export const updateUserValues = () => (dispatch, getState) => {
  const userValues = getUserSettings(getState())

  dispatch(userValuesUpdate(userValues))
}

export const fetchOrders = () => (dispatch) => {
  return apiGetOrders()
    .then((data) => dispatch(setOrders(data)))
    .catch(async error => {
      await error
    })
}

export const fetchPrices = () => (dispatch) => {
  return apiGetPrices()
    .then((data) => dispatch(setPrices(data)))
    .catch(async error => {
      await error
    })
}

export const fetchSubscriptionPrices = () => (dispatch) => {
  console.log('Fetching subscription prices')
  return apiGetSubscriptionPrices()
    .then((data) => {
      console.log('Fetched subscription prices: ', data)
      const subscriptionPlans = createSubscriptionPlansObject(data.prices.data)
      console.log('Subscription plans: ', subscriptionPlans)
      dispatch(setSubscriptionPlans(subscriptionPlans))
    })
    .catch(async error => {
      console.log(error)
    })
}
