import React, { useState } from 'react'
import { useDispatch, connect } from 'react-redux'
import { updateDesign } from '../../../../../store/card/cardActions'
import { getUploadedIcons, getQrIcons } from '../../../../../store/card/cardSelectors'
import AddImageButton from '../../common/AddImageButton'
import { FormattedMessage } from 'react-intl'

import './IconsList.scss'

function IconsList({ activeLayer, uploadedIcons, qrIcons }) {
  const [selectedIcon, setIcon] = useState(activeLayer.icon)
  const [tag, setTag] = useState('')
  const dispatch = useDispatch()

  function handleIconChange(src) {
    const editedActiveLayer = { ...activeLayer }
    editedActiveLayer.icon = src

    setIcon(src)
    dispatch(updateDesign(editedActiveLayer))
  }

  const handleInputChange = ({ target: { value } }) => {
    setTag(value)
  }

  const Icon = ({ src, remove, isUploaded }) => {
    if (isUploaded) {
      return (
        <div className={`icon ${selectedIcon === src && 'active'}`} onClick={() => handleIconChange(remove ? null : src)}>
          <img src={src} alt="" />
        </div>
      )
    } else {
      return (
        <div className={`icon ${selectedIcon === src && 'active'}`} onClick={() => handleIconChange(remove ? null : src)}>
          <img src={src} alt="" />
        </div>
      )
    }
  }

  let icons = []

  qrIcons.forEach((icon) => {
    if (icon.icon.name.indexOf(tag) !== -1) {
      if (icon.icon.name === 'remove-icon') {
        icons.push(<Icon src={`${icon.icon.url}`} remove={true}/>)
      } else {
        icons.push(<Icon src={`${icon.icon.url}`}/>)
      }
    }
  })

  if (uploadedIcons) {
    uploadedIcons.forEach((icon) => {
      icons.push(<Icon src={icon.icon} isUploaded />)
    })
  }

  return (
    <div className="qr-icons-section">
      <div className="search-upload">
        <div className="search-input">
          <FormattedMessage id="iconslist.search">
            {(msg) => <input type="text" placeholder={msg} value={tag} onChange={(e) => this.handleInputChange(e)} />}
          </FormattedMessage>
        </div>
        <div className="primary-button-wrapper">
          <AddImageButton />
        </div>
      </div>
      <div className="icons-grid">
        <div className="icon">
          <img onClick={() => handleIconChange(null)} src="/assets/icons/qr-code-icons-list/remove-icon.svg" alt="" />
        </div>
        {icons}
      </div>
    </div>
  )
}

const mapState = (state) => ({
  uploadedIcons: getUploadedIcons(state),
  qrIcons: getQrIcons(state),
})

const mapDispatch = (dispatch) => ({
  onChangeTemplate: (layer) => dispatch(updateDesign(layer)),
})

export default connect(mapState, mapDispatch)(IconsList)
