import {TYPES} from "../../utils/constants";

export const createDesignObjectFromTemplate = (template) => {
  const type = template.type;

  if (type === TYPES.B_CARD || type === TYPES.LABEL) {
    return {
      ...template,
      layers: {
        front: [...template.layers],
        back: [...template.layers]
      }
    }
  }

  return {
    ...template,
    layers: {
      front: [...template.layers],
      back: []
    }
  }
}
