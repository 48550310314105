import React from "react";
import { FormattedMessage } from "react-intl";
import { ReactSVG } from "react-svg";
import question_icon from "../../common/images/question-icon.svg";
import { ReactComponent as PipeIcon } from "../../common/images/pipe-icon.svg";

function UpgradePlan() {
  return (
    <>
      <div className="qrcode-type">
				<span>
					<FormattedMessage id='qr.dynamic' />
				</span>
        <ReactSVG src={question_icon} className="question-mark"></ReactSVG>
      </div>
      <div className="premium-board">
        <span>
          <span className="line-text">
            <PipeIcon className="pipe"/>
            <FormattedMessage id="qr.text1"/>
          </span>
        </span>

        <span>
          <span className="line-text">
            <PipeIcon className="pipe"/>
            <FormattedMessage id="qr.text2"/>
          </span>
        </span>

        <span>
          <span className="line-text">
           <PipeIcon className="pipe"/>
            <FormattedMessage id="qr.text3"/>
          </span>
        </span>
        <span>
          <span className="line-text">
            <PipeIcon className="pipe"/>
            <FormattedMessage id="qr.text4"/>
          </span>
        </span>

        <div className="action">
          <div className="button" onClick={() => window.location.href = `/app/dashboard/subscription`}>
            <FormattedMessage id="qr.button"/>
          </div>
          <div className="info">
            <FormattedMessage id="qr.info"/>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpgradePlan;