import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import DoughnutGraph from "./DoughnutGraph";
import List from "./List";

function Device({ stats }) {
  const [display, setDisplay] = useState("graph");
  return (
    <div className="device">
      <div className="title">
        <h2><FormattedMessage id="dashboard.statistics.device"/></h2>
        <div className="buttons-wrapper">
          <button
            onClick={() => setDisplay("graph")}
            className={`switch-button ${display === "graph" ? "selected" : ""}`}
          >
            <FormattedMessage id="dashboard.statistics.pie"/>
          </button>
          <button
            onClick={() => setDisplay("list")}
            className={`switch-button ${display === "list" ? "selected" : ""}`}
          >
            <FormattedMessage id="dashboard.statistics.list"/>
          </button>
        </div>
      </div>
      <div className="view">
        {display === "graph" ? (
          <DoughnutGraph stats={stats} />
        ) : (
          <List stats={stats} />
        )}
      </div>
    </div>
  );
}

export default Device;
