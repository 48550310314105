import domtoimage from 'dom-to-image'

async function downloadExport(node, settings) {
  let scale = 4 //quality of snapshot - not result

  let config = {
    filter: filter, //filter all <i> tags - not supported
    height: node.offsetHeight * scale,
    width: node.offsetWidth * scale,
    style: {
      transformOrigin: 'top left',
      transform: `scale(${scale})`,
    },
  }
  let image
  switch (settings.format) {
    case 'jpg':
      image = await domtoimage.toJpeg(node, config)
      break
    case 'png':
      image = await domtoimage.toPng(node, config)
      break
    case 'svg':
      image = await domtoimage.toSvg(node, config)
      break
    case 'pdf':
      image = await domtoimage.toPng(node, config)
      break
    default:
      image = await domtoimage.toPng(node, config)
      break
  }
  return await generateCanvas(image, settings)
}

async function generateCanvas(imageUrl, settings) {
  let { dpi, scale, size, format } = settings

  let totalWidth
  let totalHeight

  if (format === 'pdf') {
    totalWidth = size[0] * scale + 0.2 * 2 * 2
    totalHeight = size[1] * scale + 0.2 * 2 * 2
  } else {
    totalWidth = size[0] * scale
    totalHeight = size[1] * scale
  }

  let width = cmToPx(totalWidth, dpi)
  let height = cmToPx(totalHeight, dpi)

  let canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height

  let ctx = canvas.getContext('2d')

  await drawBackground(canvas, 'white')
  if (format === 'pdf') {
    await drawCorners(ctx, settings)
  }
  await drawAndCropImage(canvas, imageUrl, settings)

  //document.body.appendChild(canvas)
  return canvas.toDataURL(`image/${format}`)

  //convert(canvas, ctx)
}
function drawBackground(canvas, color) {
  let ctx = canvas.getContext('2d')
  ctx.fillStyle = color
  ctx.fillRect(0, 0, canvas.width, canvas.height)
}
async function drawAndCropImage(canvas, imageUrl, settings) {
  let ctx = canvas.getContext('2d')

  let img = new Image()
  img.src = imageUrl
  await img.decode() //takes time to load src

  //get sizes of the image
  let { size, dpi, scale, format } = settings
  let width = cmToPx(size[0] * scale + 0.2 * 2 * scale, dpi)
  let height = cmToPx(size[1] * scale + 0.2 * 2 * scale, dpi)

  //resize and landscape the image (rotate - if needed)
  let shouldRotate = img.width < img.height

  let imgCns = document.createElement('canvas')
  let imgCtx = imgCns.getContext('2d')
  imgCns.width = width
  imgCns.height = height

  if (shouldRotate) {
    imgCtx.rotate(-Math.PI / 2) //90deg
    imgCtx.drawImage(img, -height, 0, height, width)
  } else {
    imgCtx.drawImage(img, 0, 0, width, height)
  }

  //document.body.appendChild(imgCns)
  // Save the scaled and landscaped image
  let preparedImg = new Image()
  preparedImg.src = imgCns.toDataURL(`image/${settings.format}`)
  await preparedImg.decode() //takes time to load src

  if (format === 'pdf') {
    let cropLength = cmToPx(0.2 * (scale - 1), dpi)
    ctx.drawImage(
      preparedImg,
      cropLength,
      cropLength, // Start at x, y pixels from the left and the top of the image (crop),
      preparedImg.width - 2 * cropLength,
      preparedImg.height - 2 * cropLength, // "Get" a (w * h) area from the source image (crop),
      cmToPx(0.2, dpi),
      cmToPx(0.2, dpi), // Place the result at x, y in the canvas,
      cmToPx(size[0] * scale + 0.2 * 2, dpi),
      cmToPx(size[1] * scale + 0.2 * 2, dpi), // width and height (scale)
    )
  } else {
    let cropLength = cmToPx(0.2 * scale, dpi)
    ctx.drawImage(
      preparedImg,
      cropLength,
      cropLength, // Start at x, y pixels from the left and the top of the image (crop),
      preparedImg.width - 2 * cropLength,
      preparedImg.height - 2 * cropLength, // "Get" a (w * h) area from the source image (crop),
      0,
      0, // Place the result at x, y in the canvas,
      cmToPx(size[0] * scale, dpi),
      cmToPx(size[1] * scale, dpi), // width and height (scale)
    )
  }
}
function drawCorners(ctx, settings) {
  let { dpi, size, scale } = settings
  let width = cmToPx(size[0] * scale + 0.2 * 4, dpi)
  let height = cmToPx(size[1] * scale + 0.2 * 4, dpi)

  ctx.lineWidth = 1
  let rectWidth = cmToPx(0.2 + 0.2, dpi)
  ctx.rect(0, 0, rectWidth, rectWidth) //top-left
  ctx.rect(0, height - rectWidth, rectWidth, rectWidth) //top right
  ctx.rect(width - rectWidth, 0, rectWidth, rectWidth) //bottom-left
  ctx.rect(width - rectWidth, height - rectWidth, rectWidth, rectWidth) //bottom-right
  ctx.stroke()
}

function cmToPx(centimeters, dpi) {
  return (centimeters * dpi) / 2.54
}
export function downloadImage(name, url) {
  let link = document.createElement('a')
  link.download = name
  link.href = url
  link.click()
}
export async function downloadQr(format, size) {
  let node = document.getElementById("template");
  let height = node.offsetHeight
  let width = node.offsetWidth
  let config;

  document.getElementsByClassName("export-arrow-down")[0].classList.add("hidden");
  document.getElementsByClassName("export-loader-icon")[0].classList.add("visible");
  // center horizontaly portrait qr-code
  if (height > width) {
    let scale = size / height
    let finalWidth = width * scale
    let shift = ((size - finalWidth) / 2) / scale
    config = { style: { transform: `scale(${scale}) translateX(${shift}px)`, transformOrigin: "top left" }, width: size, height: size }
  } else if (height <= width) {
    // center verticaly landscape qr-code
    let scale = size / width
    let finalHeight = height * scale
    let shift = ((size - finalHeight) / 2) / scale
    config = { style: { transform: `scale(${scale}) translateY(${shift}px)`, transformOrigin: "top left" }, width: size, height: size }
  }
  let dataUrl;
  switch (format) {
    case "png":
      dataUrl = await domtoimage.toPng(node, config)
      break
    case "jpeg":
      dataUrl = await domtoimage.toJpeg(node, { ...config, bgcolor: "white" })
      break
    case "svg":
      dataUrl = await domtoimage.toSvg(node, { filter: filter, style: { transform: "scale(1)" } })
      break
    default:
      dataUrl = await domtoimage.toPng(node, config)
      break
  }
  // downloading
  let link = document.createElement('a');
  link.download = `export.${format}`;
  link.href = dataUrl;
  link.click();
  document.getElementsByClassName("export-arrow-down")[0].classList.remove("hidden");
  document.getElementsByClassName("export-loader-icon")[0].classList.remove("visible");
}

function filter(node) {
  return node.tagName !== 'i'
}

// Not used fn
//looping trough pixels is useless for converting to cmyk (look for: math vs profile converting)
// function convert(canvas, ctx) {
//   let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
//   let data = imageData.data
//   for (let i = 0; i < data.length; i += 4) {
//     data[i] = 255 - data[i] // red
//     data[i + 1] = 255 - data[i + 1] // green
//     data[i + 2] = 255 - data[i + 2] // blue
//   }
//   ctx.putImageData(imageData, 0, 0)
// }

export default downloadExport
