// @flow
import React, {useEffect} from 'react'
import {connect} from "react-redux";
import {Route} from "react-router-dom";
import PageLayout from "../layout/PageLayout";
import MainMenu from "./DashboardComponents/MainMenu";
import Homepage from "./DashboardComponents/Homepage";
import MyLibrary from "./DashboardComponents/MyLibrary/MyLibrary";
import QrStatistics from "./DashboardComponents/QrStatistics";
import Subscription from "./DashboardComponents/subscription/Subscription";
import SubscriptionOrderPage from "./DashboardComponents/subscription/SubscriptionOrderPage";
import SubscriptionPaymentPage from './DashboardComponents/subscription/SubscriptionPaymentPage';
import Orders from "./DashboardComponents/Orders";
import UserManagement from "./DashboardComponents/UserManagement";
import Support from "./DashboardComponents/Support";
import GeneralSettings from "./DashboardComponents/GeneralSettings";
import { fetchMyLibrary } from '../../store/myLibrary/myLibraryActions';
import './DashboardPage.scss'


function DashboardPage({ onFetchMyLibrary }) {
  useEffect( () => {
    onFetchMyLibrary()    
  }, [])

  return (
    <PageLayout>
      <div id="dashboard">
        <MainMenu/>
        <Route path="/app/dashboard/home" component={Homepage}/>
        <Route path="/app/dashboard/my-library" component={MyLibrary}/>
        <Route path="/app/dashboard/statistics" component={QrStatistics}/>
        <Route exact path="/app/dashboard/subscription" component={Subscription}/>
        <Route exact path="/app/dashboard/subscription/order" component={SubscriptionOrderPage} />
        <Route exact path="/app/dashboard/subscription/order/payment" component={SubscriptionPaymentPage} />
        <Route path="/app/dashboard/orders" component={Orders}/>
        <Route path="/app/dashboard/management" component={UserManagement}/>
        <Route path="/app/dashboard/support" component={Support}/>
        <Route path="/app/dashboard/my-account" component={GeneralSettings}/>
      </div>
    </PageLayout>
  )
}

const mapDispatch = (dispatch) => ({
  onFetchMyLibrary: () => dispatch(fetchMyLibrary()),
});

export default connect(null, mapDispatch)(DashboardPage)
