import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { Spinner } from 'reactstrap'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { FormattedMessage } from 'react-intl'
import { getUserSettings } from '../../../../store/auth/authSelectors'
import { getSubscriptionPeriod, getSubscriptionPlans, getSubscriptionPlanType } from '../../../../store/order/orderSelectors'
import { BACKEND_URL } from '../../../../utils/constants'
import SubscriptionPaymentForm from './SubscriptionPaymentForm.jsx'

import './SubscriptionPaymentPage.scss'

const stripePromise = loadStripe("pk_test_51JfUz1LZFXtl0EqzyVjJU1G5vMM3TVGcZMHwGQpzu5lThk1g1NuWlAEVvYqKZYGKHrs0GM7bhUkrrTB6tXi4iwkV00NmWRQsyx");

const SubscriptionPaymentPage = ({ userSettings, subscriptionPlans, subscriptionPlanType, subscriptionPeriod }) => {
  const [clientSecret, setClientSecret] = useState("");
  const [finalPrice, setFinalPrice] = useState("")
  const [status, setStatus] = useState("WORKING")
  const [period, setPeriod] = useState(subscriptionPeriod)
  const [currency, setCurrency] = useState(userSettings.currency.toLowerCase())
  const [subscriptionPlan, setSubscriptionPlan] = useState(subscriptionPlans[subscriptionPlanType.replaceAll(' ', '_')][period][currency])

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    const url = `${BACKEND_URL}/subscription-create`

    fetch(url,  {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        subscriptionPlan,
        userSettings,
        isBusinessPurchase: new URLSearchParams(window.location.search).get("isBusinessPurchase"),
      }),
    })
    .then((res) => res.json())
    .then((data) => {
      setClientSecret(data.clientSecret)
      setFinalPrice(data.finalPrice)
      setStatus("SUCCESS")
    })
    .catch(async error => {
      setStatus("ERROR")
    });
   }, []);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
   clientSecret,
   appearance,
  };

  return (
    clientSecret && status === 'SUCCESS' ? (
      <Elements options={options} stripe={stripePromise}>
        <SubscriptionPaymentForm email={userSettings.email} price={subscriptionPlan.price} currency={currency} finalPrice={finalPrice} />
      </Elements>
    ) : status === 'WORKING' ? (
      <div className="payment-confirmation-page">
        <div className="payment-confirmation">
          <h3>
            Payment
          </h3>
          <div className="text">
            Preparing payment...
          </div>
          <Spinner
            color="primary"
            size=""
          >
            Loading...
          </Spinner>
        </div>
      </div>
    ) : (
      <div className="payment-confirmation-page">
        <div className="payment-confirmation">
          <h3>
            Payment Error
          </h3>
          <div className="text">
            Try make order again
          </div>
          <button className="rect final-button">
            <FormattedMessage id="export.confirmation.another" />
            <svg width="14" height="9" viewBox="0 0 14 9" fill="none">
              <path d="M0.463623 4.17276H8.64544" stroke="white" strokeWidth="1.5" />
              <path fillRule="evenodd" clipRule="evenodd" d="M8.31812 0.0820312L13.2272 4.17294L8.31812 8.26385V0.0820312Z" fill="white" />
            </svg>
          </button>
        </div>
      </div>
    )
  )
}

const mapState = (state) => ({
  userSettings: getUserSettings(state),
  subscriptionPlans: getSubscriptionPlans(state),
  subscriptionPlanType: getSubscriptionPlanType(state),
  subscriptionPeriod: getSubscriptionPeriod(state),
})

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(SubscriptionPaymentPage)