import React from 'react';

function Envelope() {
    return (
        <svg width="22" height="18" viewBox="0 0 22 18" fill="#000" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.3823 0.462891H2.29597C1.11596 0.462891 0.170869 1.41864 0.170869 2.5987L0.160156 15.4134C0.160156 16.5935 1.11591 17.5492 2.29597 17.5492H19.3823C20.5623 17.5492 21.5181 16.5935 21.5181 15.4134V2.59865C21.5181 1.41864 20.5623 0.462891 19.3823 0.462891ZM19.3823 4.73446L10.8391 10.074L2.29597 4.73446V2.59865L10.8391 7.93816L19.3823 2.59865V4.73446Z"/>
        </svg>
    );
}

export default Envelope;
