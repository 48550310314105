import React, {useEffect, useState} from 'react'
import { FormattedMessage } from "react-intl";
import { utils, writeFile } from 'xlsx'
import {apiDeleteDesign, apiUpdateDesign, apiGetFeedbackData} from '../../../../api/api'
import {connect, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import useComponentVisible from "../../UserAccountsComponents/useComponentVisible";
import {
  setActiveLayer,
  setTemplateSubtype,
  setTemplateType, setEditorMode,
  setEditorName, resetActiveTemplate, resetTemplates
} from "../../../../store/card/cardActions";
import { getDesigns } from '../../../../store/myLibrary/myLibrarySelectors'
import { deleteDesign, updateDesign } from '../../../../store/myLibrary/myLibraryActions';
import EditText from "../../../common/EditText/EditText";
import Loader from "../../customization/common/Loader";
import {EDITOR_MODES} from "../../../../utils/constants";
import { Design, MicroPageType } from "../../../../ts/types";
import {loadDesignIntoEditor} from "../../../../services/design";
import './MyLibrary.scss';
import { getUserData } from '../../../../store/card/cardSelectors';
import { remove, getDynamicQrCodeById } from '../../../../api/qrcode'
import { prepareDataForExport } from '../../../../utils/helpers'

function MyLibrary({
  onSetActiveLayer,
  onSetTemplateType,
  onSetTemplateSubtype,
  onSetEditorName,
  onSetEditorMode,
  onResetActiveTemplate,
  onResetTemplates,
  designs,
  onDeleteDesign,
  onUpdateDesign,
  userData,
}) {
  // @ts-ignore
  const [designsList, setDesignsList] = useState(designs);
  const [type, setType] = useState(null)

  // Show / Hide dropdown menu
  const [ ref, isComponentVisible, setIsComponentVisible ] = useComponentVisible(null);
  const [expandedDropdownIndex, setExpandedDropdownIndex] = useState(null);


  useEffect( () => {
    const queryString = window.location.search;
    const filter = new URLSearchParams(queryString).get('filter');

    if (filter) {
      filterBy(filter)
    } else {
      setDesignsList(designs)
    }
  }, [designs])

  const toggleDropdownVisibility = (index) => {
    if (expandedDropdownIndex === index) {
      setExpandedDropdownIndex(null);
      setIsComponentVisible(false)
      return;
    }
    setExpandedDropdownIndex(index);
    setIsComponentVisible(true);
  }

  let history = useHistory();

  const openInEditor = async (item: Design, action) => {
    // TODO: switch active menu, but currenty calling onSetTemplateType will set currentTemplate again!
    // TODO: fix, doesnt work yet - https://app.clickup.com/t/2xjrrk0

    loadDesignIntoEditor(item)

    onSetEditorName(item.name);
    onSetTemplateType(item.type)
    onSetTemplateSubtype(item.subtype)
    onSetActiveLayer(null)
    onSetEditorMode(EDITOR_MODES.UPDATE)
    onResetActiveTemplate()
    onResetTemplates(item.type)

    if (action === 'export') {
      history.push('/app/editor?action=export');
    } else {
      history.push('/app/editor');
    }
  }

  const deleteDesign = async (item) => {
    if (item.dynamicQrCode) {
      await remove(userData.jwt, item.dynamicQrCode.id)
    }
    onDeleteDesign(item);
  }

  const updateName = async (item, name) => {
    // TODO: refactor - it directly mutates the object
    item.name = name;
    onUpdateDesign(item, name)
  }

  const filterBy = (type) => {
    if (type !== null) {
      if (type === 'static_qr' || type === 'dynamic_qr') {
        const filteredQrDesigns = designs.filter(design => design.type === 'qr_only')
        if (type === 'static_qr') setDesignsList(filteredQrDesigns.filter(design => design.dynamicQrCode === null))
        if (type === 'dynamic_qr') setDesignsList(filteredQrDesigns.filter(design => design.dynamicQrCode !== null))
      } else {
        setDesignsList(designs.filter(design => design.type === type))
      }
    } else {
      setDesignsList(designs)
    }
    setType(type)
  }

  const onExcelExport = async (item) => {
    let worksheetTitle = 'Feedback form'
    let preparedData
    let wb = utils.book_new()
    let ws
    
    if (item.dynamicQrCode.microPage.type === MicroPageType.FEEDBACK_FORM) {
      const feedbackFormId = item.dynamicQrCode.microPage.id
      const feedbackFormData = await apiGetFeedbackData(feedbackFormId)

      preparedData = prepareDataForExport(feedbackFormData.items)

      ws = utils.json_to_sheet(preparedData);
      utils.book_append_sheet(wb, ws, worksheetTitle)

      writeFile(wb, `${item.name}.xlsx`)
    }    
  }

  return (
    <div className="my-library mt-5">
      <div>
        <h2 className="text-center mb-5"><FormattedMessage id="dashboard.mainMenu.myLibrary"/></h2>
        <div className="filtration">
          <FormattedMessage id="dashboard.myLibrary.types"/>:
          {/* @ts-ignore */}
          <span className={(type === null) ? 'bold' : ''} onClick={() => filterBy(null)}><FormattedMessage id="dashboard.myLibrary.all"/></span>
          {/* @ts-ignore */}
          <span className={(type === 'bcard') ? 'bold' : ''} onClick={() => filterBy('bcard')}><FormattedMessage id="dashboard.myLibrary.biz"/></span>
          {/* @ts-ignore */}
          <span className={(type === 'sticker') ? 'bold': ''} onClick={() => filterBy('sticker')}><FormattedMessage id="dashboard.myLibrary.stickers"/></span>
          {/* @ts-ignore */}
          <span className={(type === 'label') ? 'bold' : ''} onClick={() => filterBy('label')}><FormattedMessage id="dashboard.myLibrary.labels"/></span>
           {/* @ts-ignore */}
          <span className={(type === 'static_qr' ) ? 'bold' : ''} onClick={() => filterBy('static_qr')}><FormattedMessage id="dashboard.myLibrary.static"/></span>
            {/* @ts-ignore */}
          <span className={(type === 'dynamic_qr') ? 'bold' : ''} onClick={() => filterBy('dynamic_qr')}><FormattedMessage id="dashboard.myLibrary.dynamic"/></span>
        </div>
      </div>
      <div className="d-flex justify-content-center">{designs.length < 1 && <div>
        <FormattedMessage id="dashboard.myLibrary.notFound"/>
      </div>}</div>

      <div>
        <div className='main-container'>
          <div className='grid-container'>
            {designsList.map((item) => {
              return (
                <div key={item.id} className={`card ${item?.thumbnail?.width === item?.thumbnail?.height ? 'card--1x' : item?.thumbnail?.width > item?.thumbnail?.height ? 'card--horizontal' : 'card card--vertical'}`}>
                  <div className='card__image' onClick={() => openInEditor(item, null)}>
                    <img
                      src={item?.thumbnail?.url}/>
                  </div>
                  <div className="thumbnail-meta">
                    <div className="name-overlay" style={{
                      fontSize: '12px',
                      lineHeight: '15px'
                    }}>
                      <div className="m-0 font-weight-bold">
                        {/*{item.name}*/}
                        <EditText text={item.name} template={item} onSubmit={updateName}/>
                      </div>
                      <div className="m-0" style={{
                        fontSize: '10px',
                      }}>
                        {item.type} / <FormattedMessage id="dashboard.myLibrary.edited"/>: { new Intl.DateTimeFormat('cs-CZ').format(new Date(item.updatedAt))}
                      </div>
                    </div>
                  </div>

                  <div
                    onClick={() => toggleDropdownVisibility(item.id)}
                    className="thumbnail-dropdown px-2 py-1"
                    style={{
                      position: 'absolute',
                      left: '10px',
                      top: '10px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      zIndex: '2'
                    }}>
                      <img src="/assets/icons/icons-list/dotshorizontal.svg" height="15"/>

                      {/* dropdown menu */}
                      {isComponentVisible && expandedDropdownIndex === item.id &&
                        <div ref={ref} className="" style={{
                          position: 'absolute',
                          left: '26px',
                          top: '5px',
                          background: '#FFF',
                          padding: '0px',
                          fontSize: '14px'
                        }}>
                          <div style={{
                            boxShadow: '5px 7px 12px rgb(0 14 23 / 39%)'
                          }}>
                            <ul className="thumbnail-dropdown-list" style={{
                              listStyle: 'none',
                              lineHeight: '30px',
                              width: 'max-content'
                            }}>
                              {/* TODO: Temporary disabled
                                <li className="thumbnail-dropdown-item" onClick={() => openInEditor(item, 'export')}>Export</li>
                              */}
                              <li className="thumbnail-dropdown-item" onClick={() => openInEditor(item, null)}>
                                <FormattedMessage id="dashboard.myLibrary.edit"/>
                              </li>
                              <li className="thumbnail-dropdown-item" onClick={() => deleteDesign(item)}><FormattedMessage id="dashboard.myLibrary.delete"/></li>
                              {item.dynamicQrCode && item.dynamicQrCode.microPage.type === MicroPageType.FEEDBACK_FORM && <li className="thumbnail-dropdown-item" onClick={() => onExcelExport(item)}>
                                <FormattedMessage id="dashboard.myLibrary.getFeedback"/>
                              </li>}
                            </ul>
                          </div>
                        </div>
                      }
                    </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapState = (state) => ({
	designs: getDesigns(state),
  userData: getUserData(state),
})

const mapDispatch = (dispatch) => ({
  onSetTemplateType: (type) => dispatch(setTemplateType(type)),
  onSetTemplateSubtype: (template) => dispatch(setTemplateSubtype(template)),
  onSetEditorName: (name) => dispatch(setEditorName(name)),
  onSetEditorMode: (mode) => dispatch(setEditorMode(mode)),
  onSetActiveLayer: (type) => dispatch(setActiveLayer(type)),
  onResetActiveTemplate: () => dispatch(resetActiveTemplate()),
  onResetTemplates: (type) => dispatch(resetTemplates(type)),
  onDeleteDesign: (design) => dispatch(deleteDesign(design)),
  onUpdateDesign: (design, name) => dispatch(updateDesign(design, name)),
})


export default connect(mapState, mapDispatch)(MyLibrary)

